<template>
	<div id="env-display" :class="classEnv">env: {{ $env.NODE_ENV }} / db: {{ dbName }}</div>
</template>

<script>
// Stores
import { useApiStore } from '@/stores/ApiStore'

export default {
	name: 'EnvDisplay',
	setup() {
		const apiStore = useApiStore()
		const generalApi = apiStore.loadApi('general')
		return { generalApi }
	},
	data() {
		return {
			dbName: '...',
		}
	},
	async created() {
		this.dbName = await this.getDbName()
	},
	computed: {
		classEnv() {
			return this.$env.isStaging ? 'staging' : ''
		},
	},
	methods: {
		async getDbName() {
			const { status, data, statusText } = await this.generalApi.dbName()
			return status == 200 ? data : `1-Error ${status}: ${statusText}`
		},
	},
}
</script>

<style lang="scss" scoped>
#env-display {
	position: fixed;
	right: 0;
	bottom: 0;
	font-size: 0.12rem;
	padding: 0 0.2rem;
	height: 0.2rem;
	line-height: 0.2rem;
	background: #fff;
}
#env-display.stage {
	color: $bad;
	background: #ff0;
}
</style>
