import { defineStore } from 'pinia'

export const usePageUserStore = defineStore('PageUserStore', {
	state: () => ({
		user: null,
		listNamePath: null, // When set, we'll display the list content instead of user
		// list: {
		// 	id: null,
		// 	namePath: null,
		// },
		listEditMode: false,
	}),
	getters: {
		userId() {
			return this.user ? this.user.id : null
		},
		isList() {
			return this.list && this.list.id
		},
		list() {
			if (this.listNamePath) {
				const list = this.user.lists.find(list => list.namePath == this.listNamePath)
				return list || {}
			} else {
				return {}
			}
		},
	},
	actions: {
		// Store user data
		storeUser(user) {
			this.user = user
		},

		// Set list by either id or namePath
		setList(namePath) {
			this.listNamePath = namePath
		},
		unsetList() {
			this.listNamePath = null
		},

		// Update lists whenever they change (add/delete)
		updateClusters({ listIds, roomIds, lists, rooms }) {
			if (!this.user) return
			this.user.listIds = listIds
			this.user.roomIds = roomIds
			this.user.lists = lists
			this.user.rooms = rooms
		},

		toggleListEditMode(bool) {
			this.listEditMode = bool
		},
	},
})
