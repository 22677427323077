// Detects if we're being rendered server-side
export const isSSR = typeof window === 'undefined'

// Detects is we're on the client side of the SSR setup (production)
export const isSSRClient =
	typeof window != 'undefined' ? !!document.getElementById('app').getAttribute('data-ssr') : false

export const isProd = process.env.NODE_ENV == 'production' && !process.env.VUE_APP_API_URL

// 1 transarent pixel
export const pixel =
	'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='
