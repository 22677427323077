<template>
	<!-- Plus icon dropdown (used in selection bar) -->
	<div v-if="minimal" class="act-cluster" :class="{ dark, disabled }">
		<BaseIcon name="plus" />
		<select v-model="cluster" tabindex="1" :disabled="disabled" @click="onClusterBtnClick">
			<option
				v-for="(option, i) in ddClusters"
				:key="i"
				:value="option.value"
				:disabled="option.disabled"
				:hidden="option.hidden"
				>{{ option.display }}</option
			>
		</select>
		<div v-if="containingClusterCount" class="count">
			{{ containingClusterCount }}
		</div>
	</div>

	<!-- Regular dropdown -->
	<FormDropdown
		v-else
		:class="{ minimal }"
		v-model="cluster"
		defaultDisplay="- Add to List / Room -"
		:options="ddClusters"
		:disabled="disabled"
		:debug="debug && debug > 1"
	/>
</template>

<script>
/*

This is either a dropdown (upload form) or a plus button (everywher else)
that lets the user add an itm to a list or room.

*/

// Stores
import { useMeStore } from '@/stores/MeStore'
import { useDialogStore } from '@/stores/DialogStore'

// Components
import FormDropdown from '@/components/FormDropdown'
import BaseIcon from '@/components/BaseIcon'

// Internal
import { nonUserBlock } from '@/use/General'
import { ddSep } from '@/use/Constants'

export default {
	name: 'ActionCluster',
	components: {
		FormDropdown,
		BaseIcon,
	},
	inject: ['$itmActions'],
	props: {
		itmIds: Array,
		minimal: Boolean,
		disabled: Boolean,
		modelValue: Object,
		dark: Boolean,
		// Ids of my own lists/rooms in which the itm is already present
		containingLists: Array,
		containingRooms: Array,
		callbacks: Object, // See defaultCallbacks
		debug: Boolean,
	},
	emits: ['update', 'update:modelValue'],
	setup() {
		const meStore = useMeStore()
		const dialogStore = useDialogStore()
		return { meStore, dialogStore }
	},
	data() {
		return {
			defaultCallbacks: {
				add: () => {},
				addSuccess: () => {},
				addError: () => {},
			},
		}
	},
	computed: {
		cluster: {
			get() {
				return this.modelValue
			},
			set(id) {
				const type = this.idToType(id)

				// Cleaner & more consistent to send null values than empty object.
				const updateVal = id ? { id, type } : null
				this.$emit('update', updateVal)
				this.$emit('update:modelValue', updateVal) // Required for v-model
			},
		},

		// Rooms dropdown
		ddClusters() {
			if (!this.$isLoggedIn) return []

			// Fetch active rooms
			const activeRooms = this.meStore.activeRooms.map(room => {
				const flag = this.containingRooms && this.containingRooms.includes(room.id) ? '★ ' : ''
				return {
					display: flag + 'Draft: ' + room.nameTruncated,
					value: room.id,
					type: 'room',
				}
			})

			// Fetch active lists
			const activeLists = this.meStore.activeLists.map(list => {
				const flag = this.containingLists && this.containingLists.includes(list.id) ? '★ ' : ''
				return {
					display: flag + list.nameTruncated,
					value: list.id,
					type: 'list',
				}
			})

			// Construct options
			const options = []
			const hasClusters = activeRooms.length || activeLists.length
			if (!hasClusters) {
				options.push({
					display: 'You don\t have any lists or rooms',
					value: null,
					disabled: true,
				})
				options.push(ddSep)
				options.push({
					display: ' 	About Rooms & Lists',
					value: 'about',
				})
			} else {
				options.push({
					display: 'Add to:',
					value: null,
				})
				options.push(ddSep)
			}
			if (activeRooms.length) options.push(...activeRooms)
			if (activeRooms.length && activeLists.length) options.push(ddSep)
			if (activeLists.length) options.push(...activeLists)
			options.push(ddSep)
			options.push({
				display: '+ Create New List',
				value: 'create-list',
			})
			options.push({
				display: '+ Create New Room',
				value: 'create-room',
			})
			return options
		},

		containingClusterCount() {
			if (!this.containingLists || !this.containingRooms) return 0
			return this.containingLists.length + this.containingRooms.length
		},

		_callbacks() {
			return Object.fromEntries(
				Object.keys(this.defaultCallbacks).map(key => {
					const func = this.callbacks[key] || function() {}
					return [key, func]
				})
			)
		},
	},
	watch: {
		cluster(newValue) {
			if (!newValue) return
			const { id, type } = newValue || {}
			if (id == 'about') {
				this.showDialogAboutRooms()
			} else if (id == 'create-list') {
				this.showDialogClusterCreate('list')
			} else if (id == 'create-room') {
				this.showDialogClusterCreate('room')
			} else if (id && this.minimal) {
				// The room dropdown can be part of a form (upload or edit)
				// where changes are saved after submitting the form. But when
				// we're dealing with a button (in carousel or selection bar),
				// changes are saved immediately.)
				const listIds = id && type == 'list' ? [id] : null
				const roomIds = id && type == 'room' ? [id] : null
				this.addToClusters({ roomIds, listIds })
			}
			if (this.minimal) this.cluster = null
		},
	},
	methods: {
		// Block non-logged in clicks.
		onClusterBtnClick(e) {
			if (!this.$isLoggedIn) {
				nonUserBlock('organize images')
				e.preventDefault()
				e.target.blur()
				window.focus()
			}
		},

		// About rooms dialog.
		showDialogAboutRooms() {
			this.dialogStore.dialog(
				[
					'<ul class="pad">',
					'<li>Lists are like visual playlists, use them to organize your collection.<br><a href="/themoenen/favorites" target="_blank">Here\'s an example</a></li>',
					'<li>Rooms are like digital exhibitions meant for sharing.<br><a href="/room/Bik01g5JZjti" target="_blank">Here\'s an example</a></li>',
					'<ul>',
				].join(''),
				{
					title: 'Rooms and Lists',
					html: true,
					hideCancel: true,
				}
			)
		},

		// Create room dialog
		showDialogClusterCreate(type) {
			this.dialogStore.dialogByName('DialogClusterCreate', {
				type,
				onConfirm: newCluster => {
					this.room = newCluster.id
					this.meStore.loadMyClusters()
				},
			})
		},

		// Action handler
		addToClusters({ roomIds, listIds }) {
			// Db
			this.$itmActions.addToClusters(
				{
					itmIds: this.itmIds,
					roomIds,
					listIds,
				},
				{
					onError: this._callbacks.addError,
					onSuccess: this._callbacks.addSuccess,
				}
			)

			// UI
			const clusterIds = [...(listIds || []), ...(roomIds || [])] // Same data structure as onSuccess
			this._callbacks.add({ clusterIds, listIds, roomIds })
		},

		// Get type from id
		idToType(id) {
			const cluster = this.ddClusters.find(option => option.value == id)
			return cluster ? cluster.type : null
		},
	},
}
</script>

<style scoped lang="scss">
// Plus icon dropdown
.act-cluster {
	width: 0.5rem;
	height: 0.5rem;
	position: relative;
	cursor: pointer;
}
.act-cluster:deep() .icn {
	stroke: $black;
	padding: 0.15rem;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

// Dark version
.act-cluster.dark:deep() .icn {
	stroke: $white-75;
}

// Disabeld state
.act-cluster.disabled:deep() .icn {
	stroke: $black-30;
}
.act-cluster.disabled.dark:deep() .icn {
	stroke: $white-20;
}
.act-cluster.disabled select {
	cursor: default;
}

// Dropdown
.act-cluster select {
	width: 100%;
	height: 100%;
	opacity: 0;
	cursor: pointer;
}

// Count
.act-cluster .count {
	position: absolute;
	font-size: $small;
	top: 0.27rem;
	left: 0.31rem;
	color: $black-50;
}

@media (hover: hover) {
	.act-cluster:hover:deep() .icn {
		stroke: $primary;
	}
}
</style>
