// Shared properties of all form items
export default {
	props: {
		modelValue: {
			type: [String, Number, Boolean, null],
			default: null,
			// required: true, // Had this in dropdown+radios... don't think this needs to be required.
		},
		label: {
			type: String,
			default: null,
		},
		error: {
			type: String,
			default: null,
		},
		// Link with info about the form item
		helper: {
			type: String,
			default: null,
		},
	},
	emits: ['update', 'update:modelValue'],
	data() {
		return {
			internalError: null,
			// Sometines a form component doesn't get a v-model value assigned,
			// for example in the artwork upload where values ara managed in
			// the Vuex store. We need this fallback so components like dropdown
			// remain functional.
			fallbackModelValue: '',
		}
	},
	computed: {
		theValue: {
			get() {
				return this.modelValue === null ? this.fallbackModelValue : this.modelValue
			},
			set(val) {
				this.fallbackModelValue = val

				// Cleaner & more consistent to send null values tam empty strings.
				const updateVal = val || val === false ? val : null
				this.$emit('update', updateVal) // Allows us to attach events to @update
				this.$emit('update:modelValue', val) // Required for v-model to work
			},
		},
		theError() {
			return this.error || this.internalError
		},
		classError() {
			return this.theError ? 'error' : ''
		},
	},
}
