/*

This module stores the different API instances.
- - -
import { useApiStore } from '@/stores/ApiStore'
const apiStore = useApiStore()
const generalApi = await apiStore.loadApi('general')
*/

import { defineStore } from 'pinia'
import { capitalize } from '@/use/StringMagic'
import * as ApiService from '@/services/ApiService'

export const useApiStore = defineStore('ApiStore', {
	state: () => ({
		pinia: null,
		router: null,
		apiInstances: {},
	}),
	actions: {
		// Store pinia & router immediately after creation via server-entry.js/client-entry.js.
		init(pinia, router) {
			this.pinia = pinia
			this.router = router
		},

		// Fetch any API instance, create it if missing
		loadApi(name) {
			if (!this.apiInstances[name]) {
				const apiClassName = capitalize(name) + 'Api'
				this.apiInstances[name] = new ApiService[apiClassName](this.pinia, this.router)
			}
			return this.apiInstances[name]
		},
	},
})
