// Admin controls for entities (delete etc.)
// Used in /admin/inspect and adminControls overlay panel

// Stores
import { useDialogStore } from '@/stores/DialogStore'
import { useApiStore } from '@/stores/ApiStore'

// Internal
import { prettyNr } from '@/use/StringMagic'
import flash from '@/use/FlashAlert'

class EntityActions {
	constructor(imperial) {
		this.dialogStore = useDialogStore()
		const apiStore = useApiStore()
		this.artistApi = apiStore.loadApi('artist')
		this.itmApi = apiStore.loadApi('itm')
		this.userApi = apiStore.loadApi('user')
		this.clusterApi = apiStore.loadApi('cluster')
		this.imperial = imperial
	}
}

export class ArtistActions extends EntityActions {
	// Delete artist
	delete(id) {
		this.dialogStore.confirmCritical(
			async () => {
				const { status, statusText, data: report } = await this.artistApi.delete(id)
				// prettier-ignore
				if (status == 200) {
				console.log('Deletion Report:\n', report)
				const deletedArtworks = report.artworks.deleted ? report.artworks.deleted.artworks : 0
				const deletedEndorsed = report.artworks.deleted ? report.artworks.deleted.endorsed : 0
				const deletedImages = report.artworks.deleted ? report.artworks.deleted.images : 0
				const modifiedUsers = report.artworks.modified ? report.artworks.modified.users : 0
				const modifiedLists = report.artworks.modified ? report.artworks.modified.lists : 0
				const modifiedRooms = report.artworks.modified ? report.artworks.modified.rooms : 0
				// prettier-ignore
				const reportText = [
						`<p>Name: ${report.name}</p>`,
						`Deleted:`,
						`<ul class="small bump-b">`,
						`	<li class="${deletedArtworks ? '' : 'dim'}">Artworks: ${prettyNr(deletedArtworks, this.imperial)}</li>`,
						`	<li class="${deletedEndorsed ? '' : 'dim'}">Endorsed Artworks: ${prettyNr(deletedEndorsed, this.imperial)}</li>`,
						`	<li class="${deletedImages ? '' : 'dim'}">Images: ${prettyNr(deletedImages, this.imperial)}</li>`,
						`</ul>`,
						`Affected:`,
						`<ul class="small bump-b">`,
						`	<li class="${modifiedUsers ? '' : 'dim'}">Users: ${prettyNr(modifiedUsers, this.imperial)}</li>`,
						`	<li class="${modifiedLists ? '' : 'dim'}">Lists: ${prettyNr(modifiedLists, this.imperial)}</li>`,
						`	<li class="${modifiedRooms ? '' : 'dim'}">Rooms: ${prettyNr(modifiedRooms, this.imperial)}</li>`,
						`</ul>`,
					]
				flash(reportText.join(''), {
					title: 'Artist Deleted',
					type: 'success',
					persistent: true,
					html: true,
				})
			} else {
				flash(statusText, {
					type: 'error',
					persistent: true,
					html: true,
				})
			}
			},
			{ actionKey: 'delete artist' }
		)
	}

	// Take down artist
	takeDown(id, callback) {
		this.dialogStore.confirm(async () => {
			const { status, data: report, statusText } = await this.artistApi.takeDown(id)
			if (status == 200) {
				this._displayArtistTakedownReport(report)
				callback(true)
			} else {
				// prettier-ignore
				flash(statusText, {
					type: 'error',
					persistent: true,
					html: true,
				})
				callback(null)
			}
		})
	}

	// Restore artist
	restore(id, callback) {
		this.dialogStore.confirm(async () => {
			const { status, data: report, statusText } = await this.artistApi.restore(id)
			if (status == 200) {
				this._displayArtistTakedownReport(report, true)
				callback(true)
			} else {
				// prettier-ignore
				flash(statusText, {
					type: 'error',
					persistent: true,
					html: true,
				})
				callback(null)
			}
		})
	}

	// Display report of artist take down
	_displayArtistTakedownReport(report, restore) {
		const { artworks, lists, rooms, users, promoted } = report
		const reportText = [
			`Artworks ${restore ? 'restored' : 'taken down'}: ${artworks}`,
			`Users affected: ${users}`,
			`Lists affected: ${lists}`,
			`Rooms affected: ${rooms}`,
			`Promoted artworks: ${promoted}`,
		]
		flash(reportText.join('<br>'), {
			title: (restore ? 'Restoration' : 'Take Down') + ' Report',
			type: 'success',
			persistent: true,
			html: true,
		})
	}
}

export class ItmActions extends EntityActions {
	// Delete artwork / image
	delete(id, entityType) {
		this.dialogStore.confirmCritical(
			async () => {
				const { status, data: report, statusText } = await this.itmApi.delete(id)
				if (status == 200) {
					console.log('Deletion Report:\n', report)
					// prettier-ignore
					const reportText = [
					report.title ? `<p>Title: ${report.title}</p>` : undefined,
						`Deleted:`,
						`<ul class="small bump-b">`,
						`	<li class="${report.deleted.artworks ? '' : 'dim'}">Artworks: ${prettyNr(report.deleted.artworks, this.imperial)}</li>`,
						`	<li class="${report.deleted.endorsed ? '' : 'dim'}">Endorsed Artworks: ${prettyNr(report.deleted.endorsed, this.imperial)}</li>`,
						`	<li class="${report.deleted.images ? '' : 'dim'}">Images: ${prettyNr(report.deleted.images, this.imperial)}</li>`,
						`	<li class="${report.deleted.artworks ? '' : 'dim'}">Artists: ${prettyNr(report.deleted.artworks, this.imperial)}</li>`,
						`</ul>`,
						`Affected:`,
						`<ul class="small bump-b">`,
						`	<li class="${report.modified.users ? '' : 'dim'}">Users: ${prettyNr(report.modified.users, this.imperial)}</li>`,
						`	<li class="${report.modified.lists ? '' : 'dim'}">Lists: ${prettyNr(report.modified.lists, this.imperial)}</li>`,
						`	<li class="${report.modified.rooms ? '' : 'dim'}">Rooms: ${prettyNr(report.modified.rooms, this.imperial)}</li>`,
						`	<li class="${report.modified.artists ? '' : 'dim'}">Artists: ${prettyNr(report.modified.artists, this.imperial)}</li>`,
						`</ul>`,
					]
					flash(reportText.join('\n'), {
						title: 'Artwork Deleted',
						type: 'success',
						persistent: true,
						html: true,
					})
				} else {
					flash(statusText, {
						type: 'error',
						persistent: true,
						html: true,
					})
				}
			},
			{ actionKey: 'delete ' + entityType }
		)
	}

	// Show B2 images
	showB2Images(itmId, viewNr) {
		this.dialogStore.dialogByName('DialogB2Images', { itmId, viewNr })
	}
}

export class UserActions extends EntityActions {
	// Delete user
	delete(id) {
		this.dialogStore.confirmCritical(
			async () => {
				const { status, data: report, statusText } = await this.userApi.delete(id)
				if (status == 200) {
					console.log('Deletion Report:\n', report)

					// prettier-ignore
					const reportText = [
						`<p>Name: ${report.name}</p>`,
						`Deleted:`,
						`<ul class="small bump-b">`,
						`	<li class="${report.deleted.lists ? '' : 'dim'}">Lists: ${prettyNr(report.deleted.lists, this.imperial)}</li>`,
						`	<li class="${report.deleted.rooms ? '' : 'dim'}">Rooms: ${prettyNr(report.deleted.rooms, this.imperial)}</li>`,
						`</ul>`,
						`Affected:`,
						`<ul class="small bump-b">`,
						`	<li class="${report.modified.contributed ? '' : 'dim'}">Contributed Artworks: ${prettyNr(report.modified.contributed, this.imperial)}</li>`,
						`	<li class="${report.modified.collected ? '' : 'dim'}">Collected Artworks: ${prettyNr(report.modified.collected, this.imperial)}</li>`,
						`	<li class="${report.modified.discovered ? '' : 'dim'}">Discovered Artists: ${prettyNr(report.modified.discovered, this.imperial)}</li>`,
						`</ul>`,
					]
					flash(reportText.join(''), {
						title: 'User Deleted',
						type: 'success',
						persistent: true,
						html: true,
					})
				} else {
					flash(statusText, {
						type: 'error',
						persistent: true,
						html: true,
					})
				}
			},
			{ actionKey: 'delete user' }
		)
	}
}

export class ClusterActions extends EntityActions {
	// Delete room
	delete(id, type) {
		this.dialogStore.confirmCritical(
			async () => {
				const { status, statusText, data: report } = await this.clusterApi.delete(id)
				if (status == 200) {
					console.log('Deletion Report:\n', report)
					// prettier-ignore
					const reportText = [
						`<p>Name: ${report.name}<br>`,
						`Owner: ${report.owner}</p>`,
						`Affected:`,
						`<ul class="small bump-b">`,
						`	<li class="${report.modified.artworks ? '' : 'dim'}">Artworks: ${prettyNr(report.modified.artworks, this.imperial)}</li>`,
						`	<li class="${report.modified.users ? '' : 'dim'}">Users: ${prettyNr(report.modified.users, this.imperial)}</li>`,
						`</ul>`,
					]
					flash(reportText.join(''), {
						title: 'Room Deleted',
						type: 'success',
						persistent: true,
						html: true,
					})
				} else {
					flash(statusText, {
						type: 'error',
						persistent: true,
						html: true,
					})
				}
			},
			{ actionKey: 'delete ' + type }
		)
	}
}
