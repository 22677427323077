<template>
	<div class="preview-loader">Loading{{ isAnim ? ' animation' : ' hi-res' }}</div>
</template>

<script>
export default {
	name: 'PreviewLoader',
	props: {
		isAnim: Boolean,
	},
}
</script>

<style scoped lang="scss">
.preview-loader {
	font-size: $small;
	line-height: 0.2rem;
	position: absolute;
	bottom: 0.05rem;
	left: 0.05rem;
	border-radius: 0.015rem;
	z-index: 1;
	display: block;
	background: $black;
	color: $white;
	padding: 0 0.05rem;
	opacity: 0.3;
	// animation: blink 1s infinite;
	// animation-delay: 5000ms;
}
</style>
