/*

Preload images into cache.
- - -
Currently not used anywhere. Its' not speeding up image loading
for some reason (to be further investigated) while also causing
a bottle neck on page load. Should only be applied after the
rest of the page has loaded successfully.
- - -

// Usage:
import { preloadImages } from '@/use/PreloadImages'
preloadImages({
	itms,
	imgSize: 'large',
})

*/

import { parseSrc } from '@/use/General'

// Once a new batch of itms is added to the itms array, we preload all images.
// - imgSize: small, medium, large, xlarge
export function preloadImages({ itms, imgSize }) {
	itms.forEach(itm => {
		const src = parseSrc({
			baseSrc: itm.views[0].src,
			imgSize,
		})
		// console.log('* preload', itm.views[0].src, src)
		const img = new Image()
		img.onload = e => {
			console.log('Preloaded:', e.path[0].currentSrc)
		}
		img.src = src
		// document.body.append(img)
		// img.style.position = 'absolute'
		// img.style.top = 0
		// img.style.width = '50px'
		// img.style['z-index'] = 10000
	})
}
