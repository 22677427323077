import { defineStore } from 'pinia'

// Stores
import { useSessionStore } from '@/stores/SessionStore'
import { useRouteStore } from '@/stores/RouteStore'

// Internal
import { useApiStore } from '@/stores/ApiStore'
import { lockScroll } from '@/use/Helpers'
import { debounce, destructureRoute } from '@/use/Helpers'
// import { domLog } from '@/use/Helpers'

export const useSearchStore = defineStore('SearchStore', {
	state: () => ({
		// API
		searchApi: null,

		// Search parameters
		entityType: null,
		entry: '',

		// Results
		results: [],
		total: null,
		page: null, // Pagination
		pageCount: null, // Pagination
		resultsCap: 10, // Autocomplete results.
		pageSizeAll: 30, // Paginated results.

		// Controllers
		active: false, // This disabled all watchers when the user clicks a resul link.
		listAll: false, // Controls if we're fetching autocomplete or deep-search results from API.
		overlay: false, // Whether the search UI is loaded as overlay or standalone page.
		processing: false, // True while we're waiting for API results.
		error: null, // When API returns error, it stores { status, statusText }
		forbiddenMsg: null, // Message that shows for non-users searching for forbidden results (users).

		// Memory
		// ogUrl: null, // The url path we should return to when exiting search.
		scrollbarWidth: 0, // When the underlying page has a scroll bar, we adjust the X position.
		prevRoute: { name: 'Home' }, // When you enter paginated results, route changes but X will still bring you back.

		// Other
		// Debounces the autocomplete.
		fastSearchDebouncer: null,
	}),
	getters: {
		pageSize() {
			return this.listAll ? this.pageSizeAll : this.resultsCap
		},
		navItems() {
			return [
				{
					display: 'Artists',
					onClick: () => this.switchCategory('artist'),
					sel: this.entityType == 'artist',
				},
				{
					display: 'Artworks',
					onClick: () => this.switchCategory('artwork'),
					sel: this.entityType == 'artwork',
				},
				{
					display: 'Rooms',
					onClick: () => this.switchCategory('room'),
					sel: this.entityType == 'room',
				},
				{
					display: 'Users',
					onClick: () => this.switchCategory('user'),
					sel: this.entityType == 'user',
				},
				{
					display: 'Lists',
					onClick: () => this.switchCategory('list'),
					sel: this.entityType == 'list',
				},
				{
					display: 'Labels',
					onClick: () => this.switchCategory('label'),
					sel: this.entityType == 'label',
				},
			]
		},
	},
	actions: {
		// Set entityType when page is mounted.
		setEntityType(entityType) {
			this.entityType = entityType || 'artist'
		},

		// Activate overlay.
		async activateOverlay() {
			// console.log('activateOverlay')
			// Lock route
			const sessionStore = useSessionStore()
			sessionStore.toggleMaintainScrollPos(true)
			const routeStore = useRouteStore()
			routeStore.lockRoute()
			this.$router.push({ name: 'SearchEntity', params: { entityType: 'artist' } })

			// Activate search
			this.scrollbarWidth = lockScroll(true)
			this.entityType = 'artist'
			this.overlay = true

			// Record where to go when we close the search overlay.
			this.setPrevRoute(this.$router.currentRoute.value)
		},

		// Deactivate overlay.
		async deactivateOverlay(resetRoute) {
			// console.log('deactivateOverlay')
			// Unlock route
			const routeStore = useRouteStore()
			await routeStore.unlockRoute(resetRoute)

			lockScroll(false)
			this.scrollbarWidth = 0
			this.overlay = false
		},

		// Toggle general active switch.
		activate() {
			this.active = true
		},
		deactivate() {
			this.active = false
		},

		exit() {
			if (this.overlay) {
				this.deactivateOverlay(true)
			} else {
				this.$router.replace(this.prevRoute)
			}
		},

		// Store page where we opened search so we can return.
		setPrevRoute(route) {
			// console.log('setPrevRoute', route)
			// We don't want to store the search route as
			// previous route because then we'd never exit.
			if (route.name == 'SearchEntity') return

			// Need to destructure, storing reactive
			// route object is trouble and causes errors.
			this.prevRoute = destructureRoute(route)
		},

		// Fast autocomplete indexed search (10 results).
		fastSearch() {
			// console.log('fastSearch')
			return this.doSearch({ listAll: false })
		},

		fastSearchDebounce() {
			if (!this.fastSearchDebouncer) {
				this.fastSearchDebouncer = debounce(this.fastSearch, 300)
			}
			this.fastSearchDebouncer()
		},

		// Full paginated search (30 / page).
		fullSearch(page) {
			// console.log('fullSearch', page)
			return this.doSearch({ listAll: true, page: page || 1 })
		},

		// Query database.
		// The options.listAll parameter defines what kind of search we do,
		// either fast (autocomplete) or full (regex).
		async doSearch(options) {
			// console.log(':: doSearch', this.entry, options)
			this.error = null

			// Abort empty searches.
			if (!this.entry) {
				this.reset(true)
				return
			}

			// Search
			const { listAll, page } = options || {}
			// We only want to switch the view before the query
			// when we're showing all results, because ther opposite
			// results in a flash with empty pagination.
			if (listAll) this.listAll = listAll
			this.processing = true
			if (!this.searchApi) {
				const apiStore = useApiStore()
				this.searchApi = apiStore.loadApi('search')
			}
			const { status, statusText, data } = await this.searchApi.getSearchResults(
				this.entry,
				this.entityType,
				{ listAll: this.listAll ? 1 : 0, page, pageSize: this.pageSize }
			)
			this.processing = false
			this.page = +page

			// Update the URL with the query.
			this.updateRoute(page) // !!!

			// Process results.
			if (status == 200) {
				this.results = data.results
				this.total = data.total
				this.pageCount = data.pageCount
			} else {
				console.error('err', data)
				this.clearResults()

				// Any HTTP error
				this.error = {
					status,
					statusText,
				}
			}

			// This needs to happen after the API call
			// to avoid ugly UI flashes.
			this.listAll = listAll
		},

		// Retry search after server error.
		retrySearch() {
			if (this.listAll) {
				this.fullSearch(this.page)
			} else {
				this.fastSearch()
			}
		},

		// Switch between categories (entityType).
		switchCategory(entityType) {
			this.clearResults()
			this.entityType = entityType
			this.fastSearch()
		},

		// Update URL.
		updateRoute(page) {
			// console.log('updateRoute')
			const query = this.entry && this.entry.length ? this.entry : ''

			// Using the router for this
			this.$router.replace({
				name: 'SearchEntity',
				params: { entityType: this.entityType, page },
				query: { q: query },
			})
		},

		setListAll(bool) {
			this.listAll = bool
		},

		// Clear results.
		clearResults() {
			this.results = []
			this.total = null
		},

		// Reset to blank search.
		reset(doUpdateRoute) {
			// console.log('reset')
			this.listAll = false
			this.entry = ''
			this.results = []
			this.total = null
			this.page = null
			this.pageCount = null
			if (doUpdateRoute) this.updateRoute()
		},
	},
})
