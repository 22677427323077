<template>
	<div class="act-logo" :class="{ dark, inverse }" @click="onClick">
		<BaseIcon name="arthur-emblem" />
	</div>
</template>

<script>
// Stores
import { useKeyStore } from '@/stores/KeyStore'
import { usePageHomeStore } from '@/stores/PageHomeStore'

// Components
import BaseIcon from '@/components/BaseIcon'

export default {
	name: 'ButtonBack',
	components: { BaseIcon },
	props: {
		to: {
			type: Object,
			default: () => {
				return {
					name: 'Home',
				}
			},
		},
		dark: Boolean,
		inverse: Boolean,
	},
	setup() {
		const keyStore = useKeyStore()
		return { keyStore }
	},
	computed: {
		isHomePage() {
			return ['Featured', 'Explore', 'About'].includes(this.$route.name)
		},
	},
	methods: {
		onClick() {
			// console.log('onLogoClick')
			if (this.isHomePage) {
				if (window.scrollY === 0) {
					const pageHomeStore = usePageHomeStore()
					pageHomeStore.resetWhiteWall()
				} else {
					window.scrollTo(0, 0)
				}
			} else if (this.keyStore.mod) {
				window.open(this.to.path)
			} else {
				// Home unless custom route is set.
				this.$router.push(this.to)
			}
		},
	},
}
</script>

<style scoped lang="scss">
.act-logo {
	width: 0.5rem;
	height: 0.5rem;
	padding: 0.15rem;
	cursor: pointer;
}

// Dark & inverse version
.act-logo.dark:deep() .icn,
.act-logo.inverse:deep() .icn {
	fill: $white-75;
}

@media (hover: hover) {
	.act-logo:hover:deep() .icn {
		fill: $primary;
	}

	// Inverse version
	.act-logo.inverse:hover:deep() .icn {
		fill: $primary-inverse;
	}
}
</style>
