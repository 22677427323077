<template>
	<div id="wormhole">
		<div class="hole">
			<div>
				<div>
					<div>
						<div>
							<div></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<router-link @click="onClick" :to="goRandom()">
			<div>psst</div>
		</router-link>
	</div>
</template>

<script>
export default {
	methods: {
		onClick() {
			window.scrollTo(0, 0)
		},
		goRandom() {
			if (this.$route.name == 'Artist') return { name: 'RandomArtist' }
			if (this.$route.name == 'Artwork') return { name: 'RandomArtwork' }
			return { name: 'Random' }
		},
	},
}
</script>

<style lang="scss" scoped>
#wormhole {
	width: 0.7rem;
	height: 0.7rem;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	display: flex;
	vertical-align: middle;
	align-items: center;
}
#wormhole a {
	width: 0.7rem;
	height: 0.7rem;
	display: flex;
	vertical-align: middle;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
}
#wormhole a div {
	text-align: center;
	margin: 0 auto;
	// margin:0.1rem auto 0 auto;
	color: $black;
	text-transform: uppercase;
	font-size: 0.12rem;
	letter-spacing: 0.02rem;
	transition: margin-bottom 200ms ease-out, opacity 100ms linear, line-height 200ms ease-out;
}

// Hole
#wormhole .hole {
	// background: rgba(0, 0, 0, 0.6);
	border: solid 0.01rem $black-15;
	overflow: hidden;
}
#wormhole .hole,
#wormhole .hole div {
	width: 100%;
	height: 100%;
	border-radius: 1rem;
	transition: transform 200ms ease-out, background-color 200ms linear;
}
#wormhole .hole div {
	transition: transform 200ms ease-out, background-color 200ms linear;
}

@media (hover: hover) {
	#wormhole:hover .hole {
		transform: rotate3d(1, 0, 0, 65deg) scale(1.7);
		background: rgba(0, 0, 0, 0.01);
		border-color: $black-05;
	}
	#wormhole:hover .hole div {
		transform: translateY(0.12rem);
		background: rgba(0, 0, 0, 0.01);
		border: solid 0.01rem $black-10;
		transition-duration: 300ms;
	}
	#wormhole:hover a div {
		margin-bottom: -0.8rem;
		line-height: 0.3rem;
		opacity: 0;
	}
}
</style>
