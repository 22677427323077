<template>
	<div class="subnav-wrap" :class="{ center }">
		<div ref="ref_subnav" class="subnav" :class="{ smooth }">
			<!-- Subnav with route-changing links -->
			<template v-if="routed">
				<router-link
					v-for="(link, i) in items"
					:key="i"
					:to="link.to"
					:class="{ exact }"
					@click="e => onClick(e)"
				>
					{{ link.display }}
				</router-link>
			</template>

			<!-- Subnav with in-page functionality -->
			<template v-else>
				<a
					href="#"
					v-for="(link, i) in items"
					:key="i"
					@click.prevent="e => onClick(e, link, i)"
					:class="{ 'router-link-active': selStates[i] }"
				>
					{{ link.display }}
				</a>
			</template>
		</div>
	</div>
</template>

<script>
// Stores
import { useWindowStore } from '@/stores/WindowStore'

export default {
	name: 'NavSub',
	props: {
		items: {
			type: Array,
			required: true,
		},
		// Use router or not
		routed: {
			type: Boolean,
			default: false,
		},
		// When this is set true, navigation will
		// only be highlighted with exact match
		// (as opposed to parent-match)
		exact: Boolean,
		// Center-align navigation
		center: Boolean,
	},
	setup() {
		const windowStore = useWindowStore()
		return { windowStore }
	},
	data() {
		return {
			offset: 0,
			smooth: false,

			// Non-routed functionality
			selStates: this.items.map(item => item.sel),
		}
	},
	computed: {
		// Sometimes the selection state is manipulated from
		// within the parent. This is the case for the login/signup/waitlist
		// page/dialog, where the navigation is not controlled by
		// the router (because it can exist inside a dialog) but still
		// needs to update with route changes.
		externalSelStates() {
			return this.items.map(item => item.sel)
		},
	},
	watch: {
		externalSelStates(newValue) {
			this.selStates = newValue
		},
	},
	mounted() {
		// Center selected nav item.
		const selectedNavItm = this.$refs.ref_subnav.querySelector('.router-link-active')
		this.centerSel(selectedNavItm)

		// Center again after fonts are loaded.
		document.fonts.ready.then(() => {
			this.centerSel(selectedNavItm)
			this.smooth = true
		})

		// Re-center on window resize
		this.windowStore.listen('resize', this.centerSel, { debounce: 300 })
	},
	methods: {
		onClick(e, link, i) {
			// Manually set state for non-router navigation.
			if (!this.routed) {
				this.selStates = Array(this.items.length)
				this.selStates[i] = true
				link.onClick()
			}

			// Center selected nav item.
			this.centerSel(e.target)
		},

		// Center selected nav item.
		// If element is passed, we center based on that element.
		// Otherwise we center based on previously stored offset value.
		centerSel(elm) {
			if (elm && elm.nodeType) this.calculateOffset(elm)
			if (this.$refs.ref_subnav) this.$refs.ref_subnav.scrollLeft = this.offset
		},

		calculateOffset(elm) {
			// Scroll the clicked/tapped element into position.
			const width = elm.clientWidth
			const wrapWidth = elm.parentNode.clientWidth
			const left = elm.offsetLeft
			// The distance between the link and left edge for it to be centered.
			const offsetViewport = (wrapWidth - width) / 2
			// The offset needed to center the link.
			this.offset = left - offsetViewport
		},
	},
}
</script>

<style lang="scss" scoped>
// Wrapper that lets you horizontally
// scroll on smaller screens.
.subnav-wrap {
	// background: pink;
	// border: solid 1px red;
	position: relative;
	height: 1rem;

	// Vertical compensation to have better touch surface for scrolling
	margin-top: -0.1rem;

	// Scroll behavior
	overflow: hidden;
	margin-left: calc(var(--pp) * -1);
	margin-right: calc(var(--pp) * -1);
}
// When the sub nav is at the top of the page,
// without the content-pad wrapper. This is mostly
// relevant for pages with the art grid.
.subnav-wrap.main {
	margin: calc(var(--pp) - 0.1rem) 0 0 0;
}
.subnav {
	left: 0;
	gap: 0.3rem;
	display: flex;
	justify-content: flex-start;
	flex-wrap: nowrap;

	// Vertical compensation to have better touch surface for scrolling
	margin-top: 0.1rem;

	// Scroll behavior
	max-width: 100vw;
	overflow-x: scroll;
	padding: 0 var(--pp) 1rem var(--pp);
}
.subnav-wrap:not(.center) .subnav {
	position: absolute;
}
.subnav.smooth {
	scroll-behavior: smooth;
}
.subnav-wrap.center .subnav {
	justify-content: center;
}
.subnav a {
	font-size: 0.16rem;
	height: 0.3rem;
	line-height: 0.3rem;
	font-weight: 400;
	position: relative;
	white-space: nowrap;
}
.subnav a,
.subnav a:not(.exact).router-link-active,
.subnav a.exact.router-link-exact-active {
	color: $black;
}
.subnav a:not(.exact).router-link-active,
.subnav a.exact.router-link-exact-active {
	font-weight: 600;
	cursor: default;
	pointer-events: none;
}
.subnav a:not(.exact).router-link-active::after,
.subnav a.exact.router-link-exact-active::after {
	content: '';
	display: block;
	width: 100%;
	height: 0.02rem;
	background: $primary;
	position: absolute;
	left: 0;
	bottom: 0;
}

@media (hover: hover) {
	.subnav a:hover {
		color: $primary;
	}
}

/* Sub navigations */
@media only screen and (max-width: $mobile) {
	.subnav {
		gap: 0.2rem;
	}
}
</style>
