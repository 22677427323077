<template>
	<div v-show="!show" ref="invisible-btn" @click="toggle(true)" class="invisible-btn"></div>
	<div v-show="show" id="adm-controls">
		<component
			:is="panelName"
			:entityType="entityType"
			:entity="entity"
			:viewNr="viewNr"
			@loadPanel="loadPanel"
			@hide="toggle(false)"
		></component>
	</div>
</template>

<script>
// Stores
import { useApiStore } from '@/stores/ApiStore'
import { useKeyStore } from '@/stores/KeyStore'

// Internal
import AdminPanel from '@/mixins/mx_AdminPanel'

// Panels
import Actions from '@/components/AdminControls/Actions'
import ImgRecoArtist from '@/components/AdminControls/ImgRecoArtist'
import ImgRecoArtwork from '@/components/AdminControls/ImgRecoArtwork'

export default {
	name: 'AdminControls',
	components: { Actions, ImgRecoArtist, ImgRecoArtwork },
	mixins: [AdminPanel],
	setup() {
		const keyStore = useKeyStore()
		const apiStore = useApiStore()
		const generalApi = apiStore.loadApi('general')

		return { keyStore, generalApi }
	},
	data() {
		return {
			show: false,
			panelName: 'Actions',
		}
	},
	created() {
		this.getShowStatus()
	},
	mounted() {
		this.keyStore.add('a', this.toggle)
	},
	methods: {
		// Check if showAdminControls is set to true for admin user
		async getShowStatus() {
			this.show = (await this.generalApi.getAdminControlStatus()).data
		},
		// Toggle panel
		toggle(toggle) {
			const setTo = typeof toggle == 'boolean' ? toggle : !this.show
			this.show = setTo
			this.generalApi.toggleAdminControls(setTo)
		},
		// Load different panel
		loadPanel(panelName) {
			console.log('loadPanel: ', panelName)
			this.panelName = panelName
		},
	},
}
</script>

<style scoped lang="scss">
.invisible-btn {
	width: 0.4rem;
	height: 0.4rem;
	position: fixed;
	bottom: 0;
	left: 0;
}

#adm-controls {
	max-width: 2.2rem;
	position: fixed;
	bottom: 0.1rem;
	left: 0.1rem;
	z-index: 35;
	background: #fff;
	border: solid 0.01rem $black-10;
	font-size: 0.13rem;
	border-radius: $br;
}
#adm-controls:deep() h3 {
	display: flex;
	align-items: flex-end;
	min-height: 0.2rem;
	line-height: 0.2rem;
}
#adm-controls:deep() h3 span {
	flex: 1 1;
}
#adm-controls:deep() .act-close {
	flex: 0 0;
	position: static;
	display: block;
	margin-left: 0.1rem;
}
</style>
