<template>
	<div id="flash-alert" :class="classFlash">
		<h3 v-if="flash.title">{{ flash.title }}</h3>
		<div v-if="flash.html" v-html="flash.message"></div>
		<div v-else>{{ flash.message }}</div>
		<FormButton
			v-if="flash.button && (flash.buttonRoute || flash.buttonAction)"
			id="button"
			:level="1"
			@click="onButtonClick(flash)"
			:value="flash.button"
		/>
		<ActionClose @click="flashStore.remove(flash.index)" />
	</div>
</template>

<script>
// Animation is handled in TheFlashWrap

// Stores
import { useFlashStore } from '@/stores/FlashStore'

// Components
import FormButton from '@/components/FormButton'
import ActionClose from '@/components/ActionClose'

export default {
	name: 'FlashAlert',
	components: {
		FormButton,
		ActionClose,
	},
	props: {
		flash: {
			type: Object,
			required: true,
		},
	},
	setup() {
		const flashStore = useFlashStore()
		return { flashStore }
	},
	data() {
		return {
			defaultExpiration: 3000,
			removerTimeout: null,
		}
	},
	computed: {
		classFlash() {
			const classes = []
			if (this.flash.type == 'error') classes.push('error')
			if (this.flash.type == 'success') classes.push('success')
			if (this.flash.blink) classes.push('blink')
			return classes.join(' ')
		},
	},
	mounted() {
		if (!this.flash.persistent) {
			const expiration = this.flash.expiration || this.defaultExpiration
			this.removerTimeout = setTimeout(() => {
				this.flashStore.remove(this.flash.index)
			}, expiration)
		}
	},
	beforeUnmount() {
		clearTimeout(this.removerTimeout)
	},
	methods: {
		onButtonClick(flash) {
			if (flash.buttonRoute) {
				this.$router.push(flash.buttonRoute)
			} else {
				flash.buttonAction()
			}
			this.flashStore.remove(flash.index)
		},
	},
}
</script>

<style lang="scss" scoped>
#flash-alert {
	background: white;
	min-height: 0.2rem;
	line-height: 0.2rem;
	padding: 0.2rem 0.6rem 0.2rem 0.3rem;
	border-radius: $br;
	box-shadow: 0 0 0.3rem $black-10;
	position: relative;
	max-width: min(100%, 6rem);
	// Float is needed to make sure alerts with
	// different widths don't affect each other's width.
	float: right;
	clear: right;
}
#flash-alert h3 {
	margin-bottom: 0.05rem;
}
#flash-alert:deep() .act-close {
	top: 0.05rem;
	right: 0.05rem;
}

#button {
	margin-top: 0.2rem;
}

/* Error & success states */
#flash-alert.error {
	position: relative;
	color: $bad;
}
#flash-alert.success {
	color: $good;
}
#flash-alert.error::before,
#flash-alert.success::before {
	content: '';
	position: absolute;
	top: 0.03rem;
	right: 0.03rem;
	bottom: 0.03rem;
	left: 0.03rem;
	border-radius: $br;
	opacity: 0.1;
	pointer-events: none;
}
#flash-alert.error::before {
	background: $bad;
}
#flash-alert.success::before {
	background: $good;
}

/* Blinking animation */
#flash-alert.blink {
	animation: blink 200ms 3;
	animation-delay: 200ms;
}
</style>
