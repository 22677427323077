<template>
	<div class="flag" :class="{ green, red }">{{ text }}</div>
</template>

<script>
export default {
	name: 'BaseFlag',
	props: {
		// draft / published / deleted / custom
		text: String,
		// green / red
		color: String,
	},
	data() {
		return {}
	},
	computed: {
		green() {
			return this.color == 'green' || this.text == 'published'
		},
		red() {
			return this.color == 'red' || this.text == 'deleted'
		},
	},
}
</script>

<style scoped lang="scss">
.flag {
	font-family: 'Barlow', sans-serif;
	background: $black-50;
	color: $white;
	display: inline-block;
	font-size: $small;
	line-height: 0.2rem;
	height: 0.2rem;
	border-radius: $br;
	text-transform: uppercase;
	font-weight: 500;
	padding: 0 0.05rem;
}
.flag.green {
	background: $good;
}
.flag.red {
	background: $bad;
}
</style>
