<template>
	<pre id="display">{{ data }}</pre>
</template>

<script>
export default {
	name: 'DebugDisplay',
	props: {
		data: {
			default: '-',
		},
	},
}
</script>

<style scoped lang="scss">
#display {
	font-size: 0.11rem;
	line-height: 0.12rem;
	background: $black-05;
	padding: 0.05rem;
	border-radius: $br;
	margin: 0.05rem 0 0 0;
	border: solid 0.01rem $black-05;
	white-space: break-spaces;
}
</style>
