// Meta Data
// - - -
// Uses @vueuse/head to generate and inject meta data
// See app.js, server-entry.js, server.js
// https://www.npmjs.com/package/@vueuse/head

import { useHead } from '@vueuse/head'
import { useRoute } from 'vue-router'

export function injectMetaData(data) {
	const route = useRoute()

	// Parse meta data
	let { title, description, imgSrc, imgWidth, imgHeight, noIndex } = data || {}
	const url = 'https://arthur.io' + route.fullPath

	// Fall back to defaults when value is missing
	const defaults = {
		title: 'Arthur.io • A Digital Museum',
		description: 'Arthur is a place to collect and discover art.',
		imgSrc: 'https://arthur.io/imgs/arthur-cover.jpg',
		imgWidth: 1200,
		imgHeight: 630,
	}
	title = title || defaults.title
	description = description || defaults.description
	imgSrc = imgSrc || defaults.imgSrc
	imgWidth = imgWidth || defaults.imgWidth
	imgHeight = imgHeight || defaults.imgHeight
	noIndex = noIndex ? { name: 'robots', content: 'noindex' } : {}

	// prettier-ignore
	useHead({
		title: title,
		meta: [
			// General Info
			{ name: 'description',			content: description },
			noIndex,

			// Open Graph (Facebook & Pinterest)
			{ property: 'og:title',			content: title },
			{ property: 'og:description',	content: description },
			{ property: 'og:type',			content: 'website' },
			{ property: 'og:url',			content: url },
			{ property: 'og:image',			content: imgSrc },
			{ property: 'og:image:type',	content: 'image/jpeg' },
			{ property: 'og:image:alt',		content: title },
			{ property: 'og:image:width',	content: imgWidth },
			{ property: 'og:image:height',	content: imgHeight },
			{ property: 'og:site_name',		content: 'Arthur' },

			// Twitter
			// https://developer.twitter.com/en/docs/twitter-for-websites/cards/overview/markup
			{ name: 'twitter:card',			content: 'summary_large_image' },
			{ name: 'twitter:site',			content: '@butisitarthur' },
			{ name: 'twitter:creator',		content: '@butisitarthur' },
			{ name: 'twitter:title',		content: title }, // Max 70
			{ name: 'twitter:description',	content: description }, // Max 200
			{ name: 'twitter:image:src',	content: imgSrc },
			{ name: 'twitter:image:alt',	content: title }, // Max 420
		],
	})
}

export function updateTitle(title) {
	if (typeof document !== 'undefined') document.title = title
}
