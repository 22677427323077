<template>
	<div id="wrap">
		<h3>Image Recognition<ActionClose :tiny="true" @click="exit" /></h3>
		<p>
			<b>Indexed:</b> {{ indexed }} / {{ entity.artworkCount }}<br />
			<b>Size:</b> {{ size }}<br />
			<span v-if="error" class="error-msg">{{ error }}</span>
		</p>
		<FormButton
			:level="1"
			:value="['Index', 'Indexing']"
			:wait="indexing"
			:small="true"
			@click="indexArtist"
		/>
	</div>
</template>

<script>
// Components
import ActionClose from '@/components/ActionClose'
import FormButton from '@/components/FormButton'

// Internal
import { imgRecoApi } from '@/services/ImgRecoService'
import AdminPanel from '@/mixins/mx_AdminPanel'
import { handleApiError } from '@/use/Helpers'

export default {
	name: 'AdminControlsImgReco',
	mixins: [AdminPanel],
	// inject: { artistApi: { default: {} } },
	components: { ActionClose, FormButton },
	data() {
		return {
			indexed: '...',
			size: '...',
			indexing: false,
			error: null,
		}
	},
	created() {
		if (this.entityType != 'artist') {
			return this.exit()
		}
		this.loadReport()
	},
	methods: {
		exit() {
			this.$emit('loadPanel', 'Actions')
		},
		async loadReport() {
			const { status, data: report, statusText } = await imgRecoApi.reportArtist(this.entity.id)
			if (status == 200 && report) {
				this.indexed = report.images
				this.size = report.size
			} else {
				handleApiError({ status, data: report, statusText, action: 'loading the report' })
			}
		},
		async indexArtist() {
			this.error = null
			this.indexing = true
			const { status, data, statusText } = await imgRecoApi.indexArtist(this.entity.id)
			this.indexing = false
			if (status == 200 && data) {
				if (data[0].success) {
					// This response shouldn't be array, [0] to be removed!
					this.loadReport()
				} else {
					this.error = 'Something went wrong.'
				}
			} else {
				handleApiError({ status, data, statusText, action: 'indexing this artist' })
			}
		},
	},
}
</script>

<style scoped lang="scss">
#wrap {
	padding: 0.2rem;
}
</style>
