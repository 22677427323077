import { defineStore } from 'pinia'

// Stores
import { useApiStore } from '@/stores/ApiStore'

// Internal
import flash from '@/use/FlashAlert'
import { handleApiError } from '@/use/Helpers'

export const usePageRoomStore = defineStore('PageRoomStore', {
	state: () => ({
		room: null,

		// Publishing
		forceShow: false, // To show the publish bar after publishing
		processing: false, // Controls waiting state
	}),
	getters: {
		roomId() {
			// getRoomId
			return this.room ? this.room.id : null
		},
	},
	actions: {
		// Load user
		async storeRoom(room) {
			this.room = room
		},

		// Clear artwork & artist data
		clearStore() {
			this.room = null
		},

		// Publish
		publish() {
			this._publish(true)
		},
		unpublish() {
			this._publish(false)
		},
		async _publish(doPublish) {
			if (this.processing) return
			this.processing = true

			const apiStore = useApiStore()
			const clusterApi = apiStore.loadApi('cluster')
			const { status, statusText } = await clusterApi.publish({ id: this.room.id, doPublish })
			if (status == 200) {
				this.room.isPublished = doPublish
				this.forceShow = false
				if (doPublish) {
					flash('This room has been published.', { type: 'success' })
				} else {
					flash('This room has been unpublished.', { type: 'success' })
				}
			} else {
				const action = doPublish ? 'unpublishing' : 'publishing'
				handleApiError({ status, statusText, action })
			}
			this.processing = false
		},

		// Publish as feature
		feature() {
			this._feature(true)
		},
		unfeature() {
			this._feature(false)
		},
		async _feature(doFeature) {
			if (this.processing) return
			this.processing = true

			const apiStore = useApiStore()
			const clusterApi = apiStore.loadApi('cluster')
			const { status, statusText } = await clusterApi.feature({
				id: this.room.id,
				doFeature,
			})
			if (status == 200) {
				this.room.featured = doFeature ? new Date() : null
				if (doFeature) {
					flash('This room has been featured on the Arthur editorial feed.', { type: 'success' })
				} else {
					flash('This room has been removed from the Arthur editorial feed.', { type: 'success' })
				}
			} else {
				const action = (doFeature ? 'featuring' : 'unfeaturing') + ' this room'
				handleApiError({ status, statusText, action })
			}
			this.processing = false
		},

		// Update privacy setting in database
		async togglePrivate() {
			const apiStore = useApiStore()
			const clusterApi = apiStore.loadApi('cluster')
			const response = await clusterApi.togglePrivate({
				id: this.room.id,
				state: this.room.isPrivate,
			})
			const { status, data, statusText } = response
			if (status == 200) {
				if (this.room.isPublished) {
					if (this.room.isPrivate) {
						flash('This room has been made private.', { type: 'success' })
					} else {
						flash('This room has been been public.', { type: 'success' })
					}
				}
			} else {
				const action = 'making this room ' + (status ? 'private' : 'public')
				handleApiError({ status, data, statusText, action })
			}
		},

		// Show publish bar after publishing
		showBar() {
			// console.log(22, 'showBar')
			this.forceShow = true
		},
		hideBar() {
			this.forceShow = false
		},
	},
})
