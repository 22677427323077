<template>
	<div id="art-box">
		<!-- <pre>{{ pageItmStore.previewLoading }}</pre> -->

		<div id="img-wrap" @click="openCarousel">
			<img id="img-main-placeholder" :src="imgPlaceholder" />
			<BaseLoading v-if="pageItmStore.imgLoading" />

			<!-- Hi-res -->
			<BaseImageArt
				:itm="itm"
				imgSize="large"
				itemprop="image"
				:viewNr="viewNr"
				@load="onImgLoad"
				:style="{ opacity: pageItmStore.imgLoading ? 0 : 1 }"
			/>

			<!-- Lo-res preview -->
			<!-- Needs to be above hi-res so we can show immediate feedback when swapping itm. -->
			<!-- We also need to keep it in the DOM (v-show) to avoid triggereing onLoad twice. -->
			<img
				v-show="pageItmStore.imgLoading"
				class="art"
				:src="pageItmStore.loadingSrc"
				@load="onPreviewLoad"
				:style="{ opacity: pageItmStore.previewLoading ? 0 : 1 }"
			/>
			<PreviewLoader
				v-if="pageItmStore.imgLoading && !pageItmStore.previewLoading"
				:isAnim="itm.views[viewNr].isAnim"
			/>
		</div>
	</div>
</template>

<script>
// Stores
import { useCarouselStore } from '@/stores/CarouselStore'
import { usePageItmStore } from '@/stores/PageItmStore'

// Components
import BaseImageArt from '@/components/BaseImageArt'
import BaseLoading from '@/components/BaseLoading'
import PreviewLoader from '@/components/PreviewLoader'

// Internal
import { imgPlaceholder } from '@/use/ImgPlaceholder'

export default {
	name: 'ItmArtBox',
	components: {
		BaseImageArt,
		BaseLoading,
		PreviewLoader,
	},
	props: {
		itm: Object,
		viewNr: {
			type: [String, Number],
			default: 0,
		},
	},
	emits: ['load'],
	setup() {
		const carouselStore = useCarouselStore()
		const pageItmStore = usePageItmStore()
		return { carouselStore, pageItmStore }
	},
	data() {
		return {}
	},
	computed: {
		imgPlaceholder() {
			// When you're on view (n) of itm A, and you go to itm B which doens't have view (n),
			// Vue will start computing this value before the viewNr is reset to its default 0.
			if (!this.itm.views[this.viewNr]) return ''
			return this.itm ? imgPlaceholder({ view: this.itm.views[this.viewNr], displaySize: 'large' }) : ''
		},
	},
	watch: {
		'itm.id'() {
			this.pageItmStore.startLoading()
			this.pageItmStore.startPreviewLoading()
		},
		viewNr() {
			this.pageItmStore.startLoading()
			this.pageItmStore.startPreviewLoading()
		},
	},
	methods: {
		onPreviewLoad() {
			this.pageItmStore.endPreviewLoading()
		},

		// Turn off loader once image src is loaded
		onImgLoad(e) {
			this.pageItmStore.endLoading()
			this.$emit('load', e)
		},

		openCarousel(e) {
			// If the user opens the carousel before we have
			// loaded the default  feed info, we wait until
			// we have teh info and then continue.
			if (!this.pageItmStore.defaultFeedInfo) {
				this.$watch('pageItmStore.defaultFeedInfo', () => {
					this.carouselStore.init(e.target, this.itm, this.pageItmStore.defaultFeedInfo)
				})
			} else {
				// Open immediately.
				this.carouselStore.init(e.target, this.itm, this.pageItmStore.defaultFeedInfo)
			}
		},
	},
}
</script>

<style scoped lang="scss">
/**
 * ART BOX
 */
#art-box {
	// background: $black-dark;
	width: 100%;
	max-height: calc(100vh - 1.2rem);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.4rem 0.4rem 0 0.4rem;
}
#art-box:deep() img {
	max-height: calc(100vh - 2rem);
	max-width: 100%;
	user-select: none;
}
#art-box:deep() img.loading {
	opacity: 0;
}

/**
 * This wrap contains the main itm image plus
 * a placeholder data image that enables browser
 * to set box height before the image loads
 */
#img-wrap {
	position: relative;
	font-size: 0;
	line-height: 0;
}
#img-wrap:deep() img.art {
	position: absolute;
	top: 0;
	left: 0;
	// width: 100%; /* Needed for when 2x image is loaded on hi-res screens */
	height: 100%; /* Needed for when 2x image is loaded on hi-res screens */
	background: transparent;
}

// Loader animation
#art-box #loader-anim {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/**
 * RESPONSIVE
 */

@media only screen and (max-width: $tablet) {
	#art-box {
		padding: 0;
		max-height: 100vh;
	}
	#art-box:deep() img {
		max-height: calc(100vh - 1.2rem);
		max-width: 100%;
		border-radius: 0;
	}
	#gallery {
		margin: 1rem 0;
		padding: 0 0.03rem;
		max-width: calc(100vw - 0.06rem);
	}
}
</style>
