<template>
	<div id="dialog">
		<AuthMain :isDialog="true" :landingPage="page" @exit="dialogStore.doCancel" />
	</div>
</template>

<script>
/*

// To use:
dialogStore.show('dialogAuth', { page: 'login' })
// login/signup/wait-list

*/

// Stores
import { useDialogStore } from '@/stores/DialogStore'

// Components
import AuthMain from '@/views/Auth/AuthMain'

export default {
	name: 'DialogAuth',
	components: { AuthMain },
	setup() {
		const dialogStore = useDialogStore()
		return { dialogStore }
	},
	computed: {
		page() {
			return this.dialogStore.params.page || 'login'
		},
	},
	mounted() {
		this.dialogStore.disableEnterKey()
	},
}
</script>
