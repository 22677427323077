// Flash alert

import { useFlashStore } from '@/stores/FlashStore'
import { isSSR } from '@/use/Base'

// Flash alert
export default function(message, options) {
	if (isSSR) return

	const flashStore = useFlashStore()

	// Note: some options are handled by the store, others by the template
	const {
		type,
		persistent,
		expiration,
		blink,
		title,
		html,
		name,
		button,
		buttonRoute,
		buttonAction,
	} = options ? options : {}

	const flash = {
		message,
		type,
		persistent,
		expiration,
		blink,
		title,
		html,
		name,
		button,
		buttonRoute,
		buttonAction,
	}

	flashStore.add(flash)
}
//
