<template>
	<label class="wrap" :class="{ disabled }" :style="style">
		<!-- Label text -->
		<div class="label-text">
			{{ isMedium ? 'Medium & Materials' : 'Labels'
			}}<span v-if="theError" class="error"> {{ theError }}</span
			><span v-else-if="isMedium">Free-form<FormToggle tiny v-model="freeForm"/></span>
		</div>

		<!-- Medium dropdown -->
		<FormDropdown
			v-if="isMedium"
			v-model="data.medium"
			:options="ddMedium"
			:margin="[0, 0, 5, 0]"
			:disabled="disabled"
		/>

		<!-- Freeform input -->
		<FormText
			v-if="isMedium && freeForm"
			v-model="data.materialsFF"
			type="textarea"
			placeholder="Materials (free-form)"
			:mB="0"
			maxlength="200"
			:disabled="disabled"
		/>

		<template v-else>
			<!-- Materials + substrate preview -->
			<div v-if="isMedium && showMaterialsPreview" id="materials-preview">
				{{ materialsPreviewText }}
			</div>

			<!-- List of selected labels -->
			<LabelList ref="labelList" :labels="data.labels" :editMode="true" @delete="deleteLabel" />

			<template v-if="isMedium">
				<!-- Materials input -->
				<FormAutocomplete
					ref="material"
					v-model="value.material"
					:placeholder="materialsPlaceholder"
					entityType="label"
					:filter="{ type: 'material' }"
					maxlength="40"
					:margin="[0, 0, showSubstrate ? 5 : 0, 0]"
					:lowercase="true"
					:preventKeys="preventKeys"
					:disabled="disabled"
					@select="selected => selectLabel(selected, 'material')"
					@create="createLabelDialog('material')"
					@error="error => (internalError = error)"
				/>
				<!-- Substrate input -->
				<FormAutocomplete
					v-if="showSubstrate"
					ref="substrate"
					v-model="value.substrate"
					:placeholder="substratePlaceholder"
					entityType="label"
					:filter="{ type: 'substrate' }"
					maxlength="40"
					:margin="[0, 0, 0, 0]"
					:lowercase="true"
					:preventKeys="preventKeys"
					:disabled="disabled"
					@select="selected => selectLabel(selected, 'substrate')"
					@create="createLabelDialog('substrate')"
					@error="error => (internalError = error)"
				/>
			</template>

			<!-- Label input  -->
			<FormAutocomplete
				ref="label"
				v-if="!isMedium"
				v-model="value.label"
				placeholder="Subject, style, period..."
				entityType="label"
				:filter="{ type: 'label' }"
				maxlength="40"
				:margin="[0, 0, 0, 0]"
				:lowercase="true"
				:preventKeys="preventKeys"
				:disabled="disabled"
				@select="selected => selectLabel(selected, 'label')"
				@create="createLabelDialog('label')"
				@error="error => (internalError = error)"
			/>
		</template>
		<DebugDisplay v-if="debug" :data="isMedium ? data : data.labels" />
	</label>
</template>

<script>
// NOTE #1: Make sure not to get confused with how we have content labels
// and also HTML input labels. The 'label-text' class is related to the latter.

import { useDialogStore } from '@/stores/DialogStore'

// Components
import FormText from '@/components/FormText'
import LabelList from '@/components/LabelList'
import FormAutocomplete from '@/components/FormAutocomplete'
import FormDropdown from '@/components/FormDropdown'
import DebugDisplay from '@/components/DebugDisplay'
import FormToggle from '@/components/FormToggle'

// Internal
import materialsText from '@/use/MaterialsText'
import FormItems from '@/mixins/mx_FormItems'
import FormItemsStyle from '@/mixins/mx_FormItemsStyle'
import { ddSep } from '@/use/Constants'

export default {
	name: 'FormLabels',
	mixins: [FormItems, FormItemsStyle],
	components: {
		FormText,
		DebugDisplay,
		FormAutocomplete,
		FormDropdown,
		LabelList,
		FormToggle,
	},
	props: {
		initialLabels: Array,
		initialMedium: String,
		initialMaterialsFF: String,
		isMedium: Boolean, // Adds additional medium input UI
		disabled: Boolean,
		debug: {
			type: [Boolean, Number],
			default: false,
		},
	},
	setup() {
		const dialogStore = useDialogStore()
		return { dialogStore }
	},
	data() {
		return {
			// List of labels
			// { value: 'pop art', type: 'style' }
			// Note: initial values can't be set here because
			// a) Dropdown default overwrites mediums and
			// b) Freeform materials require more logic
			data: {
				medium: null,
				labels: [],
				materialsFF: null,
			},

			// v-model for inputs
			value: {
				label: '',
				material: '',
				substrate: '',
			},

			// Freeform input toggle
			freeForm: false,

			// Medium dropdown contents
			// Keep in sync with artwork model in API.
			ddMedium: [
				{
					display: '- Medium -',
					selected: true,
					hidden: true,
				},
				{
					display: 'None',
					value: null,
				},
				ddSep,
				{
					display: 'Painting',
					value: 'painting',
				},
				{
					display: 'Photography',
					value: 'photography',
				},
				{
					display: 'Print',
					value: 'print',
				},
				{
					display: 'Work on paper',
					value: 'work-on-paper',
				},
				ddSep,
				{
					display: 'Sculpture',
					value: 'sculpture',
				},
				{
					display: 'Installation',
					value: 'installation',
				},
				{
					display: 'Mixed Media',
					value: 'mixed-media',
				},
				{
					display: 'Street Art',
					value: 'street-art',
				},
				ddSep,
				{
					display: 'Video',
					value: 'video',
				},
				{
					display: 'Digital',
					value: 'digital',
				},
				ddSep,
				{
					display: 'Architecture',
					value: 'acrhitecture',
				},
				{
					display: 'Jewelry',
					value: 'jewelry',
				},
				{
					display: 'Fashion',
					value: 'fashion',
				},
				{
					display: 'Tattoo',
					value: 'tattoo',
				},
				{
					display: 'Product Design',
					value: 'product-design',
				},
				ddSep,
				{
					display: 'Other',
					value: 'other',
				},
			],

			// Only alphabumeric keys allowed, plus some
			preventKeys: '[^a-zA-Z0-9&+\\-\\s]',
		}
	},
	computed: {
		// Only show materials preview when there's more than one material.
		showMaterialsPreview() {
			return this.data.labels ? this.data.labels.length > 1 : false
		},
		materialsPreviewText() {
			return materialsText(this.data.labels)
		},
		materialsPlaceholder() {
			let placeholder = 'Materials'
			// if (this.data.medium == 'painting') {
			// 	placeholder += ' (eg. oil)'
			// }
			return placeholder
		},
		substratePlaceholder() {
			let placeholder = 'Substrate'
			if (this.data.medium == 'sculpture' || this.data.medium == 'mixed-media') {
				placeholder += ' (eg. armature)'
			} else if (this.data.medium == 'painting') {
				placeholder += ' (eg. canvas)'
			} else if (this.data.medium == 'work-on-paper') {
				placeholder += ' (eg. cardstock)'
			} else if (this.data.medium == 'print') {
				placeholder += ' (eg. paper)'
			} else if (this.data.medium == 'photography') {
				placeholder += ' (eg. c-print)'
			} else if (this.data.medium == 'street-art') {
				placeholder += ' (eg. brick wall)'
			}
			return placeholder
		},
		// Whether to show substrate input (only for 2D works)
		showSubstrate() {
			return (
				!this.data.medium ||
				this.data.medium == 'painting' ||
				this.data.medium == 'photography' ||
				this.data.medium == 'print' ||
				this.data.medium == 'work-on-paper' ||
				this.data.medium == 'sculpture' ||
				this.data.medium == 'street-art'
			)
		},
	},
	watch: {
		// Clear labels when switching to freeform + emit.
		freeForm(newValue) {
			if (newValue) {
				this.data.labels = []
			} else {
				this.data.materialsFF = null
			}

			// Emit
			this.emitUpdate()
		},

		// Clear labels for certain mediums + emit.
		'data.medium'(newValue) {
			if (newValue == 'photography') {
				// Photography --> Remove all labels
				this.data.labels = []
			} else if (!this.showSubstrate) {
				// Non-2D --> Remove substrate labels
				this.data.labels = this.data.labels.filter(label => label.type != 'substrate')
			}

			// Emit
			this.emitUpdate()
		},

		// Emit freeform materials update.
		'data.materialsFF'() {
			this.emitUpdate()
		},
	},
	mounted() {
		this.data.labels = this.initialLabels || []
		this.data.medium = this.initialMedium
		if (this.initialMaterialsFF) {
			this.data.materialsFF = this.initialMaterialsFF
			this.freeForm = true
		}
	},
	methods: {
		// Select suggestion (click or keyboard)
		// labelType = label/material/substrate
		selectLabel(selected, labelType) {
			console.log(88, selected)
			// Check if label was already added
			let matchIndex = null
			this.data.labels.some((currentLabel, i) => {
				if (currentLabel.id == selected.id) matchIndex = i
			})
			if (matchIndex !== null) {
				// Already added --> flash
				this.$refs.labelList.flash(matchIndex)
			} else {
				// Add existing label
				this.data.labels.push({
					value: selected.value,
					id: selected.id,
					type: selected.meta,
				})

				// Emit
				this.emitUpdate()
			}
			// Clear input
			this.$refs[labelType].clearInput()
		},

		// Show dialog to create new label
		createLabelDialog(labelType) {
			// Making scope available for callbacks
			const that = this
			const submittedValue = this.value[labelType]
			this.dialogStore.dialogByName('DialogLabelCreate', {
				submittedValue,
				labelType,
				defaultType: labelType == 'label' ? null : labelType, // Default type
				onConfirm(cbData) {
					// New label was created
					that.data.labels.push({
						value: cbData.value,
						type: cbData.type,
						id: cbData.id,
					})
					that.$refs[labelType].clearInput()
					that.$refs[labelType].focus()

					// Emit
					that.emitUpdate()
				},
				onCancel() {
					// Cancel
					that.$refs[labelType].select()
				},
			})
		},

		// Delete label
		deleteLabel(i) {
			this.data.labels.splice(i, 1)

			// Emit
			this.emitUpdate()
		},

		//
		//

		// Emit update event
		emitUpdate() {
			let emitData = {}

			// Reduce data to { labels } or { materialLabels, medium, materialsFF }
			const labels = this.data.labels.map(label => label.id)
			if (this.isMedium) {
				emitData.materialLabels = labels
				emitData.medium = this.data.medium
				emitData.materialsFF = this.data.materialsFF || null
			} else {
				emitData = labels
			}
			this.$emit('update', emitData)
		},

		// External
		select() {
			this.$refs.label.select()
		},
		focus() {
			this.$refs.label.focus()
		},
	},
}
</script>

<style src="../assets/css/form-items.scss" lang="scss" scoped></style>

<style scoped lang="scss">
// Lowervcase labels
.wrap:deep() input {
	text-transform: lowercase;
}

// Free-form Toggle
.label-text:deep() .wrap {
	// overriding form-items.css
	display: block;
	width: auto !important; // Responsive css applies general .wrap styling
	margin: 0 0 0 0.1rem;
	float: right;
}
label.wrap:deep() .form-elm.tiny {
	margin-right: 0;
}

// Anchor for suggestions
.tray-anchor {
	position: relative;
	top: 0.05rem;
	display: none;
}
.tray-anchor.show {
	display: block;
}

// Materials preview text
#materials-preview {
	font-style: italic;
	font-size: $regular;
	line-height: $regular-line-height;
	// background: #fff;
	min-height: 0.4rem;
	border: solid 0.01rem $black-15;
	padding: 0 0.13rem;
	border-radius: $br;
	font-family: 'Barlow', sans-serif;
	font-weight: 400;
	padding: 0.1rem 0.13rem;
	margin-bottom: 0.05rem;
}
</style>
