<template>
	<div id="footer">
		© {{ new Date().getFullYear() }} Arthur &nbsp;&nbsp;•&nbsp;&nbsp; Built by
		<a target="_blank" href="https://twitter.com/themoenen">@themoenen</a>
		<span class="rsp">&nbsp;&nbsp;•&nbsp;&nbsp;</span><br />
		<router-link :to="{ name: 'LegalTerms' }">Terms &amp; Policies</router-link>
		&nbsp;&nbsp;•&nbsp;&nbsp;
		<router-link :to="{ name: 'CatalogIndex' }">Artist Index</router-link>
		&nbsp;&nbsp;•&nbsp;&nbsp;
		<router-link :to="{ name: 'Contact' }">Contact</router-link>
	</div>
</template>

<style lang="scss" scoped>
#footer {
	height: 0.9rem;
	text-align: center;
	font-size: 0.11rem;
	line-height: 0.2rem;
	// margin-top: -0.9rem;
}
#footer.left {
	text-align: left;
	padding: 0 0.6rem;
}
#footer br {
	display: none;
}
#footer,
#footer a {
	color: rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: $bp-grid-pad) {
	#footer br {
		display: inline;
	}
	#footer .rsp {
		display: none;
	}
}

// Keep insync with main-layout.scss
@media only screen and (max-width: $mobile) {
	#footer.left {
		padding: 0 0.3rem;
	}
}
</style>
