import { isSSR } from '@/use/Base'
import { createCanvas } from 'canvas'

// Placeholder image for artwork page that supports the page
// structure while the rest of the page loads. This is needed
// to avoid CLS on page load, which is problematic on artwork
// page for our search ranking - https://web.dev/cls/
export const imgPlaceholder = function({ view, displaySize }) {
	const { h, w } = _defineSize({ view, displaySize })

	let canvas
	if (isSSR) {
		canvas = createCanvas(w, h)
	} else {
		canvas = document.createElement('canvas')
		canvas.width = w
		canvas.height = h
	}

	// Add a random non-transparent pixel to make sure the image always
	// reloads when cycling the carousel and images have the same size.
	const ctx = canvas.getContext('2d')
	ctx.beginPath()
	const x = Math.floor(Math.random() * w)
	const y = Math.floor(Math.random() * h)
	ctx.rect(x, y, 1, 1)
	ctx.fillStyle = 'rgba(250,250,250,.01)'
	ctx.fill()

	const dataUrl = canvas.toDataURL('image/png')
	return dataUrl
}

// Based on the requested image size (medium, large-2x, etc.)
// we calculate the exact image size before it is returned from
// the server, by mirroring the API's resize logic.
// - - -
// Keep in sync with API:
// img/imgprocessor --> parseSize()
function _defineSize({ view, displaySize }) {
	const { h: ogH, w: ogW } = view
	let h, w
	const vertical = ogH > ogW

	let inside = null // Used for large & up
	let maxHeight = null // Used for medium-2x and down
	if (displaySize == 'xlarge-2x' || displaySize == 'xlarge' || displaySize == 'large-2x') {
		inside = 2400
	} else if (displaySize == 'large') {
		inside = 1200
	} else if (displaySize == 'medium-2x') {
		maxHeight = 1400
	} else if (displaySize == 'medium') {
		maxHeight = 700
	} else if (displaySize == 'small-2x') {
		maxHeight = 500
	} else if (displaySize == 'small') {
		maxHeight = 250
	}

	if (inside) {
		if (vertical) {
			h = Math.min(inside, ogH)
			w = Math.round(ogW * (h / ogH))
		} else {
			w = Math.min(inside, ogW)
			h = Math.round(ogH * (w / ogW))
		}
	} else if (maxHeight) {
		h = maxHeight
		w = Math.round((ogW * maxHeight) / ogH)
	} else {
		h = ogH
		w = ogW
	}

	// console.log('#' + h, w)
	return { h, w }
}
