// The same props are required for each panel
export default {
	// For parent and panels
	props: {
		entityType: {
			type: String,
			required: true,
		},
		entity: {
			type: Object,
			required: true,
		},
		// Artwork only
		viewNr: {
			type: Number,
			default: 0,
		},
	},

	// Only for panels
	emits: ['loadPanel'],
}
