<template>
	<!-- <pre style="font-size:0.13rem; line-height:0.2rem;">/{{ flashIndex }}/</pre> -->
	<div class="label-wrap" :class="{ center }">
		<BaseLabel
			v-for="(label, i) in labels"
			:key="i"
			:label="label"
			:editMode="editMode"
			:class="{ flash: i == flashIndex }"
			:to="{ name: 'Label', params: { label: label.value } }"
			@delete="$emit('delete', i)"
		/>
	</div>
</template>

<script>
import BaseLabel from '@/components/BaseLabel'

export default {
	name: 'LabelList',
	components: { BaseLabel },
	props: {
		labels: Array,
		editMode: Boolean,
		center: Boolean,
	},
	data() {
		return {
			flashIndex: null,
		}
	},
	methods: {
		// Used for upload form, when user adds a label that was already added, it flashes
		flash(i) {
			this.flashIndex = i
			setTimeout(() => {
				this.flashIndex = null
			}, 600)
		},
	},
}
</script>

<style scoped lang="scss">
.label-wrap {
	display: flex;
	flex-wrap: wrap;
}
.label-wrap.center {
	justify-content: center;
}
.label-wrap .label {
	margin: 0 0.05rem 0.05rem 0;
}
.label-wrap:deep() .label:last-child {
	margin-right: 0;
}
</style>
