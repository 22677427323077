<template>
	<div v-if="outdated && !hide" id="old-browser">
		<!-- {{ browser }} -->
		We're sorry if things look wonky,<br />
		you're using an outdated browser<br /><span> - </span>
		<a href="#" @click.prevent="showDialogBrowser">Read more</a> /
		<a href="#" @click.prevent="dismiss">Dismiss</a>
		<ActionClose @click.prevent="dismiss" />
	</div>
</template>

<script>
// Stores
import { useDialogStore } from '@/stores/DialogStore'

// Modules
import Bowser from 'bowser'
import Cookies from 'universal-cookie'

// Components
import ActionClose from '@/components/ActionClose'

export default {
	name: 'OldBrowsers',
	components: {
		ActionClose,
	},
	data() {
		return {
			bowser: null, // Not a typo, holds the class
			browser: null,
			hide: true, // Set to false under mounted()
			cookies: new Cookies(),
		}
	},
	computed: {
		// The most recent-tech feature we use is flexbox gap, which unsupported on not-too-old safari when your Mac is out of date.
		// So we use this as our reference to warn about your browser: https://caniuse.com/flexbox-gap
		outdated() {
			if (!this.browser) return false

			if (this.browser.name.match(/Internet Explorer|UC Browser|QQ Browser|KaiOS Browser/)) {
				// Invalid browsers.
				return true
			} else if (this.browser.name.match(/Chrome|Safari|Firefox|Opera|Microsoft Edge/)) {
				// Major browsers by version number.
				const valid = this.bowser.satisfies({
					// System-specific browsers
					macos: {
						safari: '>=14.1',
					},
					windows: {
						edge: '>=84',
					},

					// Mobile
					mobile: {
						chrome: '>=1',
						safari: '>=14.5',
						opera: '>=13',
						'android browser': '>=5',
						firefox: '>=1',
					},

					// General
					chrome: '>=84',
					firefox: '>=63',
					opera: '>=73',
				})

				return !valid
			} else {
				// Unknown browsers don't get a message.
				return false
			}
		},

		// When your browser is not supported, or when your browser is (probably) tied to your outdated
		// system (Safari/Edge), we'll suggest to use a different browser (instead of just update).
		needOtherBrowser() {
			if (!this.browser) return false

			return !!this.browser.name.match(
				/Internet Explorer|UC Browser|QQ Browser|KaiOS Browser|Safari|Microsoft Edge/
			)
		},

		// Dialog message
		message() {
			if (!this.outdated) return null

			const msg = [
				`<h3>The Bad News</h3>`,
				`<p>Arthur uses some of the latest web technologies and we don't have the resources to support older browsers.</p>`,
				`<h3>The Good News</h3>`,
			]
			if (this.needOtherBrowser) {
				const safariMsg =
					this.browser.name == 'Safari'
						? ` We don't like Safari, because they won't let you upgrade when your system is out of date, forcing you to buy a new computer.`
						: ''
				msg.push(
					`<p>You can simply download a more recent browser, like <a target="_blank" href="https://www.google.com/chrome/downloads/">Chrome</a>.</p>`,
					safariMsg ? `<p>${safariMsg}</p>` : ''
				)
			} else {
				msg.push(`<p>You can simply update your browser.</p>`)
			}
			return msg.join('')
		},
	},
	mounted() {
		this.hide = !!this.cookies.get('hideBrowserWarning')

		// Get browser version
		this.bowser = Bowser.getParser(window.navigator.userAgent)
		this.browser = this.bowser.getBrowser()
		// Output:
		// {
		//	 name: "Internet Explorer"
		//	 version: "11.0"
		// }
	},
	methods: {
		showDialogBrowser() {
			const dialogStore = useDialogStore()
			dialogStore.dialog(this.message, { html: true, hideCancel: true })
		},
		dismiss() {
			console.log('dismiss')
			this.hide = true

			// Store cookie
			this.cookies.set('hideBrowserWarning', true, {
				path: '/',
				maxAge: 60 * 60 * 24 * 180, // 6 months
			})
		},
	},
}
</script>

<style scoped lang="scss">
#old-browser {
	min-height: 0.24rem;
	line-height: 0.16rem;
	width: 100%;
	background: $bad;
	color: $white;
	font-size: $small;
	font-weight: 500;
	text-align: center;
	padding: 0.04rem 0.3rem;
}
#old-browser a {
	color: $gold;
}
#old-browser .act-close {
	width: 0.24rem;
	height: 0.24rem;
	padding: 0.02rem;
}
#old-browser .act-close:deep() .icn {
	fill: $white;
}

#old-browser br {
	display: none;
}
@media only screen and (max-width: 560px) {
	#old-browser {
		padding: 0.08rem 0.3rem;
	}
	#old-browser .act-close {
		margin: 0.05rem;
	}
	#old-browser br {
		display: inline;
	}
	#old-browser span {
		display: none;
	}
}
</style>
