<template>
	<div id="sel-bar">
		<!-- Collect / Uncollect button -->
		<ActionCollect
			:itmIds="selectedItmIds"
			:collected="allCollected"
			:alreadyCollected="collected.length"
			:callbacks="collectCallbacks"
		/>

		<!-- Remove button for lists & rooms -->
		<ActionRemove
			v-if="feedStore.isMyCluster"
			:class="{ add: noneInCluster, room: feedStore.isRoom, list: feedStore.isList }"
			:forceState="noneInCluster ? 'add' : null"
			@click="noneInCluster ? onAddToCluster([entityId]) : removeFromCluster(entityId)"
		/>

		<!-- Cluster button -->
		<ActionCluster
			v-model="cluster"
			:itmIds="selectedItmIds"
			:minimal="true"
			:callbacks="clusterCallbacks"
		/>

		<!-- Menu -->
		<!-- <ActionMenu :options="menuOptions" /> -->

		<!-- Display (text via css ::after) -->
		<div id="display"></div>

		<div class="buffer"></div>
		<div class="count">{{ count }} Selected</div>
		<ActionClose @click="exitSelMode" :inline="true" />
	</div>
</template>

<script>
// Stores
import { useApiStore } from '@/stores/ApiStore'
import { useFeedStore } from '@/stores/FeedStore'
import { useKeyStore } from '@/stores/KeyStore'

// Components
import ActionClose from '@/components/ActionClose'
import ActionCluster from '@/components/ActionCluster'
import ActionCollect from '@/components/ActionCollect'
import ActionRemove from '@/components/ActionRemove'
// import ActionMenu from '@/components/ActionMenu'

export default {
	name: 'TheSelectionBar',
	components: {
		ActionClose,
		ActionCluster,
		ActionCollect,
		ActionRemove,
		// ActionMenu,
	},
	inject: ['$itmActions'],
	setup() {
		const feedStore = useFeedStore()
		const keyStore = useKeyStore()
		const apiStore = useApiStore()
		const itmApi = apiStore.loadApi('itm')

		return { feedStore, keyStore, itmApi }
	},
	props: {
		entityId: String, // The id of the entity whose feed this is (artist/user/room)
	},
	data() {
		return {
			cluster: null,
			collectCallbacks: {
				collect: this.onCollect,
				collectError: this.undoCollect,
				uncollect: this.onUncollect,
				uncollectError: this.undoUncollect,
				uncollectCancel: this.undoUncollect,
			},
			clusterCallbacks: {
				add: this.onAddToCluster,
				addError: this.feedStore.reselect,
			},
			menuOptions: [
				{
					value: 'download',
					display: 'Download',
					onSelect: () => {},
					wall: 'download images',
				},
			],
		}
	},
	computed: {
		count() {
			return this.feedStore.selected.length
		},
		allItms() {
			return this.feedStore.itms
		},
		selectedItms() {
			return this.feedStore.selected
		},
		selectedItmIds() {
			return this.selectedItms.map(itm => itm.id)
		},
		// Array: List of selected itms that has already been collected.
		collected() {
			return this.selectedItms.filter(itm => itm.collected)
		},
		// Array: List of selected itms that are in current list/room.
		notInCluster() {
			return this.selectedItms.filter(itm => itm.removed)
		},
		// Boolean: If any of the selected itms have already been collected.
		someSollected() {
			return this.collected.length > 0
		},
		// Boolean: If all selected itms have already been collected.
		allCollected() {
			return this.collected.length == this.selectedItms.length
		},
		// Boolean: If all selected itms are in the current list/room.
		noneInCluster() {
			return this.notInCluster.length == this.selectedItms.length
		},
	},
	watch: {
		// Because the selectionBar is toggled with v-show,
		// we need to manually reset the cluster dropdown.
		'feedStore.selMode'(newValue) {
			if (newValue) {
				this.cluster = null
			}
		},
	},
	mounted() {
		this.keyStore.addAll({
			// Enter: { action: this.toggleCollected },
			Escape: { action: this.exitSelMode },
		})
	},
	beforeUnmount() {
		this.keyStore.removeAll({
			// Enter: { action: this.toggleCollected },
			Escape: { action: this.exitSelMode },
		})
	},
	methods: {
		onCollect() {
			this.markCollected()
			this.exitSelMode()
		},
		onUncollect() {
			this.markUncollected()
			this.exitSelMode()
			if (this.feedStore.isMyCluster) this.markOutOfCluster()
		},

		// Reset uncollect action
		// Triggered when server fails or cancel is pressed in cluster warning dialog.
		undoUncollect() {
			this.feedStore.reselect()
			this.markCollected()
			this.markInCluster()
		},

		onAddToCluster({ clusterIds }) {
			if (!clusterIds[0]) return

			this.markCollected()
			if (this.feedStore.isMyCluster && clusterIds.includes(this.feedStore.id)) {
				// If you remove an itm from a list/room and it's marked as removed,
				// and you then add it back via the cluster dropdown, we have to update the marking.
				this.markInCluster()
			}
			this.exitSelMode()
		},

		// Remove itms from my list/room.
		removeFromCluster(clusterId) {
			const listIds = this.feedStore.isList ? [clusterId] : null
			const roomIds = this.feedStore.isRoom ? [clusterId] : null
			this.$itmActions.removeFromClusters(
				{
					itmIds: this.selectedItmIds,
					listIds,
					roomIds,
				},
				{
					onError: this.undoRemoveFromCluster,
				}
			)
			this.markOutOfCluster()
			this.exitSelMode()
		},
		undoRemoveFromCluster() {
			this.feedStore.reselect()
			this.markInCluster()
		},

		// Update feed store with new collected state.
		markCollected() {
			this._markCollected(true)
		},
		markUncollected() {
			this._markCollected(false)
		},
		_markCollected(collected) {
			this.selectedItms.forEach(itm => {
				const index = this.allItms.indexOf(itm)
				// Store current value so we can reset it in case of an error
				this.feedStore.setProp(index, 'collectedPrevValue', this.feedStore.itms[index].collected)
				this.feedStore.toggleCollected(index, collected)
				if (this.feedStore.isMyCollection) {
					this.feedStore.toggleRemoved(index, !collected)
					this.feedStore.setProp(index, 'removedPrevValue', this.feedStore.itms[index].removed)
				}
			})
		},
		undoCollect() {
			this.feedStore.reselect()
			this.selectedItms.forEach(itm => {
				const index = this.allItms.indexOf(itm)
				const { collectedPrevValue, removedPrevValue } = this.feedStore.itms[index]
				console.log({ collectedPrevValue, removedPrevValue })
				this.feedStore.toggleCollected(index, collectedPrevValue)
				if (this.feedStore.isMyCollection) {
					this.feedStore.toggleRemoved(index, removedPrevValue)
				}
			})
		},

		// Update store with new in/out room state.
		markInCluster() {
			this._markInCluster(true)
		},
		markOutOfCluster() {
			this._markInCluster(false)
		},
		_markInCluster(inCluster) {
			this.selectedItms.forEach(itm => {
				const index = this.allItms.indexOf(itm)
				this.feedStore.toggleRemoved(index, !inCluster)
			})
		},

		// Deselect all itms and hide the selection bar.
		exitSelMode() {
			this.feedStore.exitSelMode()
		},
	},
}
</script>

<style scoped lang="scss">
#sel-bar {
	padding: 0 0.05rem;
	height: 0.6rem;
	background: $primary;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 5; // See App.vue for z-index coordination.
	display: flex;
	flex-direction: row;
	align-items: center;
	// background: pink;
}

// X
.act-close {
	flex: 0 0;
	// background: pink;
}
.act-close:deep() .icn {
	fill: $black;
}

// Buffer
#sel-bar .buffer {
	flex: 1 1;
}

// Display
#display::after {
	color: $white;
	background: $black-30;
	padding: 0.02rem 0.05rem;
	border-radius: $br;
	margin-left: 0.1rem;
}

// Cluster dropdown
.act-cluster {
	flex: 0 0 0.5rem;
}

// Collect/delete
.act-collect,
.act-remove {
	flex: 0 0;
}
.act-collect:deep() .icn-collect,
.act-remove:deep() .icn {
	fill: $black;
}

@media (hover: hover) {
	.act-close:hover:deep() .icn,
	.act-menu:hover:deep() .icn {
		fill: $white;
	}
	.act-cluster:hover:deep() .icn {
		stroke: $white;
	}
	.act-collect:not(.block-hover):hover:deep() .icn,
	.act-remove:hover:deep() .icn {
		fill: $white;
	}

	// Functionality Display
	.act-cluster:hover ~ #display::after {
		content: 'Add to List / Room';
	}
	.act-collect.collected:hover ~ #display::after {
		content: 'Uncollect';
	}
	.act-collect:not(.collected):hover ~ #display::after {
		content: 'Collect';
	}
	.act-remove.room:hover ~ #display::after {
		content: 'Remove from this Room';
	}
	.act-remove.list:hover ~ #display::after {
		content: 'Remove from this List';
	}
	.act-remove.add:hover ~ #display::after {
		content: ' Restore';
	}
}
</style>
