<template>
	<component
		:is="to ? 'router-link' : 'div'"
		:to="to"
		:class="['act', `act-${icon}`, { small, tiny, dark, inverse, soft, inline }]"
		@click="onClick"
	>
		<BaseIcon :name="icon" />
	</component>
</template>

<script>
// Stores
import { useKeyStore } from '@/stores/KeyStore'

// Components
import BaseIcon from '@/components/BaseIcon'

export default {
	name: 'ActionAny',
	components: { BaseIcon },
	props: {
		icon: {
			type: String,
			required: true,
		},
		to: Object,
		//
		small: Boolean,
		tiny: Boolean,
		dark: Boolean,
		inverse: Boolean,
		soft: Boolean,
		inline: Boolean,
	},
	setup() {
		const keyStore = useKeyStore()
		return { keyStore }
	},
	methods: {
		onClick() {
			// if (!this.to) return
			// if (this.keyStore.mod) {
			// 	window.open(this.to.path)
			// } else {
			// 	this.$router.push(this.to)
			// }
		},
	},
}
</script>

<style lang="scss" scoped>
.act {
	width: 0.5rem;
	height: 0.5rem;
	padding: 0.15rem;
	cursor: pointer;
}
// .act:not(.inline) {
// 	position: absolute;
// 	top: 0;
// 	right: 0;
// }

// Small version (currently unused, too small for tapping better to use negative margin)
.act.small {
	width: 0.3rem;
	height: 0.3rem;
	padding: 0.05rem;
}

// Tiny version
.act.tiny {
	width: 0.2rem;
	height: 0.2rem;
	padding: 0;
}

// Dark & inverse version
.act.dark:deep() .icn,
.act.inverse:deep() .icn {
	fill: $white-75;
}

// Soft
.act.soft:deep() .icn {
	fill: $black-30;
}

// Soft dark
.act.soft.dark:not(:hover):deep() .icn {
	fill: $white-30;
}

@media (hover: hover) {
	.act:hover:deep() .icn {
		fill: $primary;
	}

	// Inverse version
	.act.inverse:hover:deep() .icn {
		fill: $primary-inverse;
	}
}
</style>
