// Search for $$$ for fixes to be implemented once the CORS issue is resolved.

import axios from 'axios'
// import { imgRecoUrl } from '@/use/BaseUrls'

//
//
//
//

// Image recognition
class ImgRecoApi {
	constructor() {
		// Create axios instance for image recognition.
		// this.imgRecoClient = axios.create({
		// 	baseURL: imgRecoUrl,
		// 	headers: {
		// 		Accept: 'application/json',
		// 		'Content-Type': 'application/json',
		// 	},
		// })

		// Fake client routed via API to test (while we wait for CORS issues to be resolved)
		this.imgRecoClient = axios.create({
			baseURL: 'http://localhost:4000/img-reco/',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		})

		// Error interceptor
		this.imgRecoClient.interceptors.response.use(res => res, _handleError)

		// Catch errors and return { status, error }
		function _handleError(err) {
			const { code, response } = err
			if (response) {
				// 404 Page not found
				return response
			} else {
				// Invalid URL
				if (code == 'ENOTFOUND') {
					// Mongo error when file is not found
					return { status: 500, statusText: 'Invalid URL' }
				} else {
					// console.error('matchImage() --> Unknown error: ', err)
					return { status: 500, statusText: 'Unknown error' }
				}
			}
		}
	}

	// We can't directly request from image reco bc of CORS
	reportArtist(id) {
		// return this.imgRecoClient.get(`/report/${artistId}`) // Current api endpoint
		return this.imgRecoClient.get(`/report-artist/${id}`)
	}
	indexArtist(id) {
		return this.imgRecoClient.post('/index-artist', { id })
	}
	matchImage(artistId, imgUrl) {
		return this.imgRecoClient.post('/match-image', { artistId, imgUrl })
	}

	// // Image recognition // TRASH
	// reportArtist(id) {
	// 	return this.apiClient.get(`/img-reco/report-artist/${id}`)
	// }
	// indexArtist(id) {
	// 	return this.apiClient.post('/img-reco/index-artist', { id })
	// }
	// matchImage(url) {
	// 	return this.apiClient.post('/img-reco/match-image', { url })
	// }
}

export const imgRecoApi = new ImgRecoApi()
