<template>
	<router-link :to="to" custom v-slot="{ href }">
		<a :href="href" @click.prevent="$emit('click', href)" :id="id" :class="aClass">
			<span v-html="value"></span>
		</a>
	</router-link>
</template>
<script>
/*

This component extends router-link so you use @click.prevent

*/
export default {
	name: 'RouterLinkPrevent',
	props: {
		// Link text
		value: {
			type: String,
			required: true,
		},

		// Destination route
		to: {
			type: Object,
			required: true,
		},

		// Html props
		id: String,
		aClass: String,
		// class: {
		// 	type: Object,
		// 	default: () => {
		// 		return {}
		// 	},
		// },
	},
	emits: ['click'],
}
</script>
