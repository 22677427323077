<template>
	<FloatActions :hideOnTop="true" :responsive="{ mobile: 0 }" />
	<template v-if="pageItmStore.itm && !pageItmStore.itm.takenDown">
		<!-- Edit mode -->
		<ItmEdit
			v-if="editMode && $isLoggedIn"
			:itm="pageItmStore.itm"
			@exit="toggleEditMode"
			@update="onUpdateArtwork"
		/>

		<!-- Itm page -->
		<template v-else>
			<AdminControls
				v-if="$isAdmin"
				entityType="artwork"
				:entity="pageItmStore.itm"
				:viewNr="+viewNr"
			/>

			<ItmArtBox :itm="pageItmStore.itm" :viewNr="+viewNr" @load="onMainImgLoad" />

			<!-- Artwork info -->
			<div v-if="isArtwork" id="info" itemscope itemtype="https://schema.org/VisualArtwork">
				<!-- Title -->
				<h1 itemprop="name">
					{{ pageItmStore.itm.title || 'Untitled'
					}}<em
						v-if="pageItmStore.itm.year && pageItmStore.itm.year[0]"
						class="year"
						itemprop="copyrightYear"
						>, {{ displayYear(pageItmStore.itm.year) }}</em
					>
				</h1>
				<!-- Artist -->
				<h3>
					<router-link
						:to="{
							name: 'Artist',
							params: {
								namePath: pageItmStore.itm.artistNamePath,
								category: pageItmStore.itm.category,
							},
						}"
						class="incog"
						itemprop="artist"
						>{{ pageItmStore.itm.artistName }}</router-link
					>
				</h3>
			</div>

			<!-- Image info -->
			<div v-else id="no-info"></div>

			<!-- Actions -->
			<div id="itm-actions">
				<ActionCollect
					:itmIds="[pageItmStore.itm.id]"
					:collected="pageItmStore.itm.collected"
					:callbacks="actions.collectCallbacks"
				/>
				<ActionCluster
					v-model="actions.cluster.value"
					:itmIds="[pageItmStore.itm.id]"
					:minimal="true"
					:containingLists="pageItmStore.containingLists"
					:containingRooms="pageItmStore.containingRooms"
					:callbacks="actions.clusterCallbacks"
				/>
				<ActionMenu :options="menuOptions" />
			</div>

			<!-- Views -->
			<div v-if="pageItmStore.itm.views.length > 1" id="views">
				<ArtThumbSquare
					v-for="(view, i) in pageItmStore.itm.views"
					@click="e => onViewClick(e, i)"
					:key="i"
					:itm="pageItmStore.itm"
					:viewNr="i"
					:data-width="pageItmStore.itm.views[i].w"
					:data-height="pageItmStore.itm.views[i].h"
				/>
			</div>

			<!-- Meta info -->
			<div v-if="pageItmStore.hasMeta" id="meta-info">
				<!-- Caption -->
				<div
					v-if="pageItmStore.itm.caption"
					class="caption"
					:class="pageItmStore.itm.caption.length > 90 ? 'block' : ''"
					itemprop="about"
				>
					{{ pageItmStore.itm.caption }}
				</div>
				<!-- Medium & Materials / Dimensions -->
				<!-- metaInfo -->
				<div
					v-if="(isArtwork && pageItmStore.materials) || pageItmStore.dimensions"
					class="dimensions"
				>
					<div v-if="pageItmStore.materials">{{ pageItmStore.materials }}</div>
					<div v-if="pageItmStore.dimensions">{{ pageItmStore.dimensions }}</div>
				</div>

				<!-- Labels -->
				<LabelList
					v-if="pageItmStore.labels"
					:labels="pageItmStore.labels"
					:center="true"
					itemprop="keywords"
				/>

				<!-- Legacy google images: :href="`https://images.google.com/searchbyimage?image_url=${baseUrl}${image.views[0].src.replace(/__SIZE__/, 'large')}`" -->
				<!-- <a
						:href="
							`https://lens.google.com/uploadbyurl?url=${getBaseUrl('staging')}${pageItmStore.itm.views[0].src.replace(
								/__SIZE__/,
								'large'
							)}`
						"
						target="_blank"
						>Reverse image search</a
					> -->
			</div>

			<!-- Contributor info -->
			<div id="by-lines">
				<div v-if="pageItmStore.itm.contributor && pageItmStore.itm.contributor.username">
					Uploaded on {{ prettyDate(pageItmStore.itm.created) }} by
					<router-link
						v-if="pageItmStore.itm.contributor"
						:to="{ name: 'User', params: { username: pageItmStore.itm.contributor.username } }"
						class="incog soft"
						>{{ pageItmStore.itm.contributor.name }}</router-link
					>
					<template v-else>an unknown user</template>
				</div>
				<!-- Individual copyright -->
				<div
					v-if="
						(pageItmStore.artist && pageItmStore.artist.copyright) || pageItmStore.itm.photoCredit
					"
				>
					© {{ pageItmStore.copyrightYear
					}}<template v-if="pageItmStore.itm.photoCredit">
						~ Photo credit: {{ pageItmStore.itm.photoCredit }}</template
					>
				</div>
			</div>

			<!-- Gallery -->
			<div id="gallery" v-if="pageItmStore.renderGallery">
				<!-- Artist title -->
				<template v-if="pageItmStore.hasMeta">
					<h3>
						<router-link
							:to="{
								name: 'Artist',
								params: {
									namePath: pageItmStore.artist.namePath,
									category: pageItmStore.artist.category,
								},
							}"
							class="incog"
							>{{ pageItmStore.artist.name }}</router-link
						>
					</h3>
					<small>{{ prettyNr(pageItmStore.artist.artworkCount, $imperial) }} artworks</small>
				</template>
				<template v-else>
					<small>More artworks by {{ pageItmStore.itm.artistName }}</small>
				</template>

				<!-- Thumbnails -->
				<div class="thumb-wrap">
					<ArtThumbSquare
						v-for="itm in pageItmStore.artist.feed.itms"
						@click="onThumbClick"
						:key="itm.id"
						:itm="itm"
						:data-width="itm.views[0].w"
						:data-height="itm.views[0].h"
					/>
					<template v-if="pageItmStore.artist.feed.itms.length < 12">
						<ArtThumbSquare v-for="i in 3" :key="i" :class="{ first: i == 1 }" />
					</template>
				</div>

				<!-- See more -->
				<FormButton
					v-if="pageItmStore.artist.artworkCount > pageItmStore.artist.feed.itms.length + 1"
					:level="2"
					:value="`See all ${prettyNr(pageItmStore.artist.artworkCount, $imperial)}`"
					@click="
						$router.push({
							name: 'Artist',
							params: {
								namePath: pageItmStore.artist.namePath,
								category: pageItmStore.artist.category,
							},
						})
					"
				/>
			</div>

			<!-- Empty gallery -->
			<div id="gallery" v-else-if="pageItmStore.artist">
				<p id="no-artworks">
					That's all we have by {{ pageItmStore.artist.name }}.<br />Try
					<a target="_blank" :href="pageItmStore.googleUrl">Google</a>,
					<a target="_blank" :href="pageItmStore.tumblrUrl">Tumblr</a> or
					<a target="_blank" :href="pageItmStore.pinterestUrl">Pinterest</a> or...
				</p>
			</div>

			<!-- Wormhole -->
			<TheWormhole />

			<!-- Section: Rooms -->
			<div class="section" v-if="pageItmStore.itm.rooms && pageItmStore.itm.rooms.length">
				<h2>Rooms</h2>
				<RoomList :rooms="pageItmStore.itm.rooms" />
			</div>
		</template>
	</template>
</template>

<script>
export default {
	name: 'ItmMain',
	// inheritAttrs: false,
	// customOptions: {}
}
</script>

<script setup>
// Vue functions
import { computed, watch, onServerPrefetch, onMounted, onBeforeUnmount, reactive, toRefs, toRef } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { inject } from 'vue'

// Stores
import { useApiStore } from '@/stores/ApiStore'
import { useHttpErrorStore } from '@/stores/HttpErrorStore'
import { usePageItmStore } from '@/stores/PageItmStore'
import { useKeyStore } from '@/stores/KeyStore'

// Views
import ItmEdit from '@/views/Itm/ItmEdit'

// Components
import FloatActions from '@/components/FloatActions'
import AdminControls from '@/components/AdminControls/AdminControls'
import ItmArtBox from '@/views/Itm/ItmArtBox'
import ArtThumbSquare from '@/components/ArtThumbSquare'
import FormButton from '@/components/FormButton'
import LabelList from '@/components/LabelList'
import TheWormhole from '@/components/TheWormhole'
import ActionCollect from '@/components/ActionCollect'
import ActionMenu from '@/components/ActionMenu'
import ActionCluster from '@/components/ActionCluster'
import RoomList from '@/components/RoomList'

// Internal
import { injectMetaData } from '@/use/MetaData'
import { prettyNr } from '@/use/StringMagic'
import flash from '@/use/FlashAlert'
import displayYear from '@/use/DisplayYear'
import artCategories from '@/use/ArtCategories'
import { preloadImages } from '@/use/PreloadImages'
import { parseSrc, share } from '@/use/General'
import { prettyDate } from '@/use/StringMagic'
import { downloadImg, destructureRoute } from '@/use/Helpers'
import { baseUrl } from '@/use/BaseUrls'

const props = defineProps({
	// For images
	id: String,

	// For artworks
	//
	// Categories:
	// art
	// photography
	// illustration
	// product-design
	// graphic-design
	// fashion-design
	// architecture
	// other
	category: String,
	namePath: String,
	titlePath: String,
	viewNr: {
		type: [String, Number],
		default: 0,
	},
})

//
//
//
//

// Import stores
const pageItmStore = usePageItmStore()
const httpErrorStore = useHttpErrorStore()
const keyStore = useKeyStore()

// Import router
const router = useRouter()
const route = useRoute()

// Load API
const apiStore = useApiStore()
const artistApi = apiStore.loadApi('artist')
const itmApi = apiStore.loadApi('itm')

// Import global vars
const $imperial = inject('$imperial')
const $itmActions = inject('$itmActions')

//
//

// Load artwork amd artist info
const {
	editMode,
	isArtwork,
	menuOptions,
	//
	toggleEditMode,
	onUpdateArtwork,
} = loadContent()

const actions = initActions()

const { onThumbClick, onViewClick } = initInteraction()

// Assemble & inject meta data
createMetaData(pageItmStore.itm, pageItmStore.artist)

// Preload all views so they load faster.
const { onMainImgLoad } = preloadViews()

//
//
//
//

/**
 * Load artwork and artist info
 */
function loadContent() {
	// Reactive data
	const state = reactive({
		editMode: route.hash == '#edit',
		isArtwork: computed(() => !props.id),
		// Menu options
		menuOptions: [
			{
				value: 'share',
				display: 'Share',
				onSelect: () => {
					share({
						entityType: 'itm',
						entity: pageItmStore.itm,
						url: baseUrl + route.path,
					})
				},
			},
			{
				value: 'edit',
				display: 'Edit',
				onSelect: toggleEditMode,
				wall: 'edit items',
			},
			{
				value: 'download',
				display: 'Download',
				onSelect: () => {
					downloadImg(pageItmStore.itm.id)
				},
				wall: 'download images',
			},
		],
		// Not exported to template
		namePath_titlePath: computed(() => {
			return `${props.namePath}_${props.titlePath}`
		}),
	})

	// Server-side initiation
	onServerPrefetch(_loadPage)

	// Client-side initiation
	onMounted(() => {
		// Only load page if it wasn't already fetched server-side (development)
		const isHydrating = !!pageItmStore.itm
		if (!isHydrating) _loadPage()
	})

	onBeforeUnmount(() => {
		// Required to avoid the last visited itm to linger after you return from another page.
		pageItmStore.$reset()
	})

	// Reload content whenever path changes.
	const namePath_titlePath = toRef(state, 'namePath_titlePath') // Note #1
	watch(namePath_titlePath, () => {
		pageItmStore.startLoading()
		pageItmStore.startPreviewLoading()
		_loadPage()
	})

	// Note #1
	// In order for a vue prop or an event property to be watchable,
	// it needs to be turned into a reactive reference with toRefs()
	// https://v3.vuejs.org/guide/composition-api-introduction.html#reacting-to-changes-with-watch

	// Return data
	return {
		...toRefs(state),
		toggleEditMode,
		onUpdateArtwork,
	}

	//
	//

	/**
	 * Public functions
	 */

	// Toggle edit mode
	function toggleEditMode() {
		window.scrollTo(0, 0)
		state.editMode = !state.editMode
		const newRoute = { ...route }
		newRoute.hash = state.editMode ? '#edit' : null
		router.push(newRoute)
	}

	// When artwork is successfully edited.
	function onUpdateArtwork(data) {
		$itmActions.onUpdate({
			itm: pageItmStore.itm,
			data,
			router,
			callbackReloadPage: _loadPage,
			callbackToggleEditMode: toggleEditMode,
		})
	}

	//
	//

	/**
	 * Private functions
	 */

	// Load page
	// Loads artwork, then artist, rerouts when not found.
	async function _loadPage() {
		// Load artwork
		const artwork = await _loadItm()
		if (!artwork) {
			// This is catching obscure edge cases on production, not sure how to reproduce.
			const errLog = `Artwork.vue _loadPage(): failed to load artwork ${props.namePath}/${props.titlePath}`
			console.error(errLog)
			return
		}
		const { status: artworkStatus, statusText: artworkStatusText, data: artworkData } = artwork

		// Display lo-res image while we wait for hi-res to load.
		if (pageItmStore.itm) {
			const src = parseSrc({
				baseSrc: pageItmStore.itm.views[0].src,
				imgSize: 'small',
			})
			pageItmStore.setLoadingSrc(src)
		}

		// If artist is taken down, forward to artist page.
		if (artworkData && artworkData.takenDown) {
			return router.push({
				name: 'Artist',
				params: { namePath: artworkData.artistNamePath, category: artworkData.category },
			})
		}

		// Fetch default feed info, so we know what
		// feed to load when you open the carousel.
		pageItmStore.fetchDefaultFeedInfo()

		// Load artist
		if (state.isArtwork) {
			const { status: artistStatus, data: artistData } = await _loadArtist()
			if (artworkData && artworkData.id && !artworkData.views)
				console.error('//////////\nARTWORK IS MISSING VIEWS!\n//////////')

			_handleStatus({ artworkStatus, artistStatus, artworkStatusText, artworkData, artistData })
		}
	}

	// Itm
	async function _loadItm() {
		const { namePath, titlePath, id } = props
		const response = state.isArtwork
			? await itmApi.getByPath(namePath, titlePath)
			: await itmApi.getById(id)
		const { status, statusText, data } = response || {}
		if (status == 200 && data) {
			pageItmStore.storeItm(data)
		}
		return { status, statusText, data }
	}

	// Artist
	async function _loadArtist() {
		const { namePath, category } = props
		const count = 12 // Number of artworks to load in gallery
		const artworkId = pageItmStore.itm ? pageItmStore.itm.id : null // Artwork to start gallery with
		const { status, statusText, data } =
			(await artistApi.get({ namePath, category, includeItms: count, afterId: artworkId })) || {}
		if (status == 200 && data) {
			pageItmStore.storeArtist(data)
		}
		return { status, statusText, data }
	}

	// When no artwork is found, forward to artist page.
	// If no artist if found, display 404.
	async function _handleStatus({
		artworkStatus,
		artistStatus,
		artworkStatusText,
		artworkData,
		artistData,
	}) {
		if (artworkStatus == 200) {
			// Do nothing
		} else if (artworkStatus == 301) {
			// The artwork url has changed, forward to correct URL
			const { artistNamePath, titlePath } = artworkData
			const redirect = destructureRoute(route)
			// 301 returns new titlePath as data
			redirect.params.namePath = artistNamePath
			redirect.params.titlePath = titlePath
			router.push(redirect)
		} else if (artistStatus == 200) {
			// Forward to artist page if possible
			flash(`There is no artwork called <em>'/${props.titlePath}'</em> by ${artistData.name}.`, {
				title: 'Artwork not found',
				blink: true,
				type: 'error',
				html: true,
				// persistent: true,
			})
			router.push({
				name: 'Artist',
				params: { namePath: props.namePath, category: props.category },
			})
		} else {
			// Throw HTTP error / 404 etc
			let type = artCategories.validate(props.category) ? 'artwork' : 'page'
			httpErrorStore.setError({
				status: artworkStatus,
				statusText: artworkStatusText,
				type,
				origin: 'Artwork.vue',
			})
			pageItmStore.$reset()
		}
	}
}

function initActions() {
	const state = reactive({
		cluster: null,
	})

	const collectCallbacks = {
		collect: pageItmStore.markCollected,
		collectError: pageItmStore.markUncollected,
		uncollect: pageItmStore.markUncollected,
		uncollectSuccess: pageItmStore.clearClusterIds,
		uncollectError: pageItmStore.markCollected,
		uncollectCancel: pageItmStore.markCollected,
	}

	const clusterCallbacks = {
		add: pageItmStore.markCollected,
		addSuccess: ({ listIds, roomIds }) => {
			pageItmStore.addClusterIds({ listIds, roomIds })
			state.room = null
		},
		addError: () => {
			if (!pageItmStore.containingClusterCount) pageItmStore.markUncollected()
		},
	}

	return { ...toRefs(state), collectCallbacks, clusterCallbacks }
}

/**
 * Interaction
 */
function initInteraction() {
	function onThumbClick(e) {
		_preloadThumb(e)
	}

	// When clicking a view, the itm in the pageItmStore is not actually refreshed
	// resulting in the hi-res image never to load when we swap out the src.
	function onViewClick(e) {
		_preloadThumb(e, true)
	}

	// When another artwork thumbnail is clicked, it takes view
	// a split second to update the thumbmails, so we manually swap
	// in the lo-res version for etra-snappy feedback.
	function _preloadThumb(e, isView) {
		if (keyStore.mod) return
		if (e.target.tagName == 'IMG') {
			window.scrollTo(0, 0)
			// When we're loading another itm page, we also swap out the itm data.
			if (!isView) {
				const index = Number(e.target.getAttribute('data-index'))
				const targetItm = pageItmStore.artist.feed.itms.find(itm => itm.index == index)
				if (targetItm.id != pageItmStore.itm.id) pageItmStore.itm = targetItm
			}
			pageItmStore.setLoadingSrc(e.target.currentSrc)
		}
	}

	return { onThumbClick, onViewClick }
}

/**
 * Preload other views
 */

// To be implemented & tested.
function preloadViews() {
	// // Delete this, just to avoid LINT errors.
	function onMainImgLoad() {}
	if (preloadImages) {
		;() => {}
	}

	// async function onMainImgLoad() {
	// 	preloadImages({
	// 		itms: pageItmStore.itm.views.map(view => ({ views: [view] })),
	// 		imgSize: 'large',
	// 	})
	// }

	return { onMainImgLoad }
}

/**
 * Assemble & inject meta data
 */
// prettier-ignore
function createMetaData(artwork, artist) {
	// Construct meta data
	const year = computed(() =>	artwork.year && artwork.year[0] ? ', ' + displayYear(artwork.year) : '')
	const title = computed(() => artwork && artist ? artist.name + ' - ' + (artwork.title || 'Untitled') + year.value : null)
	const artworkCount = computed(() =>	artist ? artist.artworkCount : '')
	const description = computed(() => {
		if (artworkCount.value > 1) {
			return `Browse ${artworkCount.value} artworks by ${artist ? artist.name : 'this artist'} on Arthur.`
		} else {
			return 'Arthur is a digital museum.'
		}
	})
	const imgSrc = computed(() => {
		return artwork ? 'https://arthur.io' + artwork.views[0].src.replace(/__SIZE__/, 'large') : null
	})
	const imgWidth = computed(() =>	artwork && artwork.views && artwork.views[0] ? artwork.views[0].w : null)
	const imgHeight = computed(() => artwork && artwork.views && artwork.views[0] ? artwork.views[0].h : null)

	// Inject meta data
	injectMetaData({ title, description, imgSrc, imgWidth, imgHeight })
}
</script>

<style scoped lang="scss">
/**
 * Sections
 */
.section {
	margin-bottom: 2rem;
}
.section h2 {
	text-align: center;
	margin-bottom: 0.4rem;
}

/**
 * Info
 */
#info {
	width: 100%;
	margin: 0 auto;
	padding: 0.4rem;
	text-align: center;
}
#no-info {
	height: 0.4rem;
}

// Artwork title
#info h1 {
	margin-bottom: 0.04rem;
}
#info h3 {
	margin-bottom: 0;
}

/**
 * Actions
 */
#itm-actions {
	display: flex;
	justify-content: center;
	margin-bottom: 0.6rem;
	// background: pink;
}

/**
 * Views
 */
#views {
	font-size: 0;
	line-height: 0;
	display: flex;
	flex-wrap: wrap;
	gap: 0.03rem;
	justify-content: center;
	margin-bottom: 0.4rem;
}
#views a {
	margin-bottom: 0.2rem;
	flex-basis: 0.6rem;
	height: 0.6rem;
}
#views:deep() img {
	width: 0.6rem;
	height: 0.6rem;
}
#views a.router-link-exact-active {
	pointer-events: none;
	position: relative;
	overflow: visible;
}
#views a.router-link-exact-active::after {
	content: '';
	display: block;
	width: 0.05rem;
	height: 0.05rem;
	border-radius: 0.03rem;
	background-color: $black;
	position: absolute;
	bottom: -0.1rem;
	left: 50%;
	margin-left: -0.02rem;
}

/**
 * Meta info
 */
#meta-info {
	width: 100%;
	max-width: 7rem;
	margin: 0 auto;
	padding: 0 0.4rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}

// Caption
#meta-info .caption {
	margin-bottom: 0.3rem;
}
#meta-info .caption.block {
	text-align: justify;
	margin: 0 auto 0.3rem auto;
	white-space: pre-wrap;
	word-break: break-word;
}

// Dimensions
#meta-info .dimensions {
	margin-bottom: 0.3rem;
	color: $black-50;
	display: flex;
	flex-direction: column;
	gap: 0.1rem;
}

// Labels
#meta-info .label-wrap {
	// 30px - 5px to make up for the labels bottom margin
	margin-bottom: 0.25rem;
}

/**
 * By-lines
 */
#by-lines {
	font-size: $small;
	line-height: $small-line-height;
	color: $black-30;
	margin: 0.3rem auto 0.9rem auto;
	text-align: center;
	padding: 0 var(--pp);
}
#by-lines > div:not(:last-child) {
	margin-bottom: 0.1rem;
}

/**
 * Gallery
 */

#gallery {
	margin: 0 auto 1rem auto;
	max-width: 6.09rem;
	text-align: center;
}
#gallery h4 {
	margin-bottom: 0.05rem;
}
#gallery small {
	display: block;
	margin-bottom: 0.2rem;
	color: $black-50;
}
#gallery .thumb-wrap {
	font-size: 0;
	line-height: 0;
	display: flex;
	gap: 0.03rem;
	flex-wrap: wrap;
}
#gallery button {
	margin-top: 0.3rem;
}
#no-artworks {
	margin: 0.3rem 0.4rem 0 0.4rem;
}
#wormhole {
	margin-bottom: 1rem;
}

// "That's all we have" message in blank thumbnails
#gallery a.blank.first {
	position: relative;
}
#gallery a.blank.first::after {
	content: "That's all \00000a we have";
	display: block;
	font-size: $small;
	line-height: $small-line-height;
	color: $black-30;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	white-space: pre;
}

/**
 * Responsive
 */

// Fit gallery to screen width
@media only screen and (max-width: $tablet) {
	#gallery h4 {
		padding: 0 0.4rem;
	}
	#gallery {
		margin-left: 0.03rem;
		margin-right: 0.03rem;
		max-width: none;
	}

	// Search button
	.act-search {
		bottom: 0.2rem;
		left: 0.2rem;
	}
}

@media only screen and (max-width: $mobile) {
	#info {
		padding: 0.2rem;
	}
}

/* 4x4 Grid */
// (150 x 4) + (3 x 3) + (40 x 2) = 689 --> $tablet = 690
@media only screen and (min-width: 463px) and (max-width: $tablet) {
	#gallery .img-wrap {
		// https://sass-lang.com/documentation/interpolation
		flex-basis: calc((#{$vw100} - 0.15rem) / 4);
		height: calc((100vw - 0.15rem) / 4);
		width: auto;
	}
}
@media only screen and (min-width: 463px) {
	// Hide overflowing blank thumbs
	// +0 blanks (4 thumbs)
	#gallery a.blank:nth-child(4n-1):nth-last-child(1),
	#gallery a.blank:nth-child(4n-2):nth-last-child(2),
	#gallery a.blank:nth-child(4n-3):nth-last-child(3),
	// +1 blanks (3 thumbs)
	#gallery a.blank:nth-child(4n-2):nth-last-child(1),
	#gallery a.blank:nth-child(4n-3):nth-last-child(2),
	// +2 blanks (2 thumbs)
	#gallery a.blank:nth-child(4n-3):nth-last-child(1) {
		display: none;
	}
}

/* 3x3 Grid */
// (150 x 3) + (3 x 4) = 462
@media only screen and (min-width: 310px) and (max-width: 462px) {
	#gallery .img-wrap {
		flex-basis: calc((#{$vw100} - 0.12rem) / 3);
		height: calc((100vw - 0.12rem) / 3);
		width: auto;
	}

	// Hide overflowing blank thumbs
	// +0 blanks (3 thumbs)
	#gallery a.blank:nth-child(3n):nth-last-child(1),
	#gallery a.blank:nth-child(3n-1):nth-last-child(2),
	#gallery a.blank:nth-child(3n-2):nth-last-child(3),
	// +1 blanks (2 thumbs)
	#gallery a.blank:nth-child(3n-1):nth-last-child(1),
	#gallery a.blank:nth-child(3n-2):nth-last-child(2),
	// +2 blanks (1 thumbs)
	#gallery a.blank:nth-child(3n-2):nth-last-child(1) {
		display: none;
	}
}

/* 2x2 Grid */
// (150 x 2) + (3 x 3) = 309
@media only screen and (max-width: 309px) {
	#gallery .img-wrap {
		flex-basis: calc((#{$vw100} - 0.09rem) / 2);
		height: calc((100vw - 0.09rem) / 2);
		width: auto;
	}

	// Hide overflowing blank thumbs
	// +1 blanks (1 thumbs)
	#gallery a.blank:nth-child(2n):nth-last-child(1),
	#gallery a.blank:nth-child(2n-1):nth-last-child(2),
	// +0 blanks (2 thumbs)
	#gallery a.blank:nth-child(2n-1):nth-last-child(1),
	#gallery a.blank:nth-child(2n):nth-last-child(2),
	#gallery a.blank:nth-child(2n-1):nth-last-child(3) {
		display: none;
	}
}
</style>
