<template>
	<div
		ref="ref_searchPage"
		class="content-pad"
		:class="{ overlay: searchStore.overlay }"
		:style="cssVars"
		@click="onClickAnywhere"
	>
		<!-- style="opacity: .9" -->
		<!-- List all header -->
		<div id="search-list-all-header" v-if="searchStore.listAll">
			<div class="h1-wrap">
				<a href="#" class="edit-entry" @click.prevent="searchStore.fastSearch">
					<h1><em>&ldquo;</em>{{ searchStore.entry }}<em>&rdquo;</em></h1>
					<ActionAny icon="edit-text" />
				</a>
				<ActionClose :inline="true" icon="edit-text" @click.stop="onClickX" />
			</div>
			<div id="search-info">
				{{ capitalize(searchStore.entityType) }} Search<span v-if="searchStore.total" class="soft"
					>&nbsp;&nbsp;/&nbsp;&nbsp;{{ prettyNr(searchStore.total) }} Results</span
				>
			</div>
			<BasePagination
				v-if="searchStore.listAll && searchStore.pageCount > 1"
				:pageCount="searchStore.pageCount"
				:currentPage="searchStore.page"
			/>
		</div>

		<!-- Active search header -->
		<template v-else>
			<div id="search-wrap" :class="{ 'scroll-compensate': searchStore.scrollbarWidth }">
				<input
					ref="ref_search"
					id="search-input"
					:class="{ 'show-key': !sessionStore.isTouch }"
					type="text"
					placeholder="Search..."
					autocomplete="off"
					maxlength="100"
					v-model="searchStore.entry"
				/>
				<BaseKey v-if="sessionStore.ua.android && sessionStore.ua.chrome" text="space" />
				<BaseKey v-else-if="!sessionStore.isTouch" text="enter" />
				<ActionClose :soft="true" @click="onClickX" />
			</div>
			<div id="search-info">
				<span v-if="searchStore.total" class="soft">{{ prettyNr(searchStore.total) }} Results</span>
				<span v-else>&nbsp;</span>
			</div>

			<NavSub :items="searchStore.navItems" />
		</template>

		<!-- Search error -->
		<template v-if="searchStore.error">
			<!-- Forbidden msg -->
			<template v-if="searchStore.error.status == 403 && searchStore.entityType == 'user'">
				<p>You need an account to search for other users.</p>
				<FormButton
					value="Wait list"
					:small="true"
					@click="dialogStore.dialogByName('DialogAuth', { page: 'wait-list' })"
				/>
			</template>

			<!-- HTTP error -->
			<template v-else>
				<p class="error-msg">
					{{ searchStore.error.msg || 'There was an error connecting to the server.' }}
				</p>
				<p class="small soft">
					<template v-if="searchStore.error.status"
						>Error {{ searchStore.error.status }}:
					</template>
					{{ searchStore.error.statusText }}
				</p>
				<a href="#" @click.prevent="searchStore.retrySearch">Retry</a>
			</template>
		</template>

		<!-- Loading ellipsis -->
		<BaseLoadingText v-else-if="!searchStore.results.length && searchStore.processing" />

		<!-- Search empty -->
		<div v-else-if="!searchStore.results.length && searchStore.entry.length">
			<p class="soft">No results...</p>
			<a href="#" @click.prevent="searchStore.reset">Reset</a>
		</div>

		<!-- Search results -->
		<div v-else id="search-results-wrap" :class="{ processing: searchStore.processing }">
			<!-- Artists -->
			<div v-if="searchStore.entityType == 'artist'">
				<div class="result" v-for="(result, i) in searchStore.results" :key="result.id">
					<div class="count">{{ prettyNr(result.itmCount) }}</div>
					<div>
						<RouterLinkPrevent
							:to="resultLinks[i]"
							:value="result.display"
							@click="onResultClick"
						/>
						<span class="identifier" v-if="result.identifier"> - {{ result.identifier }}</span>
					</div>
				</div>
			</div>

			<!-- Artworks & images -->
			<div v-if="searchStore.entityType === 'artwork'">
				<div class="result preview" v-for="(result, i) in searchStore.results" :key="result.id">
					<router-link :to="resultLinks[i].itm" custom v-slot="{ href }">
						<a :href="href" @click.prevent="onResultClick(href)">
							<img
								v-if="result.img"
								:src="resultImgs[i]"
								:height="result.img.h * (120 / result.img.w)"
							/>
							<div v-else class="img-placeholder"></div>
						</a>
					</router-link>
					<div>
						<RouterLinkPrevent
							aClass="artist-name"
							:to="resultLinks[i].artist"
							:value="result.artistName"
							@click="onResultClick"
						/>
						<RouterLinkPrevent
							aClass="itm-title"
							:to="resultLinks[i].itm"
							:value="result.display"
							@click="onResultClick"
						/>
						<div class="small">In {{ result.collectorCount }} collections</div>
					</div>
				</div>
			</div>

			<!-- Rooms -->
			<div v-if="searchStore.entityType === 'room'">
				<div class="result" v-for="(result, i) in searchStore.results" :key="result.id">
					<div class="count">{{ prettyNr(result.itmCount) }}</div>
					<div class="double">
						<RouterLinkPrevent
							:to="resultLinks[i].room"
							:value="result.display"
							@click="onResultClick"
						/>
						<RouterLinkPrevent
							aClass="small"
							:to="resultLinks[i].owner"
							:value="result.ownerName"
							@click="onResultClick"
						/>
					</div>
				</div>
			</div>

			<!-- Users -->
			<div v-if="searchStore.entityType === 'user'">
				<div class="result user" v-for="(result, i) in searchStore.results" :key="result.id">
					<router-link :to="resultLinks[i]" custom v-slot="{ href }">
						<a :href="href" @click.prevent="onResultClick(href)">
							<img
								v-if="result.profilePic"
								:src="`/img/prof/user/jpg/${result.id}/1x/${result.profilePic}`"
								alt="img"
							/>
							<div v-else class="no-pic">
								<h1>{{ result.initial }}</h1>
							</div>
							<div class="name">
								<span v-html="result.display"></span>
								<div class="small">{{ prettyNr(result.itmCount) }}</div>
							</div>
						</a>
					</router-link>
					<FormButton v-if="0" :level="2" value="unfollow" :small="true" />
					<ButtonFollow />
				</div>
			</div>

			<!-- Lists -->
			<div v-if="searchStore.entityType === 'list'">
				<div class="result" v-for="(result, i) in searchStore.results" :key="result.id">
					<div class="count">{{ prettyNr(result.itmCount) }}</div>
					<div class="double">
						<RouterLinkPrevent
							:to="resultLinks[i].list"
							:value="result.display"
							@click="onResultClick"
						/>
						<RouterLinkPrevent
							aClass="small"
							:to="resultLinks[i].owner"
							:value="result.ownerName"
							@click="onResultClick"
						/>
					</div>
				</div>
			</div>

			<!-- Labels -->
			<div v-if="searchStore.entityType === 'label'">
				<div class="result" v-for="(result, i) in searchStore.results" :key="result.id">
					<div class="count">{{ prettyNr(result.itmCount) }}</div>
					<RouterLinkPrevent :to="resultLinks[i]" :value="result.display" @click="onResultClick" />
				</div>
			</div>
		</div>

		<!-- Show all -->
		<FormButton
			v-if="!searchStore.listAll && searchStore.total && searchStore.total > searchStore.resultsCap"
			value="Show all"
			:level="2"
			@click.prevent="showAll"
		/>

		<!-- Bottom pagination -->
		<BasePagination
			v-if="
				searchStore.listAll &&
					searchStore.pageCount > 1 &&
					!searchStore.error &&
					searchStore.results.length
			"
			:pageCount="searchStore.pageCount"
			:currentPage="searchStore.page"
		/>
	</div>
</template>

<script>
// vue
import { nextTick } from 'vue'

// Stores
import { useSearchStore } from '@/stores/SearchStore'
import { useSessionStore } from '@/stores/SessionStore'
import { useDialogStore } from '@/stores/DialogStore'
import { useKeyStore } from '@/stores/KeyStore'

// Components
import NavSub from '@/components/NavSub'
import BaseKey from '@/components/BaseKey'
import ActionClose from '@/components/ActionClose'
import ActionAny from '@/components/ActionAny'
import FormButton from '@/components/FormButton'
// import ActionBack from '@/components/ActionBack'
import BasePagination from '@/components/BasePagination'
import BaseLoadingText from '@/components/BaseLoadingText'
import ButtonFollow from '@/components/ButtonFollow'
import RouterLinkPrevent from '@/components/RouterLinkPrevent'

// Internal
import { prettyNr, capitalize } from '@/use/StringMagic'

export default {
	name: 'SearchPage',
	components: {
		NavSub,
		BaseKey,
		ActionClose,
		ActionAny,
		FormButton,
		// ActionBack,
		BasePagination,
		BaseLoadingText,
		ButtonFollow,
		RouterLinkPrevent,
	},
	props: {
		entityType: String,
		page: [Number, String], // For pagination
	},
	setup() {
		const searchStore = useSearchStore()
		const sessionStore = useSessionStore()
		const dialogStore = useDialogStore()
		const keyStore = useKeyStore()
		return { searchStore, sessionStore, dialogStore, keyStore, prettyNr, capitalize }
	},
	data() {
		return {
			cssVars: { '--scrollbarWidth': this.searchStore.scrollbarWidth + 'px' },
			keyActions: {
				Enter: { action: this.onEnter },
				Escape: { action: this.onEscape },
			},
		}
	},
	computed: {
		// Router links to entity pages.
		resultLinks() {
			if (this.searchStore.entityType == 'artist') {
				// Artists
				return this.searchStore.results.map(result => {
					return {
						name: 'Artist',
						params: {
							category: result.category,
							namePath: result.namePath,
						},
					}
				})
			} else if (this.searchStore.entityType == 'artwork') {
				// Artworks
				return this.searchStore.results.map(result => {
					return {
						itm: {
							name: 'Artwork',
							params: {
								category: result.category,
								namePath: result.artistNamePath,
								titlePath: result.titlePath,
							},
						},
						artist: {
							name: 'Artist',
							params: {
								category: result.category,
								namePath: result.artistNamePath,
							},
						},
					}
				})
			} else if (this.searchStore.entityType == 'room') {
				// Room
				return this.searchStore.results.map(result => {
					return {
						room: {
							name: 'Room',
							params: {
								id: result.id,
							},
						},
						owner: {
							name: 'User',
							params: {
								username: result.ownerUsername,
							},
						},
					}
				})
			} else if (this.searchStore.entityType == 'user') {
				// User
				return this.searchStore.results.map(result => {
					return {
						name: 'User',
						params: {
							username: result.username,
						},
					}
				})
			} else if (this.searchStore.entityType == 'list') {
				// List
				return this.searchStore.results.map(result => {
					return {
						list: {
							name: 'UserList',
							params: {
								username: result.ownerUsername,
								namePath: result.namePath,
							},
						},
						owner: {
							name: 'User',
							params: {
								username: result.ownerUsername,
							},
						},
					}
				})
			} else if (this.searchStore.entityType == 'label') {
				// Labels
				return this.searchStore.results.map(result => {
					return {
						name: 'Label',
						params: {
							label: result.value,
						},
					}
				})
			} else {
				return {}
			}
		},

		resultImgs() {
			if (this.searchStore.entityType == 'artwork') {
				return this.searchStore.results.map(result => {
					// prettier-ignore
					return `/img/${result.img.ext}/small/${result.img.src_tmp || result.img.src}${result.dev ? '?dev=1' : ''}`
				})
			} else {
				return null
			}
		},
	},
	watch: {
		// When we leave the search, we want to clear all results.
		'$route.name'(newValue) {
			if (newValue != 'SearchEntity') this.searchStore.reset()
		},

		// Android Chrome doesn't update v-model until you type space.
		// There's no easy workaround so we display space button suggestion.
		// More info: https://github.com/vuejs/vue/issues/9777#issuecomment-478831263
		// - - -
		// We don't listen to the @input event, because then we're
		// doing a new search with each keystroke without the entry
		// having been updated. By listening to the entry we avoid this.
		'searchStore.entry'(newValue) {
			if (!this.searchStore.active) return
			if (this.searchStore.listAll) return
			if (!newValue && newValue !== '') return
			this.searchStore.fastSearchDebounce()
		},

		// Pagination
		'$route.params.page'(newValue, oldValue) {
			if (this.searchStore.active && oldValue && newValue) {
				this.searchStore.fullSearch(+newValue)
			}
		},

		// Focus on input when switching.
		'searchStore.listAll'(newValue) {
			if (!newValue) {
				this.select()
			}
		},
	},
	mounted() {
		// console.log('MOUNTED')

		// Activate the search functions.
		this.searchStore.activate()

		// Focus on search
		this.focus()

		// Store the search category (entityType).
		const entityType = this.$route.name == 'SearchEntity' ? this.$route.params.entityType : null
		this.searchStore.setEntityType(entityType)

		// Store the page.
		this.searchStore.page = this.page

		// Store the entry & launch search.
		if (window.location.search) {
			// https://stackoverflow.com/a/901144
			const params = new Proxy(new URLSearchParams(window.location.search), {
				get: (searchParams, prop) => searchParams.get(prop),
			})
			if (params.q) {
				// console.log('SEARCH RECORDED')
				this.searchStore.entry = params.q
				this.searchStore.fullSearch(+this.page)
			}
		}

		// Keyboard functionality.
		this.keyStore.addAll(this.keyActions)
	},
	beforeUnmount() {
		// Keyboard functionality.
		this.keyStore.removeAll(this.keyActions)
	},
	methods: {
		// Show all results.
		async showAll() {
			// console.log('showAll!')
			await this.searchStore.fullSearch()
		},

		// Check if a route is different from the current route.
		isSameRoute(route) {
			if (route.query.q != this.$route.query.q) return false
			if (route.params.page != this.$route.params.page) return false
			if (route.params.entityType != this.$route.params.entityType) return false
			if (route.name != this.$route.name) return false
			return true
		},

		onClickX() {
			if (this.searchStore.entry) {
				this.searchStore.reset()
			} else {
				this.searchStore.exit()
			}
		},

		// We can't simply click on the link, because then the lockedRoute
		// will be deactivated before we get to the new page, causing an
		// unnecessary page load, which in this case causes teh search page
		// to reload thanks to the updateRoute() inside searchStore.doSearch.
		// So instead we push the link programatically to the router, so we
		// can wait for it to completes before deactivating the lockedRoute.
		// - - -
		// Note: you can't simply do @click.prevent on a router-link, hence
		// the more complicated HTML. Inspired by https://stackoverflow.com/a/68083283
		// and more docs: https://router.vuejs.org/guide/advanced/extending-router-link.html
		async onResultClick(href) {
			this.searchStore.deactivate()
			await this.$router.push(href)
			if (this.searchStore.overlay) await this.searchStore.deactivateOverlay()
			// Note: resetting searchStore happens via the router watcher.
		},

		// Page-wide click handler makes surer
		// search input is always put back in focus.
		onClickAnywhere(e) {
			const isLinkOrButton = e.target.closest('a') || e.target.closest('button')
			if (!isLinkOrButton || e.target.closest('.subnav')) {
				this.focus()
			}
		},

		// Focus on text input.
		async focus() {
			await nextTick()
			if (this.$refs.ref_search) {
				this.$refs.ref_search.focus()
			}
		},

		// Select text input.
		async select() {
			await nextTick()
			if (this.$refs.ref_search) {
				this.$refs.ref_search.select()
			}
		},

		scrollUp() {
			if (this.$refs.ref_searchPage) this.$refs.ref_searchPage.scrollTo(0, 0)
		},

		// Escape key
		onEscape() {
			if (this.searchStore.listAll) {
				// Exit paginated results
				this.searchStore.fastSearch()
			} else if (this.searchStore.entry.length) {
				// Clear
				this.searchStore.reset()
			} else {
				// Exit overlay
				this.searchStore.exit()
			}
		},

		// Enter key
		onEnter() {
			if (this.searchStore.listAll) {
				// Exit paginated results
				this.searchStore.fastSearch()
			} else {
				// Show paginated results
				this.showAll()
			}
		},
	},
}
</script>

<style scoped lang="scss">
// Overlay
.content-pad {
	background: $bg;
	height: 100vh;
	overflow-y: auto;
	scroll-behavior: smooth;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 19;
}

/**
 * Active Search Bar
 */

#search-wrap {
	position: relative;
}
// Offset the x icon so it appears at the same position
// as the search icon offset by the scroll bar.
#search-wrap.scroll-compensate {
	margin-right: var(--scrollbarWidth);
}
#search-input {
	// background: lightblue;
	width: 100%;
	border: none;
	color: $black;
	background: none;
	padding-right: 0.4rem;
	font-family: 'Barlow', sans-serif;
	font-size: 0.24rem;
	line-height: 0.24rem;

	// To align lowercase character
	// height with the x button.
	height: 0.34rem;
	margin-top: -0.04rem;
}

// ENTER / ESCAPE key suggestions
#search-input.show-key {
	padding-right: 1.05rem;
}
#search-wrap .key {
	position: absolute;
	top: 0.03rem;
	right: 0.4rem;
}

// Close button
#search-wrap .act-close {
	top: -0.1rem;
	right: -0.1rem;
}

// Subnav
.subnav-wrap {
	margin-top: -0.15rem;
	height: 1.05rem;
}

/**
 * List-all header
 */

// Search entry
#search-list-all-header h1 {
	flex: 1;
	margin-bottom: 0;
	margin-left: -0.1rem; // Compensate for the quotes
	cursor: pointer;

	// To align lowercase character
	// height with the x button.
	position: relative;
	top: 0.02rem;
}
// Quotes
#search-list-all-header h1 em {
	color: $black-30;
}

// Search info
#search-info {
	font-size: $small;
	line-height: 0.2rem;
	margin-bottom: 0.4rem;
}

// Serach entry
#search-list-all-header .h1-wrap {
	display: flex;
}
#search-list-all-header .edit-entry {
	flex: 1;
	display: flex;
}

// Edit icon
#search-list-all-header .act-edit-text {
	flex: 0;
	margin: -0.1rem 0 -0.1rem -0.1rem;
}

// Close icon
#search-list-all-header .act-close {
	flex: 0;
	margin: -0.1rem -0.1rem -0.1rem 0;
}

// Pagination
.pagination {
	margin-bottom: 0.7rem;
}

/**
 * Results
 */

// Container
#search-results-wrap {
	margin-bottom: 0.3rem;
}
#search-results-wrap .result {
	display: flex;
	gap: 0.1rem;
	margin-bottom: 0.2rem;
}

// Links
#search-results-wrap .result:deep() a:not(:hover):not(.small) {
	color: $black;
}
#search-results-wrap .result:deep() a:not(:hover).small,
#search-results-wrap .result:deep() div.small {
	color: $black-30;
}

// Duplicate name identifiers.
// To test, search for Joel Morrison.
#search-results-wrap .result:deep() .identifier {
	color: $black-30;
}

// Itm count per result.
#search-results-wrap .result .count {
	color: $black-30;
	flex: 0 0 0.35rem;
}

// Dim results while processing.
#search-results-wrap.processing {
	opacity: 0.3;
}

// Artworks
#search-results-wrap .result.preview {
	margin-bottom: 0.1rem;
	gap: 0.2rem;
}
#search-results-wrap .result.preview img {
	width: 120px; // Px value on purpose
	background: $black-05;
}
#search-results-wrap .result.preview .img-placeholder {
	width: 120px; // Px value on purpose
	height: 120px;
	background: $black-05;
}
#search-results-wrap:deep() .itm-title {
	display: block;
}
#search-results-wrap:deep() .artist-name {
	display: block;
	font-weight: 500;
}

// Users
#search-results-wrap .result.user {
	// background: pink;
	align-items: center;
	max-width: 5rem;
}
#search-results-wrap .result.user > a {
	display: flex;
	align-items: center;
	gap: 0.15rem;
	// border: solid 1px red;
	flex: 1;
}
#search-results-wrap .result.user img,
#search-results-wrap .result.user .no-pic {
	width: 0.6rem;
	height: 0.6rem;
	border-radius: 0.3rem;
	background: $black-10;
}
#search-results-wrap .result.user .no-pic {
	display: flex;
	align-items: center;
	justify-content: center;
}
#search-results-wrap .result.user h1 {
	display: flex;
	align-items: center;
	justify-content: center;
	color: $black-30;
	margin: 0;
	text-transform: uppercase;
	position: relative;
	top: 0.03rem;
}
#search-results-wrap .result.user .name {
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 0.05rem;
	justify-content: center;
}
// Slight offset of text next to profile pic for better centering.
#search-results-wrap .result.user:deep() .name .small {
	margin-bottom: -0.05rem;
}

// Double links
// (Rooms & lists)
#search-results-wrap .double {
	display: flex;
	flex-direction: column;
	gap: 0.05rem;
}

/**
 * Hover state
 */

@media (hover: hover) {
	#search-results-wrap .result:deep() a:hover mark.x {
		color: $primary;
	}
	#search-results-wrap .result:deep() a:hover mark {
		color: $primary;
	}
}
</style>
