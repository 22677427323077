<template>
	<div id="img-list" :class="{ dragging }">
		<draggable
			class="wrap"
			v-model="orderedItms"
			@start="dragging = true"
			@end="dragging = false"
			@update.stop="onRearrange"
			item-key="orderedItms"
		>
			<!-- item-key and #item are part of the vuedraggable API - see https://www.npmjs.com/package/vuedraggable/v/next / https://github.com/SortableJS/vue.draggable.next -->
			<!-- #item is shorthand for v-slot:item - see https://vuejs.org/guide/components/slots.html#named-slots -->
			<template #item="{ element }">
				<div
					class="imgItem"
					:class="{ removable }"
					@mouseenter="onMouseEnter"
					@mouseleave="onMouseLeave"
					@mousedown="onMouseDown"
				>
					<a v-if="removable" href="#" @click.prevent=""><BaseIcon name="remove"/></a>
					<BaseImageArt :itm="element" :width="element.width" :height="element.height" :imgSize="imgSize" />
				</div>
			</template>
		</draggable>
	</div>
</template>

<script>
/*
This components uses the vuedraggable library
Note that the vue 3 version has a separate url!
https://www.npmjs.com/package/vuedraggable/v/next
https://github.com/SortableJS/vue.draggable.next
Unfortunately the way this library is built, itms
flash briefly after they are rearranged. To be
replaced with a bespoke solution later on.
*/
import Draggable from 'vuedraggable'
import BaseImageArt from '@/components/BaseImageArt'
import BaseIcon from '@/components/BaseIcon'

export default {
	name: 'RearrangeImages',
	components: {
		Draggable,
		BaseImageArt,
		BaseIcon,
	},
	props: {
		itms: Array,
		removable: Boolean,
		imgSize: {
			type: String,
			default: 'thumb',
		},
	},
	emits: ['rearrange'],
	data() {
		return {
			// We need to deep-clone the image list so we can set a
			// custom heigt without affecting the main image grid.
			orderedItms: (() => {
				const baseHeight = 150
				let order = this.itms.map((itm, i) => ({
					...itm,
					index: i,
					height: baseHeight,
					width: baseHeight * (itm.views[0].w / itm.views[0].h),
				}))
				return order
			})(),

			dragging: false,
		}
	},
	methods: {
		onRearrange() {
			const order = this.orderedItms.map(v => v.index)
			this.$emit('rearrange', order)
		},
	},
}
</script>

<style scoped lang="scss">
#img-list {
	font-size: 0;
	line-height: 0;
	margin-bottom: 0.4rem;
}
#img-list .wrap {
	display: flex;
	gap: 0.1rem;
	flex-wrap: wrap;
}
#img-list .imgItem {
	position: relative;
	cursor: grab;
}

// Shadow overlay & icon
#img-list .imgItem.removable::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 0.6rem;
	background: linear-gradient($black-30, transparent);
	transition: opacity 100ms linear;
	opacity: 0;
}
#img-list .imgItem.removable a {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
}
#img-list .imgItem.removable:deep() .icn {
	margin: 0.1rem;
	fill: $white;
}

@media (hover: hover) {
	// Images
	#img-list:not(.dragging) .imgItem:hover::before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		border: solid 1px $white;
		border-radius: $br;
		opacity: 0.5;
		outline: solid 6px $black-30;
		margin: 0.06rem;
		z-index: 1;
		pointer-events: none;
	}

	#img-list:not(.dragging) .imgItem:hover a {
		display: block;
	}
	#img-list:not(.dragging) .imgItem:hover::after {
		opacity: 1;
	}
	#img-list:not(.dragging) a:hover:deep() .icn {
		fill: $primary;
	}
}
</style>
