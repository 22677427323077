// Functions to modify words or text

// Imports
import moment from 'moment'

// Make parameter title worthy
// Turns 'hello-world' into 'Hello World'
export function titlefy(str) {
	if (!str) return null
	let words = str.split(/[\s-]+/)
	words = words.map(word => word.charAt(0).toUpperCase() + word.slice(1))
	return words.join(' ')
}

// Only capitalize first word
export function capitalize(str) {
	if (!str) return ''
	return str.charAt(0).toUpperCase() + str.slice(1)
}

// Turn lowercase, simplify special characters, remove non-standard latin characters.
// Keep in sync with same helper in API.
export function urlify(str) {
	if (!str) return ''
	// Removing diacritics
	// https://stackoverflow.com/a/37511463/2262741
	str = str
		.trim()
		.toLowerCase()
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '') // Fold diacritics
		.replace(/[–|—]/g, '-') // Replace m- and l-dashes
		.replace(/[^\w\s-]/gi, '') // Remove all special characters
		.replace(/\s+/g, '-') // Replace whitespaces
		.replace(/-+/g, '-') // Replacing spaces with dashes can lead to multiple dashes
	return str
}

// Add commas to large numbers
export function prettyNr(nr, imperial) {
	if (!nr && nr !== 0) return

	if (imperial) {
		return nr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
	} else {
		nr = nr.toString().replace(/,(\d{1,2})$/, '.$1')
		return nr.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
	}
}

// Nicely format date
// Add 'true' to include hour, or otherwise custom moment format
export function prettyDate(date, format) {
	if (!date) return ''
	format = format ? (format === true ? 'MMM D, YYYY - HH:mm' : format) : 'MMM D, YYYY'
	return moment(date).format(format)
}

// Truncate text
export function trunc(str, maxLength) {
	if (!str) return ''
	maxLength = maxLength ? maxLength : 30
	const suffix = str.length > maxLength ? '...' : ''
	return str.slice(0, maxLength) + suffix
}

// Be smart about possessive form ending with -s
export function possessive(str) {
	if (!str) return ''
	return str.slice(-1) == 's' ? str + "'" : str + "'s"
}

// Singular version of plural word
export function singular(str) {
	if (!str) return ''
	return str.replace(/s$/, '')
}

// Add smart article in front of word.
// An artist but a photographer.
export function article(str, returnWithString) {
	const article = str.match(/^[aeiou]/) ? 'an' : 'a'
	return returnWithString ? `${article} ${str}` : article
}

// Trim the 'http://www.' from any url
export function displayUrl(url, options) {
	const { showSlug } = options || {}
	url = url.replace(/^http(s)?:\/\/(www\.)?/, '')
	if (!showSlug) {
		url = url.split('?')[0]
		url = url.split('/')[0]
	}
	return url
}
