import { defineStore } from 'pinia'

// Internal
import { inputInFocus } from '@/use/Helpers'

export const useDialogStore = defineStore('DialogStore', {
	state: () => ({
		// Dialog content
		dialogName: null, // Name of dialog component from @/dialogs
		paramsDefault: {
			// Used to reset the parameters.
			okText: 'Confirm',
			cancelText: 'Cancel',
		},
		params: {
			okText: 'Confirm',
			cancelText: 'Cancel',

			// Additional options:
			// - - -
			// title: String, // Dialog title
			// message: String, // Dialog text
			// html: Boolean, // Render message as HTML or plain text (default plain).
			// hideCancel: Boolean, // Hide cancel button
			// warning: String or Boolean // Red 'Warning!' or custom text before title.
			// persistent: Boolean // Forces user to click cancel (soft-exit by clicking outside or ESC are blocked)
			// width: Number, // Custom dialog width (default is 250) - Not used
			// actionKey: String // When set, the user has to re-type this string to confirm a critical confirm dialog.

			// Callbacks:
			// - - -
			// validate: Function, // A validation function that will block confirm when it returns false.
			// onConfirm: Function, // Callback when you click OK
			// onCancel: Function, // When you click cancel

			// Note: individual dialogs will have their own bespoke parameters that are also added here.
		},

		// UI controllers
		showShadow: false,
		showDialog: false,
		showDialogTimeout: null,
		flashRed: false,
		flashRedTimeout: null,
		waitingSubmit: false,
		waitingCancel: false,
		skipTransition: false,
		ignoreEnterKey: false, // By default the entyer key is blocked, DialogMain enables it but most bespoke dialogs have their own logic.

		// Critical confirm
		critical: {
			ipValue: '',
			error: false,
		},
	}),
	getters: {
		isActive: state => !!state.dialogName,
	},
	actions: {
		/**
		 * Shortcuts for different types of dialogs.
		 */

		// Plain text dialog.
		dialog(message, params) {
			this._activateDialog('DialogMain', {
				message,
				...params,
			})
		},

		// Confirm dialog.
		confirm(onConfirm, message, params) {
			this._activateDialog('DialogMain', {
				message: message ? message : 'Are you sure about this?',
				okText: 'Confirm',
				onConfirm,
				...params,
			})
		},

		// Critical confirm dialog.
		confirmCritical(onConfirm, options) {
			const { title, actionKey, params } = options || {}
			this._activateDialog('DialogMain', {
				warning: 'Warning!',
				title: title || 'Permanent Deletion',
				actionKey: actionKey || 'delete',
				onConfirm,
				...params,
			})
		},

		// Dialog by name
		dialogByName(name, params) {
			// console.log('dialogByName', name, params)
			this._activateDialog(name, params)
		},

		//
		//
		//
		//

		/**
		 * Custom dialogs need to set the
		 * callbacks from their mounted hook.
		 */

		setValidation(func) {
			this.params.validate = func
		},

		setOnCancel(func) {
			this.params.onCancel = func
		},

		setOnConfirm(func) {
			console.log('SET onConfirm')
			this.params.onConfirm = func
		},

		// Dialogs that have their own logic usually don't
		// want the enter key to close them.
		disableEnterKey() {
			this.ignoreEnterKey = true
		},

		// To set non-standard parameters that are
		// specific to individual dialogs.
		setCustomParams(obj) {
			Object.keys(obj).forEach(key => {
				this.params[key] = obj[key]
			})
		},

		//
		//
		//
		//

		/**
		 * Handling showing/hiding of the dialog.
		 */

		// Triggered from cancel button / esc / click outside.
		async doCancel() {
			// console.log('doCancel')
			// Trigger callback
			const { onCancel } = this.params
			if (onCancel && typeof onCancel == 'function') {
				this.waitingCancel = true
				await onCancel()
				this.waitingCancel = false
			}

			// Close
			this._transitionOut()
		},

		// Triggered from confirm button / enter.
		async doConfirm() {
			if (this.ignoreEnterKey || !this.showDialog) return
			this.waitingSubmit = true

			// Critical confirm dialog requires user
			// to type an action key to continue.
			if (!this._verifyActionKey()) {
				this.waitingSubmit = false
				return
			}

			// Check if there's a validation to be done before continuing.
			if (this.params.validate && typeof this.params.validate == 'function') {
				const validate = await this.params.validate()
				if (!validate) {
					this.waitingSubmit = false
					return
				}
			}

			// Trigger callback
			const { onConfirm } = this.params
			if (onConfirm && typeof onConfirm == 'function') {
				await onConfirm()
				this.waitingSubmit = false
			}

			// When another dialog is opened via onConfirm
			// the nested flag is set to true to avoid the
			// confirm action to close the dialog, but we
			// then have to immediately reset it to false
			// so the next time it works.
			if (this.params.nested) {
				this.params.nested = false
				return
			}

			// Close
			this._transitionOut()
		},

		// Stop & show error is typed value is not matching the required action key.
		_verifyActionKey() {
			if (!this.params.actionKey) return true
			const valid = this.critical.ipValue == this.params.actionKey
			this.critical.error = !valid
			return valid
		},

		// Exiting the dialog by clicking outside or pressing ESC.
		// This triggers doCancel unless the dialog is persistent.
		softClose(event) {
			// console.log('softClose', event)
			// When you select text and release the mouse outside the dialog,
			// a click is triggered (classic UX bug). // By checking if it's
			// an actual event, we stil allow the Escape key and back button
			// to let the dialog close.
			if (event && event.target) {
				const inputFocus = inputInFocus()
				if (inputFocus) return
			}

			// Block if persistent
			if (this.params.persistent) {
				// Flash background red to show you can't close it
				this.flashRed = true
				this.flashRedTimeout = setTimeout(() => {
					this.flashRed = false
				}, 500)
				return
			}

			// Hide
			this.doCancel()
		},

		_transitionOut() {
			// console.log('_transitionOut')
			this.skipTransition = false
			this.showDialog = false
			this.showDialogTimeout = setTimeout(() => {
				this.showShadow = false
			}, 1)
		},

		// Triggered from the mounted hook in TheDialog.vue.
		// - - -
		// Because we have one transition embedded into another,
		// we need to have a tiny delay between both,
		// otherwise the inner transition is ignored.
		transitionIn() {
			this.showShadow = true
			this.showDialogTimeout = setTimeout(() => {
				this.showDialog = true
			}, 1)
		},

		//
		//
		//
		//

		// Called locally from the public functions.
		_activateDialog(name, params) {
			// When we're opening a dialog from within another dialog,
			// we need to skip the animation.
			if (this.dialogName) {
				// console.log('SET nested', this.dialogName)
				this._resetParams()
				this.skipTransition = true
				this.params.nested = true
			}

			this.dialogName = name
			this.params = { ...this.params, ...params }

			// When a dialog is opened from a button or link,
			// the button needs to be blurred or it will interfere
			// with the enter button functionality in the dialog.
			const focusElm = document.querySelector('a:focus, button:focus')
			if (focusElm) focusElm.blur()
		},

		// This is triggered from the transition hook
		// v-on:after-leave="dialogStore.closeDialog"
		// in TheDialog.vue.
		deactivateDialog() {
			this.$reset()
		},

		// Reset parameters
		_resetParams() {
			this.params = this.paramsDefault
		},
	},
})
