/**
 * Display formatted dimensions.
 * - - -
 * @param {Number} h_w_d { height, width, depth }
 * @param {String} unit mm / cm / in / ft
 * - - -
 * @returns Something like 39 ⅜ x 59 x 47 in
 */
function displayDimensions(dim, unit) {
	let { h, w, d } = dim || {}
	if (!dim || (!h && !w && !d) || !unit) return null

	// Calculate inch or cm
	h = mmToUnit(h, unit, true) || null
	w = mmToUnit(w, unit, true) || null
	d = mmToUnit(d, unit, true) || null

	// Feet are notated at 6'3" so we don't actually show the unit 'ft'
	unit = unit == 'ft' ? '' : unit

	// Render string
	if (h && w && d) {
		return [h, w, d].join(' x ') + ' ' + unit
	} else if (h && w) {
		return [h, w].join(' x ') + ' ' + unit
	} else {
		return null
	}
}

/**
 * Convert milimeter value into desired unit.
 * - - -
 * @param {Number} mm Milimeter value to be converted
 * @param {String} unit mm / cm / in / ft
 * @param {Boolean} display Output display format (3¾") or numeric format (3.75)
 * - - -
 * @returns Converted value, in display or numeric format
 */
function mmToUnit(mm, unit, display) {
	if (!mm) return null

	if (unit == 'mm') return mm
	if (unit == 'cm') return _mmToCm(mm)
	if (unit == 'm') return _mmToM(mm)
	if (unit == 'in') return _mmToInch(mm)
	if (unit == 'ft') return _mmToFt(mm)

	//
	//

	// Convert to cm
	function _mmToCm(mm) {
		// Show decimals below 10 cm
		if (mm < 100) {
			return mm / 10
		} else {
			return Math.round(mm / 10)
		}
	}

	function _mmToM(mm) {
		// Show decimals below 10 m
		if (mm < 10000) {
			return mm / 1000
		} else {
			return Math.round(mm / 1000)
		}
	}

	// Convert to inch
	function _mmToInch(mm) {
		// Round values over 1m
		if (mm > 1000) {
			return Math.round(mm / 25.4)
		}

		// Render inches with fractions below 1m
		const inch = Math.round(mm / 3.175) / 8
		if (display) {
			const decimalSplit = String(inch).split('.')
			let decimals = decimalSplit[1]
			const fractions = {
				'125': '⅛',
				'25': '¼',
				'375': '⅜',
				'5': '½',
				'625': '⅝',
				'75': '¾',
				'875': '⅞',
			}
			decimals = decimals ? ` ${fractions[decimals]}` : ''
			return decimalSplit[0] + decimals
		} else {
			return inch
		}
	}

	// Convert to feet
	function _mmToFt(mm) {
		const ft = mm / 304.8
		if (display) {
			const ftFloor = Math.floor(ft)
			const inch = Math.round((ft - ftFloor) * 12)
			return `${ftFloor}'${inch}"`
		} else {
			return Math.round(ft * 100) / 100
		}
	}
}

/**
 * Convert unit value to milimeter
 * - - -
 * @param {Number} value Measurement in any unit
 * @param {String} unit Unit the measurement is in
 * - - -
 * @returns Measurement in mm
 */
function unitToMm(value, unit) {
	if (!value || !unit) return null

	value = sanitizeNumber(value)

	const multipliers = {
		mm: 1,
		cm: 10,
		m: 1000,
		in: 25.4,
		ft: 304.8,
	}
	const factor = multipliers[unit]

	return Math.round(+value * factor)
}

/**
 * Input hygiene
 * We're dealing with commas and decimals being used interchangeably as
 * decimal separator and large number spacer (US/EU/Intl) so converting
 * this into the correct numeric value up is a little intricate.
 * - - -
 * @param {value} value Input with only numbers, commas and period
 * - - -
 * @returns Clean numeric value
 */
function sanitizeNumber(value, debug) {
	if (!value) return null

	value = String(value)

	// More than one period --> used as spacer, remove all
	// 1.000.000,00 --> 1000000,00
	_log('\n', value)
	if ((value.match(/\./g) || []).length > 1) value = value.replace(/\./g, '')
	_log(1111, value)

	// More than one comma --> used as spacer, remove all
	// 1,000,000.01 --> 1000000.01
	if ((value.match(/,/g) || []).length > 1) value = value.replace(/,/g, '')
	_log(222, value)

	// One comma and one period --> first will be spacer, second decimal separator
	// 1,234.01 --> 1234.01
	// 1.234,01 --> 1234,01
	if ((value.match(/,|\./g) || []).length == 2) {
		value = value.replace(/^(\d+?)(,|\.)(\d+?)(,|\.)(\d+?)$/, '$1$3.$5')
	}
	_log(333, value)

	// Remove any spaces
	value = value.replace(/\s/g, '')
	_log(444, value)

	// Starting with a period or comma --> add zero, remove all other perdios and commas
	// ,100,000 --> 0.100000
	// ,100.000 --> 0.100000
	// .100.000 --> 0.100000
	// .100,000 --> 0.100000
	if (value.match(/^(,|\.)/)) value = '0.' + value.replace(/,|\./g, '')
	_log(555, value)

	// Ending with period or comma --> remove
	// 1000, --> 1000
	value = value.replace(/(,|\.)$/, '')
	_log(666, value)

	// Change european comma decimals into period decimals
	// 100,10 --> 100.10
	value = value.replace(/,(\d+)$/, '.$1')
	_log(777, value)

	_log(888, +value)

	return +value

	//
	//

	function _log(value1, value2) {
		if (debug) console.log(value1, value2)
	}
}

/**
 * Get optimal unit based on size.
 * - - -
 * @param {Object} dim { h,w,d }
 * @param {Boolean} imperial Whether to use in/ft or cm/m
 * - - -
 * @returns Optional unit: m/ft or cm/in
 */
function getOptimalUnit(dim, imperial) {
	// Return default if no dimensions are set.
	if (!dim || !Object.keys(dim).length) return imperial ? 'in' : 'cm'

	let nonZero = Object.entries(dim).filter(entry => !!entry[1])
	nonZero = nonZero.map(entry => entry[1])
	const lowestValue = Math.min(...nonZero)
	if (imperial) {
		// Over 10ft / 120in --> use ft
		return lowestValue > 3048 ? 'ft' : 'in'
	} else {
		// Over 3m --> use m
		return lowestValue > 3000 ? 'm' : 'cm'
	}
}

module.exports = { displayDimensions, mmToUnit, unitToMm, sanitizeNumber, getOptimalUnit }
