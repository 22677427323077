// Vue
import createApp from '@/app'
import createRouter from '@/router'
import { createPinia } from 'pinia'
import { markRaw } from 'vue'

// Stores
import { useGlobalStore } from '@/stores/GlobalStore'
import { useSsrCookieStore } from '@/stores/SsrCookieStore'
import { useApiStore } from '@/stores/ApiStore'

// Internal
import { isSSR } from '@/use/Base'

//
;(async () => {
	// Make pinia available in store
	const pinia = createPinia()

	if (window.__PINIA_INITIAL_STATE__) {
		// Transfer store state from server to client.
		// - - -
		// Note: This only transfers the state, but not any dynamically
		// registered modules, so these need to be re-registered in the
		// frontend with { preserveState: true }
		// https://github.com/vuejs/vue-loader/issues/1887
		// console.log('Transfer state server --> client')
		pinia.state.value = window.__PINIA_INITIAL_STATE__
		delete window.__INITIAL_STATE__
		delete window.__PINIA_INITIAL_STATE__

		// Apply SSR cookies to client browser.
		const ssrCookieStore = useSsrCookieStore(pinia)
		ssrCookieStore.applyApiCookies()
	} else if (process.env.NODE_ENV != 'development') {
		// Warn if state didn't transfer.
		const warning = [
			`client-entry.js / env: ${process.env.NODE_ENV}`,
			'Vuex initial state is undefined. This is probably because NOVE_ENV is not set to production.',
			'This prevents auth and ssrCookie data to be sent to client side, which might cause minor glitches.',
			'To fix this, disable NOTE_ENV on staging and instead link the fake-production-staging environment variable group.',
		]
		console.warn(warning.join('\n'))
	}

	// Create router
	const router = isSSR ? null : createRouter(pinia)

	// Make router available in Pinia.
	// https://github.com/vuejs/pinia/discussions/1092#discussioncomment-2250146
	const globalStore = useGlobalStore()
	pinia.use(({ store }) => {
		store.$router = markRaw(router)
		store.$gl = globalStore
	})

	// Initialize API Store with store & router.
	const apiStore = useApiStore()
	apiStore.init(pinia, router)

	// Create app.
	const { app } = createApp({
		pinia,
		router,
	})
	await router.isReady()
	await app.mount('#app', true)
})()
