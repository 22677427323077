<template>
	<div class="text-loader">{{ loading }}</div>
</template>

<script>
export default {
	name: 'BaseLoadingText',
	data() {
		return {
			dots: 0,
			loading: '',
			animateTimeout: null,
		}
	},
	mounted() {
		setTimeout(this.animateDots, 300)
	},
	beforeUnmount() {
		clearTimeout(this.animateTimeout)
	},
	methods: {
		animateDots() {
			this.dots = (this.dots + 1) % 4
			this.loading = '. '.repeat(this.dots)
			this.animateTimeout = setTimeout(this.animateDots, 100 + this.dots * 30)
		},
	},
}
</script>

<style scoped lang="scss">
.text-loader {
	height: 0.2rem;
	line-height: 0.2rem;
}
</style>
