<template>
	<component
		:is="label ? 'label' : 'div'"
		:class="{
			wrap: 1,
			good: validation == 'good',
			bad: validation == 'bad',
			disabled,
		}"
		:style="style"
	>
		<!-- Label text -->
		<div v-if="label" class="label-text">
			{{ label }}<span v-if="theError" class="error"> {{ theError }}</span
			><a href="#" @click.prevent class="ip-toggle-wrap" v-else-if="toggleName"
				>{{ toggleName }}<FormToggle tiny v-model="theToggleValue"
			/></a>
			<a
				href="#"
				v-if="helper"
				@click.prevent="$emit('helperClick')"
				@mouseenter="$emit('helperMouseEnter')"
				@mouseleave="$emit('helperMouseLeave')"
				>{{ helper }}
			</a>
		</div>

		<!-- Locked input -->
		<div v-if="readOnly || locked" class="read-only-wrap" :class="{ locked }">
			<div class="form-elm" :class="{ error }">
				{{ readOnlyValue }}
			</div>
		</div>

		<!-- Input -->
		<component
			v-else
			:is="type == 'textarea' ? 'textarea' : 'input'"
			ref="ip"
			:id="id"
			:value="theValue"
			@input="theValue = $event.target.value"
			:type="type == 'textarea' ? null : type"
			:placeholder="placeholder || label"
			:autocomplete="autocomplete"
			:class="{
				'form-elm': 1,
				error: theError,
				lowercase,
			}"
			:maxlength="maxlength"
			tabindex="1"
			:disabled="disabled"
			@focus="onFocus"
			@blur="onBlur"
			@keydown="emitKeydown"
			@keyup="emitKeyup"
			@paste="onPaste"
		></component>
		<!-- <div v-if="type == 'textarea'" style="flex: 1 1"></div> Still need this?? -->
		<div class="icon-wrap" :class="{ labelBump: label }">
			<BaseIcon v-if="validation" :name="`mark-${validation}`" />
			<BaseIcon v-if="locked" name="lock" />
		</div>
	</component>
</template>

<script>
import { nextTick } from 'vue'
import FormItems from '@/mixins/mx_FormItems'
import FormItemsStyle from '@/mixins/mx_FormItemsStyle'
import BaseIcon from '@/components/BaseIcon'
import FormToggle from '@/components/FormToggle'

export default {
	name: 'FormText',
	components: { BaseIcon, FormToggle },
	mixins: [FormItems, FormItemsStyle],
	props: {
		type: {
			type: String,
			default: 'text',
		},
		placeholder: String,

		// Input helper tip
		// Why doesn't disabling this throw a warning?
		helper: String,

		// Optional toggle
		toggleName: String,
		toggleValue: Boolean,

		// HTML5 autocomplete instructions
		// https://tinyurl.com/3cwfevvk
		autocomplete: String,

		// This will auto focus as soon as element is rendered
		// Used in dialog
		autoFocus: {
			type: Boolean,
			default: false,
		},

		// RegEx with all characters to block, eg. [<>]
		// To limit to a certain set instead, just do [^0-9]
		preventKeys: {
			type: String,
			default: '',
		},

		// Style lowercase
		lowercase: {
			type: Boolean,
			default: false,
		},

		// These will cause good/bad marks to appear
		valid: {
			type: Boolean,
			default: null,
		},

		// Will display non-editable input
		readOnly: Boolean,
		readOnlyValue: String,
		// Will display a lock icon
		locked: Boolean,

		// HTML props
		id: String,
		maxlength: [Number, String],
		disabled: Boolean,
	},
	emits: [
		'blur',
		'focus',
		'keyup',
		'keydown',
		'paste',
		'helperClick',
		'helperMouseEnter',
		'helperMouseLeave',
		'update:toggleValue',
	],
	computed: {
		// The way we render the validation checkmark.
		validation() {
			return this.valid ? 'good' : this.valid === false ? 'bad' : null
		},

		// Toggle v-model
		theToggleValue: {
			get() {
				// if (!this.toggleName) return
				return this.toggleValue
			},
			set(val) {
				// if (!this.toggleName) return
				this.$emit('update:toggleValue', val)
			},
		},
	},
	watch: {
		theError(newValue) {
			if (newValue) this.select()
		},
	},
	mounted() {
		if (this.autoFocus) {
			this.focus()
		}
	},
	methods: {
		onBlur(e) {
			this.$emit('blur', e)
		},
		onFocus(e) {
			this.$emit('focus', e)
		},
		emitKeydown(e) {
			// Keys to ignore when we're limiting keys:
			// backspace, tab, arrow-left, arrow-top, arrow-right, arrow-bottom, delete, home, end
			const ignore = [8, 9, 37, 38, 39, 40, 46, 36, 35]

			// Prevent selected keys from entering
			if (this.preventKeys && !e.metaKey && !e.ctrlKey) {
				const regEx = new RegExp(this.preventKeys)
				if (e.key.match(regEx) && !ignore.includes(e.which)) {
					e.preventDefault()
				}
			}

			// Emit
			this.$emit('keydown', e)
		},
		emitKeyup(e) {
			// Display error when we're at max capacity,
			// but remove it after 1 sec.
			if (this.maxlength && this.theValue) {
				if (this.theValue.length >= this.maxlength) {
					this.internalError = `Max ${this.maxlength} chars`
					setTimeout(() => {
						this.internalError = null
					}, 1000)
				} else {
					this.internalError = null
				}
			}

			this.$emit('keyup', e)
		},

		// Paste event
		async onPaste(e) {
			this.$emit('paste', e)

			// If e.preventDefault is called within the emit function, we abort.
			if (e.defaultPrevented) return

			// Remove prohibited characters from any pasted string.
			if (this.preventKeys) await this.pasteLimited(e)

			// Trim the value if you paste text and exceed the maxlength.
			// Need to stringify because when you paste, the value could be a number.
			this.theValue = this.theValue ? String(this.theValue).slice(0, this.maxlength) : ''
		},

		// Paste values without excluded characters.
		async pasteLimited(e) {
			const value = e.clipboardData.getData('Text')
			const regEx = new RegExp(this.preventKeys, 'gi')
			// prettier-ignore
			if (value.match(regEx)) {
				let currentValue = String(this.theValue)

				// Sanitize prohibited characters
				const pastedValueSanitized = value.replace(regEx, '')

				// Find the caret and insert sanitized pasted value after removing selected characters.
				const inputElm = this.$refs.ip
				const { selectionStart, selectionEnd } = inputElm
				let newValue = currentValue.split('')
				newValue.splice(selectionStart, selectionEnd - selectionStart, pastedValueSanitized)
				newValue = newValue.join('')

				// Paste only allowed characters
				this.theValue = newValue

				// Set caret
				await nextTick()
				const caretPos = selectionStart + pastedValueSanitized.length
				inputElm.setSelectionRange(caretPos, caretPos)

				// Block native paste event
				e.preventDefault()
			}
		},

		// External functions
		async focus() {
			await nextTick()
			this.$refs.ip.focus()
		},
		async select() {
			await nextTick()
			this.$refs.ip.select()
		},
		blur() {
			this.$refs.ip.blur()
		},
	},
}
</script>

<style src="../assets/css/form-items.scss" lang="scss" scoped></style>
<style lang="scss" scoped>
// General
label {
	text-align: left;
}

// Input
.form-elm {
	height: 0.4rem;
	border: solid 0.01rem $black-15;
	padding: 0 0.13rem;
	border-radius: $br;
	font-family: 'Barlow', sans-serif;
	font-weight: 400;
	font-size: $regular;
}
.form-elm.lowercase {
	text-transform: lowercase;
}
input.form-elm {
	line-height: 0.4rem; /* Needed for Barlow Font */
}

// Textarea
textarea.form-elm {
	min-height: 1.5rem;
	height: 100%;
	padding: 0.1rem 0.13rem;
}
label.wrap > textarea.form-elm {
	min-width: 100%;
}

// Locked / read-only state
.read-only-wrap {
	display: flex;
	width: 100%;
}
.read-only-wrap .form-elm {
	border-style: dashed;
	padding-right: 0.4rem;
	line-height: 0.38rem;

	// Truncate
	display: block;
	flex: 1;
	min-width: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/**
 * Icons
 */
.wrap {
	position: relative;
}
.icon-wrap {
	position: absolute;
	top: 0;
	right: 0;
	padding: 0.1rem;
	width: 0.4rem;
	height: 0.4rem;
}
.icon-wrap.labelBump {
	top: 0.2rem;
}

// Lock icon
.read-only-wrap + .icon-wrap:deep() svg.icn {
	display: block;
	fill: $black-30;
}

// Validation icon
.wrap.good:deep() svg.icn {
	stroke: $good;
	display: block;
}
.wrap.bad:deep() svg.icn {
	stroke: $bad;
	display: block;
}

// Shorten text width when icon is visible
.wrap.bad .form-elm,
.wrap.good .form-elm,
.read-only-wrap.locked .form-elm {
	padding-right: 0.4rem;
}

// Free-form Toggle
.label-text:deep() .wrap {
	// overriding form-items.css
	display: block;
	float: right;
	width: auto !important;
	margin: 0 0 0 0.1rem;
	float: right;
}
label.wrap:deep() .form-elm.tiny {
	margin-right: 0;
}
a.ip-toggle-wrap,
a.ip-toggle-wrap:hover,
a.ip-toggle-wrap:active {
	// Note: this is an <a> element so we can detect it as a relatedTarget
	// during the input onBlur event. This is used on the artist edit page,
	// when you add an artist and blur the input without typing, we remove
	// it again, unless you're blurring because you clicked on the toggle.
	color: $black;
}

// Focus
.form-elm:focus {
	border: solid 0.01rem $primary;
	outline: none;
}

// Error
.form-elm.error {
	border: solid 0.01rem $bad;
}

// Disabled
input[disabled] {
	color: $black-30;
	background: transparent;
}
textarea[disabled] {
	color: $black-30;
	background: transparent;
}
</style>
