/**
 * Format Year
 * - - -
 * Takes the year range from the database and displays the year or period in the correct format.
 * Eg. [1900, 2000] ---> 20th century
 * Different formats: 1917, 1920s, 20th century, 5000 BC, 600 AD, 6th century BC, etc.
 * Preview: http://arthur.io/doc/mechanics/format-year
 * - - -
 * @param {Array} yearRange [<year_or_period_start>, <optional_period_end>]
 * @returns Formatted year
 */
module.exports = function(yearRange) {
	if (!yearRange) return
	const circa = yearRange[2] ? 'ca. ' : ''
	// Remove null or undefined valued (mostly needed for our demo page)
	// Then make sure lowest year comes first
	yearRange = yearRange
		.slice(0, 2)
		.filter(y => !!y || y === 0)
		.sort((a, b) => a - b)
	const y1 = yearRange[0]
	const y2 = yearRange[1]
	const year = _isSingleYear() || _isNthCentury() || _isNthDecade() || _isPeriod()
	return circa + year

	// Single year
	function _isSingleYear() {
		if (!y2 && y2 !== 0) {
			if (y1 < 0) {
				// 500 BC
				return `${Math.abs(y1)} BC`
			} else if (y1 < 1000) {
				// 500 AD
				return `${y1} AD`
			} else {
				// 1917
				return y1
			}
		}
	}

	// 1920s
	function _isNthDecade() {
		if (y1 < 1000) return false
		const y1Decade = Math.round(y1 / 10) == y1 / 10
		const y2Decade = Math.round(y2 / 10) == y2 / 10
		let isDecade = y1Decade && y2Decade
		isDecade = Math.abs(y2) - Math.abs(y1) == 10
		return isDecade ? `${y1}s` : false
	}

	// 20th century or 16th-18th century
	function _isNthCentury() {
		const y1Century = Math.round(y1 / 100) == y1 / 100
		const y2Century = Math.round(y2 / 100) == y2 / 100
		const isSingleCentury = y2 - y1 == 100 || y1 - y2 == 100
		const isCenturySpan = y1Century && y2Century
		if (isSingleCentury) {
			const century = y1 < 0 ? Math.abs(y1) / 100 : (Math.abs(y1) + 100) / 100
			const ordinal = _ordinal(century)
			const BC = y1 < 0 ? ' BC' : null
			const AD = y1 == 0 ? ' AD' : null // Only display AD for 1st century
			const BCAD = BC || AD || ''
			return `${century}${ordinal} century${BCAD}`
		} else if (isCenturySpan) {
			const century1 = y1 < 0 ? Math.abs(y1) / 100 : (Math.abs(y1) + 100) / 100
			const century2 = y2 < 0 ? (Math.abs(y2) + 100) / 100 : Math.abs(y2) / 100
			const ordinal1 = _ordinal(century1)
			const ordinal2 = _ordinal(century2)
			if (y1 < 0 && y2 > 0) {
				// Span BC-AD
				// 3rd century BC - 3rd century AD
				// When listing BC/AD spans, the word century is repeated,
				// and we leave a space around the dash and add AD for clarity
				return `${century1}${ordinal1} century BC - ${century2}${ordinal2} century AD`
			} else if (y1 < 0 && y2 < 0) {
				// Fully BC
				// We flip the centuries when they're both BC for readability
				return `${century2}${ordinal2}-${century1}${ordinal1} century BC`
			} else {
				// Fully AD
				return `${century1}${ordinal1}-${century2}${ordinal2} century`
			}
		} else {
			return false
		}
	}

	// Period
	function _isPeriod() {
		if (y1 < 0 && y2 < 0) {
			// 500-600 BC
			return `${Math.abs(y2)}-${Math.abs(y1)} BC` // Note how we flip y1 & y2
		} else if (y1 < 0) {
			// 100 BC - 100 AD
			return `${Math.abs(y1)} BC-${y2} AD`
		} else if (y1 < 1000 || y2 < 1000) {
			// 500-600 AD
			// 900-1100 AD
			return `${y1}-${y2} AD`
		} else {
			return `${y1}-${y2}`
		}
	}

	function _ordinal(number) {
		if (number == 1) return 'st'
		if (number == 2) return 'nd'
		if (number == 3) return 'rd'
		return 'th'
	}
}
