// Vue
import { createRouter, createMemoryHistory, createWebHistory } from 'vue-router'

// Stores
import { useAuthStore } from '@/stores/AuthStore'
import { useMeStore } from '@/stores/MeStore'
import { useHttpErrorStore } from '@/stores/HttpErrorStore'
import { useApiStore } from '@/stores/ApiStore'
import { useSessionStore } from '@/stores/SessionStore'

// Internal
import { isSSR, isSSRClient } from '@/use/Base'
import artCategories from '@/use/ArtCategories'

// Components
// We import the itm & search pages so there's no delay with
// updating the route when the carousel or search are opened.
import ItmMain from '@/views/Itm/ItmMain'
import SearchPage from '@/views/SearchPage'

export default function(pinia) {
	// We need to pass the store to the useStore()
	// function to prevent pinia from sharing a global
	// state between different application instances.
	// https://pinia.vuejs.org/core-concepts/outside-component-usage.html
	//
	// Note: declaring a store inside the router, means
	// this.$router will no longer be available in that store.
	const authStore = useAuthStore(pinia)
	const meStore = useMeStore(pinia)
	const httpErrorStore = useHttpErrorStore(pinia)

	const routes = [
		/**
		 *
		 *
		 * Testing
		 */
		{
			path: '/sandbox1',
			name: 'Sandbox1',
			meta: { accessLevel: 2, hideFooter: true, hideNav: false },
			component: () => import(/* webpackChunkName: 'sandbox1' */ './views/TheSandbox1'),
		},
		{
			path: '/sandbox2',
			name: 'Sandbox2',
			meta: { accessLevel: 2, hideFooter: true, hideNav: true },
			// beforeEnter: async (to, from, next) => {
			// 	const x = await itmApi.get('pablo-picasso', 'guernica')
			// 	const testData = x.data
			// 	to.params.testData = testData
			// 	next()
			// },
			component: () => import(/* webpackChunkName: 'sandbox2' */ './views/TheSandbox2'),
		},

		/**
		 *
		 *
		 * Base Pages
		 */

		{
			path: '/login',
			name: 'Login',
			meta: { hideFooter: true, hideNav: true, guestOnly: true },
			component: () => import(/* webpackChunkName: 'auth-page' */ './views/Auth/AuthPage'),
		},
		{
			path: '/signup',
			name: 'Signup',
			meta: { hideFooter: true, hideNav: true, guestOnly: true },
			component: () => import(/* webpackChunkName: 'auth-page' */ './views/Auth/AuthPage'),
		},
		{
			path: '/wait-list',
			name: 'WaitList',
			meta: { hideFooter: true, hideNav: true, guestOnly: true },
			component: () => import(/* webpackChunkName: 'auth-page' */ './views/Auth/AuthPage'),
		},
		{
			path: '/',
			name: 'HomeTemp',
			meta: { guestOnly: true },
			component: () => import(/* webpackChunkName: 'home' */ './views/HomePage'),
		},
		{
			path: '/beta',
			name: 'Home',
			// meta: { guestOnly: true },
			component: () => import(/* webpackChunkName: 'home-main' */ './views/Home/HomeMain'),
			redirect: { name: 'Featured' },
			children: [
				{
					path: '',
					name: 'Featured',
					component: () =>
						import(/* webpackChunkName: 'home-featured' */ './views/Home/HomeFeatured'),
					// This beforeEnter should be removed once we're ready for prime time
					beforeEnter: (to, from, next) => {
						if (from.query.preview != 1 && to.query.preview != '1') {
							// console.log('####')
							next({ name: 'HomeTemp' })
						} else {
							next()
						}
					},
				},
				{
					path: 'explore',
					name: 'Explore',
					component: () =>
						import(/* webpackChunkName: 'home-explore' */ './views/Home/HomeExplore'),
					// This beforeEnter should be removed once we're ready for prime time
					beforeEnter: (to, from, next) => {
						if (from.query.preview != 1 && to.query.preview != '1') {
							// console.log('####')
							next({ name: 'HomeTemp' })
						} else {
							next()
						}
					},
				},
				{
					path: 'about',
					name: 'About',
					component: () => import(/* webpackChunkName: 'home-about' */ './views/Home/HomeAbout'),
					// This beforeEnter should be removed once we're ready for prime time
					beforeEnter: (to, from, next) => {
						if (from.query.preview != 1 && to.query.preview != '1') {
							// console.log('***')
							next({ name: 'AboutTemp' })
						} else {
							next()
						}
					},
				},
			],
		},
		{
			path: '/feed',
			name: 'Feed',
			meta: { accessLevel: 1 },
			component: () => import(/* webpackChunkName: 'feed' */ './views/Feed/FeedMain'),
			children: [
				{
					path: 'staff-picks',
					name: 'FeedStaffPicks',
					component: () =>
						import(/* webpackChunkName: 'feed-staff-picks' */ './views/Feed/FeedMain'),
				},
				{
					path: 'random',
					name: 'FeedRandom',
					component: () => import(/* webpackChunkName: 'feed-random' */ './views/Feed/FeedMain'),
				},
			],
		},
		{
			path: '/search',
			name: 'Search',
			redirect: { name: 'SearchEntity', params: { entityType: 'artist' } },
		},
		{
			path: '/search/:entityType/:page?',
			name: 'SearchEntity',
			props: true,
			// beforeEnter: (to, from, next) => {
			// 	// When a query is set, we forward to page 1.
			// 	if (to.query.q && !to.params.page) {
			// 		next({
			// 			name: 'SearchEntity',
			// 			params: {
			// 				entityType: to.params.entityType,
			// 				page: 1,
			// 			},
			// 			query: to.query,
			// 		})
			// 	} else {
			// 		next()
			// 	}
			// },
			meta: { hideFooter: true, hideNav: true },
			// component: () => import(/* webpackChunkName: 'search' */ './views/SearchPage'),
			component: SearchPage,
		},
		{
			path: '/me/:page?',
			name: 'Me',
			redirect: to => {
				const username = authStore.myUsername
				if (!username) {
					// Not logged in --> go home
					return { name: 'Home' }
				} else if (to.params.page) {
					// Forward to sub-page (eg. rooms)
					return '/' + username + to.fullPath.slice(3)
				} else {
					// Forward to user
					return { name: 'User', params: { username } }
				}
			},
		},
		{
			path: '/upload',
			name: 'Upload',
			beforeEnter: async (to, from, next) => {
				// Extract data from url query (input from browser extension)
				// One image:		?tfn=abc
				// Multiple images:	?tfn=abc&tfn=xyz --> creates array
				// Temp filename, domain, page, image url
				const { tfn, dom, path, img } = to.query
				let urlFileNames = tfn ? (Array.isArray(tfn) ? tfn : [tfn]) : []

				// Assemble origin object that will be stored in the database and on B2.
				const domains = dom ? (Array.isArray(dom) ? dom : [dom]) : []
				const paths = path ? (Array.isArray(path) ? path : [path]) : []
				const imgUrls = img ? (Array.isArray(img) ? img : [img]) : []
				const origins = urlFileNames.map((fn, i) => {
					let originalFileName = fn.match(/^tmp-\w+\.\w+\.(.+$)/)
					originalFileName = originalFileName ? originalFileName[1] : 'parse-error'
					return {
						img: imgUrls[i],
						dom: domains[i],
						path: paths[i],
						fn: originalFileName,
					}
				})

				// For debugging - this will make the image url stay
				to.params = { urlFileNames, origins }
				return next()

				// // Remove image related keys from query but keep any other keys.
				// const query = Object.fromEntries(
				// 	Object.entries(to.query).filter(entry => !entry[0].match(/^(tfn|dom|path|img)$/))
				// )

				// // Forward to page with image urls removed from query and added as a.
				// if (urlFileNames && urlFileNames.length)
				// 	return next({ name: 'Upload', query, params: { urlFileNames, origins } })
				// next()
			},
			meta: { accessLevel: 1, hideFooter: true },
			props: route => ({
				initialUrlFileNames: route.params.urlFileNames,
				origins: route.params.origins,
			}),
			component: () => import(/* webpackChunkName: 'upload' */ './views/Upload/UploadMain'),
		},
		{
			path: '/general', // We don't use this, just a shell
			meta: { leftFooter: true },
			redirect: { name: 'About' },
			component: () => import(/* webpackChunkName: 'general' */ './views/General/GeneralMain'),
			children: [
				{
					path: '/about',
					name: 'AboutTemp',
					component: () => import(/* webpackChunkName: 'about' */ './views/General/GeneralAbout'),
				},
				{
					path: '/donate',
					name: 'Donate',
					component: () => import(/* webpackChunkName: 'donate' */ './views/General/GeneralDonate'),
				},
				// {
				// 	path: '/faq',
				// 	name: 'FAQ',
				// 	component: () =>
				// 		import(/* webpackChunkName: 'faq' */ './views/General/GeneralFAQ'),
				// },
				{
					path: '/contact',
					name: 'Contact',
					component: () =>
						import(/* webpackChunkName: 'contact' */ './views/General/GeneralContact'),
				},
			],
		},
		{
			path: '/arthur-api',
			name: 'Arthur api',
			meta: { accessLevel: 2 },
			component: () => import(/* webpackChunkName: 'api' */ './views/ApiPage'),
		},

		/**
		 *
		 *
		 * Id Shortcut Pages
		 */

		{
			// Artist by id
			path: '/artist/:id',
			name: 'ArtistById',
			beforeEnter: async (to, from, next) => {
				const { id } = to.params
				next({
					name: 'EntityById',
					params: { entityType: 'artist', id },
				})
			},
		},
		{
			// Artwork by id
			path: '/artwork/:id',
			name: 'ArtworkById',
			beforeEnter: async (to, from, next) => {
				const { id } = to.params
				next({
					name: 'EntityById',
					params: { entityType: 'artwork', id },
				})
			},
		},
		{
			// User by id
			path: '/user/:id',
			name: 'UserById',
			beforeEnter: async (to, from, next) => {
				const { id } = to.params
				next({
					name: 'EntityById',
					params: { entityType: 'user', id },
				})
			},
		},
		{
			// Go to entity by id
			path: '/id/:id/:entityType?',
			name: 'EntityById',
			async beforeEnter(to, from, next) {
				// Load API
				const apiStore = useApiStore(pinia)
				const generalApi = apiStore.loadApi('general')

				// Find entity
				let { id, entityType } = to.params
				const { status, data, statusText } = await generalApi.getEntityById(id, entityType)

				// Forward error
				if (status != 200) {
					httpErrorStore.setError({
						status,
						statusText,
						byLine: 'ID forwarding failed',
						origin: 'Router /id/:id',
					})
					return next()
				}

				// Forward success
				const { entity } = data
				entityType = data.entityType
				if (entityType == 'artist') {
					next({
						name: 'Artist',
						params: { namePath: entity.namePath, category: entity.category },
					})
				} else if (entityType == 'artwork') {
					next({
						name: 'Artwork',
						params: {
							category: entity.category,
							namePath: entity.artistNamePath,
							titlePath: entity.titlePath,
						},
					})
				} else if (entityType == 'image') {
					next({
						name: 'Image',
						params: {
							id: entity.id,
						},
					})
				} else if (entityType == 'user') {
					next({
						name: 'User',
						params: { username: entity.username },
					})
				} else if (entityType == 'list') {
					next({
						name: 'UserList',
						params: {
							username: entity.owner.username,
							namePath: entity.namePath,
						},
					})
				} else if (entityType == 'room') {
					next({
						name: 'Room',
						params: {
							id,
						},
					})
				} else {
					next()
				}
			},
			props: true,
			meta: { hideFooter: true },
			component: () => import(/* webpackChunkName: 'entity-by-id' */ './views/EntityById'),
		},

		/**
		 *
		 *
		 * Art Pages
		 */
		// Note: Artist and Artwork routes are all the way at the end because they are wildcards.
		{
			// Art index
			path: '/art',
			name: 'Art',
			redirect: { name: 'CatalogIndex' },
		},
		{
			// Image
			path: '/image/:id',
			name: 'Image',
			props: true,
			meta: { showWelcome: true },
			// component: () => import(/* webpackChunkName: 'image' */ './views/Itm/ItmMain'),
			component: ItmMain,
		},

		/**
		 *
		 *
		 * Random Pages
		 * path: '/art/random',
		 * path: '/art/random-artist',
		 */
		{
			// Random artist or artwork
			path: '/random',
			name: 'Random',
			beforeEnter: async (to, from, next) => {
				const r = Math.random() - 0.5
				if (r < 0) {
					next({ name: 'RandomArtist' })
				} else {
					next({ name: 'RandomArtwork' })
				}
			},
		},
		{
			// Random artist
			path: '/random-artist',
			name: 'RandomArtist',
			beforeEnter: async (to, from, next) => {
				const apiStore = useApiStore(pinia)
				const artistApi = apiStore.loadApi('artist')
				const { status, data } = await artistApi.getRandom()
				if (status == 200) {
					const { namePath, category } = data
					next({ name: 'Artist', params: { namePath, category } })
				} else {
					return next({ name: 'CatalogIndex' })
				}
			},
		},
		{
			// Random artwork
			path: '/random-artwork',
			name: 'RandomArtwork',
			beforeEnter: async (to, from, next) => {
				const apiStore = useApiStore(pinia)
				const itmApi = apiStore.loadApi('itm')
				const { status, data } = await itmApi.getRandom()
				if (status == 200) {
					const { category, namePath, titlePath } = data
					next({ name: 'Artwork', params: { category, namePath, titlePath } })
				} else {
					return next({ name: 'Home' })
				}
			},
		},
		{
			// Random list
			path: '/random-list',
			name: 'RandomList',
			beforeEnter: async (to, from, next) => {
				const apiStore = useApiStore(pinia)
				const clusterApi = apiStore.loadApi('cluster')
				const { status, data } = await clusterApi.getRandom('list')
				if (status == 200) {
					const { owner, namePath } = data
					next({ name: 'UserList', params: { username: owner.username, namePath } })
				} else {
					return next({ name: 'Home' })
				}
			},
		},
		{
			// Random room
			path: '/random-room',
			name: 'RandomRoom',
			beforeEnter: async (to, from, next) => {
				const apiStore = useApiStore(pinia)
				const clusterApi = apiStore.loadApi('cluster')
				const { status, data } = await clusterApi.getRandom('room')
				if (status == 200) {
					const { id } = data
					next({ name: 'Room', params: { id } })
				} else {
					return next({ name: 'Home' })
				}
			},
		},

		/**
		 *
		 *
		 * Catalog
		 */

		{
			path: '/index',
			name: 'CatalogIndex',
			redirect: {
				name: 'CatalogIndexList',
				params: { entityType: 'artists', letter: 'a' },
			},
		},
		{
			// Artist + User Index
			path: '/index/:entityType',
			props: true,
			component: () => import(/* webpackChunkName: 'entity-index' */ './views/CatalogIndex/Main'),
			children: [
				{
					path: ':letter',
					name: 'CatalogIndexList',
					props: true,
					component: () =>
						import(
							/* webpackChunkName: 'entity-index-list' */ './views/CatalogIndex/CatalogIndexList'
						),
				},
			],
		},

		/**
		 *
		 *
		 * Legal
		 */

		{
			path: '/legal',
			name: 'Legal',
			meta: { leftFooter: true },
			redirect: { name: 'LegalTerms' },
			component: () => import(/* webpackChunkName: 'legal' */ './views/Legal/LegalMain'),
			children: [
				{
					path: 'terms',
					name: 'LegalTerms',
					component: () => import(/* webpackChunkName: 'legal-terms' */ './views/Legal/LegalTerms'),
				},
				{
					path: 'copyright',
					name: 'LegalCopyright',
					component: () =>
						import(/* webpackChunkName: 'legal-copyright' */ './views/Legal/LegalCopyright'),
				},
				{
					path: 'privacy',
					name: 'LegalPrivacy',
					component: () =>
						import(/* webpackChunkName: 'legal-privacy' */ './views/Legal/LegalPrivacy'),
				},
				{
					path: 'removal',
					name: 'LegalRemoval',
					component: () =>
						import(/* webpackChunkName: 'legal-removal' */ './views/Legal/LegalRemoval'),
				},
			],
		},

		/**
		 *
		 *
		 * Admin Pages
		 */

		{
			path: '/admin',
			name: 'Admin',
			meta: { accessLevel: 2, hideFooter: true, hideNav: true },
			redirect: { name: 'AdminMonitor' },
			component: () => import(/* webpackChunkName: 'admin-layout' */ './views/Admin/Main'),
			children: [
				// Monitor
				{
					path: 'monitor',
					name: 'AdminMonitor',
					redirect: {
						name: 'AdminMonitorNewPeople',
						params: { entityType: 'wait-list' },
					},
				},
				{
					path: 'monitor/:entityType/:page?',
					name: 'AdminMonitorNewPeople',
					props: route => ({
						entityType: route.params.entityType,
						page: +route.params.page,
					}),
					component: () =>
						import(
							/* webpackChunkName: 'admin-monitor-new-people' */ './views/Admin/Monitor/NewPeople'
						),
				},

				// Index
				{
					path: 'index',
					name: 'AdminIndex',
					redirect: {
						name: 'AdminIndexEntity',
						params: { entityType: 'users', letter: 'a', page: 1 },
					},
				},
				{
					// Add letter and page is missing from URL
					path: 'index/:entityType/:letter?/:page?',
					name: 'AdminIndexEntity',
					props: route => ({
						entityType: route.params.entityType,
						letter: route.params.letter,
						page: +route.params.page,
					}),
					component: () =>
						import(/* webpackChunkName: 'admin-index-entity' */ './views/Admin/Index/Entity'),
					beforeEnter: (to, from, next) => {
						// Complete URL if missing letter and/or page
						if (!to.params.page) {
							// Forward to /a/1 if missing
							next({
								name: to.name,
								params: {
									entityType: to.params.entityType,
									letter: to.params.letter ? to.params.letter : 'a',
									page: 1,
								},
							})
						} else {
							next()
						}
					},
				},

				// Maintain
				{
					path: 'maintain',
					name: 'AdminMaintain',
					component: () =>
						import(/* webpackChunkName: 'admin-maintain' */ './views/Admin/Maintain'),
				},

				// Inspect
				{
					// About wildcard: using a username or namePath might
					// result in both artist and user results. Users will get priority.
					// Needs to have a different name from id_username_namePath because
					// both get sent as props to the template (see AdminInspectEntity below).
					path: 'inspect/:id_username_namePath_wildcard?',
					name: 'AdminInspect',
					beforeEnter: async (to, from, next) => {
						// Load API (locally bc we don't want to bloat the router)
						const apiStore = useApiStore(pinia)
						const adminInspectApi = apiStore.loadApi('adminInspect')

						const { id_username_namePath_wildcard } = to.params
						if (id_username_namePath_wildcard) {
							const { data, status } = await adminInspectApi.getEntityType(
								id_username_namePath_wildcard
							)
							if (status == 200) {
								next({
									name: 'AdminInspectEntity',
									params: {
										entityType: data,
										id_username_namePath: id_username_namePath_wildcard,
									},
								})
							} else {
								next()
							}
						} else {
							next()
						}
					},
					props: true,
					component: () =>
						import(/* webpackChunkName: 'admin-inspect' */ './views/Admin/Inspect/Landing'),
					children: [
						{
							path: ':entityType/:id_username_namePath',
							name: 'AdminInspectEntity',
							// props: true,
							props: route => ({
								entityType: route.params.entityType,
								id_username_namePath: route.params.id_username_namePath,
							}),
							redirect: { name: 'AdminInspectEntityInfo' },
							component: () =>
								import(
									/* webpackChunkName: 'admin-inspect-entity' */ './views/Admin/Inspect/Layout'
								),
							children: [
								{
									path: 'info',
									name: 'AdminInspectEntityInfo',
									props: route => ({
										entityType: route.params.entityType,
									}),
									component: () =>
										import(
											/* webpackChunkName: 'admin-inspect-entity-info' */ './views/Admin/Inspect/Entity/Info/Layout'
										),
								},
								{
									path: 'artworks/:page?',
									name: 'AdminInspectEntityArtworks',
									props: route => ({
										entityType: route.params.entityType,
										id_username_namePath: route.params.id_username_namePath,
										page: +route.params.page,
									}),
									component: () =>
										import(
											/* webpackChunkName: 'admin-inspect-entity-artworks' */ './views/Admin/Inspect/Entity/Artworks'
										),
								},
								{
									path: 'lists/:page?',
									name: 'AdminInspectEntityLists',
									props: true,
									meta: { relationType: 'lists' },
									component: () =>
										import(
											/* webpackChunkName: 'admin-inspect-entity-linked' */ './views/Admin/Inspect/Entity/Linked'
										),
								},
								{
									path: 'rooms/:page?',
									name: 'AdminInspectEntityRooms',
									props: true,
									meta: { relationType: 'rooms' },
									component: () =>
										import(
											/* webpackChunkName: 'admin-inspect-entity-linked' */ './views/Admin/Inspect/Entity/Linked'
										),
								},
								{
									path: 'collectors/:page?',
									name: 'AdminInspectEntityCollectors',
									props: true,
									meta: { relationType: 'collectors' },
									component: () =>
										import(
											/* webpackChunkName: 'admin-inspect-entity-linked' */ './views/Admin/Inspect/Entity/Linked'
										),
								},
								{
									path: 'discrepancies/:relationType/:page?',
									name: 'AdminInspectEntityDiscrep',
									props: true,
									component: () =>
										import(
											/* webpackChunkName: 'admin-inspect-entity-discrepancies' */ './views/Admin/Inspect/Entity/Discrep'
										),
								},
								{
									path: 'json',
									name: 'AdminInspectEntityJson',
									component: () =>
										import(
											/* webpackChunkName: 'admin-inspect-entity-json' */ './views/Admin/Inspect/Entity/Json'
										),
								},
							],
						},
					],
				},

				// Sitemaps
				{
					path: 'sitemaps',
					name: 'AdminSitemaps',
					component: () =>
						import(/* webpackChunkName: 'admin-sitemaps' */ './views/Admin/Sitemaps'),
				},
			],
		},
		{
			path: '/admin/feed',
			name: 'AdminMonitorFeed',
			component: () => import(/* webpackChunkName: 'admin-feed' */ './views/Admin/Feed'),
		},

		/**
		 *
		 *
		 * Documentation
		 */

		{
			path: '/doc',
			name: 'Doc',
			meta: { accessLevel: 2, hideFooter: true, hideNav: true },
			redirect: { name: 'DocForms' },
			component: () => import(/* webpackChunkName: 'doc' */ './views/Doc/DocMain'),
			children: [
				// Forms
				{
					path: 'forms',
					name: 'DocForms',
					redirect: { name: 'DocFormsImplementation' },
				},
				{
					path: 'forms/implementation',
					name: 'DocFormsImplementation',
					component: () =>
						import(
							/* webpackChunkName: 'doc-inputs-implementation' */ './views/Doc/Forms/Implementation'
						),
				},
				{
					path: 'forms/preview',
					name: 'DocFormsPreview',
					component: () =>
						import(/* webpackChunkName: 'doc-inputs-preview' */ './views/Doc/Forms/Preview'),
				},
				{
					path: 'forms/text',
					name: 'DocFormsText',
					component: () =>
						import(/* webpackChunkName: 'doc-inputs-text' */ './views/Doc/Forms/Text'),
				},
				{
					path: 'forms/radio',
					name: 'DocFormsRadio',
					component: () =>
						import(/* webpackChunkName: 'doc-inputs-radio' */ './views/Doc/Forms/Radio'),
				},
				{
					path: 'forms/dropdown',
					name: 'DocFormsDropdown',
					component: () =>
						import(/* webpackChunkName: 'doc-inputs-dropdown' */ './views/Doc/Forms/Dropdown'),
				},
				{
					path: 'forms/labels',
					name: 'DocFormsLabels',
					component: () =>
						import(/* webpackChunkName: 'doc-inputs-labels' */ './views/Doc/Forms/Labels'),
				},
				{
					path: 'forms/button',
					name: 'DocFormsButton',
					component: () =>
						import(/* webpackChunkName: 'doc-inputs-button' */ './views/Doc/Forms/Button'),
				},

				// Components
				{
					path: 'components',
					name: 'DocComponents',
					redirect: { name: 'DocComponentsAccordeonList' },
				},
				{
					path: 'components/accordeon-list',
					name: 'DocComponentsAccordeonList',
					component: () =>
						import(
							/* webpackChunkName: 'doc-accordeon-list' */ './views/Doc/Components/AccordeonList'
						),
				},
				{
					path: 'components/buttons',
					name: 'DocComponentsButtons',
					component: () =>
						import(/* webpackChunkName: 'doc-buttons' */ './views/Doc/Components/Buttons'),
				},
				{
					path: 'components/icons',
					name: 'DocComponentsIcons',
					component: () =>
						import(/* webpackChunkName: 'doc-icons' */ './views/Doc/Components/Icons'),
				},
				{
					path: 'components/sub-nav',
					name: 'DocComponentsSubNav',
					redirect: { name: 'DocComponentsSubNavOne' },
					component: () =>
						import(/* webpackChunkName: 'doc-sub-nav' */ './views/Doc/Components/SubNav'),
					children: [
						{
							path: 'one',
							name: 'DocComponentsSubNavOne',
							component: () =>
								import(
									/* webpackChunkName: 'doc-sub-nav-one' */ './views/Doc/Components/SubNavOne'
								),
						},
						{
							path: 'two',
							name: 'DocComponentsSubNavTwo',
							component: () =>
								import(
									/* webpackChunkName: 'doc-sub-nav-two' */ './views/Doc/Components/SubNavTwo'
								),
						},
					],
				},
				{
					path: 'components/side-nav',
					name: 'DocComponentsSideNav',
					component: () =>
						import(/* webpackChunkName: 'doc-side-nav' */ './views/Doc/Components/SideNav'),
				},
				{
					path: 'components/pagination/:page?',
					name: 'DocComponentsPagination',
					component: () =>
						import(/* webpackChunkName: 'doc-pagination' */ './views/Doc/Components/Pagination'),
				},
				{
					path: 'components/table',
					name: 'DocComponentsTable',
					component: () =>
						import(/* webpackChunkName: 'doc-table' */ './views/Doc/Components/Table'),
				},

				// Modules
				{
					path: 'modules',
					name: 'DocModules',
					redirect: { name: 'DocModulesDialogs' },
				},
				{
					path: 'modules/dialogs',
					name: 'DocModulesDialogs',
					component: () =>
						import(/* webpackChunkName: 'doc-dialogs' */ './views/Doc/Modules/Dialogs'),
				},
				{
					path: 'modules/flash',
					name: 'DocModulesFlash',
					component: () => import(/* webpackChunkName: 'doc-flash' */ './views/Doc/Modules/Flash'),
				},
				{
					path: 'components/key-events',
					name: 'DocModulesKeyEvents',
					component: () =>
						import(/* webpackChunkName: 'doc-key-events' */ './views/Doc/Modules/KeyEvents'),
				},
				{
					path: 'modules/copy-text',
					name: 'DocModulesCopyText',
					component: () =>
						import(/* webpackChunkName: 'doc-copy-text' */ './views/Doc/Modules/CopyText'),
				},

				// CSS
				{
					path: 'css',
					name: 'DocCss',
					redirect: { name: 'DocCssLists' },
				},
				{
					path: 'css/lists',
					name: 'DocCssLists',
					component: () => import(/* webpackChunkName: 'doc-css-lists' */ './views/Doc/CSS/Lists'),
				},

				// Mechanics
				{
					path: 'mechanics',
					name: 'DocMechanics',
					redirect: { name: 'DocMechanicsEndorsing' },
				},
				{
					path: 'mechanics/endorsing',
					name: 'DocMechanicsEndorsing',
					component: () =>
						import(/* webpackChunkName: 'doc-endorsing' */ './views/Doc/Mechanics/Endorsing'),
				},
				{
					path: 'mechanics/ssr-content',
					name: 'DocMechanicsSSRContentLoading',
					component: () =>
						import(
							/* webpackChunkName: 'doc-ssr-content' */ './views/Doc/Mechanics/SSRContentLoading'
						),
				},
				{
					path: 'mechanics/year-format',
					name: 'DocMechanicsYearFormat',
					component: () =>
						import(/* webpackChunkName: 'doc-year-format' */ './views/Doc/Mechanics/YearFormat'),
				},
				{
					path: 'mechanics/non-latin',
					name: 'DocMechanicsNonLatin',
					component: () =>
						import(/* webpackChunkName: 'doc-non-latin' */ './views/Doc/Mechanics/NonLatin'),
				},
			],
		},

		/**
		 *
		 *
		 * Development
		 * - - -
		 * For internal use only
		 */

		{
			path: '/dev-dialog-preview/:preview',
			name: 'DevDialogPreview',
			props: true,
			meta: { accessLevel: 2, hideFooter: true, hideNav: true },
			component: () => import(/* webpackChunkName: 'dialog-preview' */ './views/DevDialogPreview'),
		},

		/**
		 *
		 *
		 * Artist & Artworks
		 */

		{
			// Artist
			path: '/:category/:namePath',
			name: 'Artist',
			beforeEnter: async (to, from, next) => {
				const { category, namePath } = to.params
				// /art/photography/illustration/etc
				if (!artCategories.validate(category)) {
					// The category doesn't exist, which means this is probably a list path.
					return next({ name: 'UserList', params: { username: category, namePath } })
				}
				next()
			},
			props: true,
			meta: { showWelcome: true },
			component: () => import(/* webpackChunkName: 'artist' */ './views/Artist/ArtistMain'),
		},
		{
			// Artwork
			path: '/:category/:namePath/:titlePath/:viewNr?',
			name: 'Artwork',
			props: true,
			meta: { showWelcome: true },
			component: () => import(/* webpackChunkName: 'artwork' */ './views/Itm/ItmMain'),
		},

		/**
		 *
		 *
		 * Label
		 */

		{
			path: '/label/:label', // foo~bar~lorem
			name: 'Label',
			props: true,
			beforeEnter: async (to, from, next) => {
				if (to.params.label.match(/\s/)) {
					const route = { ...to }
					route.params.label = route.params.label.replace(/\s/g, '_')
					next(route)
				} else {
					next()
				}
			},
			component: () => import(/* webpackChunkName: 'label' */ './views/LabelPage'),
		},

		/**
		 *
		 *
		 * User
		 */

		{
			// User Profile - Public
			path: '/:username',
			props: true,
			beforeEnter: async (to, from, next) => {
				// console.log('router: user', to.name, to.fullPath)
				next()
			},
			meta: { showWelcome: true },
			component: () => import(/* webpackChunkName: 'user' */ './views/User/UserMain'),
			children: [
				{
					// User Collection
					path: '',
					name: 'User',
					component: () =>
						import(/* webpackChunkName: 'user-collection' */ './views/User/UserCollection'),
				},
				{
					// User List
					path: ':namePath',
					name: 'UserList',
					// props: true,
					// meta: { showWelcome: true },
					component: () =>
						import(/* webpackChunkName: 'user-list' */ './views/User/UserCollection'),
				},
				{
					// User Rooms
					path: 'rooms',
					name: 'UserRooms',
					component: () => import(/* webpackChunkName: 'user-rooms' */ './views/User/UserRooms'),
				},
			],
		},
		{
			// Room
			path: '/room/:id',
			name: 'Room',
			props: true,
			meta: { showWelcome: true },
			component: () => import(/* webpackChunkName: 'room' */ './views/Room/RoomMain'),
		},

		/**
		 *
		 *
		 * Curiator legacy
		 * - - -
		 * These routes catch all the old Curiator urls
		 * still indexed by search engines, Pinterest etc.
		 */

		{
			path: '/crtr/:catchAll(.*)', // crtr.co
			alias: [
				'/explore/:catchAll(.*)',
				'/search/:catchAll(.*)',
				'/exhibitions/:catchAll(.*)',
				'/1/:catchAll(.*)',
			],
			beforeEnter: async (to, from, next) => {
				next()
			},
			redirect: { name: 'Home' },
		},

		/**
		 *
		 *
		 * 404
		 * - -
		 * Note: HTTP Error handling is mostly steered from
		 * the User/List/Room/Artist/Artwork views, due to the
		 * catch-all URL structure. The router only handles
		 * very unlikely 404 paths that don't possible match
		 * an entity url, eg. arthur.io/abc/def/ghi
		 */

		{
			path: '/:catchAll(.*)',
			name: 'Blank404',
			component: () => import(/* webpackChunkName: 'blank-404' */ './views/Blank404Page'),
		},
	]

	const history = isSSR ? createMemoryHistory() : createWebHistory()

	const sessionStore = useSessionStore()
	const router = createRouter({
		history,
		routes,
		scrollBehavior(to) {
			// As long as the route is locked (by the carousel or search, see RouteStore)
			// we want to disable this scroll behavior (sessionStore.maintainScrollPos).
			// The 404 overlay also doesn't want to trigger a scroll on exit (disableScrollReset)
			if (!sessionStore.maintainScrollPos && !to.params.disableScrollReset) {
				return { left: 0, top: 0 }
			}
		},
	})

	// ENTRY GATE
	// Initialization for our development evironment.
	// On production these are initialized in server.js.
	if (!isSSR && !isSSRClient) {
		// SSR inits user from server.js
		// Client needs to init user from here or else
		// first page will be loaded with wrong permissions
		authStore.initUser()
		meStore.setImperial(true)
	}

	// Intercept requests and handle Auth requirements
	router.beforeResolve(async (to, from, next) => {
		// console.log(from.fullPath, '-->', to.fullPath)
		// to.matched.some(record => record.meta.hideNav) // <-- FYI: How to check for a value in the route tree

		// Get access levels for all pages in the matched tree.
		// Note: the forced login on staging happens in server.js
		const accessLevelTree = to.matched.map(record => record.meta.accessLevel || 0)
		const requiredAccessLevel = Math.max(...accessLevelTree)
		const accessLevel = authStore.accessLevel
		// console.log('authStore', authStore)
		// console.log('Access level: ', accessLevel, 'Required: ', requiredAccessLevel)
		if (requiredAccessLevel > accessLevel) {
			// Unauthorized access
			console.log(
				`UNAUTHORIZED! Level ${accessLevel} but need ${requiredAccessLevel} --> ${to.name} --> ${to.path}\n`,
				`Coming from: ${from.path}`
			)
			if (accessLevel === 0) {
				// Visitor is not logged in --> forward to login.
				next({ name: 'Login', query: { redirect: to.fullPath } })
			} else {
				// User is trying to access an admin page --> forward to homepage.
				next({ name: 'Home' })
			}
		} else if (to.matched.some(record => record.meta.guestOnly)) {
			// Guests only (eg. login page)
			const isLoggedIn = authStore.isLoggedIn
			if (isLoggedIn) {
				next({ name: 'Feed' })
			} else {
				next()
			}
		} else {
			// next()

			// TEMP: To preview the beta.
			if (from.query.preview == 1 && !to.query.preview) {
				to.query.preview = 1
				return next(to)
			} else {
				next()
			}
		}
	})

	// Sometimes we need to push the same route twice in the history
	// (see carouselStore._updateRoute and sessionStore.lockRoute)
	// so we add a single hash which is then immediately removed.
	router.afterEach(to => {
		if (to.hash == '#') {
			delete to.hash
			router.replace(to)
			// console.log('--REMOVE HASH')
		}
	})

	return router
}
