<template>
	<div id="about-arthur" :class="{ home }">
		<div class="content-wrap">
			<MastHead />
			<div class="subnav">
				<router-link :to="{ name: 'About' }">About</router-link>
				<a class="icon-wrap" target="_blank" href="https://www.instagram.com/butisitarthur">
					<BaseIcon name="instagram" />
				</a>
				<router-link :to="{ name: 'Donate' }">Donate</router-link>
			</div>

			<FormButton
				v-if="home"
				value="Join wait list"
				@click="dialogStore.dialogByName('DialogAuth', { page: 'wait-list' })"
			/>
			<FormButton v-else :level="2" value="Home" @click="$router.push({ name: 'Home' })" />
		</div>
	</div>
	<TheFooter v-if="!home && !$root.hideFooter && !$root.httpErrorIsActive" />
</template>

<script>
// Stores
import { useDialogStore } from '@/stores/DialogStore'

// Components
import MastHead from '@/components/MastHead'
import BaseIcon from '@/components/BaseIcon'
import FormButton from '@/components/FormButton'
import TheFooter from '@/components/TheFooter'

export default {
	name: 'TheWelcome',
	components: {
		MastHead,
		BaseIcon,
		FormButton,
		TheFooter,
	},
	props: {
		home: Boolean,
	},
	setup() {
		const dialogStore = useDialogStore()
		return { dialogStore }
	},
}
</script>

<style lang="scss" scoped>
/**
 * Logo & header
 */

#about-arthur {
	padding: 0.4rem;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	// 5rem to make sure the footer doesn't
	// overlap on tiny mobile screens.
	min-height: max(100vh, 5rem);
	// background: pink;
}
#about-arthur.home {
	min-height: 5rem;
}
#about-arthur .content-wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 3.2rem;
}

/**
 * Sub nav
 */

.subnav {
	// background: yellow;
	height: 0.4rem;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0.3rem 0;
	width: 100%;
}
.subnav a:not(.icon-wrap) {
	font-size: 0.16rem;
	margin: 0 0.15rem;
	height: 0.3rem;
	line-height: 0.28rem;
	display: inline-block;
	width: 0.5rem;
	// background: pink;
}
.subnav a:not(:hover) {
	color: $black;
}

/* Social icons */
.subnav a.icon-wrap {
	padding: 0.2rem;
	margin: 0;
	height: 0.6rem;
	line-height: 0rem;
}

/* Footer */
#footer {
	margin-top: -1rem;
}

/**
 * RESPONSIVE
 */

@media only screen and (max-width: $tablet) and (min-height: $mobile-height) {
	#about-arthur.home .content-wrap {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
}
@media only screen and (max-width: $mobile) and (max-height: $mobile-height-small) {
	#about-arthur.home .content-wrap {
		padding-top: 0.5rem;
		padding-bottom: 0;
	}
}

// S - Mobile
@media only screen and (max-height: $mobile-height) {
	// #about-arthur.home {
	// 	min-height: 2.5rem;
	// }
}

// XS - Eg. iPhone 4
@media only screen and (max-height: $mobile-height-small) {
	#about-arthur {
		padding: 0.3rem;
	}
	#about-arthur.home {
		min-height: 3.5rem;
	}
	#about-arthur.home:deep() .act-logo {
		margin-bottom: 0.1rem;
	}
	#about-arthur:deep() .subnav {
		margin-top: 0.2rem;
		margin-bottom: 0.2rem;
	}
}

@media (hover: hover) {
	.icon-wrap:hover:deep() .icn-social-ig {
		fill: $primary;
	}
}
</style>
