<template>
	<component :is="label ? 'label' : 'div'" class="wrap">
		<!-- Label text -->
		<div v-if="label" class="label-text">
			{{ label
			}}<a
				href="#"
				v-if="helper"
				@click.prevent="$emit('helperClick')"
				@mouseenter="$emit('helperMouseEnter')"
				@mouseleave="$emit('helperMouseLeave')"
			>
				{{ helper }}</a
			><span v-if="error" class="error"> {{ error }}</span>
		</div>

		<!-- Radios -->
		<div :id="label ? 'box' : null" class="form-elm" :class="{ horizontal }">
			<template v-for="(radio, i) in radioOptions" :key="i">
				<label class="radio"
					><input v-model="theValue" :name="name" type="radio" :value="radio.value" tabindex="1" />{{
						radio.text
					}}</label
				>
			</template>
		</div>
	</component>
</template>

<script>
import FormItems from '@/mixins/mx_FormItems'

export default {
	name: 'FormRadios',
	mixins: [FormItems],
	props: {
		name: {
			type: String,
			required: true,
		},
		radioOptions: {
			type: Array,
			required: true,
			// [{ value="foo-bar", text="Foo Bar" }]
		},
		horizontal: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['helperClick', 'helperMouseEnter', 'helperMouseLeave'],
	created() {
		// When a default value is checked, we have to push it to the theValue
		const checked = this.radioOptions.find(option => option.checked)
		if (checked) this.theValue = checked.value
	},
}
</script>

<style src="../assets/css/form-items.scss" lang="scss" scoped></style>
<style lang="scss" scoped>
// Radio box
#box {
	width: $default-form-item-width;
	padding: 0.2rem;
	border-radius: $br;
	border: solid 0.01rem $black-10;
	background: rgba(255, 255, 255, 0.5);
}

// Radio buttons
label.radio {
	display: block;
	height: 0.2rem;
	font-size: 0.16rem;
	line-height: 0.2rem;
	margin-top: 0.15rem;
	cursor: pointer;
}
label.radio:first-child {
	margin-top: 0;
}
label.radio input {
	width: 0.14rem;
	height: 0.14rem;
	vertical-align: middle;
	margin: 0.03rem 0.1rem 0.03rem 0;
}

// Horizontal styling
.form-elm.horizontal {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: -0.15rem;
}
.form-elm.horizontal label.radio {
	margin-top: 0.15rem;
	margin-right: 0.3rem;
}
.form-elm.horizontal label.radio:last-child {
	margin-right: 0;
}
</style>
