export function google(name) {
	if (!name) return 'https://www.google.com'
	// prettier-ignore
	return `https://www.google.com/search?q=${name.replace(/ /, '+')}+art&tbm=isch`
}

export function tumblr(name) {
	if (!name) return 'https://www.tumblr.com'
	// prettier-ignore
	return `https://www.tumblr.com/tagged/${name.replace(/ /, '%20')}`
}

export function pinterest(name) {
	if (!name) return 'https://www.pinterest.com'
	// prettier-ignore
	return `https://www.pinterest.com/search/pins/?q=${name.replace(/ /, '+')}`
}
