<template>
	<!-- <pre style="position:fixed; top: 0">@@@{{ isHomePage }}</pre> -->
	<router-link v-if="showGoTo" id="go-to" :to="getItmUrl(feedStore.selected[0])">
		<BaseIcon name="arrow-right" color="white" />
	</router-link>
	<div v-if="showNav" id="nav-wrap" :class="{ show: showMobileNav }" @click="onNavWrapClick">
		<div id="main-nav" :style="{ 'margin-right': sessionStore.scrollBarWidth + 'px' }">
			<ul>
				<li v-for="(item, i) in navItems" :key="i">
					<router-link @click="showMobileNav = false" v-if="item.to" :to="item.to">{{
						item.display
					}}</router-link>
					<a
						@click="showMobileNav = false"
						href="#"
						v-if="item.onClick"
						@click.prevent="item.onClick"
						>{{ item.display }}</a
					>
				</li>
			</ul>
		</div>
		<div id="mobile-nav-btn" @click="toggleMobileNav">
			<div></div>
			<div></div>
			<div></div>
		</div>
	</div>
</template>

<script>
// Stores
import { useFeedStore } from '@/stores/FeedStore'
import { useSessionStore } from '@/stores/SessionStore'
import { useAuthStore } from '@/stores/AuthStore'

// Components
import BaseIcon from '@/components/BaseIcon'

// Internal
import { getItmUrl } from '@/use/ItmHelpers'

export default {
	name: 'TheNav',
	components: {
		BaseIcon,
	},
	setup() {
		const feedStore = useFeedStore()
		const sessionStore = useSessionStore()
		const authStore = useAuthStore()
		return { getItmUrl, feedStore, sessionStore, authStore }
	},
	data() {
		return {
			showMobileNav: false,
			showGoTo: false,
			showNav: true,
			navUser: [
				{
					display: 'Feed',
					to: { name: 'Feed' },
				},
				{
					display: 'My Collection',
					// $myUsername is not yet available so we initialize this via the watcher
					to: { name: 'Me' },
				},
				{
					display: 'Upload',
					to: { name: 'Upload' },
				},
				{
					display: 'Log out',
					onClick: () => {
						this.authStore.logout(this.$router)
					},
				},
			],
			navAdmin: [
				{
					display: 'Admin',
					to: { name: 'Admin' },
				},
			],
		}
	},
	computed: {
		navItems() {
			return this.navUser.concat(this.$isAdmin ? this.navAdmin : [])
		},
	},
	watch: {
		'feedStore.selected'(newValue, oldValue) {
			this.onSelectedChange(newValue, oldValue)
		},
	},
	created() {
		// Because $myUsername is not available during page load,
		// we generate the "My Collection" link after.
		const myCollectionRoute = this.navUser.find(route => route.display == 'My Collection')
		myCollectionRoute.to.name = 'User'
		myCollectionRoute.to.params = { username: this.$myUsername }
	},
	methods: {
		toggleMobileNav() {
			this.showMobileNav = !this.showMobileNav
		},
		onNavWrapClick(e) {
			if (e.target.id == 'nav-wrap') {
				this.showMobileNav = false
			}
		},

		// Display go-to button when only one itm is selected,
		// and hide navigation as long as any itms are selected.
		onSelectedChange(newValue, oldValue) {
			const selectedCount = newValue.length
			const previouslySelectedItms = oldValue.length
			if (selectedCount == 1) {
				// Show go-to
				this.showGoTo = true
				if (previouslySelectedItms === 0) {
					// Hide navigation
					this.showNav = false
				}
			} else {
				// Hide go-to
				this.showGoTo = false
				if (!selectedCount) {
					// Show navigation
					this.showNav = true
				}
			}
		},
	},
}
</script>

<style lang="scss" scoped>
/**
 * Main Nav
 */
#main-nav {
	position: fixed;
	right: 0.15rem;
	bottom: 0.15rem;
	z-index: 2;
	color: #fff;
	display: flex;
}
#main-nav ul {
	background: $black-dark;
	border-radius: $br;
	display: flex;
	gap: 0;

	list-style-type: none;
	margin-block-start: 0;
	margin-block-end: 0;
	margin-inline-start: 0;
	margin-inline-end: 0;
	// padding-inline-start: 0;

	padding: 0 0.1rem;
}
#main-nav ul.join {
	margin-left: 0.1rem;
}
#main-nav a {
	display: block;
	height: 0.4rem;
	line-height: 0.2rem;
	padding: 0.1rem 0.1rem;
}
#main-nav a:not(:hover) {
	color: rgba(255, 255, 255, 0.8);
}

// Active state
#main-nav a.router-link-active {
	color: $white;
	font-weight: 500;
	cursor: default;
	// pointer-events: none;
}

// Wait list button
#main-nav li.waitlist a {
	color: $gold;
}

/**
 * Mobile Nav / Go-to Button
 * The go-to button appears when you select an image
 * and lets you go to the itm page.
 */
#mobile-nav-btn,
#go-to {
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 0.4rem;
	position: fixed;
	bottom: 0.2rem;
	right: 0.2rem;
	z-index: 2;
	background: $black;
	display: none;
	transition: transform calc(var(--animSpeed) / 2) ease-in-out;
}
#mobile-nav-btn div {
	width: 0.18rem;
	height: 0.02rem;
	border-radius: 0.01rem;
	background: #fff;
	position: absolute;
	left: 0.16rem;
	transform-origin: center center;
	transition: transform 200ms ease-in-out, opacity 200ms linear;
}
#mobile-nav-btn > div:nth-child(1) {
	top: 0.18rem;
	transform-origin: center center;
}
#mobile-nav-btn > div:nth-child(2) {
	top: 0.24rem;
}
#mobile-nav-btn > div:nth-child(3) {
	top: 0.3rem;
	transform-origin: center center;
}

// Go-to button & transition
#go-to {
	padding: 0.15rem;
	display: block;
}
#go-to.shrink,
#mobile-nav-btn.shrink {
	transform: scale(0);
}

// Hamburger for tablet/mobile
@media only screen and (max-width: $tablet) {
	// Invisible nav-wrap lets you click outside the nav to close it
	#nav-wrap {
		z-index: 2;
	}
	#nav-wrap.show {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(250, 250, 250, 0.5);
	}

	#main-nav {
		flex-direction: column;
	}

	#main-nav ul {
		flex-direction: column;
		padding: 0.15rem;
		background: $black-dark;
	}
	#main-nav ul:first-child {
		margin: 0;
		padding-bottom: 0;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
	#main-nav ul.join {
		margin-left: 0;
		padding-top: 0;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
	#main-nav ul:last-child {
		padding-bottom: 0.5rem;
	}
	#main-nav a {
		height: 0.45rem;
		border-radius: unset;
		background: transparent;
	}
	#mobile-nav-btn {
		display: block;
	}

	// Off state
	#nav-wrap:not(.show) #main-nav {
		opacity: 0;
		pointer-events: none;
	}
	#nav-wrap:not(.show) #main-nav a {
		padding: 0.09rem 0.2rem;
	}
	#nav-wrap.show #mobile-nav-btn {
		background: none;
	}

	// X animation
	#nav-wrap.show #mobile-nav-btn > div:nth-child(1) {
		transform: scaleX(0);
		opacity: 0;
	}
	#nav-wrap.show #mobile-nav-btn > div:nth-child(2) {
		transform: rotate(45deg);
	}
	#nav-wrap.show #mobile-nav-btn > div:nth-child(3) {
		transform: translateY(-0.06rem) rotate(-45deg);
	}
}
</style>
