<template>
	<img :class="classValue" :src="pixel" />
</template>

<script>
import { pixel } from '@/use/Base'
export default {
	props: {
		classValue: {
			type: String,
		},
	},
	setup() {
		return { pixel }
	},
}
</script>
