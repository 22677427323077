<template>
	<component :is="label ? 'label' : 'div'" :class="{ wrap: 1, disabled }" :style="style" @click="toggle">
		<!-- Toggle -->
		<div class="form-elm" :class="{ on: theValue, tiny }">
			<div class="slider"></div>
		</div>

		<!-- Label text -->
		<div v-if="label" class="label-text" :class="{ tiny }">
			{{ label }}<span v-if="error" class="error"> {{ error }}</span>
		</div>
	</component>
</template>

<script>
import FormItems from '@/mixins/mx_FormItems'
import FormItemsStyle from '@/mixins/mx_FormItemsStyle'

export default {
	name: 'FormToggle',
	mixins: [FormItems, FormItemsStyle],
	props: {
		tiny: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['toggle'],
	methods: {
		toggle() {
			this.theValue = !this.theValue
			this.$emit('toggle', this.theValue)
		},
	},
}
</script>

<style src="../assets/css/form-items.scss" lang="scss" scoped></style>
<style scoped lang="scss">
// Structure
.wrap > .form-elm {
	width: 0.4rem;
	height: 0.2rem;
	border-radius: 0.2rem;
	background: $black-15;
	position: relative;
	cursor: pointer;
	transition: all 100ms ease-in-out;
}
.wrap > .form-elm .slider {
	width: 0.14rem;
	height: 0.14rem;
	border-radius: 0.07rem;
	background: $bg;
	position: absolute;
	left: 0.03rem;
	top: 0.03rem;
	transition: left 100ms ease-in-out;
}

// Overriding default form-items.css behavior
.wrap {
	width: auto;
}
.wrap > .label-text:not(.tiny) {
	font-size: $regular;
	line-height: $regular-line-height;
	height: 0.2rem;
}

// Tiny version
.wrap > .form-elm.tiny {
	width: 0.28rem;
	height: 0.14rem;
}
.wrap > .form-elm.tiny .slider {
	width: 0.1rem;
	height: 0.1rem;
	left: 0.02rem;
	top: 0.02rem;
}

// With Label
label.wrap {
	display: flex;
	flex-direction: row;
	white-space: nowrap;
	cursor: pointer;
}
label.wrap .form-elm:not(.tiny) {
	margin-right: 0.1rem;
	flex: 0 0 0.4rem;
}
label.wrap .form-elm.tiny {
	margin-right: 0.1rem;
	flex: 0 0 0.28rem;
}
label.wrap > .label-text {
	margin-bottom: 0;
}
.wap > .label-text.tiny {
	font-size: $small;
	height: 0.14rem;
	line-height: 0.14rem;
}

// Disabled state
.wrap.disabled {
	color: $black-30;
}
.wrap.disabled > .form-elm {
	cursor: default;
	pointer-events: none;
}

// ON state
.form-elm.on {
	background: $black;
}
.form-elm.on .slider {
	left: 0.23rem;
	background: white;
}
.form-elm.tiny.on .slider {
	left: 0.16rem;
}
</style>
