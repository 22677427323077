<template>
	<FloatActions :rightAlign="true" />
	<!-- <FloatActions :responsive="{ tablet: 1.9, mobile: 1.5 }" /> -->
	<!-- <WelcomeUsers :showOnLoad="showWelcome" /> -->
	<NavSub :routed="true" :items="navItems" class="main" :exact="true" />
	<TheArtGrid :feedName="feedName" />
</template>

<script>
// Components
import FloatActions from '@/components/FloatActions'
// import WelcomeUsers from '@/views/Feed/WelcomeUsers'
import NavSub from '@/components/NavSub'
import TheArtGrid from '@/components/TheArtGrid'

export default {
	name: 'FeedMain',
	components: {
		FloatActions,
		// WelcomeUsers,
		NavSub,
		TheArtGrid,
	},
	data() {
		return {
			navItems: [
				{
					display: 'Recent',
					to: {
						name: 'Feed',
					},
				},
				// {
				// 	display: 'Staff Picks',
				// 	to: {
				// 		name: 'FeedStaffPicks',
				// 	},
				// },
				{
					display: 'Random',
					to: {
						name: 'FeedRandom',
					},
				},
			],
		}
	},
	computed: {
		theRoute() {
			return this.$gl.lockedRoute || this.$route
		},
		feedName() {
			if (this.theRoute.name == 'FeedRandom') {
				// console.log('staff-picks-random')
				return 'staff-picks-random'
			} else if (this.theRoute.name == 'FeedStaffPicks') {
				// console.log('staff-picks')
				return 'staff-picks'
			} else {
				// console.log('all')
				return 'all'
			}
		},
		showWelcome() {
			return !!(this.theRoute.query && this.theRoute.query.welcome)
		},
	},
}
</script>

<style lang="scss" scoped></style>
