// API url
function getApiUrl() {
	if (process.env.VUE_APP_API_URL) {
		return process.env.VUE_APP_API_URL
	} else if (process.env.NODE_ENV == 'production') {
		return 'https://api.arthur.io'
	} else if (process.env.NODE_ENV == 'staging') {
		// This is not used because NODE_ENV for staging is set to 'production'.
		// See _docs/domain-names for more info.
		// May be brought back if we manage to reconfigure webpack.
		return 'https://api-staging.arthur.io'
	} else {
		return 'http://localhost:4000'
	}
}

// Website url
function getBaseUrl(env) {
	env = env || process.env.NODE_ENV
	if (env == 'production') {
		return 'https://arthur.io'
	} else if (env == 'staging') {
		return 'https://staging.arthur.io'
	} else {
		return 'http://localhost:8080'
	}
}

// Image recognition url
function getImgRecoUrl() {
	if (process.env.NODE_ENV == 'production') {
		return 'https://imgreco.arthur.io'
	} else {
		return 'https://imgreco.arthur.io' // This should be swapped out with staging url at some point
	}
}

// Can't use export default because this is also used by server.js (node)
// export default getApiUrl()
module.exports = { apiUrl: getApiUrl(), baseUrl: getBaseUrl(), imgRecoUrl: getImgRecoUrl(), getBaseUrl }
