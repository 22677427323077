// Shared properties of all form items
export default {
	props: {
		// width: [Number, String], // Allows auto
		width: Number, // Allows auto
		margin: Array, // Set all margins at once
		mR: Number, // Set individual margins
		mB: Number,
		mL: Number,
		mT: Number,
	},
	computed: {
		style() {
			const styleObj = {}

			// Width
			if (this.width) styleObj.width = this.width + 'rem'

			// Margin
			if (this.margin) {
				// Margin array
				styleObj.margin = `${this.margin[0] / 100}rem ${this.margin[1] / 100}rem ${this.margin[2] /
					100}rem ${this.margin[3] / 100}rem`
			} else {
				// Individually set margins
				if (this.mR != undefined) styleObj.marginRight = this.mR / 100 + 'rem'
				if (this.mB != undefined) styleObj.marginBottom = this.mB / 100 + 'rem'
				if (this.mL != undefined) styleObj.marginLeft = this.mL / 100 + 'rem'
				if (this.mT != undefined) styleObj.marginTop = this.mT / 100 + 'rem'
			}
			return styleObj
		},
	},
}
