<template>
	<div id="sbd-wrap">
		<div id="sbd" ref="block"></div>
	</div>
</template>

<script>
// When a scrollbar (dis)appears the window.resize() event is not triggered.
// This component renders an invisible div that emits a resize event even
// when it's only the scrollbar changing the window width.

import { useSessionStore } from '@/stores/SessionStore'
import { useWindowStore } from '@/stores/WindowStore'
// import { debounce, throttle } from '@/use/Helpers'

export default {
	name: 'TheScrollBarDetection',
	emits: ['resize'],
	setup() {
		const sessionStore = useSessionStore()
		const windowStore = useWindowStore()
		return { sessionStore, windowStore }
	},
	data() {
		return {
			timeOut: null,
		}
	},
	mounted() {
		this.init()
	},
	beforeUnmount() {
		clearTimeout(this.timeOut)
	},
	methods: {
		init() {
			new ResizeObserver(this.detectResize).observe(this.$refs.block)
		},
		detectResize(e) {
			// Store scrollbar width
			this.sessionStore.storeScrollBarWidth()

			// const w = e[0].borderBoxSize[0].inlineSize // This doesn't work in Safari
			const w = e[0].contentRect.width // This needs to be tested if it actually registers the scrollbar

			// If the ArtGrid is hidden (eg. on list/room edit page), w will be 0
			// and we don't want to trigger a resize event.
			if (w) {
				window.dispatchEvent(new Event('resize'))
			}
		},
	},
}
</script>

<style scoped lang="scss">
// A relatively positioned wrap is needed
// for the 100% to not count the scroll bar.
#sbd-wrap {
	position: relative;
	width: 100%;
}
#sbd {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 0;
	pointer-events: none;

	// height: 100px;
	// border: solid 1px green;
}
</style>
