export const extensionUrl =
	'https://chrome.google.com/webstore/detail/arthur-upload/npcfemjbcbdoclpojmfjfgbdfakhiocm'

export const ddSep = { display: '———', value: '-', disabled: true }

export function privateClusterMsg(type) {
	type = type || 'room'
	const msg = [
		`<p>A private ${type} is only visible to you and anyone with the link. It is not listed on your public profile.</p>`,
		// `<p>Any artworks uploaded to a private ${type} will still be publicly visible on the artist\'s profile but won\'t be associated with you.</p>`,
		'<p>If you have stricter privacy needs, please let us know at <a href="mailto:hello@arthur.io">hello@arthur.io</a>.</p>',
	]
	return msg.join('\n')
}
