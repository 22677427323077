<template>
	<div
		ref="ref_gallery"
		id="gallery"
		:class="{ center: feedStore.centerGrid }"
		:style="{ 'max-width': feedStore.maxWidth ? feedStore.maxWidth + 'px' : null }"
	>
		<!-- Note: we can't use v-if because it would cancel onCollectCancel/Error callbacks -->
		<TheSelectionBar v-show="feedStore.selMode" :entityId="id" />

		<div v-if="showReselect" id="reselect">
			<button
				:class="{ 'block-fade': !reselectTimeout }"
				@click="reselect"
				@mouseenter="abortFadeOutReselectButton"
				@mouseleave="fadeOutReselectButton"
			>
				Reselect
			</button>
			<ActionClose @click="hideReselectButton" />
		</div>
		<div v-if="error" class="error-msg">{{ error }}</div>

		<!-- Grid -->
		<div v-else ref="ref_grid" class="grid" :class="{ 'single-row': feedStore.isSingleRow }">
			<BaseLoading v-if="feedStore.loading && !feedStore.apiError" />
			<template v-else>
				<ArtThumb
					v-for="(itm, i) in feedStore.itms"
					:key="itm.id"
					:itm="itm"
					:showInfo="false"
					:hide="maybeHide[i]"
					imgSize="small"
					:width="itm ? itm.width : null"
					:height="itm ? itm.height : null"
					:imgHold="feedStore.loading"
					:selMode="feedStore.selMode"
					:inGrid="true"
				/>
			</template>
		</div>

		<div class="error-msg" v-if="feedStore.apiError">{{ feedStore.apiError }}</div>

		<FormButton
			v-if="!feedStore.loading && feedStore.itms.length && !feedStore.isLastPage"
			id="load-more"
			:wait="feedStore.nextPageLoading"
			:level="2"
			@click="loadNextPage()"
			:value="loadBtnText"
		/>
	</div>
</template>

<script>
// Stores

import { useSessionStore } from '@/stores/SessionStore'
import { useFeedStore } from '@/stores/FeedStore'
import { useKeyStore } from '@/stores/KeyStore'
import { useMeStore } from '@/stores/MeStore'
import { useFlashStore } from '@/stores/FlashStore'
import { usePageUserStore } from '@/stores/PageUserStore'
import { usePageRoomStore } from '@/stores/PageRoomStore'
import { useWindowStore } from '@/stores/WindowStore'
import { useHttpErrorStore } from '@/stores/HttpErrorStore'

// Components
import ArtThumb from '@/components/ArtThumb'
import FormButton from '@/components/FormButton'
import BaseLoading from '@/components/BaseLoading'
import TheSelectionBar from '@/components/TheSelectionBar'
import ActionClose from '@/components/ActionClose'

export default {
	components: {
		ArtThumb,
		FormButton,
		BaseLoading,
		TheSelectionBar,
		ActionClose,
	},
	props: {
		// Either feed name is passed (staff-picks/staff-picks-random/all)
		feedName: String,

		// ...or entityType + id (artist/user/list/room)
		entityType: String,
		id: String,
	},
	emits: ['loaded'],
	setup() {
		const sessionStore = useSessionStore()
		const feedStore = useFeedStore()
		const keyStore = useKeyStore()
		const meStore = useMeStore()
		const flashStore = useFlashStore()
		const pageUserStore = usePageUserStore()
		const pageRoomStore = usePageRoomStore()
		const windowStore = useWindowStore()
		const httpErrorStore = useHttpErrorStore()
		return {
			sessionStore,
			feedStore,
			keyStore,
			meStore,
			flashStore,
			pageUserStore,
			pageRoomStore,
			windowStore,
			httpErrorStore,
		}
	},
	data() {
		return {
			infiniteScroll: false,

			// Error messages
			error: null, // When initial loading fails

			// Toggles the reselect button
			showReselect: false,
			reselectTimeout: null,

			// Pagination
			batchNr: 0,
		}
	},
	computed: {
		loadBtnText() {
			return this.feedStore.nextPageLoading ? 'loading more' : 'load more'
		},
		// Hide last (incomplete) row unless it's the last page
		maybeHide() {
			return this.feedStore.itms.map(itm => {
				const isLastRow = itm && itm.lastRow
				return !this.feedStore.isLastPage && isLastRow
			})
		},
	},
	watch: {
		id() {
			// console.log('ID CHANGE')
			// Re-initialize when the entity ID changes.
			this.feedStore.$reset()
			this.init()
		},
		feedName() {
			// console.log('FEEDNAME CHANGE')
			this.feedStore.$reset()
			this.init()
		},

		// Hide reselect button when other itms are selected.
		'feedStore.selMode'(newValue) {
			if (newValue) {
				this.hideReselectButton()
			} else {
				this.showReselectButton()
			}
		},

		// TRASH TRASH

		// // isFitted will change every time fitGrid starts and completes, // %% trash
		// // But only the first time it's complete we need to emit the "loaded" event.
		// 'feedStore.isFitted'(newValue) {
		// 	console.log('* isFitted', newValue)
		// 	if (newValue) {
		// 		this.feedStore.loading = false
		// 		this.$emit('loaded')
		// 	} else {
		// 		this.feedStore.loading = true
		// 	}
		// },

		// TRASH TRASH TRASH

		// // Resetting feed on every route change assures proper feed reloading.
		// $route(newValue, oldValue) {
		// 	// When there's an http error, the home feed is loaded in the background
		// 	// and we don't want to reset it when the user closes the error overlay.
		// 	const { active } = this.httpErrorStore.getError
		// 	if (active) return

		// 	// Within user collection, pages are kept alive, so we don't want to clear the feed store.
		// 	const fromUserFeed = !!oldValue.name.match(/^(User|UserList)$/)
		// 	const toUserFeed = !!newValue.name.match(/^(User|UserList)$/)
		// 	const fromUserOther = !oldValue.name.match(/^(User|UserList)$/) && !!oldValue.name.match(/^User/)
		// 	const toUserOther = !newValue.name.match(/^(User|UserList)$/) && !!newValue.name.match(/^User/)
		// 	const sameUser = oldValue.params.username == newValue.params.username
		// 	if ((fromUserFeed && toUserOther && sameUser) || (fromUserOther && toUserFeed)) return
		// 	this.feedStore.$reset()
		// },

		// We handle feedStore.$reset() from the beforeUnmount hook,
		// but when going from one user to another user or one artist
		// to another artist, the grid also needs to be reset.
		// We can simply check the route name, with extra logic
		// for User and UserList which whare a template
		// $route(newValue, oldValue) { %%% THIS IS BUGGY AND MESSY
		// 	if (newValue.name != oldValue.name) return
		// 	if (!newValue.name.match(/^User(List)?$/) || !oldValue.name.match(/^User(List)?$/)) return // Todo, use matched or slice which is faster
		// 	// When you close the carousel with the back button,
		// 	// a route change is triggered but we're just going back
		// 	// wo where we already were. See router beforeEach().
		// 	if (newValue.fullPath == oldValue.fullPath) return
		// 	console.log('ART GRID route change')
		// 	this.feedStore.$reset()
		// },
	},

	// Server-side only:
	serverPrefetch() {
		// Note: we were doing this but this doesn't make sense
		// to make async API calls server side, because the state
		// will be passed to client before the results are back.
		//
		// Load first page
		// return this.init()
	},

	// Client-side only:
	mounted() {
		this.windowStore.listen('resize', this.onResize, { debounce: 300 })
		this.windowStore.listen('scroll', this.onScroll, { throttle: 1000 })
		this.storePageWidth()

		this.init()

		// Trash bc we no longer pretend to load grid servers side
		// if (this.feedStore.itms.length) {
		// 	// Feed was rendered SSR
		// 	this.feedStore.fitGrid() // { isFirstPage: true }
		// } else {
		// 	// Feed has yet to be loaded (dev only)
		// 	this.init()
		// }

		// Enter key will reselect last selection.
		this.keyStore.addAll({
			Enter: { action: this.reselect },
		})
	},

	beforeUnmount() {
		this.feedStore.$reset()
	},

	// No longer needed
	// // This is an event hook for the keep-alive component.
	// // https://vuejs.org/guide/built-ins/keep-alive.html#max-cached-instances
	// // We mark the feedStore as inactive when it gets hidden,
	// // so ArtThumb knows if it's inside a grid or not.
	// activated() {
	// 	this.feedStore.activate()
	// },
	// deactivated() {
	// 	this.feedStore.deactivate()
	// },

	methods: {
		// Very first action: detect available width
		storePageWidth() {
			// When the grid is hidden via keep-alive, we don't
			// want to store pageWidth and resize the grid because
			// it messes things up.
			const galleryVisible = this.$refs.ref_gallery.offsetParent
			if (!galleryVisible) return

			const gridSidePadding = parseInt(
				window.getComputedStyle(this.$refs.ref_grid).getPropertyValue('padding-left')
			)
			const screenWidth = this.$refs.ref_gallery.clientWidth
			this.sessionStore.storePageWidth(screenWidth, gridSidePadding)
		},

		// Load first page
		init() {
			this.batchNr = 0
			try {
				this.feedStore.loadFirstFeedPage({
					feedName: this.feedName,
					entityType: this.entityType,
					id: this.id,
					// page, // Not currently used but we might wanna fetch a feed at a specific page later.
				})
			} catch {
				this.feedStore.toggleLoading(false)
				this.error = 'There was a problem loading the feed.'
			}
		},

		// Load next page
		async loadNextPage() {
			this.infiniteScroll = true
			this.batchNr++
			try {
				await this.feedStore.loadNextFeedPage({
					batchNr: this.batchNr,
				})
			} catch (err) {
				console.error(err)
				this.batchNr--
			}
		},

		// Detect window resize
		onResize() {
			// Avoid useless trigger when scrollbar appearance
			// on page load causes windowWidth change.
			if (this.feedStore.itms.length) {
				this.storePageWidth()
				this.feedStore.fitGrid()
			}
		},

		// Infinite scroll
		onScroll() {
			if (this.infiniteScroll && !this.feedStore.isLastPage && !this.loading) {
				// The following condition is necessary to avoid JS error
				// when switching pages from grid page to non-grid page on SSR
				if (this.$refs.ref_grid) {
					const { bottom } = this.$refs.ref_grid.getBoundingClientRect()
					const bottomGap = bottom - window.innerHeight
					if (bottomGap < 0) {
						this.loadNextPage()
					}
				}
			}
		},

		// Show reselect button when exiting selMode.
		showReselectButton() {
			this.showReselect = true
			this.fadeOutReselectButton()
		},

		// Fade out reselect button.
		fadeOutReselectButton() {
			clearTimeout(this.reselectTimeout)
			this.reselectTimeout = setTimeout(() => {
				this.hideReselectButton()
			}, 3100)
		},

		hideReselectButton() {
			this.showReselect = false
		},

		// When hovering we abort fadeout.
		abortFadeOutReselectButton() {
			clearTimeout(this.reselectTimeout)
		},

		// Reselect last selection
		reselect() {
			this.flashStore.removeAll()
			this.feedStore.reselect()
			this.hideReselectButton()
		},
	},
}
</script>

<style lang="scss" scoped>
/**
 * GALLERY
 */

// Note: the values here are in px on purpose,
// because the grid is an exact pixel fit to
// the screen and can't resize like all other
// content (which uses rem).

body {
	// https://bit.ly/3kpKsUM
	overflow-anchor: none;
}
#gallery {
	width: 100%;
	margin: 0 auto 1.5rem auto;
	position: relative; // Required for ScrollBarDetection
}
#gallery .grid {
	display: flex;
	flex-wrap: wrap;
	gap: 3px;
	padding-left: 20px;
	font-size: 0;
	line-height: 0;
}
#gallery .grid.single-row {
	padding-left: 0.6rem;
}
#gallery.center .grid {
	justify-content: center;
	// The grid usually doesn't have right padding to avoid it breaking
	// when the scrollbar appears and it takes a second to re-fit. But
	// when there's only one row of images, we need the padding or the
	// images won't be centered.
	padding-right: 0.6rem;
}

// Error message
#gallery .error-msg {
	margin-top: 0.3rem;
	text-align: center;
	font-size: $small;
	line-height: $small-line-height;
}

// Load more
#load-more {
	display: block;
	margin: 0.4rem auto 0 auto;
	text-transform: uppercase;
	font-size: 0.12rem;
	font-weight: 300;
	letter-spacing: 0.02rem;
	padding: 0 0.18rem 0 0.2rem;
}
#load-more.loading {
	pointer-events: none;
}

// Reselect button
#reselect {
	height: 0.4rem;
	line-height: 0.4rem;
	border-radius: $br;
	background: $primary;
	display: flex;

	position: fixed;
	top: 0.1rem;
	right: 0.1rem;
	z-index: 4; // See App.vue for z-index coordination.
	overflow: hidden;
}
#reselect button {
	padding-left: 0.13rem;
	background: transparent;
	cursor: pointer;
}
#reselect .act-close {
	width: 0.4rem;
	height: 0.4rem;
	padding: 0.1rem;
	position: static;
	line-height: 0;
}
#reselect:not(:hover) {
	animation: slow-fade 3s forwards ease-in;
}
@keyframes slow-fade {
	0% {
		opacity: 1;
	}
	75% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

/**
 * RESPONSIVE
 */

// 609 is the width of 4 square thumbmails
@media only screen and (max-width: $bp-grid-pad) {
	#gallery .grid,
	#gallery.center .grid {
		padding: 0 3px 3px 3px;
	}
}

/**
 * HOVER
 */

@media (hover: hover) {
	#reselect:hover {
		background: $primary-darker;
	}
	#reselect .act-close:hover:deep() .icn {
		fill: $white;
	}
	#load-more:hover {
		background: $black-10;
	}
}
</style>
