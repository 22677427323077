import { isSSR } from '@/use/Base'

/**
 * Choose either a desired gridWidth OR rowCount
 * and get neatly fitting width & height assigned
 * to each itm.
 * In order for this to work in layout, the <ArtThumb>
 * have a parent flex container with css gap set
 * to the pad value (default 3px).
 * @param {itms} itms Array of itm objects
 * Options:
 * @param {Number} gridWidth OR: Exact width for grid to be rendered at
 * @param {Number} rowCount  OR: Exact number of rows to use (gridWidth will vary)
 * @param {Number} baseHeight Optional: custom thumb base size (default 200)
 * @param {Number} pad Optional: custom padding (default 3)
 * @returns
 */
export default function fitGrid(itms, { gridWidth, rowCount, baseHeight, pad }) {
	// Ignore server-side
	if (isSSR) return
	// console.log('** fitGrid')

	// Defaults
	baseHeight = baseHeight || 200 // Minimum row height
	pad = pad || 3 // Padding between images

	/**
	 * STEP ONE
	 * - - -
	 * Gather aspired widths and organize them into rows.
	 */

	// Gather widths & heights
	const dimensions = itms.map(aw => {
		const ogW = aw.views && aw.views[0] ? aw.views[0].w : 1
		const ogH = aw.views && aw.views[0] ? aw.views[0].h : 1
		const w = (+ogW / +ogH) * baseHeight
		return { ogW, ogH, w }
	})

	// - Store all widths into one array per row, wrapped in its own array
	// --> rows = [[100,150,200],[100,200,300]]
	// - Store the total width per row
	// --> rowWidths = [450,600]
	let { rows, rowWidths } = _assignRows()

	/**
	 * STEP TWO
	 * - - -
	 * Recalculate widths for an exact fit.
	 */

	let index = 0
	let gridHeight = 0
	rows = rows.map((row, i) => {
		const isLastRow = i == rows.length - 1
		const totalPad = (row.length - 1) * pad
		const lastRowPerfectFit = rowWidths[rowWidths.length - 1] > gridWidth
		const proportion =
			isLastRow && !(lastRowPerfectFit || rowCount) ? 1 : (gridWidth - totalPad) / rowWidths[i]
		let currentRowWidth = 0

		row = row.map((width, j) => {
			// width = width * proportion
			width = Math.round(width * proportion)
			currentRowWidth += width
			const isLastRow = i == rows.length - 1
			const isLastInRow = j == row.length - 1
			// Adjust the last image in the row itw width to make sure there's a perfect fit.
			// When rowCount is set, the last row is fit snug,
			// but when it's not set, the last row won't be fitted.
			if (isLastInRow && (rowCount || !isLastRow)) {
				let diff = gridWidth - totalPad - currentRowWidth
				// console.log({ gridWidth, totalPad, currentRowWidth })
				// console.log('Difference:', diff, '\n ')
				width += diff
			}
			itms[index].width = width
			itms[index].height = Math.round(baseHeight * proportion)
			if (j === 0) gridHeight += itms[index].height + (i > 0 ? pad : 0)
			itms[index].lastRow = isLastRow
			index++
			return width
		})
		// console.log('Row ' + i + ' : ' + row.reduce((a, b) => a + b), row)
		return row
	})

	// When you fit the grid with a rowCount, the variable
	// gridWidth needs to be set on the flex container.
	// When you fit the grid with a certain rowWidth,
	// we need to know if there is only one row.
	return { gridWidth, gridHeight, oneRow: rows.length == 1 }

	//
	//
	//
	//

	// Organize images into rows.
	function _assignRows() {
		let rows = [] // [[100,100,100],[100,100,100]]
		const rowWidths = [] // [300,300]
		// Cumulative width / padding width of images in one row, resets when startig new row.
		let rowImgWidthTotal = 0
		let rowPadWidthTotal = 0
		if (rowCount) {
			// Calculate width of all images combined
			let totalWidth = dimensions.reduce((total, dim) => total + dim.w, 0)
			totalWidth += pad * (itms.length - 1) // Include padding
			gridWidth = Math.round(totalWidth / rowCount)
		} else if (!gridWidth) {
			console.error('fitGrid requites either gridWidth or rowCount')
			return
		}

		// Optimally fit the images into a fixed rowCount.
		dimensions.forEach((dim, i) => {
			if (!dim) console.log('fitGrid() --> Itm dimensions missing.')

			// Start new row or add width to last.
			const isFirstImg = i == 0
			const startNewRow = _maybeStartNewRow(isFirstImg, dim.w) // returns boolean
			if (startNewRow) {
				// Create new row array
				rows.push([dim.w])

				// Whenever we start a new row (except the first time)
				// we store the total width of the row.
				if (!isFirstImg) rowWidths.push(rowImgWidthTotal)

				rowImgWidthTotal = dim.w
				rowPadWidthTotal = 0 // No padding on first image
			} else {
				// Add to current row array
				rows[rows.length - 1].push(dim.w)
				rowImgWidthTotal += dim.w
				rowPadWidthTotal += pad

				// When the last row is wrapper up,
				// we also stor the width of the row.
				if (i == dimensions.length - 1) rowWidths.push(rowImgWidthTotal)
			}
		})

		return { rows, rowWidths }

		//
		//

		// Returns true when we need to start a new row.
		function _maybeStartNewRow(isFirstImg, width) {
			// First image.
			if (isFirstImg) return true

			// Abort if we maxed out on rows.
			if (rowCount && rows.length == rowCount) return false

			// Check if this image puts us over the edge.
			const newRowWidth = rowImgWidthTotal + width + pad
			const maxRowWidth = gridWidth - rowPadWidthTotal
			const isMaxedOut = newRowWidth > maxRowWidth

			// Compare the width before and after and decide if it makes
			// more sense to add the image to this or the next row.
			// We're aiming both rows to be as close to the gridWidth
			// as possible before we resize them to fit.
			if (isMaxedOut) {
				const differenceBefore = Math.abs(rowImgWidthTotal - maxRowWidth)
				const differenceAfter = Math.abs(newRowWidth - maxRowWidth)
				return differenceBefore < differenceAfter
			}

			return false
		}
	}
}

// this.fitted
// itms = itms ? itms : this.itms

// const sessionStore = useSessionStore()
// const gridWidth =
// 	(this.maxWidth ? Math.min(sessionStore.availWidth, this.maxWidth) : sessionStore.availWidth) -
// 	sessionStore.sidePadding * 2

// this.isArtist && isFirstPage && this.isSingleRow
// this.center = true
