<template>
	<div v-if="rooms" ref="roomList" id="room-list" :class="{ 'left-align': leftAlign, wrapping: gridWraps }">
		<!-- <pre>{{ rooms[1] }}</pre> -->
		<RoomPreview v-for="room in rooms" :key="room.id" :room="room" :isMe="isMe" />
		<!-- Invisible stretchers let us maintain grid structure -->
		<div class="filler"></div>
		<div class="filler"></div>
		<div class="filler"></div>
		<div class="filler"></div>
		<div class="filler"></div>
		<div class="filler"></div>
		<div class="filler"></div>
		<div class="filler"></div>
	</div>
</template>

<script>
// Stores
import { useWindowStore } from '@/stores/WindowStore'

// Components
import RoomPreview from '@/components/RoomPreview'

export default {
	name: 'RoomList',
	components: {
		RoomPreview,
	},
	props: {
		rooms: Object,
		// Used to display draft/locked UI on your own profile.
		isMe: Boolean,
		leftAlign: Boolean,
		loading: Boolean, // TODO: We can remove loading state if we get rooms directly from entity
	},
	setup() {
		const windowStore = useWindowStore()
		return { windowStore }
	},
	data() {
		return {
			gridWraps: false,
		}
	},
	mounted() {
		this.checkIfGridWraps()
		this.windowStore.listen('resize', this.checkIfGridWraps, { debounce: 300 })
	},
	methods: {
		// Returns true when rooms wrap into more than one row
		checkIfGridWraps() {
			if (!this.rooms || this.rooms.length < 3) return false
			const grid = this.$refs.roomList
			const room = this.$refs.roomList.children[0]
			const roomComputedStyle = getComputedStyle(room)
			const gridComputedStyle = getComputedStyle(grid)
			const gridHeight = grid.clientHeight + parseFloat(gridComputedStyle.marginTop)
			const roomHeight =
				room.clientHeight +
				parseFloat(roomComputedStyle.marginTop) +
				parseFloat(roomComputedStyle.marginBottom)
			//
			this.gridWraps = gridHeight != roomHeight
		},
	},
}
</script>

<style lang="scss" scoped>
/**
 * Rooms Overview
 */
#room-list {
	width: 100%;
	font-size: 0;
	justify-content: center;
	margin: -0.3rem auto 0 auto;
	display: flex;
	flex-wrap: wrap;
	padding: 0 0.3rem 0.3rem 0.3rem;
}
#room-list.left-align {
	justify-content: flex-start;
}
#room-list > .room {
	margin: 0.3rem;
}
// Invisible fill div
// Required to maintain grid structure on last row.
#room-list > .filler {
	flex-basis: 3.6rem;
}
#room-list:not(.wrapping) > .filler {
	display: none;
}

/**
 * RESPONSIVE GRID
 */

// Flexible two column grid.
@media only screen and (max-width: 800px) {
	#room-list {
		padding: 0.2rem 0.4rem 0.4rem 0.4rem;
		// background: red;
	}
	#room-list > .room {
		margin: 0.2rem;
		flex-basis: calc((100% - 0.8rem) / 2);
		// To avoid title to push room wider than it should be.
		max-width: calc((100% - 0.8rem) / 2);
	}
	#room-list > .filler {
		flex-basis: calc((100% - 0.8rem) / 2);
		margin: 0 0.2rem;
	}
}

// Single column grid.
@media only screen and (max-width: 520px) {
	// #room-list {
	// 	background: yellow;
	// }
	#room-list > .room {
		flex-basis: calc(100% - 0.4rem);
		max-width: calc(100% - 0.4rem);
	}
	#room-list > .room:deep() .img-wrap {
		flex-basis: 2rem;
	}
	#room-list > .filler {
		display: none;
	}
}

// TIght single column grid.
@media only screen and (max-width: $mobile) {
	// Fit room container
	#room-list {
		// background: pink;
		padding: 3px; // Pixel value to stay consistent with TheArtGrid padding
		padding-top: 0.4rem;
	}
	#room-list > .room {
		margin: 0;
		flex-basis: 100%;
		max-width: none;
		margin-bottom: 0.6rem;

		// Align title
		padding: 0.27rem;
	}
	#room-list > .room:deep() .img-wrap {
		flex-basis: 1.7rem;
		width: calc(100% + 0.54rem);

		// Align title
		margin: -0.27rem;
		margin-bottom: 0.2rem;
	}
}
</style>
