<template>
	<!-- <pre>{{ width }} / {{ height }}</pre> -->
	<div
		class="art-thumb"
		:class="{ hide, sel, 'sel-mode': selMode, 'in-grid': inGrid, removed, shake, 'hide-btns': hideBtns }"
		:style="styleArtThumb"
		@mouseenter="onMouseEnter"
		@mouseleave="onMouseLeave"
	>
		<!-- Icons -->
		<div
			v-if="!hideBtns"
			class="buttons left"
			:class="{
				collected: itm.collected,
				hide: feedStore.isMe,
			}"
		>
			<ActionRemove
				v-if="feedStore.isMyCluster"
				:dark="true"
				:removed="removed"
				@click="toggleRemove"
			/>
			<ActionCollect
				v-else
				ref="btnCollect"
				:itmIds="[itm.id]"
				:collected="itm.collected"
				:dark="true"
				:callbacks="collectCallbacks"
			/>
		</div>
		<div v-if="!hideBtns" class="buttons right">
			<ActionSelect :on="sel" :color="true" @click="toggleSel" />
		</div>
		<div v-if="removed" class="removed-msg">
			<div>
				<p>
					Image has been removed from
					{{ feedStore.isRoom ? 'this room' : feedStore.isList ? 'this list' : 'your collection' }}.
				</p>
				<!-- <a href="#" @click="feedStore.fitGrid">Refresh</a> -->
			</div>
		</div>

		<!-- Thumbnail -->
		<!--
			Some explanation:
			- v-slot: Exposes router-link props to child <a>: https://router.vuejs.org/guide/advanced/extending-router-link.html
			- custom: To avoid default <a> wrapping for v-slot: https://stackoverflow.com/q/67664610/2262741
		-->
		<router-link :to="itmUrl" custom v-slot="{ href }">
			<!-- Note: touchstart and touchmove throw warning about "non-passive event listener
			to a scroll-blocking" event, but we do indeed need to block scroll here and vue doesn't
			let you set an explicit passive: false, so we just have to live with the warning for now. -->
			<a
				@click="onClick"
				@contextmenu="onContextMenu"
				:href="href"
				class="img-wrap"
				@touchstart="onTouchStart"
				@touchmove="onTouchMove"
				@touchend="onTouchEnd"
				@touchcancel="onTouchEnd"
			>
				<BaseImageArt
					:itm="itm"
					:imgSize="imgSize"
					:viewNr="viewNr"
					:imgHold="imgHold"
					:eager="eager"
					:style="styleImg"
				/>
			</a>
		</router-link>

		<!-- Artist & artwork title -->
		<template v-if="showInfo && itm.artistName">
			<!-- Artist -->
			<router-link
				:to="{
					name: 'Artist',
					params: {
						namePath: itm.artistNamePath,
						category: itm.category,
					},
				}"
				class="artist incog"
				>{{ itm.artistName }}
			</router-link>

			<!-- Artwork -->
			<router-link :to="itmUrl" class="itm-info incog" :title="itm.title"
				>{{ itm.title }}{{ itm.year && itm.year.length ? ', ' + displayYear(itm.year) : '' }}
			</router-link>
		</template>

		<!-- Image Caption -->
		<template v-else-if="itm && showInfo">
			<router-link :to="itmUrl" class="itm-info caption incog" :title="itm.caption">{{
				itm.caption
			}}</router-link>
		</template>
	</div>
</template>

<script>
// Stores
import { useFeedStore } from '@/stores/FeedStore'
import { useFeaturedStore } from '@/stores/FeaturedStore'
import { useKeyStore } from '@/stores/KeyStore'
import { useSessionStore } from '@/stores/SessionStore'
import { useCarouselStore } from '@/stores/CarouselStore'
import { usePageUserStore } from '@/stores/PageUserStore'
import { usePageRoomStore } from '@/stores/PageRoomStore'
import { useMeStore } from '@/stores/MeStore'

// Components
import BaseImageArt from '@/components/BaseImageArt'
import ActionCollect from '@/components/ActionCollect'
import ActionSelect from '@/components/ActionSelect'
import ActionRemove from '@/components/ActionRemove'

// Internal
import displayYear from '@/use/DisplayYear'
import { isSSR } from '@/use/Base'
import { getItmUrl } from '@/use/ItmHelpers'
// import { domLog } from '@/use/Helpers'

export default {
	name: 'ArtThumb',
	components: {
		BaseImageArt,
		ActionCollect,
		ActionSelect,
		ActionRemove,
	},
	inject: ['$itmActions'],
	props: {
		itm: Object,
		showInfo: Boolean, // Shows artist & title
		imgSize: String, // small / medium / large / xlarge / -2x
		width: Number,
		height: {
			type: Number,
			default: 200,
		},
		viewNr: Number, // Which artwork view to show.
		imgHold: Boolean, // Lets us hold off image loading - see BaseImageArt.vue for more info.
		hide: Boolean, // Used to hide the last row in grid.
		selMode: Boolean, // Once one itm is selected, selMode is turned on:
		// The checkbox icon is displayed by default and the entire thumbnail is activated as checkbox area.
		inGrid: Boolean, // Set to true when used in TheArtGrid, needed for some style stuff.
		disableCarousel: Boolean,
		parentEntity: Object, // The parent entity which feed we should open (used by FeatureGrid).
		hideBtns: Boolean, // Hides the collect / select buttons.
		eager: Boolean, // Overrides the default laze-loading setting.
	},
	setup() {
		const feedStore = useFeedStore()
		const featuredStore = useFeaturedStore()
		const keyStore = useKeyStore()
		const sessionStore = useSessionStore()
		const carouselStore = useCarouselStore()
		const pageUserStore = usePageUserStore()
		const pageRoomStore = usePageRoomStore()
		const meStore = useMeStore()
		return {
			displayYear,
			//
			feedStore,
			featuredStore,
			keyStore,
			sessionStore,
			carouselStore,
			pageUserStore,
			pageRoomStore,
			meStore,
		}
	},
	data() {
		return {
			// Touch events
			isTapping: false, // Between touchStart & touchEnd / mouseDown & mouseUp
			maybeDoubleTap: null, // To store the doubleTap quarantine generator function.
			tapTimeout: null, // To store the doubleTap timeout.
			suppressTap: false, // Used to suppress tap event after long press release.
			suppressDelayedTap: false, // Used to suppress delayed tap event after doubleTap.
			// On touch screens, the scroll event also invokes the touchStart event.
			// We record scroll position so we can suppress longPress / doubleTap in case it changed.
			// We do this for vertical window scroll (regular grid) and horizontal container scroll (previewGrid)
			scrollPosX: 0, // Set on mounted
			scrollPosY: window.scrollY,
			// After you deselect the last image, selMode is turned off,
			// which would allow the delayedTap event to be triggered.
			// To prevent this, we instead listen to selModeSafe instead, which
			// is only set to true 500ms after selMode is disabled.
			selModeSafe: false,
			// Here we store the img element that's under your finger
			// in selMode when you multi-select by longPress+drag.
			touchMoveElm: null,
			// Used to block scroll while multi-selecting using longPress+drag.
			// Gets activated on longPress, and deactivated when you stop dragging.
			// Not the same as selMode, because selMode remains on, but the user
			// still needs to be able to scroll in between.
			dragSelectMode: false,
			// Boolean triggers automatic scrolling
			autoScrolling: false,
			// The speed at which we auto-scrol (0-1)
			autoScrollFactor: null,
			// The zone where auto-scroll is activated
			scrollZoneTop: 80,
			scrollZoneBottom: isSSR ? null : window.outerHeight - 80,
			// Shakes the image when you collect it by double tapping.
			shake: false,
			collectCallbacks: {
				collect: this.onCollect,
				collectError: this.undoCollect,
				uncollect: this.onUncollect,
				uncollectError: this.undoUncollect,
				uncollectCancel: this.undoUncollect,
			},
		}
	},
	computed: {
		// v-model selected state
		sel: {
			get() {
				return this.itm ? this.itm.sel : null
			},
			set(bool) {
				this.feedStore.toggleSelect(this.itm.index, bool)
			},
		},

		// v-model removed state (my collection/list/room)
		removed: {
			get() {
				return this.itm ? this.itm.removed : null
			},
			set(bool) {
				this.feedStore.toggleRemoved(this.itm.index, bool)
			},
		},

		// Link to artwork or image page
		itmUrl() {
			return getItmUrl(this.itm)
		},

		// ArtThumb is mostly used inside TheArtGrid (inGrid == true)
		// in which case the width & height is set for the art-thumb wrapper.
		// When ArtThumb is used outside TheArtGrid (eg. upload success page)
		// then we want to apply a custom or default height to the image and
		// let the rest auto-scale around it.
		styleArtThumb() {
			if (this.inGrid) {
				return { 'flex-basis': this.width + 'px', height: this.height + 'px' }
			} else {
				return {}
			}
		},
		styleImg() {
			if (this.inGrid) {
				return {}
			} else {
				return { height: this.height + 'px', width: this.width + 'px' } // Grids don't use rem!
			}
		},

		// Whether the itm is an artwork or an image.
		isArtwork() {
			return !!this.itm.artistName
		},
	},
	watch: {
		// selModeSafe follows selMode with 500ms delay (see props for more info).
		selMode(newValue) {
			if (newValue) {
				this.selModeSafe = true
			} else {
				setTimeout(() => {
					if (!this.selMode) {
						this.selModeSafe = false
					}
				}, 500)
			}
		},
	},
	methods: {
		/**
		 * Touch events
		 * - - -
		 * Note: we can't use the @dblclick event or our custom v-longpress directive,
		 * because they don't talk to each other. So in order to combine tap, longpress
		 * and doubleTap, we need a bespoke solution.
		 * - - -
		 * Long press --> Toggle selection
		 * Double tap --> Collect image
		 * Single tap --> instant -->  (sel mode?) --> Toggle selection
		 * 			  --> delayed --> (!sel mode?) --> Go to artwork
		 */

		onTouchStart(e) {
			// Icon buttons (collect, select) have their own click handler.
			if (e.target.tagName != 'IMG') return

			// Record scroll position so we can suppress longPress / doubleTap / singleTap
			// in case it changed by the time onTouchEnd is called.
			this.scrollPosY = window.scrollY
			const previewGridWrap = e.target.closest('.preview-grid-wrap')
			this.scrollPosX = previewGridWrap ? previewGridWrap.scrollLeft : null

			// Used to detect longPress.
			this.isTapping = true

			// Activate doubleTap quarantine (triggers doubleTap on second tap only).
			this.maybeDoubleTap = this.maybeDoubleTap ? this.maybeDoubleTap : this.doubleTapQuarantine()

			// Start timer for delayedTap.
			clearTimeout(this.tapTimeout)
			this.tapTimeout = setTimeout(() => {
				// Deactivate doubleTap quarantine.
				this.maybeDoubleTap = null

				// domLog(e.target.closest('.preview-grid-wrap').scrollLeft)
				// return

				// Ignore when user is scrolling.
				if (this.scrollPosY != window.scrollY) return
				if (previewGridWrap && this.scrollPosX != previewGridWrap.scrollLeft) return

				// Long Press
				if (this.isTapping) {
					this.suppressTap = true
					// TRIGGER: Long press
					this.longPress()
					return
				}

				// Suppress delayed tap after double tap.
				if (this.suppressDelayedTap) {
					this.suppressDelayedTap = false
					return
				}

				// Supress when in selection mode
				// (we want the instant feedback from onTouchEnd)
				if (this.selModeSafe) return

				// TRIGGER: Delayed tap
				this.delayedTap(e)
			}, 500)
		},
		onTouchEnd(e) {
			// We can't use @touchend.prevent because we first need to check
			// if the event is cancellable. Otherwise we get error while scrolling:
			// "Ignored attempt to cancel a touchend event with cancelable=false,
			// for example because scrolling is in progress and cannot be interrupted."
			if (e.cancelable) e.preventDefault()

			// Used to detect longPress.
			this.isTapping = false

			// Used to multi-select by dragging.
			this.dragSelectMode = false

			// Stop autoscroll when multo-selecting images on touch.
			this.stopAutoScroll()

			// Icon buttons (collect, select) have their own click handler.
			if (e.target.tagName != 'IMG') return

			// Ignore when user is scrolling.
			if (this.scrollPosY != window.scrollY) return

			// Trigger doubleTap quarantine (triggers doubleTap on second tap only).
			if (this.maybeDoubleTap && !this.selMode) this.maybeDoubleTap.next()

			// If the timeout registered a longPress
			// then we suppress the regular tap event.
			if (this.suppressTap) {
				this.suppressTap = false
				return
			}

			// TRIGGER: Instant tap
			this.instantTap()
		},

		// Touch move: select images in selMode
		onTouchMove(e) {
			if (!this.dragSelectMode) return

			e.preventDefault()
			const x = e.touches[0].clientX
			const y = e.touches[0].clientY
			const newSelState = this.sel
			const touchedElm = document.elementFromPoint(x, y)

			// (Un)select itms as you drag
			if (
				touchedElm &&
				touchedElm.tagName == 'IMG' &&
				touchedElm.getAttribute('data-index') &&
				touchedElm != this.touchMoveElm
			) {
				this.touchMoveElm = touchedElm
				const touchedIndex = touchedElm.getAttribute('data-index')

				// (Un)select all images between where you started and where you're dragging.
				const fromIndex = Math.min(this.itm.index, touchedIndex)
				const toIndex = Math.max(this.itm.index, touchedIndex)
				for (let i = fromIndex; i <= toIndex; i++) {
					this.feedStore.toggleSelect(i, newSelState)
				}
			}

			// Scroll page as you drag close to bottom
			// scrollZoneTop
			// scrollZoneBottom
			if (y > this.scrollZoneBottom) {
				// SCROLL DOWN
				// Calculate scroll factor % 0-1
				let factor = Math.round(((y - this.scrollZoneBottom) / this.scrollZoneTop) * 100) // 0-100
				this.autoScrollFactor = Math.min(1, factor / 100) // 0-1 capped at 1 (can be higher when touch goes below viewport)

				// this.activateAutoScroll(1, factor)
				if (!this.autoScrolling) {
					this.startAutoScroll(1)
				}
			} else if (y < this.scrollZoneTop + 60) {
				// +60 to comensate for the selection bar height
				// SCROLL UP
				// Calculate scroll factor % 0-1
				let factor = Math.round(((y - this.scrollZoneTop - 60) / this.scrollZoneTop) * -100) // 0-100
				this.autoScrollFactor = Math.min(1, factor / 100) // 0-1 capped at 1 (can be higher when touch goes below viewport)

				// this.activateAutoScroll(1, factor)
				if (!this.autoScrolling) {
					this.startAutoScroll(-1)
				}
			} else if (this.autoScrolling) {
				this.stopAutoScroll()
			}
		},

		// Autoscroll
		startAutoScroll(dir) {
			this.autoScrolling = true
			const baseDist = 5
			const that = this
			_scroll()

			function _scroll() {
				let factor = that.autoScrollFactor * dir // dir = 1/-1 for down/up
				let dist = Math.ceil(baseDist * factor)
				window.scrollTo(window.scrollX, window.scrollY + dist)
				if (that.autoScrolling) setTimeout(_scroll, 5)
			}
		},
		stopAutoScroll() {
			this.autoScrolling = false
		},

		// Generator function: requires two taps to trigger,
		// the first of which is reset by the touchStart timeout.
		doubleTapQuarantine: function*() {
			// First tap: ignore
			yield

			// Second tap
			// TRIGGER: Double tap
			this.doubleTap()
			this.maybeDoubleTap = null
			this.suppressDelayedTap = true
		},

		// Long-press
		// --> Activate selMode
		longPress() {
			if (this.inGrid) {
				// console.log('__')
				this.toggleSel()
				this.dragSelectMode = true
			}
		},

		// Instant tap
		// --> Select itm in selectiom mode.
		instantTap() {
			// console.log('•')
			this.onClick()
		},

		// Delayed tap
		// Only triggers if not intercepted by longPress or doubleTap.
		// --> Go to itm page
		delayedTap(e) {
			// console.log('_•')
			// this.$router.push(this.itmUrl)

			// Open carousel
			if (e && !this.disableCarousel) this.openCarousel(e)
		},

		// Double tap
		// --> Collect itm.
		doubleTap() {
			// console.log('••')
			if (!this.itm.collected) {
				this.$refs.btnCollect.toggleCollect()
				this.shake = true
				setTimeout(() => {
					this.shake = false
				}, 150)
			}
		},

		// Click
		// --> Select itm in selectiom mode.
		onClick(e) {
			if (this.selMode) {
				// Select image
				if (e) e.preventDefault()
				this.toggleSel()
			} else {
				// Open carousel
				if (e && !this.disableCarousel && !this.keyStore.mod) this.openCarousel(e)
			}
		},

		// Context menu
		// On touch screens, this is also triggered by a long press,
		// so we have to disable it.
		onContextMenu(e) {
			if (this.sessionStore.isTouch) {
				e.preventDefault()
			}
		},

		openCarousel(e) {
			e.preventDefault()
			let index = Number(e.target.getAttribute('data-index'))
			let itm
			if (this.parentEntity) {
				itm = this.parentEntity.feed.itms[index]
			} else {
				const indexOffset = this.feedStore.itms[0].index || 0
				index -= indexOffset
				itm = this.feedStore.itms[index]
			}

			// When the thumbnail is part of an certain entity's context, we want
			// to load that entity's feed instead of the default artist feed.
			// Currently only used in the featureGrid.
			const defaultFeedInfo = this.parentEntity
				? {
						entityType: this.parentEntity.entityType,
						id: this.parentEntity.id,
						index,
				  }
				: null
			this.carouselStore.init(e.target, itm, defaultFeedInfo)
		},

		//
		//
		//
		//

		/**
		 * Toggle actions
		 */

		// (Un)select an itm.
		toggleSel() {
			if (this.keyStore.shift) {
				const lastClickedIndex = this.feedStore.lastClickedIndex
				const fromIndex = Math.min(lastClickedIndex, this.itm.index)
				const toIndex = Math.max(lastClickedIndex, this.itm.index)
				const newSelState = !this.sel
				for (let i = fromIndex; i <= toIndex; i++) {
					this.feedStore.toggleSelect(i, newSelState)
				}
			} else {
				this.sel = !this.sel
			}

			// Store last clicked index (used for batch-selecting using shift).
			this.feedStore.storeLastClicked(this.itm.index)
		},

		toggleRemove() {
			if (this.removed) {
				// Reinstate to collection/list/room & remove removed mark.
				this.removed = false
				const clusterIds = {}
				const roomId = this.pageRoomStore ? this.pageRoomStore.roomId : null
				const listId = this.pageUserStore ? this.pageUserStore.list.id : null
				if (roomId) clusterIds.roomIds = [roomId]
				if (listId) clusterIds.listIds = [listId]
				this.$itmActions.addToClusters(
					{
						itmIds: [this.itm.id],
						...clusterIds,
					},
					{
						noFlash: true,
						onError: () => {
							this.removed = true
						},
					}
				)
			} else {
				// Remove from collection/list/room & mark removed.
				this.removed = true
				const clusterIds = {}
				const roomId = this.pageRoomStore ? this.pageRoomStore.roomId : null
				const listId = this.pageUserStore ? this.pageUserStore.list.id : null
				if (roomId) clusterIds.roomIds = [roomId]
				if (listId) clusterIds.listIds = [listId]
				this.$itmActions.removeFromClusters(
					{
						itmIds: [this.itm.id],
						...clusterIds,
					},
					{
						noFlash: true,
						onError: () => {
							this.removed = false
						},
					}
				)
			}
		},

		onCollect() {
			// Update
			this.markCollected()
			this.removed = false // If you're on your own collection
		},

		onUncollect() {
			// Collection: mark removed
			this.markUncollected()
			if (this.feedStore.isMyCollection) {
				this.removed = true
			}
		},

		// Toggle collected icon
		markCollected() {
			this.feedStore.toggleCollected(this.itm.index, true)
		},
		markUncollected() {
			this.feedStore.toggleCollected(this.itm.index, false)
		},

		// Reset (un)collect actions
		// triggered when server fails or cancel is pressed in list/room warning dialog.
		undoCollect() {
			// console.log('undo collect')
			this.markUncollected()
			this.removed = true
		},
		undoUncollect() {
			// console.log('undo uncollect')
			this.markCollected()
			this.removed = false
		},
	},
}
</script>

<style lang="scss" scoped>
// Wrap
.art-thumb {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex: 0;
	font-size: 0;
	line-height: 0;
	border-radius: $br;
}

// The last (incomplete) row of the masonry
// grid gets hidden for layout purpose.
.art-thumb.hide {
	display: none;
}

// Removed state
.art-thumb.removed {
	background: rgba(221, 0, 0, 0.5);
}
.art-thumb.removed:deep() img {
	opacity: 0.8;
	mix-blend-mode: screen;
	filter: grayscale(1) blur(0.02rem);
	// filter: blur(0.05rem);
	transform: scale(1.05);
	// opacity: 0.5;
}
.art-thumb.shake {
	animation: shake 150ms;
}
.removed-msg {
	padding: 15%;
	font-size: $small;
	line-height: $small-line-height;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 1; // See App.vue for z-index coordination.
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $bad;
	pointer-events: none;
}

/**
 * Buttons
 */

.art-thumb .buttons {
	position: absolute;
	cursor: pointer;
	z-index: 1; // See App.vue for z-index coordination.
}
.art-thumb .buttons.left {
	top: 0;
	left: 0;
}
.art-thumb .buttons.left:not(.collected),
.art-thumb .buttons.left.hide {
	display: none;
}
.art-thumb .buttons.right {
	top: 0;
	right: 0;
	display: none;
}

// Move them closer to the edge
.art-thumb .buttons > div {
	padding: 0.1rem;
}
.art-thumb .buttons.right > div {
	text-align: right;
}

/**
 * Link & Image
 */

.art-thumb a.img-wrap {
	font-size: 0;
	line-height: 0;
	position: relative;
	top: 0;
	left: 0;
	overflow: hidden; // Required for removed-from-room state that blur the image
	// border: solid 1px pink;

	// Safari: replace the default blue tap overlay
	-webkit-tap-highlight-color: $black-10;
	// Safari: block link preview popup
	-webkit-touch-callout: none;
}
.art-thumb a.img-wrap,
.art-thumb a.img-wrap:deep() img {
	// Safari: block image functionality popup
	-webkit-user-drag: none;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

// Dark gradient to better show the icons.
.art-thumb:not(.hide-btns) a.img-wrap::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 0.6rem;
	background: linear-gradient($black-30, transparent);
	opacity: 0;
	pointer-events: none;
	border-radius: $br $br 0 0;
	transition: opacity 100ms linear;
}

// Image
.art-thumb:deep() img {
	user-select: none;
	background: $black-05;
}

// Art grid only:
// TheArtGrid will assign width and height to the art-thumb wrapper,
// so the <a> and <img> elements need to stretch to fill it.
.art-thumb.in-grid a.img-wrap {
	display: block;
	width: 100%;
	height: 100%;
}
.art-thumb.in-grid:deep() img {
	width: 100%;
	height: 100%;
}

/**
 * Selection
 */

// Selection mode (global)
.art-thumb.selMode a::after {
	opacity: 1;
}
.art-thumb.selMode .buttons.right {
	display: block;
}

// Selected state (individual)
.art-thumb.sel a.img-wrap {
	margin: 0.15rem;
	width: calc(100% - 0.3rem);
	height: calc(100% - 0.3rem);
}
.art-thumb.sel .buttons.right {
	display: block;
}
.art-thumb.sel .buttons.left {
	top: 0.15rem;
	left: 0.15rem;
}

// Selected state transitions
.art-thumb a.img-wrap {
	transition: margin 100ms, width 100ms, height 100ms, top 100ms, left 100ms;
}
.art-thumb .buttons.left {
	transition: left 100ms, top 100ms;
}

/**
 * Text
 */
.itm-info {
	margin-bottom: 0.3rem;
}
.art-thumb a.artist {
	font-size: $regular;
	line-height: $regular-line-height;
	margin-top: 0.1rem;
	margin-bottom: 0.05rem;
}

@media (hover: hover) {
	// Show gradient
	.art-thumb:not(.sel):hover a.img-wrap::after {
		opacity: 1;
	}

	// Show buttons
	.art-thumb:hover .buttons.left,
	.art-thumb:hover .buttons.right {
		display: block;
	}

	// Make collect icon always 100%;
	.buttons > .act-collect.collected:not(:hover):deep() .icn {
		fill: $white;
	}

	// Make other icons transparent when not hovered.
	.buttons > .act-remove:not(:hover):deep() .icn,
	.buttons > .act-collect:not(.collected):not(:hover):deep() .icn {
		fill: $white-50;
	}
	.buttons > div:not(:hover):deep() .icn-select {
		stroke: $white-50;
	}

	// Make icons white on hover.
	.buttons > div:hover {
		opacity: 1;
	}
	.buttons > .act-remove:hover:deep() .icn,
	.buttons > .act-collect:hover:deep() .icn {
		fill: $white;
	}
	.buttons > div:hover:deep() .icn-select {
		stroke: $white;
	}
}
// trash
// @media (hover: none) {
// 	// This is to avoid iPad safari bahavior that will make any image draggable.
// 	.art-thumb:deep() img {
// 		pointer-events: none;
// 	}
// }
</style>
