<template>
	<label class="wrap" :class="{ disabled }" :style="style">
		<!-- <pre>dim: {{ dim }}</pre> -->
		<!-- <pre>dimDisplay: {{ dimDisplay }}</pre> -->
		<!-- <pre>{{ isInitialValues }}</pre> -->
		<div class="label-text">Dimensions - h / w / d</div>
		<!-- prettier-ignore -->
		<div>
			<FormText v-model="modelH" ref="h" placeholder="Height" :preventKeys="preventKeys" :margin="[0, 5, 5, 0]" maxlength="7" :disabled="disabled" @blur="onBlur('h')" @update="onValueUpdate" @paste="onPaste" />
			<FormText v-model="modelW" ref="w" placeholder="Width"  :preventKeys="preventKeys" :margin="[0, 5, 5, 0]" maxlength="7" :disabled="disabled" @blur="onBlur('w')" @update="onValueUpdate" />
			<FormText v-model="modelD" ref="d" placeholder="Depth"  :preventKeys="preventKeys" :margin="[0, 0, 5, 0]" maxlength="7" :disabled="disabled" @blur="onBlur('d')" @update="onValueUpdate" />
		</div>
		<FormDropdown
			:options="ddOptions"
			v-model="modelUnit"
			:margin="[0, 0, 0, 0]"
			:disabled="disabled"
			class="dd"
		/>

		<DebugDisplay v-if="debug" :data="{ modelH, modelW, modelD, modelUnit }" />
	</label>
</template>

<script>
// Vue
import { inject, nextTick } from 'vue'

// Components
import FormText from '@/components/FormText'
import FormDropdown from '@/components/FormDropdown'
import DebugDisplay from '@/components/DebugDisplay'

// Internal
import FormItemsStyle from '@/mixins/mx_FormItemsStyle'
import { mmToUnit, unitToMm, sanitizeNumber, getOptimalUnit } from '@/use/Dimensions'
import { ddSep } from '@/use/Constants'

export default {
	name: 'FormDimensions',
	mixins: [FormItemsStyle],
	props: {
		disabled: Boolean,

		// v-model bindings
		dim: {
			type: Object,
			default: () => {
				return {}
			},
		},
		unit: {
			// mm/cm/m/in/ft
			// We currently don't ever bind the unit as it's only used
			// here within the component, but this could come in handy later.
			type: String,
			default(props) {
				const $imperial = inject('$imperial')
				return getOptimalUnit(props.dim, $imperial)
			},
		},

		debug: {
			type: [Boolean, Number],
			default: false,
		},
	},
	emits: ['update:dim', 'update:unit'],
	components: {
		FormText,
		FormDropdown,
		DebugDisplay,
	},
	data() {
		return {
			// Dimension converted to the selected unit,
			// as they are displayed in the input fields.
			dimDisplay: {
				h: mmToUnit(this.dim.h, this.unit),
				w: mmToUnit(this.dim.w, this.unit),
				d: mmToUnit(this.dim.d, this.unit),
			},

			// Usually no v-model:unit is passed, so we can't
			// bind the dropdown directly to the unit prop.
			unitLocal: this.unit,

			// Only allow numeric input, commas and period.
			preventKeys: '[^0-9.,]',

			// As long as no update has been made, the values will
			// convert when you change the unit. As soon as as you
			// edit one value, the values will no longer update.
			isInitialValues: this.dim.h || this.dim.w || this.dim.d,

			// Unit dropdown
			ddOptions: [
				{
					value: 'cm',
					display: 'cm',
				},
				{
					value: 'in',
					display: 'inch',
				},
				ddSep,
				{
					value: 'mm',
					display: 'mm',
				},
				{
					value: 'm',
					display: 'meter',
				},
				{
					value: 'ft',
					display: 'feet',
				},
			],
		}
	},
	computed: {
		// Multiple v-model bindings.
		modelH: {
			get() {
				if (!this.dim.h && this.dim.h !== 0) return null
				// if (this.dimDisplay.h || this.dimDisplay.h === 0) return this.dimDisplay.h
				return mmToUnit(this.dim.h, this.unitLocal)
			},
			async set(val) {
				const mm = val ? unitToMm(val, this.modelUnit) : null
				val = val ? Number(val) : null
				this.dimDisplay.h = val
				const dim = { ...this.dim }
				dim.h = mm
				this.$emit('update:dim', dim)
			},
		},
		modelW: {
			get() {
				if (!this.dim.w && this.dim.w !== 0) return null
				// if (this.dimDisplay.w || this.dimDisplay.w === 0) return this.dimDisplay.w
				return mmToUnit(this.dim.w, this.unitLocal)
			},
			set(val) {
				const mm = val ? unitToMm(val, this.modelUnit) : null
				val = val ? Number(val) : null
				this.dimDisplay.w = val
				const dim = { ...this.dim }
				dim.w = mm
				this.$emit('update:dim', dim)
			},
		},
		modelD: {
			get() {
				if (!this.dim.d && this.dim.d !== 0) return null
				// if (this.dimDisplay.d || this.dimDisplay.d === 0) return this.dimDisplay.d
				return mmToUnit(this.dim.d, this.unitLocal)
			},
			set(val) {
				const mm = val ? unitToMm(val, this.modelUnit) : null
				val = val ? Number(val) : null
				this.dimDisplay.d = val
				const dim = { ...this.dim }
				dim.d = mm
				this.$emit('update:dim', dim)
			},
		},
		modelUnit: {
			// Values: mm, cm, m, in, ft
			get() {
				return this.unitLocal
			},
			set(val) {
				this.unitLocal = val
				this.$emit('update:unit', val)
			},
		},
	},
	watch: {
		// Until you change either value, the display values are
		// converted to the newly selected unit. When you update
		// the unit after one of the values has changed, the display
		// values remain and the mm values are updated.
		unitLocal(newUnit) {
			if (this.isInitialValues) {
				// Convert display values as long as they haven't been changed.
				this.dimDisplay.h = mmToUnit(this.dim.h, newUnit)
				this.dimDisplay.w = mmToUnit(this.dim.w, newUnit)
				this.dimDisplay.d = mmToUnit(this.dim.d, newUnit)
			} else {
				// Emit new mm values.
				this.$emit('update:dim', {
					h: unitToMm(this.dimDisplay.h, newUnit),
					w: unitToMm(this.dimDisplay.w, newUnit),
					d: unitToMm(this.dimDisplay.d, newUnit),
				})
			}
		},
	},
	methods: {
		onValueUpdate() {
			this.isInitialValues = false
		},

		// When pasting dimensions in the first input (eg. 4½" x 3" x 1.125")
		// we split the values and distribute them across the appropriate fields.
		async onPaste(e) {
			let pasted = e.clipboardData.getData('Text')

			// Replace alternative x characters
			pasted = pasted.replace(/&#739;|X|𝗑|×|ⅹ|ˣ|ₓ/g, 'x')

			// If a single string value is pasted, we chop it up.
			// Eg. "30cm x 20cm x 2cm"
			if (pasted.indexOf('x')) {
				e.preventDefault()

				// Replace alternative " characters
				pasted = pasted.replace(/“|”/g, '"')

				// Extract unit
				this.modelUnit = _extractUnit.bind(this)()

				// Remove alternative values in brackets, eg. 1" (2.5cm)
				pasted = pasted.replace(/\(.*\)/, '')

				// Split up by x character
				pasted = pasted.split('x')

				// Convert inch fractions to decimals
				pasted = pasted.map(value => _convertFractions(value))

				// Remove prohibited non-numeric characters
				pasted = pasted.map(value => {
					const regEx = new RegExp(this.preventKeys, 'g')
					value = value.trim().replace(regEx, '')
					// Trim any trailing periods leftover from 'in.' or end of sentence
					return value.replace(/\.$/, '')
				})

				// Update values
				// Need to wait for modelH to update modelW and then modelD,
				// or they override each other in the model's set().
				this.modelH = sanitizeNumber(pasted[0])
				await nextTick()
				this.modelW = sanitizeNumber(pasted[1])
				await nextTick()
				this.modelD = sanitizeNumber(pasted[2])

				// Move focus to last input with value
				if (pasted[2]) {
					this.$refs.d.focus()
				} else {
					this.$refs.w.focus()
				}
			}

			//
			//

			// Extract unit from pasted string
			// This is quite loose, any number + string that starts with unit will result
			// in recognized unit. First match is used regardless of what else is part of
			// ths tring.
			function _extractUnit() {
				const unitMatch = pasted.match(/(\d|½|¼|¾|⅛|⅜|⅝|⅞)\s*(mm|cm|m|inch|in|"|feet|ft.|ft)/)
				if (unitMatch) {
					let unit = unitMatch[2]
					unit = unit == 'inch' || unit == '"' ? 'in' : unit
					unit = unit.match(/^ft/) ? 'feet' : unit
					return unit
				}
			}

			// Convert inch fractions into decimals
			function _convertFractions(value) {
				value = value.replace(/\s*1\/2|½/, '.5') // 1/2
				value = value.replace(/\s*1\/4|¼/, '.25') // 1/4
				value = value.replace(/\s*3\/4|¾/, '.75') // 3/4
				value = value.replace(/\s*1\/8|⅛/, '.125') // 1/8
				value = value.replace(/\s*3\/8|⅜/, '.375') // 3/8
				value = value.replace(/\s*5\/8|⅝/, '.625') // 5/8
				value = value.replace(/\s*7\/8|⅞/, '.875') // 7/8
				return value
			}
		},

		// Sanitize number input (dealing with commas and period and spaces)
		onBlur(dim) {
			if (dim == 'h') this.modelH = sanitizeNumber(this.modelH)
			if (dim == 'w') this.modelW = sanitizeNumber(this.modelW)
			if (dim == 'd') this.modelD = sanitizeNumber(this.modelD)
		},
	},
}
</script>

<style src="../assets/css/form-items.scss" lang="scss" scoped></style>

<style scoped lang="scss">
.wrap:deep() div.wrap {
	display: inline-block;
}
.wrap:deep() .wrap:not(.dd) {
	width: calc((100% - 0.1rem) / 3) !important; // Responsive css applies to general .wrap styling
}
</style>
