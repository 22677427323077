// Returns the link to an itm (image or artwork).
export function getItmUrl(itm, viewNr) {
	if (!itm) {
		// No itm
		return {}
	} else if (itm.artistNamePath) {
		// Artwork
		return {
			name: 'Artwork',
			params: {
				category: itm.category,
				namePath: itm.artistNamePath,
				titlePath: itm.titlePath,
				viewNr: viewNr ? viewNr : '',
			},
		}
	} else {
		// Image
		return {
			name: 'Image',
			params: {
				id: itm.id,
			},
		}
	}
}

// Returns the link to an itm's artist. Not used, faster to hardcode is bc there's no logic involved.
// export function getArtistUrl(itm) {
// 	return itm
// 		? {
// 				name: 'Artist',
// 				params: {
// 					category: itm.category,
// 					namePath: itm.artistNamePath,
// 				},
// 		  }
// 		: {}
// }
