<template>
	<div id="wrap">
		<h3><span>Image Recognition</span><ActionClose :tiny="true" @click="exit" /></h3>
		<p v-if="score"><b>Score:</b> {{ score }}</p>
		<p v-else-if="error" class="error-msg">{{ error }}</p>
		<p v-else>Click to fetch score.</p>
		<FormButton
			:level="1"
			:value="['Get Score', 'Matching']"
			:wait="matching"
			:small="true"
			@click="matchArtwork"
		/>
	</div>
</template>

<script>
// Components
import ActionClose from '@/components/ActionClose'
import FormButton from '@/components/FormButton'

// Internal
import AdminPanel from '@/mixins/mx_AdminPanel'
import { imgRecoApi } from '@/services/ImgRecoService'
import { baseUrl } from '@/use/BaseUrls'
import { handleApiError } from '@/use/Helpers'

export default {
	name: 'AdminControlsImgReco',
	mixins: [AdminPanel],
	components: { ActionClose, FormButton },
	data() {
		return {
			score: null,
			matching: false,
			error: null,
		}
	},
	created() {
		if (this.entityType != 'artwork') {
			this.exit()
		}
	},
	methods: {
		exit() {
			this.$emit('loadPanel', 'Actions')
		},
		async matchArtwork() {
			// Reset
			this.error = null
			this.matching = true

			// Parameters
			const safeBaseUrl = baseUrl.match(/localhost/) ? 'https://arthur-staging.io' : baseUrl
			const imgUrl = safeBaseUrl + this.entity.views[0].src.replace(/__SIZE__/, 'large')
			const { artistId } = this.entity

			// Request
			const { status, data, statusText } = await imgRecoApi.matchImage(artistId, imgUrl)
			this.matching = false
			if (status == 200 && data) {
				// Fetch score
				const thisArtwork = data.matches.filter(match => match.artworkId == this.entity.id)[0]
				this.score = thisArtwork ? thisArtwork.score : 'No result'
			} else {
				// Log error
				handleApiError({ status, data, statusText, action: 'matching this image' })
			}
		},
	},
}
</script>

<style scoped lang="scss">
#wrap {
	padding: 0.2rem;
}
</style>
