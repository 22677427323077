// Vue
import { defineStore } from 'pinia'

// Stores
import { useApiStore } from '@/stores/ApiStore'
import { useSessionStore } from '@/stores/SessionStore'

// Internal
import { handleApiError } from '@/use/Helpers'
import fitGrid from '@/use/FitGrid'
import { isSSR } from '@/use/Base'

export const useFeedStore = defineStore('FeedStore', {
	state: () => ({
		// Feed identification for any entity allows us to refresh the feed
		// without passing variables, useful when rearranging or deleting itms.
		feedName: null, // all / staff-picks / staff-picks-random
		entityType: null,
		id: null,
		//
		itms: [], // List of itm objects
		total: null,
		page: null,
		loading: true, // Show loader while you wait for grid to load
		// this is to make sure you see the loader when you switch between empty lists.
		nextPageLoading: false, // Shows next page loader
		nextPage: null, // Next page number
		isFirstPage: true,
		isLastPage: false,
		maxWidth: null, // We limit the grid's width when less than 50 images
		fitted: false, // Allows us to check if the grid has been fitted
		lastSelection: null, // Array of all itms that were previously selected, allowing users to reselect itms to perform a second action
		lastClickIndex: null, // Last clicked itm's index, used for batch-selecting when pressing shift
		apiError: null, // Error that displays when the api fails.
		isSingleRow: false, // See centerGrid getter
		// No longer needed:
		// // The feedStore is deactivated when it's hidden
		// // by the keep-alive components. This happens in
		// // ArtGrid and is needed in the CarouselStore.
		// active: false,
		// Used for special UI treatment when (un)collecting
		isMe: false,
	}),
	getters: {
		getNextPage: state => state.nextPage, // Replace & trash
		isFitted: state => state.fitted, // Replace & trash
		selected: state => state.itms.filter(itm => itm.sel),
		selMode: state => state.itms.some(itm => itm.sel),
		lastClickedIndex: state => state.lastClickIndex || 0,
		isEmpty: state => !state.itms.length,
		// The first and last loaded id, used to load prev/next pages.
		lastId: state => (state.itms.length ? state.itms[state.itms.length - 1].id : null),
		firstId: state => (state.itms.length ? state.itms[0].id : null),
		// isUser: state => state.entityType == 'user', // Not currently used
		isList: state => state.entityType == 'list',
		isRoom: state => state.entityType == 'room',
		isMyCluster() {
			const isCluster = this.entityType == 'room' || this.entityType == 'list'
			return isCluster && this.isMe
		},
		isMyCollection: state => state.entityType == 'user' && state.isMe,
		isArtist: state => state.entityType == 'artist',
		// Where there's only one row of images we want
		// to center the grid on the artist page
		centerGrid() {
			// TODO: make this update on resize.
			if (!this.$router) {
				console.error('this.$router not working in feedStore')
				return
			}
			const route = this.$router.currentRoute.value
			if (!route) return
			return route.name == 'Artist' && this.isSingleRow
		},
	},
	actions: {
		// Load initial page (used by artGrid only).
		async loadFirstFeedPage({ feedName, entityType, id }) {
			const { status, data, statusText } = await this.loadFeed({
				feedName,
				entityType,
				id,
			})

			if (status == 200) {
				// Mark data as first loaded page.
				data.isFirstPage = true
				await this.storeFeed(data)
				this.apiError = null
				// this.activate()
				// Mark my own feed
				this.isMe = data.isMe
			} else {
				handleApiError({ status, data, statusText, action: 'loading the feed' })
				//
				this.apiError = 'Error loading feed'
			}
			this.toggleLoading(false)
		},

		// Load any page (used by carouselStore only).
		async loadFeedPage({ feedName, entityType, id, beforeAndAfterId }) {
			// console.log('-loadFeedPage')
			const { status, data, statusText } = await this.loadFeed({
				feedName,
				entityType,
				id,
				beforeAndAfterId,
			})
			if (status == 200) {
				// Mark my own feed
				this.isMe = data.isMe
				return data
			} else {
				handleApiError({ status, data, statusText, action: 'loading the carousel' })
			}
		},

		// Load prev page (used by carouselStore only, for now).
		async loadPrevFeedPage(options) {
			// console.log('loadPrevFeedPage')
			// When loading from the carousel, the beforeId is
			// passed as it is the first id in the endSlice.
			let { beforeId, beforeOId, forCarousel, batchNr } = options || {}

			// First itm
			let firstItm = this.itms[0] || {}
			beforeId = beforeId || firstItm.id
			beforeOId = beforeOId || firstItm._id

			const { status, data, statusText } = await this.loadFeed({
				feedName: this.feedName,
				entityType: this.entityType,
				id: this.id,
				beforeId,
				beforeOId,
				loop: forCarousel ? 1 : 0,
				batchNr,
				// pageSize: 100,
			})

			if (status == 200) {
				if (forCarousel) {
					// Carousel request --> don't update grid.
					return data
				} else {
					// Regular request --> update grid.
					this.storeFeed(data)
				}
			} else {
				handleApiError({ status, data, statusText, action: 'loading the previous page' })
			}
		},

		// Load next page.
		async loadNextFeedPage(options) {
			// console.log('loadNextPage')

			// Todo: integrate this with lastItm, used for carousel.
			let { afterId, afterOId, forCarousel, batchNr } = options || {}

			// Last itm
			let lastItm = this.itms[this.itms.length - 1] || {}
			afterId = afterId || lastItm.id
			afterOId = afterOId || lastItm._id

			this.nextPageLoading = true

			const { status, data, statusText } = await this.loadFeed({
				feedName: this.feedName,
				entityType: this.entityType,
				id: this.id,
				afterId,
				afterOId,
				loop: !!forCarousel,
				batchNr,
				// pageSize: 100,
			})

			if (status == 200) {
				if (forCarousel) {
					// Carousel request --> don't update grid.
					return data
				} else {
					// Regular request --> update grid.
					await this.storeFeed(data)
				}
				this.apiError = null
			} else {
				handleApiError({ status, data, statusText, action: 'loading the next page' })
				//
				this.apiError = 'Error loading next page'
			}
			this.nextPageLoading = false
		},

		// Refresh current feed.
		// Used when rearranging room itms, or when refreshing feed after having deleted itms.
		// (the latter is not yet implemented at time of writing)
		async refreshFeed(options) {
			// console.log('refreshFeed')
			const { loadAll } = options || {}
			this.toggleLoading(true)
			const { status, data, statusText } = await this.loadFeed({
				feedName: this.feedName,
				entityType: this.entityType,
				id: this.id,
				pageSize: loadAll ? 1000 : null,
			})
			data.isFirstPage = true
			if (status == 200) {
				await this.storeFeed(data)
			} else {
				handleApiError({ status, data, statusText, action: 'refreshing the feed' })
			}
			this.toggleLoading(false)
		},

		// Toggle itm states.
		toggleSelect(index, bool) {
			this.setProp(index, 'sel', bool)
		},
		toggleCollected(index, bool) {
			this.setProp(index, 'collected', bool)
		},
		toggleRemoved(index, bool) {
			this.setProp(index, 'removed', bool)
		},

		// Deselect all items (and thus exit selMode).
		exitSelMode() {
			const selected = this.itms.filter(itm => itm.sel)

			// Store selection so it can be reselected
			this.lastSelection = selected.map(itm => itm.id)

			// Deselect all
			selected.forEach(itm => {
				const index = this.itms.indexOf(itm)
				this.itms[index].sel = false
			})
		},

		// Reselect items based on lastSelection.
		reselect() {
			if (this.lastSelection) {
				this.itms
					.filter(itm => this.lastSelection.includes(itm.id))
					.forEach(itm => {
						const index = this.itms.indexOf(itm)
						this.itms[index].sel = true
					})
			}
		},

		// Set itm property (eg. sel/collected).
		setProp(index, property, value) {
			this.itms[index][property] = value
		},

		// Store last clicked index (used for batch-selecting using shift).
		storeLastClicked(index) {
			this.lastClickIndex = index
		},

		// No longer needed
		// // (De)activate the store when it's cached via keep-alive.
		// activate() {
		// 	this.active = true
		// },
		// deactivate() {
		// 	this.active = false
		// },

		// Load feed.
		loadFeed,

		// Store feed.
		storeFeed,

		// Fit images in neat masonry grid.
		fitGrid(itms, isFirstPage) {
			if (isSSR) return
			itms = itms || this.itms
			// Calculate available gridWidth
			const sessionStore = useSessionStore()
			const gridWidth =
				(this.maxWidth ? Math.min(sessionStore.availWidth, this.maxWidth) : sessionStore.availWidth) -
				sessionStore.sidePadding * 2
			const { oneRow } = fitGrid(itms, { gridWidth })

			// When there's only one row, we may want to center the grid.
			if (isFirstPage && oneRow) this.isSingleRow = true
		},

		toggleLoading(bool) {
			// console.log('~', bool)
			this.loading = bool
		},
	},
})

//
//
//
//

// Takes feedName (staff-picks/staff-picks-random) OR entityType + id.
async function loadFeed({
	feedName,
	entityType,
	id,

	// Navigation
	beforeId,
	beforeOId,
	afterId,
	afterOId,
	beforeAndAfterId,
	loop,
	batchNr,

	// Filters
	// (todo)

	// Options
	pageSize,
}) {
	// console.log('loadfeed', id)
	// Set default batchNr
	batchNr = batchNr || 0

	// Store feed identification so we can easily reload it when needed.
	this.feedName = feedName
	this.entityType = entityType
	this.id = id

	// Load API.
	const apiStore = useApiStore()
	const generalApi = await apiStore.loadApi('general')

	// Fetch itms.
	const response = await generalApi.getFeed({
		feedName,
		entityType,
		id,
		navigation: { beforeId, beforeOId, afterId, afterOId, beforeAndAfterId, loop, batchNr },
		filters: {}, // Todo
		options: { pageSize },
	})

	return response
}

// Store feed data into state.
async function storeFeed(data) {
	// console.log('--> store feed')
	this.total = data.total
	this.nextPage = data.nextPage
	this.isFirstPage = data.isFirstPage
	this.isLastPage = data.isLastPage

	this.fitted = false
	if (this.isFirstPage) {
		// Load first page

		// Limit grid width if less than 50
		if (data.isLastPage) {
			const maxWidth = calcMaxWidth.bind(this)(data.itms.length)
			this.maxWidth = maxWidth
		}

		// Fit grid
		this.fitGrid(data.itms, true)
		this.fitted = true

		// Overwrite feed
		this.itms = data.itms
	} else {
		// Load next page

		// Prepend the (hidden) last row of previous page to new itms
		let prevPageHiddenItms = this.itms.filter(aw => aw.lastRow)
		data.itms = prevPageHiddenItms.concat(data.itms)

		// Remove the (hidden) last row of previous page from previous page list
		const allButLastRow = this.itms.filter(aw => !aw.lastRow)
		this.itms = allButLastRow

		// Fit grid
		this.fitGrid(data.itms)
		this.fitted = true

		// Update itms
		this.itms = this.itms.concat(data.itms)
	}
}

// Calculate max grid width based on number of images
function calcMaxWidth(artworkCount) {
	if (!this.isArtist) return null
	return artworkCount >= 50
		? null // 50+
		: artworkCount >= 30
		? 1500 // 30-49 // Ignored because we limit pages to 30
		: artworkCount >= 20
		? 1200 // 20-29
		: artworkCount >= 10
		? 1000 // 10-19
		: artworkCount >= 5
		? 800
		: null // 5-9
}
