import { defineStore } from 'pinia'

export const useFlashStore = defineStore('FlashStore', {
	state: () => ({
		flashes: [],
		active: false,
		flashIndex: 1,
	}),
	getters: {
		isActive() {
			return this.active
		},
	},
	actions: {
		add(flash) {
			// When a flash has an id, we prevent if from being added more than once.
			// This is useful for tooltip type flashes (eg. upload form helpers)
			if (flash.name) {
				const count = this.flashes.filter(f => f.name == flash.name).length
				if (count) return
			}

			this.flashes.push({
				...flash,
				index: this.flashIndex++,
			})
			this.active = true
		},
		remove(flashIndex) {
			this.flashes = this.flashes.filter(flash => flash.index != flashIndex)
		},
		removeAll() {
			this.flashes = []
		},
		callback(flashIndex) {
			const { callback } = this.flashes.find({ index: flashIndex })
			if (callback) {
				callback()
			} else {
				console.error('Flash alert callback is missing.')
			}
		},
		// This is a separate action from remove(),
		// so we can let exit transition complete first.
		deactivate() {
			this.active = false
		},
	},
})
