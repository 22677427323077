<template>
	<!-- Form 1: name & email -->
	<form
		v-if="!success1"
		@submit.prevent="submitForm1"
		id="form-wait-list-1"
		class="input-wrap structured-form"
		novalidate
	>
		<FormText
			ref="ref_ipName"
			v-model="modelName"
			type="text"
			label="Name"
			:error="errors1.name"
			:autoFocus="true"
			@update="resetError1('name')"
		/>
		<FormText
			ref="ipEmail"
			v-model="modelEmail"
			type="email"
			label="Email"
			:error="errors1.email"
			@update="resetError1('email')"
		/>
		<p class="error-msg small" v-if="errors1.general">
			{{ errors1.general }}
		</p>
		<div class="buttons-wrap">
			<FormButton :level="1" :wait="submitting1" :value="['Join wait list', 'Joining']" />
			<FormButton :level="2" value="Skip the line" @click="showDialogSkipTheLine" />
		</div>
	</form>

	<!-- Form 2: occupation -->
	<form v-if="success1 && !success2" id="form-wait-list-2" @submit.prevent="submitForm2">
		<h3>What describes you best?</h3>
		<FormRadios
			v-model="occupation"
			@update="resetError2"
			:radioOptions="occupationDdOptions"
			name="occupation"
		/>
		<p class="error-msg small" v-if="errors2.general">
			{{ errors2.general }}
		</p>
		<FormButton :level="1" :value="submitting2 ? 'Submitting...' : 'Submit'" />
	</form>
	<div v-if="success1 && success2" class="success-msg">
		<div>
			<h3>Thank you</h3>
			<p v-if="isDuplicate">Seems like you already<br />signed up - you're all set.</p>
			<p v-else>You'll hear from us when we launch.<br /></p>
			<div class="buttons-wrap">
				<FormButton :level="1" value="Done" @click="$emit('cancel')" />
			</div>
			<!-- <p>
					<a @click="resetForm" href="#">Home</a> &nbsp;&nbsp;|&nbsp;&nbsp;
					<a @click="resetForm" href="#">Back</a>
				</p> -->
			<div class="share-wrap">
				<template v-if="shareNavigator">
					<FormButton
						:level="3"
						icon="share"
						value="Share"
						@click="
							navigator.share({
								url: 'https://arthur.io',
								text: 'I think you should see this',
							})
						"
					/>
				</template>
				<template v-else>
					<FormButton
						:level="3"
						icon="twitter"
						value="Tweet"
						@click="
							openLink(
								'https://twitter.com/intent/tweet?text=This is Arthur, a new digital museum%0D%0A%0D%0Aarthur.io'
							)
						"
					/>
					<FormButton
						:level="3"
						icon="facebook"
						value="Share"
						@click="
							openLink('https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Farthur.io')
						"
					/>
					<FormButton
						:level="3"
						icon="linkedin"
						value="Share"
						@click="openLink('https://www.linkedin.com/cws/share/?url=https%3A%2F%2Farthur.io')"
					/>
					<FormButton
						:level="3"
						icon="email"
						value="Email"
						@click="
							openLink(
								'mailto:?subject=Arthur.io&body=Arthur is a digital museum, something for you?%0D%0A%0D%0Ahttps%3A%2F%2Farthur.io'
							)
						"
					/>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
// Vue
import { mapState } from 'pinia'

// Stores
import { useApiStore } from '@/stores/ApiStore'
import { useDialogStore } from '@/stores/DialogStore'

// Components
import FormText from '@/components/FormText'
import FormButton from '@/components/FormButton'
import FormRadios from '@/components/FormRadios'

// Internal
import { processFormResponse } from '@/use/Helpers'

export default {
	name: 'AuthWaitList',
	components: {
		FormText,
		FormButton,
		FormRadios,
	},
	props: {
		home: {
			type: Boolean,
			default: false,
		},
		name: String,
		email: String,
		occupationDdOptions: Array,
	},
	emits: ['update:name', 'update:email', 'cancel'],
	setup() {
		const dialogStore = useDialogStore()
		const apiStore = useApiStore()
		const waitlistApi = apiStore.loadApi('waitlist')
		return { dialogStore, waitlistApi }
	},
	data() {
		return {
			// name: '', // See modelName
			// email: '', // See modelEmail
			errors1: {},
			errors2: {},
			submitting1: false,
			submitting2: false,
			success1: false, // <-- Set to true to show form #2
			success2: false, // <-- Set this + success1 to true to show form #3
			isDuplicate: false, // When user already signed up
			personId: null, // To connect form-2 to same user
			occupation: null, // Result from form-1
		}
	},
	computed: {
		// Pinia state
		...mapState(useDialogStore, ['openNested']),
		// Name & email are a v-model values that are shared
		// between login/signup/waitlist.
		modelName: {
			get() {
				return this.name
			},
			set(val) {
				this.$emit('update:name', val)
			},
		},
		modelEmail: {
			get() {
				return this.email
			},
			set(val) {
				this.$emit('update:email', val)
			},
		},
		shareNavigator() {
			return !!navigator.share
		},
	},
	methods: {
		// After you filled in everything, you can click "back"
		resetForm() {
			this.success1 = false
			this.success2 = false
			this.isDuplicate = false
		},

		// Form #1: name & email
		async submitForm1() {
			this.submitting1 = true
			const response = await this.waitlistApi.joinWaitList(this.name, this.email)
			processFormResponse(response, this.onError1, this.onSuccess1)
		},
		onError1(errors, statusText) {
			this.errors1 = errors
			this.submitting1 = false

			// Display error
			if (errors.email && errors.name) {
				this.errors1.general = 'Try harder my friend.'
			} else if (errors.email || errors.name) {
				this.errors1.general = null
			} else {
				this.errors1.general = statusText
			}

			// Focus on most relevant input field
			if (errors.name || errors.general) {
				this.$refs.ref_ipName.focus()
			} else {
				this.$refs.ipEmail.focus()
			}
		},
		onSuccess1(data) {
			this.personId = data._id
			this.isDuplicate = data.duplicate

			this.modelName = ''
			this.modelEmail = ''

			this.submitting1 = false
			this.success1 = true
			this.errors1 = {}
		},

		// Reset field error when editing
		resetError1(fieldName) {
			this.errors1[fieldName] = ''
			this.errors1.general = ''
		},

		// Form #2: "What describes you best"
		async submitForm2() {
			this.submitting2 = true
			const response = await this.waitlistApi.joinWaitListExtra(this.personId, this.occupation)
			processFormResponse(response, this.onError2, this.onSuccess2)
		},
		onError2(errors) {
			this.errors2 = errors
			this.submitting2 = false
		},
		onSuccess2() {
			this.personId = null
			this.occupation = null

			this.submitting2 = false
			this.success2 = true
			this.errors2 = {}
		},

		// Reset field error when editing
		resetError2() {
			this.errors2.general = ''
		},

		//
		//

		openLink(url) {
			window.open(url)
		},

		showDialogSkipTheLine() {
			const dialogStore = useDialogStore()
			dialogStore.dialogByName('DialogSkipLine')
		},
	},
}
</script>

<style lang="scss" scoped>
// Form #1
#form-wait-list-1 {
	margin: 0 auto;
}
#about-arthur p.error-msg {
	margin-top: 0.3rem;
}
#about-arthur .btn-show-form {
	margin: 0.3rem auto 0.1rem auto;
	display: none;
}
#about-arthur .success-msg {
	width: 3.2rem;
	margin: 0.3rem auto;
	display: flex;
	align-items: center;
	justify-content: center;
	/* background-color: #fff; */
}
#about-arthur .success-msg.hide {
	display: none;
}

// Form #2
#form-wait-list-2 {
	text-align: left;
}
#form-wait-list-2 .wrap {
	margin: 0.2rem 0 0.3rem;
}

// Share buttons
.share-wrap {
	margin-top: 0.3rem;
	margin-left: -0.1rem;
}
.share-wrap button {
	margin-right: 0.2rem;
}
</style>
