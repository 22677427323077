// Cookies set in the API can't be passed to the browser from an SSR request, and vice versa.
// The ssrCookie store lets us pass cookies back and forth between client and server side.
//
// Mechanics
// - - -
// API cookies:
// We intercept the axios response object (ApiService.js), parse the set-cookie header and
// store the values in state.apiCookies. Then after the client store is hydrated with the SSR state,
// we loop through the store cookies and set them in the client browser (client-entry.js).
// Note: this won't work with http-only cookies as they can't be read by javascript.
//
// Client cookies:
// On page load (server.js) we store the client cookies and then we attach them to each axios
// request object (ApiService.js) so they can be read in the API.
//
// Note:
// We refactored the ApiService so it is always initialized with access to the correct store,
// so even when a request is sent on initial page load, it will still have the cookies attached.
//
// Applications
// - - -
// • On the homepage the 'randomFeed' cookie controls the random home feed sort & pagination.
//   Every time you load the page, we send this cookie with the feed request and send back an
//   updated cookie with updated pagination.
// • The auth cookies are handled separately by the auth module but uses the same mechanics.

import { defineStore } from 'pinia'

// Parses the set-cookie header
import setCookie from 'set-cookie-parser'
// Lets us set cookies client-side
import UniversalCookie from 'universal-cookie'
// Lets us serialize cookies to set them as headers
import cookieSerializer from 'cookie'

// Internal
import { cookieMaxAge } from '@/use/Helpers'

export const useSsrCookieStore = defineStore('SsrCookieStore', {
	state: () => {
		const id = Math.round(Math.random() * 100)
		return {
			// API cookies --> To apply client side
			// - - -
			// {
			// 	name: 'foo',
			// 	value: 'bar',
			//	options: {
			// 		path: '/',
			// 		secure: true,
			//		etc...
			//	},
			// }
			apiCookies: [],

			// Client cookies --> To send with API requests
			// - - -
			// { foo: bar }
			clientCookies: [],

			id, // Useful to identify the store during testing
		}
	},
	getters: {
		// Not used for anything
		getCookies: state => state.clientCookies,

		// Client cookies
		// - - -
		// Returns single serialized string that can be set as cookie header.
		serializeClientCookies: state => {
			const cookies = state.clientCookies.map(cookie => {
				const cookieEntries = Object.entries(cookie)
				const cookieEntry = cookieEntries[0]
				const name = cookieEntry[0]
				const value = JSON.stringify(cookieEntry[1])
				return cookieSerializer.serialize(name, value)
			})
			return cookies.join('; ')
		},
	},
	actions: {
		// API cookies
		// - - -
		// Store
		// -
		// Takes the set-cookie header from a request,
		// parses the cookie data into an array of objects
		// and stores them into the store.
		storeApiCookies(setCookieHeader) {
			// Split header value into array of cookie strings.
			const splitCookieHeaders = setCookie.splitCookiesString(setCookieHeader)
			// Parse each cookie string into an object
			const apiCookies = setCookie.parse(splitCookieHeaders)
			// Store
			apiCookies.forEach(cookie => {
				const options = { ...cookie }
				delete options.name
				delete options.value
				cookie = {
					name: cookie.name,
					value: cookie.value,
					options,
				}

				// Store API cookie
				this.apiCookies.push(cookie)
			})
		},

		// Apply
		// -
		// Runs after the client store is hydrated with the SSR state.
		// Fetches cookies from the state and sets them in the browser.
		applyApiCookies() {
			const cookieMaker = new UniversalCookie()
			this.apiCookies.forEach(cookie => {
				// console.log('Apply cookie:', cookie.name, cookie.value, '\nOptions:', cookie.options)

				// The cloudflare cookie __cf_bm (render.com) has the date set
				// to a string, which weirdly enough breaks UniversalCookie.
				cookie.options.expires = new Date(cookie.options.expires)

				// Also adding try/catch just in case because if this fails the page won't load.
				try {
					cookieMaker.set(cookie.name, cookie.value, cookie.options)
				} catch (err) {
					console.error('There was an error applying a cookie:', cookie)
					console.error(err)
				}
			})

			// Reset cookie values in client store when done.
			// This is actually not important but just hiegenic.
			this.clearApiCookies()
		},

		// Clear
		// -
		// Clears API cookies server side, right after the state
		// has been serialized to hydrate the client side (server.js)
		clearApiCookies() {
			this.apiCookies = []
		},

		// Add single cookie
		// -
		// This is used to pass any other value from server.js to client.
		// Currently not used
		addApiCookie(cookie, options) {
			const name = Object.keys(cookie)[0]
			const value = cookie[name]
			const defaultOptions = {
				path: '/',
				maxAge: cookieMaxAge,
			}
			options = options ? options : defaultOptions

			// Store API cookie
			this.apiCookies.push({ name, value, options })
		},

		//
		//

		// Client cookies
		// - - -
		// Store (once on initialisation)
		// -
		// Input: 'authVersion=1; randomFeed=%7B%22r%22%3A7%2C%22page%22%3A311%2C%22shift%22%3A0%7D'
		// Output: { authVersion: '1', 'randomFeed': { r: 7, page: 311, shift: 0 } }
		setClientCookies(cookieHeader) {
			const cookieMaker = new UniversalCookie(cookieHeader)
			const cookies = cookieMaker.getAll()

			// Remove any previously stored client cookies.
			this.clientCookies = []

			// Store
			Object.entries(cookies).forEach(([key, value]) => {
				const cookie = {}
				cookie[key] = value
				this.clientCookies.push(cookie)
			})
		},
	},
})
