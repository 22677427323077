<template>
	<!-- <pre>{{ options }}</pre>
	theValue: {{ theValue }}<br />
	theValueDisplay: {{ theValueDisplay }}<br />
	<br /> -->
	<component :is="label ? 'label' : 'div'" :class="{ wrap: 1, disabled }" :style="style">
		<!-- Label text -->
		<div v-if="label" class="label-text">
			{{ label }}<span v-if="error" class="error"> {{ error }}</span
			><a
				href="#"
				v-if="helper"
				@click.prevent="$emit('helperClick')"
				@mouseenter="$emit('helperMouseEnter')"
				@mouseleave="$emit('helperMouseLeave')"
			>
				{{ helper }}</a
			>
		</div>

		<!-- Input -->
		<div class="form-elm" :class="{ error, disabled, minimal, title, flip }">
			<select ref="dropdown" v-model="theValue" tabindex="1" :disabled="disabled">
				<option
					v-for="(option, i) in options"
					:key="i"
					:value="option.value"
					:disabled="option.disabled"
					:hidden="option.hidden"
					>{{ option.display }}</option
				>
			</select>
			<div class="dsp">{{ theValueDisplay }}</div>
		</div>
		<DebugDisplay v-if="debug" :data="theValue" style="margin:0.05rem 0 0 0" />
	</component>
</template>

<script>
import FormItems from '@/mixins/mx_FormItems'
import FormItemsStyle from '@/mixins/mx_FormItemsStyle'
import DebugDisplay from '@/components/DebugDisplay'

export default {
	name: 'FormDropdown',
	components: { DebugDisplay },
	mixins: [FormItems, FormItemsStyle],
	props: {
		options: {
			type: Array,
			required: true,
			// [{value: 'abc', display: 'xyz'}]
		},
		defaultDisplay: String,
		disabled: Boolean,
		// This renders the dialog without border
		minimal: Boolean,
		// This renders the dialog as a page title
		title: Boolean,
		// Left-alig arrows
		flip: Boolean,
		debug: {
			type: [Boolean, Number],
			default: false,
		},
	},
	emits: ['helperClick', 'helperMouseEnter', 'helperMouseLeave'],
	computed: {
		theValueDisplay() {
			const selected = this.options.find(option => option.value == this.theValue)
			return selected
				? selected.passiveDisplay || selected.display
				: this.defaultDisplay || '- Select -'
		},
	},
	watch: {
		theValue(newValue) {
			const option = this.options.find(o => o.value == newValue)
			if (option && option.onSelect && typeof option.onSelect == 'function') option.onSelect()
		},
	},
	created() {
		// When a default value is selected, we have to push it to the radioVal
		const selected = this.options.find(option => option.selected)
		if (selected) this.theValue = selected.value
	},
	methods: {
		// External
		focus() {
			this.$refs.dropdown.focus()
		},
	},
}
</script>

<style src="../assets/css/form-items.scss" lang="scss" scoped></style>
<style lang="scss" scoped>
// .form-elm .dsp {
// 	background: pink;
// }
// .form-elm::after {
// 	background: orange !important;
// }
.form-elm {
	position: relative;
	width: 100%;
	height: 0.4rem;
	position: relative;
}
.form-elm:not(.minimal):not(.title) {
	border: solid 0.01rem $black-15;
	background: $white;
	border-radius: $br;
}
.form-elm select {
	height: 0.4rem;
	padding: 0 0.15rem;
	outline: none;
	margin: 0;
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1;
	opacity: 0;
}
.form-elm .dsp {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 0.38rem;
	line-height: 0.38rem;
	padding: 0 0.3rem 0 0.14rem;
	font-size: 0.15rem;

	// Rruncate
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.form-elm.minimal .dsp {
	text-align: right;
}
.form-elm::after {
	content: '';
	width: 0.3rem;
	height: 0.38rem;
	position: absolute;
	top: 0;
	right: 0;
	background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj4KICA8dGl0bGU+dHJpYW5nbGVzX2Ryb3Bkb3duLTIwPC90aXRsZT4KICA8cGF0aCBkPSJNOS43LDE2LjM2LDcuNCwxMi41M0EuMzUuMzUsMCwwLDEsNy43LDEyaDQuNmEuMzUuMzUsMCwwLDEsLjMuNTNsLTIuMywzLjgzQS4zNS4zNSwwLDAsMSw5LjcsMTYuMzZaIi8+CiAgPHBhdGggZD0iTTEwLjMsMy42NGwyLjMsMy44M2EuMzUuMzUsMCwwLDEtLjMuNTNINy43YS4zNS4zNSwwLDAsMS0uMy0uNTNMOS43LDMuNjRBLjM1LjM1LDAsMCwxLDEwLjMsMy42NFoiLz4KPC9zdmc+Cg==')
		center center no-repeat;
	opacity: 0.75;
	pointer-events: none;
}

// Flipped layout (arrows on left)
.form-elm.flip .dsp {
	padding: 0 0.14rem 0 0.3rem;
}
.form-elm.flip::after {
	right: auto;
	left: 0;
}

// Title version
.form-elm.title .dsp {
	font-family: 'Berlingske Serif', Georgia, sans-serif;
	font-weight: 700;
	font-size: 0.24rem;
	line-height: 0.42rem; // Compensation for Berlingske
	height: 0.4rem;
}
.form-elm.title::after {
	height: 0.4rem;
}

// Focus state
.form-elm:focus-within:not(.minimal):not(.title) {
	border: solid 0.01rem $primary;
}
// .form-elm:focus-within.minimal {
// background: $black-03;
// }

// Error state
.form-elm:not(.minimal):not(.title).error {
	border: solid 0.01rem $bad;
}
.form-elm.disabled {
	background: transparent;
	color: $black-30;
}

// Disabled state
.form-elm.disabled::after {
	opacity: 0.3;
}
</style>
