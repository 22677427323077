<template>
	<!-- Signup - step #0: invite code -->
	<form v-if="signupStep == 0" @submit.prevent="verifyInviteCode" novalidate class="structured-form">
		<p class="bump-b-20">
			Arthur is in private beta. Sign up for the
			<a href="#" @click.prevent="$emit('goTo', 'wait-list')">wait list</a>
			to be invited.
		</p>

		<FormText
			v-model="inviteCode"
			:error="errors.inviteCode"
			@update="resetError('inviteCode')"
			:autoFocus="true"
			label="Invitation Code"
		/>
		<div class="buttons-wrap">
			<FormButton :level="1" value="Next" :wait="waiting" />
			<FormButton :level="2" value="Cancel" @click="$emit('cancel')" />
		</div>
	</form>

	<!-- Signup - step #1 -->
	<form v-if="signupStep == 1" @submit.prevent="verifyEmail" novalidate class="structured-form">
		<FormText
			v-model="modelEmail"
			:error="errors.email"
			@update="resetError('email')"
			:autoFocus="true"
			label="Email"
			type="email"
			autocomplete="username"
		/>
		<div class="buttons-wrap">
			<FormButton :level="1" value="Next" :wait="waiting" />
			<FormButton :level="2" value="Cancel" @click="$emit('cancel')" />
		</div>
	</form>

	<!-- Signup - step #2 -->
	<form v-if="signupStep == 2" @submit.prevent="signup" novalidate class="structured-form">
		<FormText
			v-model="modelName"
			:error="errors.name"
			@update="resetError('name')"
			:autoFocus="true"
			label="Name"
			autocomplete="name"
		/>
		<FormText
			v-model="password"
			:error="errors.password"
			@update="resetError('password')"
			label="Password"
			type="password"
			autocomplete="new-password"
		/>
		<FormText
			v-model="passwordConfirm"
			:error="errors.passwordConfirm"
			@update="resetError('passwordConfirm')"
			label="Confirm Password"
			type="password"
			autocomplete="new-password"
		/>
		<div id="accept-terms" @click="toggleTerms">
			<FormToggle v-model="termsAccepted" />I accept the
			<a href="/legal/terms" target="_blank">Terms</a>.
		</div>
		<p class="error-msg small" v-if="errors.general">
			{{ errors.general }}
		</p>
		<div class="buttons-wrap">
			<FormButton :level="1" value="Next" :wait="waiting" />
			<FormButton :level="2" value="Back" @click="signupStep = 1" />
		</div>
	</form>

	<!-- Signup step #3 - occupation -->
	<form
		v-if="signupStep == 3"
		id="form-occupation"
		@submit.prevent="submitSignupExtra"
		class="structured-form"
	>
		<h3>What describes you best?</h3>

		<FormRadios
			v-model="occupation"
			@update="resetError"
			:radioOptions="occupationDdOptions"
			name="occupation"
		/>
		<p class="error-msg small" v-if="errors.general">
			{{ errors.general }}
		</p>

		<FormButton :level="1" :wait="waiting" />
	</form>

	<!-- <p class="error-msg small" v-if="errors.general">
		{{ errors.general }}
	</p>
	<div class="buttons-wrap">
		<FormButton :level="1" :wait="waiting" />
		<FormButton :level="2" value="Cancel" @click="$emit('cancel')" />
	</div> -->
</template>

<script>
// Vue
import { nextTick } from 'vue'

// Stores
import { useApiStore } from '@/stores/ApiStore'
import { useAuthStore } from '@/stores/AuthStore'

// Components
import FormText from '@/components/FormText'
import FormButton from '@/components/FormButton'
import FormRadios from '@/components/FormRadios'
import FormToggle from '@/components/FormToggle'

// Internal
import { processFormResponse } from '@/use/Helpers'

export default {
	name: 'AuthSignup',
	components: {
		FormText,
		FormButton,
		FormRadios,
		FormToggle,
	},
	props: {
		name: String,
		email: String,
		occupationDdOptions: Array,
	},
	emits: [
		'update:signupStep',
		'update:name',
		'update:email',
		'goTo',
		'alreadyRegistered',
		'cancel',
		'success',
	],
	setup() {
		const authStore = useAuthStore()
		const apiStore = useApiStore()
		const userAuthApi = apiStore.loadApi('userAuth')
		return { authStore, userAuthApi }
	},
	data() {
		return {
			// name: '', // See modelName
			// email: '', // modelEmail
			signupStep: 0, // Controls what step of the signup form we show.
			//
			inviteCode: '',
			password: '',
			passwordConfirm: '',
			termsAccepted: null,
			occupation: null, // Artist / professional / lover
			waiting: false, // Controls the waiting state of the submit buttons
			errors: {
				name: '',
				email: '',
				password: '',
				passwordConfirm: '',
				general: '',
			},
			passwordFocus: false, // To focus on password field
		}
	},
	computed: {
		// name & email are a v-model values that are shared
		// between login/signup/waitlist.
		// - - -
		// Controls what step of the signup form we show.
		modelName: {
			get() {
				return this.name
			},
			set(val) {
				this.$emit('update:name', val)
			},
		},
		modelEmail: {
			get() {
				return this.email
			},
			set(val) {
				this.$emit('update:email', val)
			},
		},
	},
	mounted() {
		// Edge case: when you submit email in step one,
		// you can remove it from the login tab, causing
		// the form to faile. So when the email is missing,
		// we reset the form.
		if (this.signupStep > 1 && !this.email) this.signupStep = 1
	},
	methods: {
		resetError(fieldName) {
			if (fieldName) this.errors[fieldName] = ''
			this.errors.general = ''
		},

		// Signup step 0: invitation code
		async verifyInviteCode() {
			console.log('verifyInviteCode', this.inviteCode)
			// Validate invitation code
			this.waiting = true
			const response = await this.userAuthApi.verifyInviteCode(this.inviteCode)
			processFormResponse(
				response,
				// Error: misisng or invalid email
				errors => {
					this.waiting = false
					this.errors = errors
				},
				// Code ok
				async () => {
					this.waiting = false
					this.signupStep = 1
				}
			)
		},

		// Signup step 1: email
		async verifyEmail() {
			// Validate email
			this.waiting = true
			const response = await this.userAuthApi.findUserByEmail(this.email)
			processFormResponse(
				response,
				// Error: missing or invalid email
				errors => {
					this.waiting = false
					this.errors = errors
				},
				// Email ok
				async user => {
					this.waiting = false
					if (user) {
						if (user.legacy) {
							// Legacy user --> continue & prefill name
							this.modelName = user.name
							this.signupStep = 2
						} else {
							// Already registered --> forward to login
							this.$emit('alreadyRegistered', true)
							await nextTick()
							this.passwordFocus = true
						}
					} else {
						// Procees to next step
						this.signupStep = 2
					}
				}
			)
		},

		// Terms
		toggleTerms(e) {
			if (e.target.tagName == 'A') return // Allow link to open
			this.termsAccepted = !this.termsAccepted
			this.resetError()
		},

		// Signup step 2: Name & password
		async signup() {
			// Front-end validation unlike email format!
			if (!this.password || !this.passwordConfirm || !this.name) {
				if (!this.name) this.errors.name = 'Required'
				if (!this.password) this.errors.password = 'Required'
				if (!this.passwordConfirm) this.errors.passwordConfirm = 'Required'
				return
			}
			if (this.password != this.passwordConfirm) {
				return (this.errors.general = "Passwords don't match")
			}
			if (!this.termsAccepted) {
				return (this.errors.general = 'Please accept the terms')
			}
			this.waiting = true
			const response = await this.userAuthApi.signup({
				name: this.name,
				email: this.email,
				password: this.password,
			})
			this.authStore.storeLogin(response)
			processFormResponse(response, this.onSignupError, this.onSignupSuccess)
		},
		onSignupError(errors) {
			this.waiting = false
			this.errors = errors
		},
		onSignupSuccess() {
			this.waiting = false
			this.signupStep = 3
		},

		// Signup step 3: occupation
		// Handles radio clicks
		async submitSignupExtra() {
			this.waiting = true
			const response = await this.userAuthApi.submitSignupExtra(this.$myUserId, this.occupation)
			processFormResponse(
				response,
				// Error
				errors => {
					this.waiting = false
					this.errors = errors
				},
				// Success
				this.onSubmitExtraSuccess
			)
		},

		// submitExtra success
		onSubmitExtraSuccess(data) {
			this.waiting = false
			this.$emit('success', data.user.name, true)
		},
	},
}
</script>

<style lang="scss" scoped>
.error-msg {
	width: 100%;
	margin-top: -0.1rem;
	margin-bottom: 0.2rem;
}

#accept-terms {
	display: flex;
	font-size: $regular;
	line-height: 0.2rem;
	margin-bottom: 0.3rem;
	cursor: pointer;
	user-select: none;
}
#accept-terms:deep() .wrap {
	margin-right: 0.1rem;
	pointer-events: none;
}

/* Signup step #3 - occupation */
#form-occupation .wrap {
	margin-top: 0.1rem;
}
</style>
