import { defineStore } from 'pinia'
import { nextTick } from 'vue'

// Stores
import { useAuthStore } from '@/stores/AuthStore'
import { useWindowStore } from '@/stores/WindowStore'

export const usePageHomeStore = defineStore('PageHomeStore', {
	state: () => ({
		// Makes the navigation sticky.
		sticky: useAuthStore().isLoggedIn,

		// Collapses the wall when you scroll past the threshold.
		wallVisible: !useAuthStore().isLoggedIn,

		// The scroll position at which we hide the white wall and make the nav sticky.
		cutoffY: null,

		// This defines whether to compensate for additional padding.
		// Keep in sync with SCSS variable $tablet.
		breakpointTablet: 690,

		// When set to true, this triggers
		// activateScrollListener() from HomeMain.vue.
		triggerActivate: false,
	}),
	actions: {
		activate(ref_whiteWall) {
			// Store white wall reference.
			this.ref_whiteWall = ref_whiteWall

			// Reset activator.
			this.triggerActivate = false

			// Reset wall.
			this.wallVisible = true
			nextTick().then(this.setCutoffY)

			if (!this.$isLoggedIn) {
				// Listen to resize
				const windowStore = useWindowStore()
				windowStore.listen('resize', this.setCutoffY, { debounce: 300 })

				// Listen to scroll
				windowStore.listen('scroll', this.onScroll)
			}
		},

		onScroll() {
			if (this.wallVisible) {
				if (window.scrollY > this.cutoffY) this.hideWhiteWall()
			}
		},

		setCutoffY() {
			if (window.innerWidth < this.breakpointTablet) {
				// When the vieport is smaller than $tablet breakpoint,
				// the subnav is no longer sticky, so we want to do the
				// switch once the subnav is scrolled outside the viewport.
				// See 'extra' insice hideWhiteWall().
				this.cutoffY = this.ref_whiteWall.getBoundingClientRect().height
			} else {
				this.cutoffY = this.ref_whiteWall.getBoundingClientRect().height - 100
			}
		},

		hideWhiteWall() {
			const scroll = window.scrollY
			const h = Math.round(this.ref_whiteWall.getBoundingClientRect().height)

			// When the vieport is smaller than $tablet breakpoint,
			// the subnav is no longer sticky, so we need to compensate
			// for the subnav height pushing the content further down.
			// The amount to be adjusted is the height of the #action-buffer
			// inside floatActions, which we can conveniently deduct from the
			// negative top margin of #whitewall.
			let compensate = 0
			if (window.innerWidth < this.breakpointTablet) {
				const marginTop = getComputedStyle(this.ref_whiteWall).getPropertyValue('margin-top')
				compensate = -parseFloat(marginTop)
			}

			window.scrollTo(0, scroll - h + compensate)
			this._toggleWall(false)

			// Remove listener.
			const windowStore = useWindowStore()
			windowStore.unlisten('scroll', this.onScroll)
		},

		// Reset white wall when you click on logo
		// See ActionLogo.vue
		resetWhiteWall() {
			const { isLoggedIn } = useAuthStore()
			window.scrollTo(0, 0)
			this.wallVisible = !isLoggedIn
			this.sticky = isLoggedIn
			this.triggerActivate = true
		},

		_toggleWall(visible) {
			this.wallVisible = visible
			this.sticky = !visible
		},
	},
})
