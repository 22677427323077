<template>
	<div id="flex-wrap" v-if="showError" @click="e => exitBubble(e, true)">
		<div id="bubble">
			<div class="content-wrap">
				<div class="content">
					<BaseIcon name="skull-large" />
					<h1>{{ httpError.status }} {{ httpError.statusText }}</h1>
					<p v-if="httpError.byLine" v-html="httpError.byLine"></p>
					<p v-else-if="httpError.status == 404">This {{ httpError.type }} does not exist.</p>
					<p v-else-if="httpError.status < 400">This is unusual...<br /></p>
					<p v-else-if="httpError.status < 500">Something went terribly wrong.<br /></p>
					<p v-else-if="httpError.status >= 500">Try refreshing in a moment.<br /></p>
					<FormButton :level="1" value="Close" class="inverse" :small="true" @click="exitBubble" />
				</div>
			</div>
			<img :src="pixel" />
		</div>
	</div>
</template>

<script>
// TO DO: Refactor to use lockedRoute

// Stores
import { useHttpErrorStore } from '@/stores/HttpErrorStore'

// Components
import BaseIcon from '@/components/BaseIcon'
import FormButton from '@/components/FormButton'

// Internal
import { pixel } from '@/use/Base'

export default {
	name: 'HttpError',
	components: {
		BaseIcon,
		FormButton,
	},
	setup() {
		const httpErrorStore = useHttpErrorStore()

		return { httpErrorStore, pixel }
	},
	data() {
		return {
			exitOnRouteChange: false,
		}
	},
	computed: {
		httpError() {
			const error = this.httpErrorStore.getError
			return error
		},
		showError() {
			// When you close the bubble, the httpError
			// stays active but the bubble is hidden.
			return this.httpErrorStore.show
		},
	},
	watch: {
		'$route.name'() {
			if (this.exitOnRouteChange) {
				this.httpErrorStore.clearError()
			}
		},
	},
	created() {
		console.log('Error 404:', this.$route.fullPath)
	},
	methods: {
		async exitBubble(e, isWrap) {
			if (isWrap && e.target.getAttribute('id') != 'flex-wrap') return
			this.exitOnRouteChange = true
			await this.httpErrorStore.hide()
			await this.$router.push({ name: 'Home', params: { disableScrollReset: true } })
		},
	},
}
</script>

<style lang="scss" scoped>
#flex-wrap {
	position: fixed;
	z-index: 30;
	width: 100%;
	height: 100vh;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 0.1rem;
	background: $bg-50;
	color: $white-75;
}

/**
 * Bubble structure
 * - - -
 * Auto-adjusts size while always remaining a circle.
 */

#bubble {
	background: $black-opaque;
	padding: 0.5rem;
	border-radius: 5rem;
	position: relative;
	box-shadow: 0 01.6rem 1.8rem $bg-75;

	// To avoid extra couple pixels after image
	font-size: 0;
	line-height: 0;
}
#bubble .content-wrap {
	height: 0;
	position: relative;
	padding-top: 50%;
	margin-bottom: -50%;
	// background: rgba(0, 0, 255, 0.5);
}
#bubble .content {
	max-width: 4rem;
	height: 3rem;
	margin-top: -1.5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: $regular;
	line-height: $regular-line-height;
	// border: solid 0.01rem red;
}
#bubble > img {
	width: 100%;
	// background: rgba(0, 255, 0, 0.5);
}

// Content structure
#bubble:deep() .icn {
	margin-top: -0.1rem;
	margin-bottom: 0.2rem;
	fill: $white-75;
}
#bubble h1 {
	margin-bottom: 0.05rem;
	// white-space: nowrap;
}
#bubble .button {
	margin-top: 0.2rem;
}
</style>
