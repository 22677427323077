<template>
	<div class="count">{{ count }}</div>
</template>

<script>
export default {
	name: 'BaseCount',
	props: {
		count: Number,
	},
}
</script>

<style scoped lang="scss">
.count {
	height: 0.2rem;
	font-size: $small;
	line-height: 0.18rem;
	border-radius: 0.1rem;
	min-width: 0.2rem;
	text-align: center;
	color: $black-30;
	border: solid 1px $black-10;
	padding: 0 0.04rem;
}
</style>
