<template>
	<!-- <pre>{{ theError }} / {{ error }} + {{ data.error }}</pre> -->
	<label class="wrap" :class="{ disabled }" :style="style">
		<FormAutocomplete
			ref="input"
			v-model="data.inputValue"
			:label="label"
			:placeholder="placeholder"
			:error="theError"
			:valid="data.valid"
			entityType="artist"
			:margin="[0, 0, 0, 0]"
			:selectedValue="data.name"
			:disabled="disabled"
			@select="selectArtist"
			@create="showDialogCreateArtist"
			@blur="onBlur"
		/>
		<DebugDisplay v-if="debug" :data="data" style="margin-top:0.05rem" />
	</label>
</template>

<script>
// Stores
import { useDialogStore } from '@/stores/DialogStore'

// Components
import FormAutocomplete from '@/components/FormAutocomplete'
import DebugDisplay from '@/components/DebugDisplay'

// Internal
import FormItemsStyle from '@/mixins/mx_FormItemsStyle'

export default {
	name: 'FormArtist',
	components: { FormAutocomplete, DebugDisplay },
	mixins: [FormItemsStyle],
	props: {
		label: {
			type: String,
			default: 'Artist *',
		},
		placeholder: {
			type: String,
			default: 'Artist name',
		},
		initialData: Object, // { name, id }
		error: String,
		disabled: Boolean,
		debug: {
			type: [Boolean, Number],
			default: false,
		},
	},
	emits: ['update'],
	setup() {
		const dialogStore = useDialogStore()
		return { dialogStore }
	},
	data() {
		return {
			// We use data.inputValue instead of the default theValue
			// because the latter only works when this component has
			// a v-model assigned in the parent context.
			data: {
				inputValue: this.initialData ? this.initialData.name : '',
				name: this.initialData ? this.initialData.name : '',
				id: this.initialData ? this.initialData.id : '',
				valid: this.initialData && this.initialData.id ? true : null, // Boolean controls good/bad icon, null hides icon
				error: null, // Error message
			},

			// When you create new artist, the blur event triggers invalid error.
			// This lets us prevent this from happening while the user creates the new artist.
			blockInvalid: false,
		}
	},
	computed: {
		// Error can come from parent (required) or within (invalid)
		theError() {
			return this.error || this.data.error
		},
	},
	watch: {
		'data.inputValue'() {
			if (!this.data.inputValue || (this.data.name && this.data.inputValue != this.data.name))
				this.removeArtist()
		},
	},
	mounted() {
		// Emit data on initiation so it's available in the edit form.
		// if (this.data.inputValue) this.$emit('update', this.data) // Can't we just ferch the data from the same source as initialData does??
	},
	methods: {
		// Invalidate field if no match is selected
		onBlur(e, selectResult) {
			//
			if (!this.data.inputValue) {
				this.$emit('update', this.data)
				return
			}

			// Artist had already been selected
			if (this.data.inputValue == this.data.name) return

			// No selected artist or name match
			if (!selectResult && !this.blockInvalid) {
				this.data.valid = false
				this.data.error = 'Invalid'
			}

			this.$emit('update', this.data)
		},
		selectArtist(selected) {
			this.data.name = selected.name
			this.data.id = selected.id
			this.data.inputValue = selected.name
			this.data.valid = true
			this.data.error = null
			this.$emit('update', this.data)
		},
		// Remove artist without clearing inputValue.
		removeArtist() {
			this.data.id = ''
			this.data.name = ''
			this.data.valid = null
			this.data.error = null
			// this.$emit('update', undefined, true)
		},
		// Clear artist and inputValue.
		// This is used in ArtistEdit.vue
		clear() {
			this.removeArtist()
			this.data.inputValue = ''
		},
		//
		// Show dialog to create new artist
		showDialogCreateArtist() {
			this.blockInvalid = true
			const that = this
			this.dialogStore.dialogByName('DialogArtistCreate', {
				submittedName: this.data.inputValue,
				onConfirm() {
					console.log('CONFIRMED', that.dialogStore.params, that.data)
					// New artist was created
					that.data.name = that.dialogStore.params.artistName
					that.data.inputValue = that.dialogStore.params.artistName
					that.data.id = that.dialogStore.params.artistId
					that.data.valid = true
					that.data.error = null
					that.blockInvalid = false
					that.$refs.input.focus()
					that.$emit('update', that.data)
				},
				onCancel() {
					that.$refs.input.select()
				},
			})
		},

		// External
		select() {
			this.$refs.input.select()
		},
		focus() {
			this.$refs.input.focus()
		},
	},
}
</script>

<style src="../assets/css/form-items.scss" lang="scss" scoped></style>

<style scoped lang="scss"></style>
