import { mapState } from 'pinia'

// Stores
import { useAuthStore } from '@/stores/AuthStore'
import { useMeStore } from '@/stores/MeStore'
import { useGlobalStore } from '@/stores/GlobalStore'

// Internal
import { isProd } from '@/use/Base'

export default {
	install: app => {
		// Exposing environment variables in $env
		app.config.globalProperties.$env = {
			NODE_ENV: process.env.NODE_ENV, // App.vue + EnvDisplay.vue
			VUE_APP_API_URL: process.env.VUE_APP_API_URL, // App.vue
			isProd,
			// Our staging server uses NODE_ENV=production – see _doc/domain-names.vue
			isStaging: process.env.NODE_ENV == 'production' && process.env.VUE_APP_API_URL,
			isDev: process.env.NODE_ENV == 'development',
		}

		// Animation speed
		// This allows us to slow down animation for debugging.
		let debugAnimSpeed =
			typeof window === 'undefined'
				? null
				: window.location.href.slice(window.location.href.indexOf('?')).match(/animSpeed=(\d+)/)

		debugAnimSpeed = debugAnimSpeed ? Number(debugAnimSpeed[1]) : null
		const animSpeed = debugAnimSpeed || 200
		app.config.globalProperties.$animSpeed = animSpeed

		// Exposing user state
		app.mixin({
			computed: {
				...mapState(useAuthStore, {
					$isLoggedIn: 'isLoggedIn',
					$isAdmin: 'isAdmin',
					$isSuperAdmin: 'isSuperAdmin',
					$myUserId: 'myUserId',
					$myUsername: 'myUsername',
				}),
				...mapState(useMeStore, { $imperial: 'imperial' }),
			},
		})

		// Global store
		// Late addition. TODO: I think we should put all global
		// variables in the globals store instead of the mess above.
		// Global store is also made available in all other stores.
		const globalStore = useGlobalStore()
		app.config.globalProperties.$gl = globalStore
	},
}
