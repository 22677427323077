import { defineStore } from 'pinia'
import { inputInFocus } from '@/use/Helpers'

export const useKeyStore = defineStore('KeyStore', {
	state: () => ({
		modifiers: {
			Control: false,
			Alt: false,
			Shift: false,
			Meta: false, // Cmnd/windows
		},

		// Storing the key listeners.
		listeners: {
			// Example:
			// 'k': [{
			//		action: () => { ... },	// Handler to be executed
			//		cumulate: true			// This allows other handlers to also be executed
			//	}]
		},
	}),

	getters: {
		ctrl: state => state.modifiers.Control,
		alt: state => state.modifiers.Alt,
		shift: state => state.modifiers.Shift,
		meta: state => state.modifiers.Meta,
		// This universal modifying key reacts to cmnd/ctrl on Mac/PC
		mod: state => (state._isMac ? state.modifiers.Meta : state.modifiers.Control),
		anyModifier: state =>
			state.modifiers.Control || state.modifiers.Alt || state.modifiers.Shift || state.modifiers.Meta,
		// If we're not listening to any keys, we abort after the modifier keys.
		_isActive: state => !!Object.keys(state.listeners).length,
		_isMac: () => typeof window != 'undefined' && window.navigator.platform.match(/^mac/i),
	},

	//

	actions: {
		init() {
			window.addEventListener('keydown', e => this.onKeyDown(e))
			window.addEventListener('keyup', e => this.onKeyUp(e))
			window.addEventListener('blur', e => this.resetModifiers(e))
		},
		activateModifier(key) {
			this.modifiers[key] = true
		},
		deactivateModifier(key) {
			this.modifiers[key] = false
		},

		// Add multiple listeners at once.
		addAll(listenerObj) {
			Object.keys(listenerObj).forEach(key => {
				const { action, cumulate } = listenerObj[key]
				this.add(key, action, cumulate)
			})
		},

		// Add single listener.
		add(key, action, cumulate) {
			// console.log('Add key listener:', key, action.name, cumulate)
			if (key.match(/^\[.+\]$/)) {
				// Split [a|b|c] into three keys with same action.
				const splitKeys = key.slice(1, key.length - 1).split('|')
				splitKeys.forEach(splitKey => {
					_add.bind(this)(splitKey, action, cumulate)
				})
			} else {
				_add.bind(this)(key, action, cumulate)
			}

			function _add(key, action, cumulate) {
				if (this.listeners[key]) {
					this.listeners[key].push({ action, cumulate })
				} else {
					this.listeners[key] = [{ action, cumulate }]
				}
			}
		},

		// Remove multiple listeners
		removeAll(listenerObj) {
			Object.keys(listenerObj).forEach(key => {
				const { action } = listenerObj[key]
				this.remove(key, action)
			})
		},

		remove(key, action) {
			if (key.match(/^\[.+\]$/)) {
				const splitKeys = key.slice(1, key.length - 1).split('|')
				splitKeys.forEach(splitKey => {
					_remove.bind(this)(splitKey, action)
				})
			} else {
				_remove.bind(this)(key, action)
			}

			function _remove(key, action) {
				// console.log('Remove key listener:', key, this.listeners[key])
				if (!this.listeners[key]) return
				const index = this.listeners[key].map(lis => lis.action).indexOf(action)
				this.listeners[key].splice(index, 1)
				if (!this.listeners[key].length) delete this.listeners[key]
			}
		},

		// Key down
		onKeyDown(e) {
			// console.log('key down: ', e.key)
			const key = e.key == ' ' ? 'Space' : e.key

			// Browser autofill will trigger keydown event without an actual key.
			if (!key) return

			// Store modifiers
			if (key.match(/Control|Alt|Shift|Meta/)) {
				this.activateModifier(key)
			}

			if (!this._isActive) return

			//
			//

			// Disable keyboard events when user is typing
			// Except Escape & Enter in input field can still trigger cancel/confirm
			const inputFocus = inputInFocus()
			if (inputFocus == 'textarea' || (inputFocus == 'input' && !key.match(/^Enter|Escape$/))) {
				return
			}

			// Execute
			if (this.listeners[key]) {
				// Key-specific actions
				for (let i = this.listeners[key].length - 1; i >= 0; i--) {
					const { action, cumulate } = this.listeners[key][i]
					action(key)
					// Unless cumulate is passed as true, other handlers will be blocked
					if (!cumulate) break
				}
			} else if (this.listeners.any) {
				// Actions on any key
				this.listeners.any.forEach(lis => lis.action(key))
			}
		},

		// Key up
		onKeyUp(e) {
			// console.log('key up: ', e.key)
			if (!e.key) return // Browser autocomplete functions trigger keyUp without key

			// Store modifiers
			if (e.key.match(/Control|Alt|Shift|Meta/)) {
				this.deactivateModifier(e.key)
			}
		},

		// When the window loses focus, we need to reset all modifiers
		// because they can be used in a shortcut to leave the browser,
		// and then they are never deactivated.
		resetModifiers() {
			this.modifiers = {
				Control: false,
				Alt: false,
				Shift: false,
				Meta: false,
			}
		},
	},
})
