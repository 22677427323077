<template>
	<div v-if="theItems.length" id="item-wrap" ref="itemWrap" tabindex="-1">
		<div
			v-for="(item, i) in theItems"
			:key="i"
			:data-value="item.value"
			:data-meta="item.meta"
			:class="{ sel: item.sel }"
			:data-index="i"
			:title="item.value"
			@mouseover="onMouseOver"
			@click="onClick(item)"
		>
			<div class="display" v-html="item.display"></div>
			<div v-if="item.meta || item.meta === 0" class="meta">{{ item.meta }}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FormSuggestions',
	props: {
		items: {
			type: Array,
			required: true,
			// { value: 'foo-bar', display: 'Foo Bar', meta: { type: 'object' } } // meta is optional, used to display artwork count or label type
		},
	},
	emits: ['click'],
	data() {
		return {
			// Index of highlighted item.
			selIndex: 0,

			// Mouseover is triggered every time content is redrawn, which means it is also
			// triggered whenever you use the keyboard arrows to cycle while your cursor is
			// resting on one of the items, effectively overriding the keyboard input.
			// To work around this, we check store the hover input separately ans we only
			// execute onMouseOver when the hoverIndex has actually changed.
			mouseOverIndex: 0,
		}
	},
	computed: {
		theItems() {
			let theItems = this.items
			theItems = theItems.map(item => {
				delete item.sel
				return item
			})
			if (theItems.length) {
				theItems[this.selIndex].sel = true
			}
			return theItems
		},
	},
	watch: {
		items() {
			this.selIndex = 0
		},
	},
	methods: {
		moveSel(down) {
			if (down) {
				this.selIndex = (this.selIndex + 1) % this.theItems.length
			} else {
				this.selIndex = (this.selIndex + this.theItems.length - 1) % this.theItems.length
			}
		},
		onMouseOver(e) {
			// See comment in data section.
			const hoverIndex = +e.currentTarget.getAttribute('data-index')
			if (this.mouseOverIndex != hoverIndex) {
				this.mouseOverIndex = hoverIndex
				if (hoverIndex || hoverIndex === 0) this.selIndex = hoverIndex
			}
		},
		onClick(item) {
			this.$emit('click', item)
		},

		// Externals
		getSelected() {
			return this.items[this.selIndex]
		},
		getTray() {
			return this.$refs.itemWrap
		},
	},
}
</script>

<style scoped lang="scss">
// Wrap
#item-wrap {
	width: 100%;
	background: #fff;
	border: solid 0.01rem $black-10;
	border-radius: $br;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	box-shadow: 0 0.05rem 0.4rem $black-10;
	font-size: $regular;
}

// Item
#item-wrap > div {
	height: 0.4rem;
	line-height: 0.4rem;
	padding: 0 0.13rem;
	border-bottom: solid 0.01rem $black-10;
	position: relative;
	display: flex;
}
#item-wrap > div:last-child {
	border-bottom: none;
}

// Value
#item-wrap .display {
	flex: 1 1 auto;

	// Truncate
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	font-size: $regular;
}

// Identifier
#item-wrap .display:deep() span {
	color: $black-30;
}

// Meta info
#item-wrap .meta {
	flex: 0 0;
	margin-left: 0.05rem;
	text-align: right;
	color: $black-30;
}

// Add value
#item-wrap:deep() .add-value {
	width: 100%;
	text-align: right;

	// Truncate
	display: block;
	flex: 1;
	// border: solid 0.01rem red;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	font-size: $regular;
}

// Item hover/active
#item-wrap > div.sel::after {
	position: absolute;
	left: -0.01rem;
	top: -0.01rem;
	content: '';
	display: block;
	width: calc(100% + 0.02rem);
	height: 0.41rem;
	background: $primary-dark-05;
	cursor: pointer;
}
#item-wrap > div.sel:first-child::after {
	border-radius: $br $br 0 0;
}
#item-wrap > div.sel:last-child::after {
	border-radius: 0 0 $br $br;
	height: 0.42rem;
}
</style>
