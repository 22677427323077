import { capitalize } from '@/use/StringMagic'

// prettier-ignore
export default function(labels) {
	// Separate material & substrate
	let materials = labels.filter(label => label.type == 'material')
	let substrates = labels.filter(label => label.type == 'substrate')
	materials = materials.map(material => material.value)
	substrates = substrates.map(substrate => substrate.value)
	
	// Separate the last two of each
	let materialsLast = materials.splice(-2)
	let substratesLast = substrates.splice(-2)

	// Render the text
	const comma1 = materials.length ? ', ' : ''
	const comma2 = substrates.length ? ', ' : ''
	let materialText = materials.join(', ') + comma1 + materialsLast.join(' & ')
	let substrateText = substrates.join(', ') + comma2 + substratesLast.join(' & ')
	let text = []
	if (materialText) text.push(materialText)
	if (substrateText) text.push(substrateText)
	text = text.join(' on ')

	return capitalize(text)
}
