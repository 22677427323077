/*

Globally accessible variables in app and router via this.$gl.
Exposed globally via /plugins/globalVars for app, and
client-entry/server-entry for the store.

Note: because this store is attached to the global pinia instance,
it can't also get access to this.$router.

TODO: cleanup globalVars and centralize all that stuff here.

*/

import { defineStore } from 'pinia'

export const useGlobalStore = defineStore('GlobalStore', {
	state: () => ({
		// This controls the locking and unlocking of a route,
		// after which we can push new routes without updating the
		// page content. This is used by the carousel and search.
		// - - -
		// When you open the carousel or search, the lockedRoute is
		// set to the route where you opened it from in routeStore.
		// This triggers a route override in App.vue, allowing us to
		// stay on the source route while also updating the URL.
		lockedRoute: null,

		// When you exit the carousel or search, lockedRoute will
		// be false, causing the progress bar to kick in which is
		// annoying (see mx_PageProgress). This boolean will stay
		// true a bit longer so we can avoid that.
		lockedRouteActive: null,
	}),
})
