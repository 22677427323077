<template>
	<!-- <pre style="position: fixed; top: 100px; left: 20px; z-index: 1000000">@@@{{ $route }}@@@</pre> -->
	<!-- First itm Loader -->
	<div
		id="carousel-loader"
		v-if="carouselStore.loadingFirstItm"
		:style="{
			left: carouselStore.loaderPos.x + 'px',
			top: carouselStore.loaderPos.y + 'px',
		}"
	></div>

	<!-- Actions -->
	<div
		id="carousel-actions"
		ref="ref_carouselActions"
		:class="{ show: actions.on.value, slow: actions.slowButtonFade.value }"
		@touchend="actions.disableAutoHide"
	>
		<ActionCollect
			:dark="dark"
			:itmIds="[carouselStore.focusItm.id]"
			:collected="carouselStore.focusItm.collected"
			:callbacks="actions.collectCallbacks"
			:disabled="!carouselStore.itmsLoaded"
		/>
		<ActionCluster
			v-model="actions.cluster.value"
			:itmIds="[carouselStore.focusItm.id]"
			:containingLists="carouselStore.focusItmContainingLists"
			:containingRooms="carouselStore.focusItmContainingRooms"
			:minimal="true"
			:dark="dark"
			:callbacks="actions.clusterCallbacks"
			:disabled="!carouselStore.itmsLoaded"
		/>
		<ActionMenu :options="infoBar.menuOptions.value" :dark="dark" />

		<div class="filler"></div>
		<div class="pageTitle" v-if="carouselStore.feedTitle">
			{{ carouselStore.feedTitle }}
		</div>
		<div
			v-if="carouselStore.total && carouselStore.total > 1 && carouselStore.itms.length > 1"
			class="pag-wrap"
		>
			<div class="pag" v-if="carouselStore.infinite">
				{{ carouselStore.index + 1 }}
			</div>
			<div class="pag" v-else>{{ carouselStore.index + 1 }} / {{ carouselStore.total }}</div>
		</div>
		<ActionClose @click="touch.closeAndResetRoute" :dark="dark" :inline="true" />
		<div class="bg-gradient" :class="{ dark }"></div>
	</div>

	<div
		id="carousel"
		:class="{ dark, active: imgBox.active.value }"
		:style="touch.cssVars.value"
		@touchstart="touch.onTouchStart"
		@touchmove="touch.onTouchMove"
		@touchend="touch.onTouchEnd"
	>
		<!-- style="opacity: 0.5" -->
		<!-- prettier-ignore -->
		<!-- <pre style="position: absolute; z-index: 100; top: 0; pointer-events: none">@{{carouselStore.carouselItms[0].roomIds}}</pre> -->
		<!-- prettier-ignore -->
		<!-- <div style="position: absolute; z-index: 100; top: 70px; pointer-events: none; max-width: 100%; word-wrap: break-word; border: solid 1px red">@{{ carouselStore.itms.map((itm, i) => itm ? itm.index + '-' + i : '').join('.') }}</div> -->

		<!-- Slides -->
		<div
			class="slide-wrap"
			ref="ref_slidesWrap"
			:class="{ 'snap-next': touch.snapNext.value, 'snap-back': touch.snapBack.value }"
			:style="{ transform: `translateX(${touch.shift.value}px)` }"
		>
			<div
				v-for="(itm, i) in carouselStore.carouselItms"
				:key="i"
				class="slide"
				:class="{
					'loading-img': carouselStore.imgLoaders[i],
					'loading-preview': carouselStore.previewLoaders[i],
					'loading-placeholder': carouselStore.placeholderLoaders[i],
					'loading-itm': carouselStore.itmLoaders[i],
				}"
				:data-ci="i"
				:data-title="itm ? itm.title : '-'"
			>
				<!-- <pre>{{ carouselStore.imgLoaders[i] }}</pre> -->
				<!-- <pre>{{ carouselStore.placeholderLoaders[i] }}</pre> -->
				<!-- <pre>{{ carouselStore.itmLoaders[i] }}</pre> -->
				<!-- <pre>{{ carouselStore.previewLoaders[i] }}</pre> -->
				<!-- <pre>{{ imgBox.revealHiRes }}</pre> -->
				<template v-if="itm">
					<!-- Image -->
					<div class="img-box" :class="{ 'with-info': infoBar.on.value }">
						<div class="img-wrap">
							<!-- Placeholder image -->
							<!-- 
								The placeholder image is used to (A) structurally render the page before the
								actual image has been loaded yet and (B) calculate the coordinates for the 
								transition image to be animated to. But unfortunately our page layout heavily
								depends on height:100% inside a max-height:100% element, which is not correctly
								rendered in Safari due to a bug (https://stackoverflow.com/q/19119910/2262741).
								And because Safari is tied to the operating system, there's still many outdated
								Safari browsers in the wild. Hence this ugly workaround where we load the
								placeholder image twice. The first one is used to prop up the second one, but only
								the second one will have correct dimensions. Eventually, when this bug is no longer
								relevant, we can remove the first img-placeholder and also remove the <picture>
								wrapper of the second placeholder and the requestAnimationFrame under _getStartAndEndCSS().
							 -->
							<img class="img-placeholder safari" :src="carouselStore.imgPlaceholders[i]" />
							<picture>
								<img
									class="img-placeholder"
									:class="{
										bg: carouselStore.readyForPagination,
									}"
									:src="carouselStore.imgPlaceholders[i]"
									@load="
										e => {
											// Start transition animation when opening the first image.
											if (!imgBox.active.value && i == carouselStore.mid)
												imgBox.startTransition()
											// Remove placholder loader when going fwd/bwd.
											carouselStore.placeholderLoaders[i] = false
										}
									"
								/>
							</picture>

							<!-- Loader for when you go fwd/bwd too fast. -->
							<div class="loader" v-if="carouselStore.imgLoaders[i]"></div>

							<!-- Lo-res preview -->
							<picture v-if="carouselStore.imgLoaders[i]">
								<img
									class="preview"
									:src="carouselStore.loRes[itm.index]"
									@load="
										() => {
											carouselStore.previewLoaders[i] = false
										}
									"
								/>
							</picture>
							<PreviewLoader
								v-if="carouselStore.imgLoaders[i] && !carouselStore.previewLoaders[i]"
								:isAnim="itm.views[0].isAnim"
							/>

							<!-- Hi-res -->
							<BaseImageArt
								:id="i == carouselStore.mid ? 'img-hi-res' : null"
								:itm="itm"
								:imgSize="carouselStore.displaySize"
								:customClass="{
									hide: i == carouselStore.mid ? !imgBox.revealHiRes.value : false,
								}"
								@load="
									e => {
										// End transition animation when opening the first image.
										if (!imgBox.revealHiRes.value && i == carouselStore.mid) {
											imgBox.endTransition({ targetLoaded: true })
										}
										// Remove image loader when going fwd/bwd.
										carouselStore.imgLoaders[i] = false
									}
								"
							/>
						</div>
					</div>

					<!-- Info -->
					<div class="info" :class="{ hide: !infoBar.on.value }" :style="infoBar.styleInfo.value">
						<div class="shutter">
							<div class="non-collapsable">
								<!-- Artwork title & artist -->
								<template v-if="itm.artistName">
									<div class="title">
										<i
											><router-link
												:to="getItmUrl(itm)"
												class="incog"
												@click="infoBar.onLinkClick"
											>
												{{ itm.title || 'Untitled' }}</router-link
											></i
										><template v-if="itm.year">, {{ displayYear(itm.year) }}</template>
									</div>
									<b>
										<router-link
											:to="{
												name: 'Artist',
												params: {
													namePath: itm.artistNamePath,
													category: itm.category,
												},
											}"
											class="incog"
											@click="infoBar.onLinkClick"
										>
											{{ itm.artistName }}
										</router-link>
									</b>
								</template>

								<!-- Image info -->
								<template v-else>
									<router-link
										v-if="itm.caption && 0"
										:to="{ name: 'Image', params: { id: itm.id } }"
										@click="infoBar.onLinkClick"
										>{{ itm.caption.slice(0, 200) }}</router-link
									>
									<template v-else>
										<router-link
											:to="{ name: 'Image', params: { id: itm.id } }"
											@click="infoBar.onLinkClick"
											>Image</router-link
										>
										<template v-if="itm.contributor">
											uploaded by

											<router-link
												:to="{
													name: 'EntityById',
													params: { entityType: 'user', id: itm.contributor },
												}"
												@click="infoBar.onLinkClick"
											>
												this user</router-link
											>.
										</template>
										<!-- TODO We need to check if this room is by the contributor before we describe it as such -->
										<!-- <template v-if="itm.roomIds && itm.roomIds.length">
											in
											<i>
												<router-link
													:to="{
														name: 'EntityById',
														params: { entityType: 'room', id: itm.roomIds[0] },
													}"
													>this room</router-link
												></i
											></template
										>. -->
									</template>
								</template>
							</div>
						</div>
					</div>
				</template>
			</div>
		</div>

		<!-- Invisible prev/next actions at the bottom corners -->
		<div v-if="sessionStore.isTouch" id="prev-next" :class="{ show: carouselStore.showPrevNext }">
			<div
				class="prev"
				@touchstart.stop.prevent="carouselStore.prev"
				@touchmove.stop.prevent
				@touchend.stop.prevent
			>
				<BaseIcon name="arrow-left" />
			</div>
			<div
				class="next"
				@touchstart.stop.prevent="carouselStore.next"
				@touchmove.stop.prevent
				@touchend.stop.prevent
			>
				<BaseIcon name="arrow-right" />
			</div>
		</div>
	</div>
</template>

<script setup>
// Modules
import Cookies from 'universal-cookie'

// Vue
import { reactive, toRefs, computed, ref, onMounted, onBeforeUnmount, watch, inject } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute, useRouter } from 'vue-router'

// Stores
import { useCarouselStore } from '@/stores/CarouselStore'
import { useFeedStore } from '@/stores/FeedStore'
import { useSessionStore } from '@/stores/SessionStore'
import { useKeyStore } from '@/stores/KeyStore'
import { usePageItmStore } from '@/stores/PageItmStore'
import { useWindowStore } from '@/stores/WindowStore'

// Components
import ActionCollect from '@/components/ActionCollect'
import ActionMenu from '@/components/ActionMenu'
import ActionClose from '@/components/ActionClose'
import ActionCluster from '@/components/ActionCluster'
import BaseImageArt from '@/components/BaseImageArt'
import BaseIcon from '@/components/BaseIcon'
import PreviewLoader from '@/components/PreviewLoader'

// Internal
import displayYear from '@/use/DisplayYear'
import { onTransitionEnd, share } from '@/use/General'
import { delay, requestAnimationFrame, downloadImg } from '@/use/Helpers'
import { getItmUrl } from '@/use/ItmHelpers'
import { ddSep } from '@/use/Constants' // Universal separator
import { baseUrl } from '@/use/BaseUrls'

// This lets us log() from within the template.
// const log = console.log

// Disabling eslist to avoid error.
// Should be unnecessary once vue-eslint-parser supports eslint v8.
// eslint-disable-next-line
const props = defineProps({
	dark: {
		type: Boolean,
		default: false,
		// default: true,
	},
})

// Import stores
const carouselStore = useCarouselStore()
const feedStore = useFeedStore()
const sessionStore = useSessionStore()
const keyStore = useKeyStore() // Private
const pageItmStore = usePageItmStore()

// General initialization
const { ref_slidesWrap } = init(carouselStore)

// Image transformations.
const imgBox = initImgBox({ carouselStore, ref_slidesWrap })

// Button interactions
const actions = initActions()
const { ref_carouselActions } = actions

// Info bar interactions
const infoBar = initInfoBar({ carouselStore, keyStore, ref_slidesWrap })

// Touch interactions
const touch = initTouch({ carouselStore, keyStore, infoBar, actions })

// Pagination change
const { index } = storeToRefs(carouselStore)
watch(index, async newValue => {
	// Ignore when we're resetting the store.
	if (!newValue) return

	// Reset info bar fixed dimensions, which
	// are only needed during transition.
	infoBar.unsetStyleInfo()

	// Delete transition image if its still visible.
	carouselStore.removeTransitionImg()

	// Slowly fade out action action buttons.
	// On touch screen we only do this after the first image.
	// If the user taps the screen, the action buttons return and remain.
	if (!sessionStore.isTouch || actions.autoHide.value) {
		actions.slowFade()
	}
})

//
//
//
//

/**
 * General initialization
 */
function init(carouselStore) {
	const ref_slidesWrap = ref(null)

	// Send carousel ref to the store.
	onMounted(() => {
		carouselStore.setRefSlideWrap(ref_slidesWrap)
	})

	return { ref_slidesWrap }
}

/**
 * Button interactions
 */
function initActions() {
	const ref_carouselActions = ref(null)

	const state = reactive({
		on: false,
		// When changing page or after some inactivity, the action buttons fade out slowly.
		slowButtonFade: false,
		clientY: null,
		cluster: null,
		// Auto-hiding happens two seconds after you launch the carousel, or when
		// you go to the prev/next slide. Moving the mouse reactivated the action buttons.
		// On touch screens however, we want to disable auto-hide as soon as the
		// user has either manually toggled the action buttons (by tapping anywhere) or
		// has performed an action, like collect or add to list/room.
		autoHide: true,
		_pointerEventsSupported: false,
		_timeout: null,
	})

	onMounted(() => {
		_showActions()
		window.addEventListener('pointermove', onPointerMove)
		window.addEventListener('mousemove', onMouseMove)
	})

	onBeforeUnmount(() => {
		window.removeEventListener('pointermove', onPointerMove)
		window.removeEventListener('mousemove', onMouseMove)
	})

	const collectCallbacks = {
		collect: _markCollected,
		collectError: _markUncollected,
		collectSuccess: () => {
			if (_isItmPage()) {
				pageItmStore.markCollected()
			}
		},
		uncollect: _markUncollected,
		uncollectSuccess: () => {
			carouselStore.clearFocusItmActiveClusterIds()
			if (_isItmPage()) {
				pageItmStore.markUncollected()
				pageItmStore.clearClusterIds()
			}
		},
		uncollectError: _markCollected,
		uncollectCancel: _markCollected,
	}

	const clusterCallbacks = {
		add: () => {
			// UI
			_markCollected()
		},
		addSuccess: ({ listIds, roomIds }) => {
			carouselStore.addFocusItmActiveClusterIds({ listIds, roomIds })
			if (_isItmPage()) {
				// If we're on the itm page, we have to update the UI there too.
				pageItmStore.addClusterIds({ listIds, roomIds })
				pageItmStore.markCollected()
			} else if (
				feedStore.isMyCluster &&
				(listIds.includes(feedStore.id) || roomIds.includes(feedStore.id))
			) {
				// If we're on your own room page and you've removed an itm
				// marked as removed, and you then add it back via the room
				// dropdown, we have to remove the "removed" flag.
				feedStore.toggleRemoved(carouselStore.index, false)
			}
			state.cluster = null
		},
		addError: () => {
			if (!carouselStore.focusItmContainingClusterCount) _markUncollected()
		},
	}

	function _isItmPage() {
		return pageItmStore.itm && pageItmStore.itm.id == carouselStore.focusItm.id
	}

	return {
		ref_carouselActions,
		...toRefs(state),
		collectCallbacks,
		clusterCallbacks,
		onPointerMove,
		onMouseMove,
		toggle,
		slowFade,
		disableAutoHide,
	}

	// Pointer events (pointermove) are not supported everywhere,
	// but they let us detect touch. If they are not supported,
	// we use the onMouseMove as fallback.
	function onPointerMove(e) {
		if (!state._pointerEventsSupported && e.pointerType) {
			state._pointerEventsSupported = true
		}
		if (e.pointerType == 'mouse') {
			state.clientY = e.clientY
			_showActions(e)
		}
	}

	// Fallback for onPointerMove (not tested).
	function onMouseMove(e) {
		// If pointermove is supported we ignore mousemove.
		if (state._pointerEventsSupported) return
		state.clientY = e.clientY
		_showActions(e)
	}

	// Toggle action buttons via screen tap.
	function toggle(e) {
		// console.log('action buttons toggle')
		if (e.target.parentNode.getAttribute('id') == 'actions') return
		state.on = !state.on
		disableAutoHide()
	}

	// Slow fade out - after mousemove or next slide.
	function slowFade() {
		// Don't fade if you're hovering the bar.
		if (state.clientY && state.clientY < 120) return

		state.slowButtonFade = true
		state.on = false

		// Reset slow transition after transition.
		onTransitionEnd(ref_carouselActions.value, () => {
			state.slowButtonFade = false
		})
	}

	// When you touch the actions bar, we prevent is from auto-hiding.
	// This is especially important so the action buttons don't hide right after
	// you peform a collect or add-to-stream action.
	function disableAutoHide() {
		state.autoHide = false
	}

	//
	//

	// Callbacks to keep carousselStore in sync.
	function _markCollected() {
		// console.log('* 	markCollected')
		// Note: we don't need to separately update the feedStore because
		// the carousel itms are the same as the feedStore itms.
		carouselStore.setFocusItmProp('collected', true)
		if (feedStore.isMyCollection) {
			feedStore.toggleRemoved(carouselStore.index, false)
		}
	}
	function _markUncollected() {
		// console.log('x markUnollected')
		carouselStore.setFocusItmProp('collected', false)
		if (feedStore.isMyCollection) {
			feedStore.toggleRemoved(carouselStore.index, true)
		}
	}

	// Reveal action action buttons on mouse move.
	function _showActions() {
		state.on = true

		// Abort slowFade
		state.slowButtonFade = false

		clearTimeout(state._timeout)
		// Hide action buttons after 2 sec.
		state._timeout = setTimeout(() => {
			if (state.autoHide) slowFade()
		}, 2000)
	}
}

/**
 * Touch interactions
 */
function initTouch({ carouselStore, keyStore, infoBar, actions }) {
	const $animSpeed = inject('$animSpeed')

	const state = reactive({
		shift: computed(() => {
			// No left swiping from teh first slide
			// unless we formed a loop.
			if (carouselStore.index == 0 && !carouselStore.allLoaded && state._distX > 0) return 0

			if (state._direction == 'h') {
				return state._forceShift || state._distX
			} else {
				return 0
			}
		}),
		// This activates CSS transition for carousel to snap.
		snapNext: false, // 100ms
		snapBack: false, // 200ms
		cssVars: {},

		// Once info has been toggled, we stop listening
		// to it until the next touch event.
		_listenForInfoToggle: true,
		_touchStart: { x: 0, y: 0 },
		_distX: 0,
		_distY: 0,
		_direction: null, // 'h' or 'v'
		// Set to screen width to trigger snapping to prev/next
		_forceShift: null,
	})

	// Key events for cycling / closing.
	// Todo: move thid into the store.
	onMounted(() => {
		keyStore.add('ArrowLeft', carouselStore.prev)
		keyStore.add('ArrowRight', carouselStore.next)
		keyStore.add('Escape', closeAndResetRoute)
		_calculateSwipeSpeed()
		const windowStore = useWindowStore()
		windowStore.listen('resize', _calculateSwipeSpeed, { debounce: 300 })
	})

	onBeforeUnmount(() => {
		keyStore.remove('ArrowLeft', carouselStore.prev)
		keyStore.remove('ArrowRight', carouselStore.next)
		keyStore.remove('Escape', closeAndResetRoute)
	})

	return { ...toRefs(state), onTouchStart, onTouchMove, onTouchEnd, closeAndResetRoute }

	//
	//

	/**
	 * Touch event handlers
	 */

	function onTouchStart(e) {
		if (e.touches[1]) return // Ignore second finger touches
		const x = e.touches[0].clientX
		const y = e.touches[0].clientY
		state._touchStart = { x, y }
	}

	function onTouchMove(e) {
		if (e.touches[1]) return // Ignore second finger touches --> let the user zoom
		e.preventDefault()

		// Store current position
		// Mouse shift happens via shift()
		const x = e.touches[0].clientX
		const y = e.touches[0].clientY
		const currentPos = { x, y }
		let distX = currentPos.x - state._touchStart.x
		let distY = currentPos.y - state._touchStart.y

		if (!state._direction) {
			// The first 20px of swiping are used to define the direction.
			if (Math.abs(distX) > 20) {
				state._direction = 'h'
				_resetDistance()
			} else if (Math.abs(distY) > 20) {
				state._direction = 'v'
				_resetDistance()
			}
		} else if (state._direction == 'v' && state._listenForInfoToggle) {
			// Vertical: toggle info.
			// (Horizontal logic is under onTouchEnd)
			if (distY > 20) {
				infoBar.hideInfo()
				state._listenForInfoToggle = false
			} else if (distY < -20) {
				infoBar.showInfo()
				state._listenForInfoToggle = false
			}
		}

		// We wait with transfering the distX & distY to the state
		// until after the _direction is set, to avoid a flash caused
		// by the shift computed value.
		if (carouselStore.total > 1 && state._direction) {
			state._distX = distX
			state._distY = distY
		}

		//
		//

		// Once the direction has been set,
		// we start measuring the distance from zero.
		function _resetDistance() {
			distX = 0
			distY = 0
			state._distX = 0
			state._distY = 0
			state._touchStart = { x, y }
		}
	}

	async function onTouchEnd(e) {
		// console.log('onTouchEnd')

		// Short touch --> Toggle action buttons
		if (Math.max(Math.abs(state._distX), Math.abs(state._distY)) === 0) {
			console.log(e.target.tagName)
			if (e.target.tagName != 'A') {
				actions.toggle(e)
				e.preventDefault() // Prevents actions from being triggered
			}
		}

		if (state._direction == 'h') {
			if (Math.abs(state._distX) <= 30) {
				await _snapBack()
			} else if (state._distX > 30) {
				await _snapToPrev()
			} else if (state._distX < -30) {
				await _snapToNext()
			}
		}

		// Reset
		_reset()
	}

	/**
	 * Other
	 */

	// When you close the carousel without clicking a link.
	function closeAndResetRoute() {
		// console.log('closeAndResetRoute')
		carouselStore.close(true)
	}

	//
	//

	/**
	 * Snap functions
	 */
	async function _snapBack() {
		state.snapBack = true
		await delay(100)
		state.snapBack = false
	}

	async function _snapToPrev() {
		// console.log('_snapToPrev')
		state.snapNext = true
		state._forceShift = window.innerWidth

		await delay(200)

		state.snapNext = false
		carouselStore.prev()
	}

	async function _snapToNext() {
		// console.log('_snapToNext')
		state.snapNext = true
		state._forceShift = -window.innerWidth

		await delay(200)

		state.snapNext = false
		carouselStore.next()
	}

	/**
	 * Other
	 */
	function _reset() {
		state._touchStart = { x: 0, y: 0 }
		state._distX = 0
		state._distY = 0
		state._forceShift = null
		state._direction = null
		state._listenForInfoToggle = true
	}
	function _calculateSwipeSpeed() {
		state.cssVars['--swipeSpeed'] = $animSpeed * (window.innerWidth / 450) + 'ms'
	}
}

/**
 * Info bar interactions
 */
function initInfoBar({ carouselStore, keyStore, ref_slidesWrap }) {
	const router = useRouter()
	const route = useRoute()
	const _cookies = new Cookies()
	let showCarouselInfo = _cookies.get('showCarouselInfo')
	showCarouselInfo = typeof showCarouselInfo === 'undefined' ? true : !!Number(showCarouselInfo)

	const state = reactive({
		on: showCarouselInfo,
		menuOptions: computed(() => {
			return [
				{
					value: 'open',
					display: 'Open detail page',
					onSelect: _openItm,
				},
				ddSep,
				{
					value: 'share',
					display: 'Share',
					onSelect: _shareItm,
				},
				// {
				// 	value: 'edit',
				// 	display: 'Edit',
				// 	onSelect: _editItm,
				// 	wall: 'edit items',
				// },
				{
					value: 'download',
					display: 'Download',
					onSelect: () => {
						downloadImg(carouselStore.focusItm.id)
					},
					wall: 'download images',
				},
				ddSep,
				{
					value: 'toggle',
					display: `${state.on ? 'Hide' : 'Show'} Info`,
					onSelect: _toggleInfo,
				},
				// {
				// 	value: 'toggle',
				// 	display: 'Change Background',
				// 	onSelect: _changeBg,
				// },
			]
		}),
		styleInfo: {},
	})

	// Up/down keys toggle info
	onMounted(() => {
		keyStore.add('ArrowUp', showInfo)
		keyStore.add('ArrowDown', hideInfo)
	})
	onBeforeUnmount(() => {
		keyStore.remove('ArrowUp', showInfo)
		keyStore.remove('ArrowDown', hideInfo)
	})

	return { ...toRefs(state), showInfo, hideInfo, unsetStyleInfo, onLinkClick }

	//
	//

	function _toggleInfo() {
		if (state.on) {
			hideInfo()
		} else {
			showInfo()
		}
	}

	function showInfo() {
		// console.log('show')
		_setStyleInfo()
		window.requestAnimationFrame(() => {
			state.on = true
			_setStyleInfo()
			_cookies.set('showCarouselInfo', 1, {
				path: '/',
				maxAge: 60 * 60 * 24 * 30, // 30 days
			})
		})
	}

	function hideInfo() {
		// console.log('hide')
		_setStyleInfo()
		window.requestAnimationFrame(() => {
			state.on = false
			_setStyleInfo()
			_cookies.set('showCarouselInfo', 0, {
				path: '/',
				maxAge: 60 * 60 * 24 * 30, // 30 days
			})
		})
	}

	// Reset style whenever index changes or screen is touched.
	// It's only needed during transition.
	function unsetStyleInfo() {
		state.styleInfo = {}
	}

	// When clicking link to itm or artist page.
	function onLinkClick() {
		carouselStore.close()
	}

	//
	//

	// The style object consiging a height in px
	// so the CSS transformation can take place.
	// - - -
	// We can't use this as a computed property because
	// it depends on a DOM ref which is not reactive.
	function _setStyleInfo() {
		console.log('_setStyleInfo')
		const nonCollapsable = ref_slidesWrap.value.children[carouselStore.mid].querySelector(
			'.non-collapsable'
		)
		console.log({ nonCollapsable })
		if (!nonCollapsable) return // Images don't have info section

		if (state.on) {
			state.styleInfo = { height: (nonCollapsable.offsetHeight + 30) / 100 + 'rem' }
			console.log(nonCollapsable.offsetHeight)
		} else {
			state.styleInfo = { height: '0px' }
		}
	}

	// Go to itm page.
	function _openItm(edit) {
		const route = carouselStore.focusItm.artistId
			? {
					name: 'Artwork',
					params: {
						namePath: carouselStore.focusItm.artistNamePath,
						titlePath: carouselStore.focusItm.titlePath,
						category: carouselStore.focusItm.category,
					},
					hash: edit ? '#edit' : null,
			  }
			: {
					name: 'Image',
					params: {
						id: carouselStore.focusItm.id,
					},
					hash: edit ? '#edit' : null,
			  }
		router.push(route)
		carouselStore.close()
	}

	// // Go to edit itm page.
	// function _editItm() {
	// 	carouselStore.close()
	// 	_openItm(true)
	// }

	// Share dialog
	function _shareItm() {
		share({
			entityType: 'itm',
			entity: carouselStore.focusItm,
			url: baseUrl + route.path,
		})
	}

	// Change background dialog
	// function _changeBg() {}
}

/**
 * Image transformation
 */
function initImgBox({ carouselStore, ref_slidesWrap }) {
	const $animSpeed = inject('$animSpeed')

	const state = reactive({
		// Controls carousel background & info fading in and
		// blocks startTransition to be triggered again once active.
		active: false,
		// The start/end style for the clicked target transision
		styleTarget: {
			opacity: 0, // So image doesn't show until it's resized
		},
		anim: false, // Toggles css animation class for the transition image

		targetLoaded: false, // Hi-res image is loaded
		animDone: false, // Intro animation is done
		revealHiRes: false, // Reveals the hi-res image when targetLoaded && animDone
	})

	// We clone the target image directly in the DOM because if we
	// wait for Vue it takes a second before the image is loaded.
	async function startTransition() {
		// console.log('startTransition')
		if (!carouselStore.isActive) return
		// console.log('startTransition')
		// Get start and end states
		const { targetPosEnd, startState, endState } = await _getStartAndEndCSS()

		// Show loader in case target image is still loading.
		if (!carouselStore.target.complete) {
			carouselStore.showLoader(startTransition)
			return
		}

		// Clone target
		const clone = carouselStore.target.cloneNode()
		clone.setAttribute('id', 'img-transition')
		clone.setAttribute('src', carouselStore.target.currentSrc)
		clone.classList.add('art', 'anim')
		clone.style.position = 'fixed'
		clone.style.width = targetPosEnd.width + 'px'
		clone.style.height = targetPosEnd.height + 'px'
		clone.style.left = targetPosEnd.left + 'px'
		clone.style.top = targetPosEnd.top + 'px'
		clone.style['transform-origin'] = '0 0'
		clone.style['pointer-events'] = 'none'
		clone.style.transition = [
			`transform ${$animSpeed}ms ease-in-out`,
			`clip-path ${$animSpeed / 2}ms ease-in-out`,
			`margin-top ${$animSpeed}ms ease-in-out`,
			`margin-left ${$animSpeed}ms ease-in-out`,
		].join(',')
		// // Transition on transform doesn't work on iOS 15.6.1
		// // It should work with -webkit-transition/-webkit-transform
		// // but it doesn't.
		// clone.style.setProperty(
		// 	'-webkit-transition',
		// 	[
		// 		`-webkit-transform ${$animSpeed}ms ease-in-out`,
		// 		`transform ${$animSpeed}ms ease-in-out`,
		// 		`transform ${$animSpeed}ms ease-in-out`,
		// 		`clip-path ${$animSpeed / 2}ms ease-in-out`,
		// 		`margin-top ${$animSpeed}ms ease-in-out`,
		// 		`margin-left ${$animSpeed}ms ease-in-out`,
		// 	].join(',')
		// )
		clone.style['z-index'] = 11 // See App.vue for z-index coordination.
		// clone.style.border = 'solid 2px red'

		// Apply start state CSS.
		_applyState(clone, startState)

		window.requestAnimationFrame(() => {
			// Insert clone to DOM.
			document.body.append(clone)

			// carouselStore.thumbIsLoaded() // trash

			// Wait for DOM to repaint, then set the end state.
			window.requestAnimationFrame(() => {
				state.active = true
				// Apply end state CSS.
				_applyState(clone, endState)

				// Load rest of slides when done.
				onTransitionEnd(
					clone,
					() => {
						// It seems like onTransitionEnd doesn't trigger on the very last frame
						// but the frame before, so the timeout delay is a hack to make sure
						// the end of the animation plays before loadRestOfFeed() causes it to
						// freeze on the second last frame
						setTimeout(() => {
							endTransition({ animDone: true })
							carouselStore.loadRestOfFeed()
						}, 1)
					},
					{ propertyName: 'transform' }
				)
			})
		})
	}

	function _applyState(clone, state) {
		Object.entries(state).forEach(([key, val]) => {
			clone.style[key] = val
		})
	}

	return {
		...toRefs(state),
		startTransition,
		endTransition,
	}

	//
	//

	// Wait for target to load AND for transition to end
	// before showing the target image.
	function endTransition({ targetLoaded, animDone }) {
		// console.log('endTransition', { targetLoaded, animDone })
		if (animDone) state.animDone = animDone
		if (targetLoaded) state.targetLoaded = targetLoaded
		if (state.targetLoaded && state.animDone) {
			carouselStore.removeTransitionImg()
			state.revealHiRes = true
		}
	}

	async function _getStartAndEndCSS() {
		const targetPosStart = carouselStore.target.getBoundingClientRect()
		const carouselImage = ref_slidesWrap.value.children[carouselStore.mid].querySelector(
			'img.img-placeholder:not(.safari)'
		)

		// Because the size of the carouselImage is defined by another image,
		// we need to wait for the DOM to update before measuring it.
		// This can be removed once we can get rid of the safari double placeholder.
		await requestAnimationFrame()
		const targetPosEnd = carouselImage.getBoundingClientRect()

		// console.log('Begin rect:', targetPosStart)
		// console.log('End rect:', targetPosEnd, targetImage.complete)

		// Calculate the css transforn so we can animate using the CPU.
		const startWidth = targetPosStart.width
		const startHeight = targetPosStart.height
		const endWidth = targetPosEnd.width
		const endHeight = targetPosEnd.height
		//
		const startLeft = targetPosStart.left
		const startTop = targetPosStart.top
		const endLeft = targetPosEnd.left
		const endTop = targetPosEnd.top
		//
		const scaleX = startWidth / endWidth
		const scaleY = startHeight / endHeight
		const translateX = Math.round(startLeft - endLeft)
		const translateY = Math.floor(startTop - endTop) // Floor has more accurate results for the Y axis some reason.

		// Detect square thumbs
		const proportionsStart = Math.round((startWidth / startHeight) * 100) / 100
		const proportionsEnd = Math.round((endWidth / endHeight) * 100) / 100
		const isSquareThumb = proportionsStart == 1 && proportionsEnd != 1

		let startState = { opacity: 1 }
		let endState = {
			transform: 'translate(0,0) scale(1,1)',
			'clip-path': 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
			opacity: 1,
		}
		if (isSquareThumb) {
			// We're opening a square thumbnail, so we have to animate a clip path.
			if (endWidth > endHeight) {
				// Horizontal image
				const factor = endWidth / endHeight
				const actualStartWidth = startHeight * factor
				const offset = (actualStartWidth - startWidth) / 2
				const offsetPct = (offset / actualStartWidth) * 100
				// prettier-ignore
				startState = {
					...startState,
					transform: `translate(${translateX}px, ${translateY}px) scale(${scaleY})`,
					'clip-path': `polygon(${offsetPct}% 0, ${100 - offsetPct}% 0, ${100 - offsetPct}% 100%, ${offsetPct}% 100%)`,
					'margin-left': -offset + 'px',
				}
				endState = {
					...endState,
					'margin-left': 0,
				}
			} else {
				// Vertical or square image
				const factor = endHeight / endWidth
				const actualStartHeight = startWidth * factor
				const offset = (actualStartHeight - startHeight) / 2
				const offsetPct = (offset / actualStartHeight) * 100
				console.log({ offset, actualStartHeight, factor, startWidth, endHeight, endWidth }) // %%
				// prettier-ignore
				startState = {
				...startState,
				transform: `translate(${translateX}px, ${translateY}px) scale(${scaleX})`,
				'clip-path': `polygon(0 ${offsetPct}%, 100% ${offsetPct}%, 100% ${100 - offsetPct}%, 0 ${100 - offsetPct}%)`,
				'margin-top': -offset + 'px',
			}
				endState = {
					...endState,
					'margin-top': 0,
				}
			}
		} else {
			// Regular thumb
			startState = {
				transform: `translate(${translateX}px, ${translateY}px) scale(${scaleX}, ${scaleY})`,
				opacity: 1,
			}
			endState = {
				transform: `translate(0,0) scale(1,1)`,
				opacity: 1,
			}
		}

		return { targetPosEnd, startState, endState }
	}
}
</script>

<style scoped lang="scss">
// #region container

/**
 * Container
 */
#carousel {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10; // See App.vue for z-index coordination.
	background-color: $bg;
	opacity: 1;
}
#carousel.dark {
	color: $white;
	background: $black-neutral;
}
#carousel:not(.active) {
	opacity: 0;
}

// #endregion

// #region loader

/**
 * Loader
 */

// Note: loader dimensions are in pixels instead of rem
// because its position is calculated in pixels and this
// is the only way to be exact
#carousel-loader,
#carousel .loader {
	width: 20px;
	height: 20px;
	border-radius: 10px;
	border: solid 2px $black-20;
	animation: rotate 500ms infinite linear;
	clip-path: $cp-load-20;
	pointer-events: none;
}
#carousel-loader::after,
#carousel .loader::after {
	content: '';
	width: 20px;
	height: 20px;
	border-radius: 10px;
	background: $white;
	clip-path: inset(16px, 0, 8px, 8px);
}
#carousel-loader {
	position: fixed;
	z-index: 9; // See App.vue for z-index coordination.
}
#carousel .loader {
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -10px 0 0 -10px;
	// z-index: 1;
}

// #endregion loader

// #region slides

/**
 * Slides
 */
#carousel .slide-wrap {
	position: relative;
	display: flex;
	flex-direction: row;
	width: 1500%;
	height: 100%;
	margin-left: -700%;
}
#carousel .slide {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: calc(100% / 15);
	height: 100%;

	// border: solid 1px;
	// background: #300;
}

// This is to avoid that when you hit next really fast,
// you see previous images/text before they're swapped out.
// We apply three different loaders for placeholder/image/text
// so they all appear as soon as they're ready.
#carousel .slide.loading-placeholder img.img-placeholder {
	display: none;
}
// Note that the actaual images can't have display:none
// because then the @load events wouldn't fire.
#carousel .slide.loading-preview:deep() img.preview,
#carousel .slide.loading-img:deep() img.art {
	// opacity: 0.3; // <-- For debugging
	opacity: 0;
}
#carousel .slide.loading-itm .info {
	opacity: 0;
}

// // Uncomment this for debugging:
// #carousel .slide:nth-child(8) {
// 	background: pink;
// }
// #carousel .slide-wrap {
// 	width: 100%;
// 	margin-left: 0;
// }

// #endregion

// #region image

/**
 * Image
 */
#carousel .img-box {
	font-size: 0;
	line-height: 0;
	user-select: none;
	padding: 0.3rem;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column; // Crucial!
	min-height: 0; // Crucial to to avoid 'auto' min-height based on content, which makes it expand past its flex container.
	// background: #ff0;
}
// #carousel .img-box.with-info {
// 	padding-bottom: 0;
// }
#carousel .img-wrap {
	max-width: 100%;
	max-height: 100%;
	position: relative;
	// border: solid 1px red;
	text-align: center; // For stupid firefox that renders img-wrap at 100% width for no reason.
}
#carousel img.img-placeholder {
	max-height: 100%;
	max-width: 100%;
	// background: lightblue;
}
#carousel img.img-placeholder.bg {
	background: $black-05;
}
#carousel .img-wrap picture {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	max-height: 100%;
	// border: solid 1px #0f0;
	text-align: center; // For stupid firefox that renders img-wrap at 100% width for no reason.
}
#carousel .img-wrap:deep() picture img {
	background: transparent;
	height: 100%;
	// width: 100%;
	// border: solid 3px pink;
}
#carousel .img-wrap #img-hi-res:deep() img.hide {
	opacity: 0;
}

// #endregion

// #region info

/**
 * Info
 */
#carousel .info {
	// flex: 0 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	text-align: center;
	padding: 0.3rem;
	padding-top: 0;
	margin-top: -0.05rem;
	// background: #29a;
}

#carousel .info h2 {
	margin-bottom: 0;
}
#carousel .info .title,
#carousel .info .title a:not(:hover) {
	color: $black-50;
}
#carousel.dark .info .title,
#carousel.dark .info .title a:not(:hover) {
	color: $white-50;
}

// Shutter allows us to apply hidden overflow
// without interfering with flexbox.
#carousel .info.hide {
	padding: 0 0.3rem;
	height: 0;
	opacity: 0;
}
#carousel .info .shutter {
	margin-top: 0;

	// background: pink;
}
#carousel .info.hide .shutter {
	margin-top: 0.1rem;
}

// #endregion

// #region actions

/**
 * Actions
 */
#carousel-actions {
	position: fixed;
	top: 0;
	z-index: 12; // See App.vue for z-index coordination.
	width: 100%;
	height: 0.6rem;
	display: flex;
	user-select: none;
	-webkit-tap-highlight-color: transparent;
	// mix-blend-mode: difference;
	// filter: invert(1);
	// background: rgba(255, 0, 0, 0.2);
}
#carousel-actions:not(.show) {
	opacity: 0;
	pointer-events: none;
}
#carousel-actions .act-collect,
#carousel-actions .act-cluster,
#carousel-actions .act-menu,
#carousel-actions .act-close {
	margin: 0.05rem 0;
	flex: 0 0 0.5rem;

	// background: #ffe;
}
#carousel-actions .act-collect {
	margin-left: 0.05rem;
}
#carousel-actions .act-close {
	width: 0.6rem;
	height: 0.6rem;
	padding: 0.2rem;
	margin: 0;
	// background: lightgreen;
}
#carousel-actions .bg-gradient {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: -1;
	height: 0.8rem;

	--light: rgba(250, 250, 250, 0.5);
	--dark: rgba(250, 250, 250, 0);
	background: linear-gradient(var(--light), 30%, var(--dark));
	mask-image: radial-gradient(50% 100% at bottom center, transparent, #000);
	display: none; // Only shows on mobile
}
// #carousel-actions .bg-gradient.dark {
// 	--light: rgba(238, 238, 238, 0.5); // $black-neutral
// 	--dark: rgba(238, 238, 238, 0); // $black-neutral
// 	// --light: rgba(red, 0.5);
// 	// --dark: rgba(green, 0);
// 	background: linear-gradient(var(--light), 30%, var(--dark));
// }

// Feed title & pagination
// The pag-wrap is to make sure the width stays consistent,
// otheriwse the title moves around which looks bad.
#carousel-actions .pag-wrap {
	// background: rgba(0, 0, 255, 0.2);
	min-width: 0.4rem;
	display: flex;
	justify-content: right;
}
#carousel-actions .pag,
#carousel-actions .pageTitle {
	height: 0.6rem;
	line-height: 0.6rem;
	color: $black-50;
	margin-left: 0.1rem; // Safeguard for unlikely large page numbers
	position: relative;
}
#carousel-actions .pageTitle {
	text-align: right;

	// Truncate
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
.dark #carousel-actions .pag {
	color: $white-50;
}
#carousel-actions .pag {
	text-align: left;
}
#carousel-actions .pag::after {
	content: '';
	display: block;
	width: 100%;
	height: 0.2rem;
	padding: 0 0.04rem;
	position: absolute;
	z-index: -1;
	top: 0.2rem;
	left: -0.04rem;
	background: $bg;
	// background: red;
	border-radius: $br;
	// opacity: 0.75;
	display: none;
}
// .dark #carousel-actions .pag::after {
// 	background: $black-dark-opaque;
// }

// Flexbox filler
#carousel-actions .filler {
	flex: 1 1;
}

// #endregion

// #region hidden actions

/**
 * Hidden Buttons
 */
#prev-next {
	opacity: 0;
}
#prev-next.show {
	opacity: 1;
}
#prev-next > div {
	position: fixed;
	width: 0.8rem;
	height: 0.8rem;
	position: fixed;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	border: dashed 1px $black-10;
	box-sizing: content-box;
	margin: -1px;
}

#prev-next .prev {
	left: 0;
	border-top-right-radius: 0.4rem;
}
#prev-next .next {
	right: 0;
	border-top-left-radius: 0.4rem;
}
#prev-next:deep() .icn {
	fill: $black-20;
	margin-top: 0.05rem;
}
#prev-next .prev:deep() .icn {
	transform: rotate(180deg);
	margin-right: 0.05rem;
}
#prev-next .next:deep() .icn {
	margin-left: 0.05rem;
}

// #endregion

// #region transitions

/**
 * Transitions
 */

// Fading in/out of carousel background
#carousel {
	transition: opacity calc(var(--animSpeed) * 0.7) linear;
}

/* Swiping between slides */

// Release too early, snaps back.
#carousel .slide-wrap.snap-back {
	transition: transform calc(var(--animSpeed) * 0.5) ease-out;
}
// Transition to next.
#carousel .slide-wrap.snap-next {
	// Swipespeed is relative to the window width.
	// It will be 200ms at 450px wide and is calculated
	// proportionally to that.
	transition: transform var(--swipeSpeed) ease-out;
}

/* Toggling info */

#carousel .info {
	transition: padding var(--animSpeed) ease-out, height var(--animSpeed) ease-out,
		opacity calc(var(--animSpeed) * 0.6) linear calc(var(--animSpeed) * 0.4);
}
#carousel .info.hide {
	transition: padding var(--animSpeed) ease-out, height var(--animSpeed) ease-out,
		opacity calc(var(--animSpeed) * 0.6) linear;
}
#carousel .info .shutter {
	transition: margin-top var(--animSpeed) ease-out;
}

/* Loading slide */

// #img-transition --> This is applied in JS - see startTransition()

/* Actions */

// Regular button toggling.
#carousel-actions {
	transition: opacity calc(var(--animSpeed) * 0.5) linear;
}

// Slow button fadeout after 2s of inactivity.
#carousel-actions.slow:not(.show) {
	// background: pink;
	transition: opacity calc(var(--animSpeed) * 2) linear;
}

/* Hidden actions */

#prev-next {
	transition: opacity calc(var(--animSpeed) * 10) linear calc(var(--animSpeed) * 3);
}

// #endregion

/**
 * Responsive
 */

@media only screen and (max-width: $tablet) {
	// #carousel .img-box {
	// 	padding-top: 0.6rem;
	// }
	#carousel-actions .bg-gradient {
		display: block;
	}
}
@media only screen and (max-width: $mobile) {
	#carousel .img-box {
		padding: 0;
		padding-bottom: 0.2rem;
	}
	#carousel .info {
		margin-top: 0;
		// padding-top: 0.2rem;
	}
	#carousel .img-wrap:deep() picture img {
		border-radius: 0;
	}
}
</style>
