<template>
	<div :class="alphabet ? 'alphabet' : ''" class="pagination">
		<template v-if="!alphabet">
			<router-link :class="classPrev" :to="to(prevPage)">PREV</router-link>
			<router-link :class="classNext" :to="to(nextPage)">NEXT</router-link>
		</template>
		<template v-for="(page, i) in pages" :key="i">
			<a href="#" v-if="page == '...'" @click.prevent="shift(i == 1)">{{ page }}</a>
			<router-link v-else :to="to(page)">{{ page }}</router-link>
		</template>
	</div>
</template>

<script>
export default {
	name: 'NavAlphabet',
	props: {
		// Whether we should show alphabet or regular pages
		alphabet: {
			type: Boolean,
			default: false,
		},
		// Total pages
		pageCount: Number,
		// Current page passed via router
		currentPage: {
			type: [Number, String],
			reuired: true,
		},
		// How many pages we should show in pagination
		range: {
			type: Number,
			default: 10,
		},
	},
	data() {
		return {
			abc: 'abcdefghijklmnopqrstuvwxyz#'.split(''),
			// If not null, these will override the default visible  pagination range
			// These are used by the ... to shift pagination
			shiftPagination: 0,
		}
	},
	computed: {
		prevPage() {
			return this.currentPage == 1 ? 0 : this.currentPage - 1
		},
		nextPage() {
			return +this.currentPage == this.pageCount ? 0 : +this.currentPage + 1
		},
		classPrev() {
			return this.currentPage == 1 ? 'disabled' : ''
		},
		classNext() {
			return this.currentPage == this.pageCount ? 'disabled' : ''
		},
		pages() {
			if (this.alphabet) {
				return this.abc
			} else {
				let pages = this.pageCount
				if (this.pageCount > this.range + 2) {
					// Set begin and end of visible pagination
					let startPage =
						1 +
						Math.floor((+this.currentPage - 1) / this.range) * this.range +
						this.shiftPagination
					const endPage = Math.min(startPage + this.range - 1, this.pageCount)

					// Generate array with pages
					pages = []
					for (let i = startPage; i <= endPage; i++) {
						pages.push(i)
					}

					// Add ... and extra pages front and back
					if (startPage > 1) pages.unshift(1, '...', startPage - 1)
					if (endPage < this.pageCount) pages.push(endPage + 1, '...', this.pageCount)
				}
				return pages
			}
		},
	},
	watch: {
		currentPage() {
			this.shiftPagination = 0
		},
	},
	methods: {
		to(pageOrLetter) {
			// Recreate route object because we can't modifyprop
			const completeRoute = {
				name: this.$route.name,
				params: { ...this.$route.params },
				query: { ...this.$route.query },
			}

			// Add params: letter, page
			if (this.alphabet) {
				// ABC
				pageOrLetter = pageOrLetter == '#' ? '123' : pageOrLetter
				completeRoute.params.letter = pageOrLetter
				delete completeRoute.params.page
			} else {
				// 123
				completeRoute.params.page = pageOrLetter
			}
			return completeRoute
		},
		shift(left) {
			if (left) {
				this.shiftPagination -= this.range
			} else {
				this.shiftPagination += this.range
			}
		},
	},
}
</script>

<style lang="scss" scoped>
/* Alphabet */
.pagination {
	margin-bottom: 0.3rem;
	font-size: 0;
	line-height: 0;
}
.pagination.alphabet + .pagination {
	margin-top: -0.2rem;
}
.pagination a {
	font-size: $regular;
	display: inline-block;
	min-width: 0.2rem;
	height: 0.2rem;
	line-height: 0.18rem;
	padding: 0 0.04rem;
	text-transform: uppercase;
	background: #fff;
	border: solid 0.01rem $black-10;
	margin: 0 0.02rem;
	color: $black;
	border-radius: $br;
	text-align: center;
}
.pagination a.router-link-active {
	/* font-weight: bold; */
	pointer-events: none;
	color: #fff;
	background: $black;
	border-radius: $br;
	text-align: center;
}
.pagination a.disabled {
	opacity: 0.3;
	pointer-events: none;
}

@media (hover: hover) {
	.pagination a:hover {
		background: $black-50;
		color: white;
	}
}
</style>
