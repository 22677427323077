<template>
	<!-- <pre style="color: red">@@{{ this.itm }}</pre> -->
	<!-- <img
		v-if="loading"
		:src="itm.views[this.viewNr].src.replace(/__SIZE__/, 'small-2x')"
		style="opacity:.5;background:red"
	/> -->
	<picture>
		<source :srcset="srcsetWebp" type="image/webp" />
		<source :srcset="srcsetDefault" type="image/jpeg" />
		<img
			class="art"
			:class="customClass"
			:style="theStyle"
			:src="src"
			:alt="alt"
			:title="title"
			:itemprop="itemprop ? itemprop : ''"
			:imgHold="imgHold"
			:loading="eager ? 'eager' : 'lazy'"
			:data-id="itm.id"
			:data-index="itm.index"
			@load="
				e => {
					if (!e.target.src.match(/^data:image/)) {
						$emit('load', e)
					}
				}
			"
		/>
		<!-- This was blocking artwork page thumb to be clickable, not sure why we neede this? -->
		<!-- @click.prevent -->
	</picture>
</template>

<script>
// Internal
import { pixel } from '@/use/Base'

export default {
	props: {
		itm: {
			type: Object,
			required: true,
		},
		imgSize: {
			type: String,
			default: 'small', // small/medium/large/xlarge (-2x is handled by <source>)
		},
		viewNr: {
			type: Number,
			default: 0,
		},
		// Width & height, used in grid.
		width: Number,
		height: Number,

		// Custom class
		customClass: Object,

		// Style is used for carousel transition (see Carousel.vue)
		style: Object,

		index: Number,
		itemprop: String, // Microdata https://schema.org/docs/gs.html
		// This lets us "hold" the source from loading, useful for the art grid
		// where images otherwise block the mounted() hook from being called.
		imgHold: Boolean,
		eager: Boolean, // Overrides the default laze-loading setting.
	},
	emits: ['load'],
	data() {
		return {
			loading: true,
		}
	},
	computed: {
		srcsetWebp() {
			if (!this.itm.views[this.viewNr]) return pixel
			// When you're on view (n) of itm A, and you go to itm B which doens't have view (n),
			// Vue will start computing this value before the viewNr is reset to its default 0.
			if (!this.itm.views[this.viewNr].srcsetWebp) return ''
			return this.imgHold
				? pixel
				: this.itm.views[this.viewNr].srcsetWebp.replace(/__SIZE__/g, this.imgSize)
		},
		srcsetDefault() {
			if (!this.itm.views[this.viewNr]) return pixel
			// When you're on view (n) of itm A, and you go to itm B which doens't have view (n),
			// Vue will start computing this value before the viewNr is reset to its default 0.
			if (!this.itm.views[this.viewNr].srcsetDefault) return ''
			return this.imgHold
				? pixel
				: this.itm.views[this.viewNr].srcsetDefault.replace(/__SIZE__/g, this.imgSize)
		},
		src() {
			if (!this.itm.views[this.viewNr]) return pixel
			// When you're on view (n) of itm A, and you go to itm B which doens't have view (n),
			// Vue will start computing this value before the viewNr is reset to its default 0.
			if (!this.itm.views[this.viewNr].src) return ''
			return this.imgHold ? pixel : this.itm.views[this.viewNr].src.replace(/__SIZE__/g, this.imgSize)
		},

		alt() {
			if (this.isArtwork) {
				return (
					'Artwork Title: ' + this.itm.title ||
					'Untitled' + ' - Artist Name: ' + this.itm.artistName
				)
			} else {
				return null
			}
		},
		title() {
			if (this.isArtwork) {
				return this.itm.title || 'Untitled' + ' by ' + this.itm.artistName
			} else {
				if (this.itm.caption) {
					return this.itm.caption.trim(1024)
				} else if (this.itm.labels) {
					const labels = this.itm.labels.map(label => label.value)
					return labels.join(' ')
				}
				return null
			}
		},
		theStyle() {
			const style = this.style || {}
			let widthHeight =
				this.width && this.height
					? { width: Math.round(this.width) + 'px', height: Math.round(this.height) + 'px' }
					: {}

			return { ...widthHeight, ...style }
		},
		// Whether the itm is an artwork or an image
		isArtwork() {
			return !!this.itm.artistName
		},
	},
	/*
	watch: {
		itm() {
			this.loading = true
			this.preloadImg()
		},
		viewNr() {
			this.loading = true
			this.preloadImg()
		},
	},
	mounted() {
		// Needed to trigger preLoad on first load (client only)
		this.preloadImg()
	},
	methods: {
		// Artificially load image with js
		preloadImg() {
			const img = new Image()
			img.onload = () => {
				this.loading = false
				this.$emit('preLoad')
			}
			img.srcset = this.srcsetDefault
			img.src = this.srcJpg
		},
	},
	 */
}
</script>
