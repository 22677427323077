/*

This store controls the locking and unlokcing of a route,
after which we can push new routes without updating the
page content. This is used by the carousel and search.

Notes about implementation:
- unlockRoute is async, make sure to await befor doing
  anything else or you may run into problems.
- Whenever you exit the overlaying content with a link
  or with a back button, the route should not be reset,
  that's why we have the resetRoute parameter.
- Look at carousel & search for exampe implementations.

*/
import { defineStore } from 'pinia'

// Helpers
import { destructureRoute } from '@/use/Helpers'

export const useRouteStore = defineStore('RouteStore', {
	// We use the globalStore for the state!
	// So instead of this.lockedRoute we use this.$gl.lockedRoute.
	// That's because we can't put the lockRoute logic inside the
	// globalStore as this.$router is not available there. That's
	// because it is added to the pinia global state in via
	// client-entry/server-entry.
	actions: {
		// Enable router pushing without updating content.
		lockRoute() {
			// console.log('lockRoute')

			// When you go back in your browser's history, it will reset
			// the scroll position by default. Because we use history.go(-2)
			// (via $router.go(-2)) to unlock the route (see below), we don't
			// want this behavior, so we temporarily disable it.
			if (history.scrollRestoration) history.scrollRestoration = 'auto'

			// Lock current route.
			this.$gl.lockedRoute = destructureRoute(this.$router.currentRoute.value)
			this.$gl.lockedRouteActive = true
		},
		// The resetRoute parameter will push the lockedRoute back
		// into the history chain, then jump back two pages so we
		// don't polute the history and are back where we came from.
		async unlockRoute(resetRoute) {
			// console.log('unlockRoute')
			if (resetRoute) {
				// console.log('*** RESET')
				// When pushing the same route as the current one,
				// we force a history entry by adding the #.
				// We do the same in carouselStore._updateRoute().
				if (this.$router.currentRoute.value.fullPath == this.$gl.lockedRoute.fullPath) {
					this.$gl.lockedRoute.hash = '#'
				}

				// We need to push the lockedRoute first because
				// otherwise go(-1) triggers a page reload.
				await this.$router.push(this.$gl.lockedRoute)
				this.$router.go(-2) // Doesn't return a promise.

				// This needs a timeout so the history state can be reset first.
				// Otherwise it won't record the new value.
				setTimeout(() => {
					if (history.scrollRestoration) history.scrollRestoration = 'manual'
				}, 1)
			}
			this.$gl.lockedRoute = null
			setTimeout(() => {
				this.$gl.lockedRouteActive = null
			}, 100)
		},
	},
})
