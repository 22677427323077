// Vue
import { useRoute } from 'vue-router'

// Stores
import { useSessionStore } from '@/stores/SessionStore'
import { useDialogStore } from '@/stores/DialogStore'

// Internal
import flash from '@/use/FlashAlert'
import { itmCredits } from '@/use/Helpers'

// Before submitting artist/artwork changes, we remove any unchanges values.
export function removeUnchangedValues(uploadData, currentData) {
	uploadData = Object.fromEntries(
		Object.entries(uploadData).filter(([key, val]) => {
			if (typeof val == 'object') val = JSON.stringify(val)
			const artistVal =
				typeof currentData[key] == 'object' ? JSON.stringify(currentData[key]) : currentData[key]
			return key == 'id' || val != artistVal
		})
	)
	return uploadData
}

// Unified pageSize dropdown
export function pageSizeDdOptions() {
	const route = useRoute()
	return [
		{
			value: 10,
			display: 'Show 10',
			selected: +route.query.pageSize == 10,
		},
		{
			value: 30,
			display: 'Show 30',
			// Default
			selected: !route.query.pageSize || +route.query.pageSize == 30,
		},
		{
			value: 50,
			display: 'Show 50',
			selected: +route.query.pageSize == 50,
		},
		{
			value: 100,
			display: 'Show 100',
			selected: +route.query.pageSize == 100,
		},
		{
			value: 250,
			display: 'Show 250',
			selected: +route.query.pageSize == 250,
		},
	]
}

// Can be trashed after TheWelcome is phased out.
export function occupationDdOptions() {
	return [
		{
			text: "I'm an artist/designer",
			value: 'artist',
		},
		{
			text: 'I collect art/design',
			value: 'collector',
		},
		{
			text: 'I work in the art/design world',
			value: 'professional',
		},
		{
			text: 'I just love art/design',
			value: 'lover',
		},
	]
}

// Show flash alert saying you need to log in to perform this action.
export function nonUserBlock(action) {
	const dialogStore = useDialogStore()

	flash(`Please log in to ${action}.`, {
		button: 'Log in',
		buttonAction: () => {
			dialogStore.dialogByName('DialogAuth', { page: 'login' })
		},
		persistent: 'true',
	})
}

export function comingSoon(action) {
	flash(`${action} is not yet supported but it will be soon.`)
}

// Take the raw src from database and transform it into
// { webp, webp2x, jpg, jpg2x }
export function parseSrc({ baseSrc, imgSize, imgFormat }) {
	if (!imgFormat) {
		// Auto format - webp/jpg
		const sessionStore = useSessionStore()
		imgFormat = sessionStore.webpSupport ? 'webp' : 'jpg'
	}
	return baseSrc.replace(/__SIZE__/, imgSize).replace(/\.[a-zA-Z]{3}(\?dev=1)?$/, `.${imgFormat}$1`)
}

// Shorthand for attaching transitionend listeners.
// - - -
// We don't really need to support these old browsers,
// transitionend is quite standard now.
export function onTransitionEnd(elm, callback, options) {
	if (!elm) return
	const { propertyName } = options || {}
	const transitionEndName = _filterName({
		transition: 'transitionend',
		WebkitTransition: 'webkitTransitionEnd',
		MozTransition: 'transitionend',
		OTransition: 'oTransitionEnd otransitionend',
	})

	elm.addEventListener(transitionEndName, _listener)

	//
	//

	function _filterName(transEndEventNames) {
		for (let name in transEndEventNames) {
			if (!elm.style) console.log('????', elm)
			if (elm.style[name] !== undefined) {
				return transEndEventNames[name]
			}
		}
	}

	function _listener(e) {
		if (!propertyName || (propertyName && e.propertyName == propertyName)) {
			elm.removeEventListener(transitionEndName, _listener)
			callback()
		}
	}
}

// Shorthand for attaching animationend listeners.
export function onAnimationEnd(elm, callback) {
	if (!elm) return
	elm.addEventListener('animationend', _listener)

	//
	//

	function _listener() {
		elm.removeEventListener('animationend', _listener)
		callback()
	}
}

// Spell out a number (currently not used)
export function spellNumber(num) {
	if (num == 0) return 'zero'

	const ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine']
	const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety']
	// prettier-ignore
	const teens = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];

	return _convertMillions(num)

	//
	//

	function _convertMillions(num) {
		if (num >= 1000000) {
			return (
				_convertMillions(Math.floor(num / 1000000)) + ' million ' + _convertThousands(num % 1000000)
			)
		} else {
			return _convertThousands(num)
		}
	}

	function _convertThousands(num) {
		if (num >= 1000) {
			return _convertHundreds(Math.floor(num / 1000)) + ' thousand ' + _convertHundreds(num % 1000)
		} else {
			return _convertHundreds(num)
		}
	}

	function _convertHundreds(num) {
		if (num > 99) {
			return ones[Math.floor(num / 100)] + ' hundred ' + _convertTens(num % 100)
		} else {
			return _convertTens(num)
		}
	}

	function _convertTens(num) {
		if (num < 10) {
			return ones[num]
		} else if (num >= 10 && num < 20) {
			return teens[num - 10]
		} else {
			return tens[Math.floor(num / 10)] + ' ' + ones[num % 10]
		}
	}
}

export function share({ entityType, entity, url, viewNr }) {
	viewNr = viewNr || 0
	const { text, imgUrl, id } = _parseData()

	if (navigator.share) {
		navigator.share({
			url,
			text,
		})
	} else {
		const dialogStore = useDialogStore()
		dialogStore.dialogByName('DialogShare', { url, text, imgUrl, id, entityType })
	}

	//
	//

	function _parseData() {
		let text
		let imgUrl
		const id = entity.id

		if (entityType == 'itm') {
			if (entity.artistName) {
				// Artwork
				text = itmCredits(entity)
			} else {
				// Image
				text = 'Found on Arthur.io'
			}
			imgUrl = entity.views[viewNr].src.replace(/__SIZE__/, 'large')
		} else if (entityType == 'room') {
			text = entity.name
			// text = 'A Room on Arthur.io'
		}

		return { text, imgUrl, id }
	}
}
