<template>
	<div
		ref="ref_actCollect"
		class="act-collect"
		:class="{ dark, collected, 'block-hover': blockHover, pop, disabled }"
		@click="toggleCollect"
		@mouseleave="() => (blockHover = false)"
	>
		<!-- <pre>@{{ forceState }}@</pre> -->
		<BaseIcon name="collect" />
		<BaseIcon name="collect-outline" />
		<BaseIcon name="uncollect" />
	</div>
</template>

<script>
// Stores
import { useFeedStore } from '@/stores/FeedStore'
import { useCarouselStore } from '@/stores/CarouselStore'

// Components
import BaseIcon from '@/components/BaseIcon'

// Internal
import { nonUserBlock } from '@/use/General'
import { onAnimationEnd } from '../use/General'

export default {
	name: 'ActionCollect',
	components: { BaseIcon },
	inject: ['$itmActions'],
	props: {
		itmIds: Array, // Array of itm ids that should be (un)collected.
		collected: Boolean, // Sets outline/fill icon.
		alreadyCollected: Number, // When batching, this this the number of selected itms that is already in your collection.
		dark: Boolean,
		disabled: Boolean,
		callbacks: Object, // See defaultCallbacks
	},
	setup() {
		const feedStore = useFeedStore()
		const carouselStore = useCarouselStore()
		return { feedStore, carouselStore }
	},
	data() {
		return {
			// Right after you press collect, we want to avoid
			// the 'uncollect' hover state to be triggered.
			blockHover: false,
			// Controls the popping animation when you collect
			pop: false,
			defaultCallbacks: {
				collect: () => {},
				collectSuccess: () => {},
				collectError: () => {},
				uncollect: () => {},
				uncollectSuccess: () => {},
				uncollectError: () => {},
				uncollectCancel: () => {},
			},
		}
	},
	computed: {
		iconName() {
			if (this.collected) {
				return 'collect'
			} else {
				return 'collect-outline'
			}
		},
		_callbacks() {
			return Object.fromEntries(
				Object.keys(this.defaultCallbacks).map(key => {
					const func = this.callbacks[key] || function() {}
					return [key, func]
				})
			)
		},
	},
	methods: {
		toggleCollect() {
			if (!this.$isLoggedIn) {
				nonUserBlock('collect images')
				return
			}

			if (!this.collected) {
				this._collect()
			} else {
				this._uncollect()
			}
		},
		_collect() {
			// console.log('* collect')
			this.blockHover = true
			this.pop = true
			onAnimationEnd(this.$refs.ref_actCollect, () => {
				this.pop = false
			})
			this._callbacks.collect()
			// this.$emit('collect')
			this.$itmActions.collectAll(this.itmIds, {
				alreadyCollected: this.alreadyCollected,
				onError: () => {
					// console.log('collect error')
					this._callbacks.collectError()
					// this.$emit('collectError')
				},
				onSuccess: () => {
					// console.log('collect success')
					this._callbacks.collectSuccess()
					// this.$emit('collectSuccess')
				},
			})
		},
		_uncollect() {
			// console.log('x uncollect')
			this._callbacks.uncollect()
			// this.$emit('uncollect')
			this.$itmActions.uncollectAll(this.itmIds, {
				onError: () => {
					// console.log('uncollect error')
					this._callbacks.uncollectError()
					// this.$emit('uncollectError')
				},
				onCancel: () => {
					// console.log('uncollect cancel')
					this._callbacks.uncollectCancel()
					// this.$emit('uncollectCancel')
				},
				onSuccess: () => {
					// console.log('uncollect success')
					this._callbacks.uncollectSuccess()
					// this.$emit('uncollectSuccess')
				},
			})
		},
	},
}
</script>

<style scoped lang="scss">
.act-collect {
	width: 0.5rem;
	height: 0.5rem;
	padding: 0.15rem;
	cursor: pointer;
}
.act-collect:deep() .icn {
	fill: $black;
}

// Dark version
.act-collect.dark:deep() .icn {
	fill: $white-75;
}
.act-collect.dark:deep() .icn:not(.icn-collect-outline) {
	filter: drop-shadow(0 0 0.01rem $black-20);
}

// Icon management
.act-collect:not(.collected):deep() > .icn-collect,
.act-collect.collected:deep() > .icn-collect-outline,
.act-collect:deep() > .icn-uncollect {
	display: none;
}

// Pop animation
.act-collect.pop {
	animation: pop 200ms 1 ease-out;
}

// Uncollect
.act-collect:deep() .icn-collect + .icn-uncollect {
	display: none;
}

// Disabled state
.act-collect.disabled {
	cursor: default;
	pointer-events: none;
}
.act-collect.disabled:deep() .icn {
	fill: $black-30;
}
.act-collect.disabled.dark:deep() .icn {
	fill: $white-20;
}

@media (hover: hover) {
	// Uncollect
	.act-collect:not(.block-hover):hover:deep() .icn {
		fill: $primary;
	}
	.act-collect.collected:not(.block-hover):hover:deep() .icn-collect {
		display: none;
	}
	.act-collect.collected:not(.block-hover):hover:deep() .icn-uncollect {
		display: block;
	}

	// Outline
	.act-collect:hover:deep() .icn-collect-outline {
		fill: $primary;
	}
}
</style>
