// This service holds all api calls.
// - - -
// Apis are loaded via loadApi() in App.vue
// - - -
// Modeled after:
// https://www.vuemastery.com/courses/real-world-vue3/api-calls-with-axios (service layer)
// https://itnext.io/vue-tricks-smart-api-module-for-vuejs-b0cae563e67b (using classes so we can pass pinia and router in SSR context)

// Stores
import { useAuthStore } from '@/stores/AuthStore'
import { useSsrCookieStore } from '@/stores/SsrCookieStore'

// Modules
import axios from 'axios'

// Internal
import { isSSR } from '@/use/Base'
import { apiUrl } from '@/use/BaseUrls'
import { queryUrl } from '@/use/Helpers'

// Log the server-side API path
if (isSSR) console.log('Axios Base URL: ', apiUrl)

//
//
//
//

class ApiService {
	// apiName is just for debugging.
	constructor(pinia, router, apiName) {
		this.router = router
		// this.pinia = pinia
		this.authStore = useAuthStore(pinia)
		this.ssrCookieStore = useSsrCookieStore(pinia)

		// Create axios instance for API.
		this.apiClient = axios.create({
			baseURL: apiUrl,
			// We manually add credentials to the routes where it matters. This can be deleted after testing.
			// withCredentials: noCredentials ? false : true,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		})

		// Enable this for debugging
		// if (process.env.NODE_ENV != 'production') {
		// 	console.log('Registered API module:', apiName)
		// }
		apiName

		this.setupInterceptors.bind(this)()
	}
}

/**
 * Set up interceptors.
 */
ApiService.prototype.setupInterceptors = async function() {
	// Intercept requests - Attach authToken to all requests
	this.apiClient.interceptors.request.use(
		async req => {
			// Avoid recursive loop
			// WARNING: BREAKING THIS MATCH WILL CAUSE CRASH WITHOUT ERROR
			if (req.url.match(/\/get-guest-token$/)) return req

			// Fetch current token
			let authToken = this.authStore.token

			// If none available, create guest token
			if (!authToken) {
				// console.log('# req:', req.url)
				authToken = await this.createGuestToken()
				if (authToken) {
					this.authStore.setAuthToken(authToken)
				}
			}

			// Set token as auth header
			req.headers.authorization = authToken

			// Fetch client cookies from store & attach to the header.
			if (isSSR) {
				const serializedCookies = this.ssrCookieStore.serializeClientCookies
				req.headers.cookie = serializedCookies
			}

			return req
		},
		// Do nothing with request errors
		error => Promise.reject(error)
	)

	// Intercept responses - Store guest authTokens & handle errors
	this.apiClient.interceptors.response.use(
		// Handle response success
		async res => {
			// Process any cookies that are sent with SSR requests.
			if (isSSR) this.ssrCookieStore.storeApiCookies(res.headers['set-cookie'])

			// Expired guest tokens are refreshed
			const newGuestToken = res.headers['new-guest-token']
			if (newGuestToken) {
				// Log out & set fresh guest token
				// this.authStore.logout() // I don't think this should be here?
				console.log('*** new guest token')
				this.authStore.setAuthToken(newGuestToken)

				// Forward to login if current page requires authentication
				const requiresAuth = this.router.currentRoute.value.meta.accessLevel > 0
				if (requiresAuth) {
					const { fullPath } = this.router.currentRoute.value
					this.router.push({ name: 'Login', query: { redirect: fullPath } })
				}
			}
			return res
		},

		// Handle response errors
		async err => {
			// On the rare occasian that we'd refresh the jwt key on the API server
			// we'll end up with invalid tokens stored in the user's local storage.
			// When this happens, the API sends a clear-token header, to which the
			// client responds by fetching a new token and retrying the request.
			const clearToken = err.response && err.response.headers['clear-token']
			if (clearToken) {
				console.log('$ res err:', err.response.headers)
				this.authStore.logout()
				const token = await this.createGuestToken()
				if (token) {
					this.authStore.setAuthToken(token)
				} else {
					this.authStore.setAuthToken('')
				}
				return this.apiClient.request(err.config)
			}
			return _handleError(err)
			// return Promise.reject(error) // This breaks email validation
		}
	)

	// Catch errors and return { status, error }
	function _handleError(err) {
		let { code, response } = err
		if (response) {
			// Regular http error (might still have data)
			const { status, data } = response

			// We built our API around statusText but turns out this is no longer
			// supported in HTTP2. Going forward we won't use the native statusText
			// (called statusMessage in Node Express) but instead add custom statusText
			// to the error data, and extract it to the top level in this interceptor.
			// If no statusText is passed we still fall back on the HTTP1 native statusText.
			const statusText = data.statusText || response.statusText
			delete data.statusText
			response = { status, statusText, data }
		} else {
			// Invalid URL
			if (code == 'ENOTFOUND') {
				// Mongo error when file is not found
				response = { status: 500, statusText: 'Invalid URL' }
			} else {
				// console.error('matchImage() --> Unknown error: ', err)
				response = { status: 500, statusText: 'API Offline' }
			}
		}
		return response
	}
}

/**
 * Create guest token when nothing is set yet (very first visit)
 * - - -
 * @returns JWT guest token
 */
ApiService.prototype.createGuestToken = async function() {
	// console.log('\n\n- - - - - - -createGuestToken\n\n')
	const { status, data: guestToken } = await this.apiClient('/user-auth/get-guest-token')
	return status == 200 ? guestToken : null
}

//
//
//
//

/**
 * General API
 */

export class GeneralApi extends ApiService {
	constructor(pinia, router) {
		super(pinia, router, 'GeneralApi')
	}

	//
	//

	test() {
		return this.apiClient(`/test`)
	}

	health() {
		return this.apiClient(`/u-up`)
	}

	// To display db name in dev/stage environment
	dbName() {
		return this.apiClient(`/db-name`)
	}

	// Fetch artwork feed
	// Takes feedName (picks/random) OR entityType + id
	// extraFields = array (currently not used)
	getFeed({ feedName, entityType, id, navigation, filters, options }) {
		let { endorse, isArtwork, category, yearBefore, yearAfter, medium, takenDown } = filters || {}
		const { beforeId, afterId, afterOId, beforeAndAfterId, loop, batchNr } = navigation
		let { sort, pageSize, extraFields } = options || {}
		extraFields = extraFields ? extraFields.join(',') : null
		endorse = endorse ? endorse.join(',') : null
		const query = queryUrl({
			// Filters
			endorse,
			isArtwork,
			category,
			yearBefore,
			yearAfter,
			medium,
			takenDown, // -1/0/1 = exlude/include/exlusive

			// Navigation
			beforeId,
			afterId,
			afterOId,
			beforeAndAfterId,
			loop,
			batchNr,

			// Options
			pageSize,
			sort,
			extraFields,
		})
		const url =
			feedName == 'staff-picks'
				? `/feed/staff-picks${query}`
				: feedName == 'staff-picks-random'
				? `/feed/staff-picks/random${query}`
				: feedName == 'all'
				? `/feed/all/${query}`
				: `/feed/${entityType}/${id}${query}`
		// const url = '/feed/all?pageSize=20'
		// const url = '/feed/all?pageSize=200&isArtwork=1&category=art&dateAfter=03-03-2014&dateBefore=03-03-2014&medium=sculpture&endorse=2'
		// console.log(url)
		return this.apiClient(url, { withCredentials: true })
	}

	// Fetch the last 7 itm ids of a feed so we can loop the carousel.
	getFeedEndSlice({ entityType, id }) {
		return this.apiClient(`/feed/end-slice/${entityType}/${id}`)
	}

	// Set endorsed level
	setEntityEndorse(entityType, id, status) {
		return this.apiClient.post(`/set-endorse/${entityType}/${id}/${status}`)
	}

	// Get entity by id
	getEntityById(id, entityType) {
		entityType = entityType || ''
		return this.apiClient(`/entity-by-id/${id}/${entityType}`)
	}

	// Delete entity
	// Not used, probably don't need a generalized delet function anymore
	// Would only be used in Admin Controls panel, but it has its own version of this
	// When removing this, don't forget to also clean out the API
	// export function deleteEntity(entityType, id) {
	// 	return this.apiClient.delete(`/${entityType}/${id}`)
	// }

	// Check if we should display admin controls
	getAdminControlStatus() {
		return this.apiClient(`/get-admin-control-status`)
	}

	// Toggle admin controls
	toggleAdminControls(toggle) {
		toggle = toggle ? 1 : 0
		return this.apiClient.post(`/toggle-admin-controls/${toggle}`)
	}
}

export class EditorialApi extends ApiService {
	// Featured rooms
	getFeaturedRooms(p) {
		p = p || 1
		return this.apiClient(`/editorial/featured-rooms/${p}/`)
	}
}

//
//
//
//

/**
 * Account: Login & Signup
 */

export class UserAuthApi extends ApiService {
	constructor(pinia, router) {
		super(pinia, router, 'UserAuthApi')
	}

	//
	//

	// Log in
	// --> Returns { user, authToken }
	login(email, password) {
		return this.apiClient.post(
			'/user-auth/login',
			{
				email: email,
				password: password,
			},
			{ withCredentials: true }
		)
	}

	// Log out
	logout() {
		return this.apiClient.post('/user-auth/logout', {}, { withCredentials: true })
	}

	verifyInviteCode(code) {
		return this.apiClient(`/user-auth/verify-invite-code/${code}`)
	}

	// Signup - Step #1: Check if email is already registered
	findUserByEmail(email) {
		return this.apiClient(`/user-auth/find-by-email/${email}`)
	}

	// Sign up - Step #2: account creation
	// --> Returns { user, authToken }
	signup({ name, email, password }) {
		return this.apiClient.post('/user-auth/signup', {
			name,
			email,
			password,
		})
	}

	// Signup - Step #3: Submit occupation
	submitSignupExtra(id, occupation) {
		return this.apiClient.post('/user-auth/signup-extra', {
			id,
			occupation,
		})
	}
}

//
//
//
//

/**
 * Wait List
 */

export class WaitlistApi extends ApiService {
	// Join waitlist - Step #1
	joinWaitList(name, email) {
		return this.apiClient.post('/wait-list/join', {
			name: name,
			email: email,
		})
	}

	// Join waitlist - Step #2 (What describes you best)
	joinWaitListExtra(_id, occupation) {
		return this.apiClient.post('/wait-list/join-extra', {
			_id,
			occupation,
		})
	}
}

//
//
//
//

/**
 * Upload
 */

export class UploadApi extends ApiService {
	constructor(pinia, router) {
		// Upload API endpoints have a * catch-all for allow-origin headers to allow uploads
		// from the browser extension, but for security reasons we can't send credentials (cookies)
		// or the server CORS policy will refuse the request.
		super(pinia, router, 'UploadApi')
	}

	//
	//

	// Takes array of file names that are being uploaded
	// and returns array of unique filenames we'll use to
	// store them in our /upload-storage folder and then
	// in our temporary storage bucket "arthur-upload-storage".
	getTempFileNames(fileNameArray) {
		return this.apiClient.post(`/upload/get-temp-filenames`, fileNameArray)
	}

	// Upload artwork images (while user still fills out form).
	// We use a separate request per image, so we can track individual progress.
	uploadImage(file, tempFileName, callback) {
		const formData = new FormData()
		formData.append('images', file, tempFileName)
		// Debug formData:
		// console.log('formData:')
		// for (var pair of formData.entries()) {
		// 	console.log(pair[0] + ':')
		// 	console.log(pair[1] + '\n')
		// }

		// Measure progress
		const config = {
			// headers: { 'x-temp-filename': tempFileName }, // trash
			onUploadProgress: e => {
				const progress = Math.round((e.loaded * 100) / e.total)
				callback(progress)
			},
		}
		// Upload image
		return this.apiClient.post(`/upload/upload-images`, formData, config)
	}

	// Upload new artwork data (on form submit)
	uploadData(data) {
		return this.apiClient.post(`/upload/upload-data`, data)
	}

	// When user cancels upload, we remove temp files.
	deteleTempFiles(fileNames) {
		return this.apiClient.post('/upload/delete-temp-files', fileNames)
	}
}

//
//
//
//

/**
 * Artist
 */

export class ArtistApi extends ApiService {
	constructor(pinia, router) {
		super(pinia, router, 'ArtistApi')
	}

	//
	//

	/**
	 * Fetch
	 * - - - - - - - - - -
	 */

	// Get artist
	get({ namePath, category, includeItms, afterId }) {
		// includeItms can be true (default) or custom number
		const query = queryUrl({ category, includeItms, afterId })
		return this.apiClient(`/artist/${namePath}${query}`)
	}

	// Turn array of ids into simple artwork objects
	// that can be used to construct router links.
	expandIds(ids) {
		return this.apiClient.post(`/artist/expand-ids/`, { ids })
	}

	// Get random artist
	// --> data: { namePath, category }
	getRandom() {
		return this.apiClient('/artist/random')
	}

	/**
	 * Edit
	 * - - - - - - - - - -
	 */

	// Create new
	create({
		firstName,
		lastName,
		name,
		latinFirstName,
		latinLastName,
		latinName,
		category,
		forceDuplicate,
	}) {
		return this.apiClient.post(`/artist`, {
			firstName,
			lastName,
			name,
			latinFirstName,
			latinLastName,
			latinName,
			category,
			forceDuplicate,
		})
	}

	// Update artist
	update(data) {
		return this.apiClient.put('/artist', data)
	}

	/**
	 * Admin
	 * - - - - - - - - - -
	 */

	// Delete
	delete(id) {
		return this.apiClient.delete(`/artist/${id}`)
	}

	// Take down artist (copyright violation)
	takeDown(id) {
		return this.apiClient.put(`/artist/take-down/${id}`)
	}

	// Restore artist (copyright violation)
	restore(id) {
		return this.apiClient.put(`/artist/restore/${id}`)
	}
}

//
//
//
//

/**
 * Itm (Artwork / Image)
 */

export class ItmApi extends ApiService {
	constructor(pinia, router) {
		super(pinia, router, 'ItmApi')
	}

	/**
	 * GET
	 */

	// Get artwork or image
	getByPath(namePath, titlePath) {
		titlePath = titlePath || ''
		return this.apiClient(`/itm/fetch-by-path/${namePath}/${titlePath}`)
	}

	// Get artwork or image
	getById(id) {
		return this.apiClient(`/itm/fetch-by-id/${id}`)
	}

	// Get random artwork
	// --> data: { namePath, titlePath }
	getRandom() {
		return this.apiClient('/itm/random')
	}

	// Get all clusters an itm is in (not yet used).
	getContainingClusters(itmId) {
		return this.apiClient(`/itm/containing-clusters/${itmId}`)
	}

	// Get my clusters an itm is in.
	getMyContainingClusters(itmIds) {
		// If multiple ids are passed, we join them ID1~ID2~ID3
		if (Array.isArray(itmIds)) {
			itmIds = itmIds.join('~')
		}
		return this.apiClient(`/itm/my-containing-clusters/${itmIds}`)
	}

	// Get the default feed info for an itm.
	// { entityType, id, index }
	fetchItmDefaultFeedInfo(id) {
		return this.apiClient(`/itm/default-feed-info/${id}`)
	}

	/**
	 * PUT
	 */

	// Update artwork
	update(data) {
		return this.apiClient.put('/itm', data)
	}

	// (Un)collect ims (accepts id or array of ids)
	collect(itmIds) {
		itmIds = Array.isArray(itmIds) ? itmIds : [itmIds]
		return this.apiClient.put('/itm/collect', { itmIds })
	}
	uncollect(itmIds) {
		itmIds = Array.isArray(itmIds) ? itmIds : [itmIds]
		return this.apiClient.put('/itm/uncollect', { itmIds })
	}

	// Add/remove itm(s) to one or more lists/rooms
	addToClusters({ itmIds, roomIds, listIds }) {
		return this.apiClient.put('/itm/clusters/add', { itmIds, roomIds, listIds })
	}
	removeFromClusters({ itmIds, roomIds, listIds }) {
		return this.apiClient.put('/itm/clusters/remove', { itmIds, roomIds, listIds })
	}

	/**
	 * POST
	 */

	// Delete and recreate B2 images based on original image
	regenerateB2Images(artworkId, viewNr, ext) {
		return this.apiClient.post(`/itm/regenerate-b2-images`, {
			artworkId,
			viewNr,
			ext,
		})
	}

	/**
	 * DELETE
	 */

	// Delete
	delete(id) {
		return this.apiClient.delete(`/itm/${id}`)
	}
}

//
//
//
//

/**
 * User
 */

export class UserApi extends ApiService {
	constructor(pinia, router) {
		super(pinia, router, 'UserApi')
	}

	//
	//

	// Get user
	get(id_username, options) {
		const { includeItms, includeLists, includeRooms } = options || {}
		let { extraFields } = options || {}
		extraFields = extraFields ? extraFields.join(',') : null
		// includeItms & includeRooms can be true (default) or custom number
		const query = queryUrl({ includeItms, includeLists, includeRooms, extraFields })
		return this.apiClient(`/user/${id_username}${query}`)
	}

	// // Get user rooms
	// getRooms(id_username) {
	// 	return this.apiClient(`/user/${id_username}/rooms?isMe`)
	// }

	// Get list of your own lists & and draft rooms.
	getMyClusters(id) {
		return this.apiClient(`/user/${id}/my-clusters`)
	}

	// Dismiss legacy users welcome message
	dismissLegacyWelcome(id) {
		return this.apiClient.put('/user/dismiss-legacy-welcome', { id })
	}

	// Delete
	delete(id) {
		return this.apiClient.delete(`/user/${id}`)
	}
}

//
//
//
//

/**
 * Room
 */

export class ClusterApi extends ApiService {
	constructor(pinia, router) {
		super(pinia, router, 'ClusterApi')
	}

	//
	//

	// Fetch room
	// (Lists are loaded via )
	getRoom(id) {
		return this.apiClient(`/cluster/room/${id}`)
	}

	// Fetch list
	// No longer used – lists are really just a collection of itms so getFeed is all we need.
	// get(id_username, namePath, artworks) {
	// 	namePath = namePath ? namePath : ''
	// 	const query = artworks ? `?artworks=${artworks}` : ''
	// 	return this.apiClient(`/cluster/${id_username}/${namePath}${query}`)
	// }

	// Fetch random list(s)/room(s)
	getRandom(type, amount) {
		// type room/list
		return this.apiClient(`/cluster/random/${type}/${amount}`)
	}

	// Create new list/room
	create({ type, name, intro, owner, isPrivate }) {
		return this.apiClient.post('/cluster/', {
			type,
			name,
			intro,
			owner,
			isPrivate,
		})
	}

	// Edit list/room
	update({ type, id, name, intro, owner, isPrivate }) {
		return this.apiClient.put('/cluster/', {
			type,
			id,
			name,
			intro,
			owner,
			isPrivate,
		})
	}

	// Turn list into room
	listToRoom(id) {
		return this.apiClient.put('/cluster/list-to-room', { id })
	}

	// Edit room's image order
	updateImgOrder({ id, imgOrder }) {
		return this.apiClient.put('/cluster/img-order', { id, imgOrder })
	}

	// Publish & unpublish room
	publish({ id, doPublish }) {
		if (doPublish) {
			return this.apiClient.put('/cluster/publish', { id })
		} else {
			return this.apiClient.put('/cluster/unpublish', { id })
		}
	}

	// Feature and unfeature a room on the Arthur editorial feed
	feature({ id, doFeature }) {
		if (doFeature) {
			return this.apiClient.put('/cluster/feature', { id })
		} else {
			return this.apiClient.put('/cluster/unfeature', { id })
		}
	}

	// Set room's private flag
	togglePrivate({ id, state }) {
		state = state ? 1 : 0
		return this.apiClient.put('/cluster/toggle-private', { id, state })
	}

	// Delete list/room
	delete(id) {
		return this.apiClient.delete(`/cluster/${id}`)
	}
}

//
//
//
//

/**
 * Label
 */

export class LabelApi extends ApiService {
	constructor(pinia, router) {
		super(pinia, router, 'LabelApi')
	}

	//
	//

	// Temporary to clean out old labels
	listOldLabels(page) {
		return this.apiClient(`/label/old-labels?p=${page}`)
	}

	add(value, type) {
		return this.apiClient.post(`/label/${value}?type=${type}`)
	}
}

//
//
//
//

/**
 * Index
 */

export class IndexApi extends ApiService {
	constructor(pinia, router) {
		super(pinia, router, 'IndexApi')
	}

	//
	//

	// List of artists or users by letter (index)
	get(entityType, letter) {
		return this.apiClient(`/index/${entityType}/${letter}`)
	}
}

//
//
//
//

/**
 * ADMIN
 */

// Monitor
export class AdminMonitorApi extends ApiService {
	constructor(pinia, router) {
		super(pinia, router, 'AdminMonitorApi')
	}

	//
	//

	/**
	 * Get list of latest signups or, wait lists signups
	 * @param {String} entityType wait-list / users
	 * @param {Number} page Page number
	 * @returns Object { signups, total, pageCount }
	 */
	getLatest(entityType, page) {
		return this.apiClient(`/admin/monitor/latest/${entityType}/${page}`)
	}
}

// Index
export class AdminIndexApi extends ApiService {
	constructor(pinia, router) {
		super(pinia, router, 'AdminIndexApi')
	}

	//
	//

	/**
	 * Get list of entities:
	 * @param {String} entityType users / artists / artworks / lists / rooms
	 * @param {Number} letter Letter to load
	 * @param {Number} page Page number
	 * @returns
	 */
	getIndex(entityType, letter, page) {
		return this.apiClient(`/admin/index/${entityType}/${letter}/${page}`)
	}
}

// Sitemaps
export class AdminSitemapsApi extends ApiService {
	constructor(pinia, router) {
		super(pinia, router, 'AdminSitemapsApi')
	}

	//
	//

	/**
	 * Get lists of sitemap names by entityType
	 * Note: main sitemap.xml and individual sitemaps are proxied directly via vue.config.js
	 * @returns Object { users:['abx.xml'], artists:['abx.xml'], artworks:['abx.xml'] }
	 */
	getAll() {
		return this.apiClient('/admin/sitemaps/list')
	}

	/**
	 * Generate new sitemaps: START signal
	 * @param {String} entityType users / artists / artworks / images
	 * TODO: Missing rooms!
	 * @returns Nothing, just status 200
	 */
	regenerate(entityType) {
		return this.apiClient.post(`/admin/sitemaps/generate/${entityType}`)
	}

	/**
	 * Get progress while sitemaps are being generated
	 * @returns Object { progress: 100 }
	 */
	getProgress() {
		return this.apiClient('/admin/sitemaps/get-progress')
	}

	/**
	 * Reset progress when done
	 * @returns Nothing, just status 200
	 */
	resetProgress() {
		return this.apiClient.post('/admin/sitemaps/reset-progress')
	}

	/**
	 * Get last update dates per entityType
	 * @returns Object { users: <date>, artists: <data>, etc. }
	 */
	getLastUpdateDate() {
		return this.apiClient('/admin/sitemaps/get-dates')
	}
}

// Maintain
export class AdminMaintainApi extends ApiService {
	constructor(pinia, router) {
		super(pinia, router, 'AdminMaintainApi')
	}

	//
	//

	/**
	 * Get flagged count per entity
	 * @returns Object with percentages { artworks: 50, artists: 33, etc. }
	 */
	getFlagCounts() {
		return this.apiClient('/admin/maintain/flag-count')
	}

	/**
	 *
	 * @param {String} entityType artists / artworks / users / clusters
	 * @returns Number of flags reset
	 */
	resetFlags(entityType) {
		return this.apiClient.post(`/admin/maintain/reset-flags/${entityType}`)
	}

	/**
	 * Generate 10 randomly shuffled indices for each endorsed artwork
	 * @returns Number of endorsed artworks
	 */
	reshuffleWelcome() {
		return this.apiClient.post('/admin/maintain/actions/reshuffle-endorsed')
	}

	/**
	 * Report artist-artwork discrepancies
	 * All artwork ids under each artist should correspond
	 * with an artwork in the artworks table and vice versa
	 * @param {String} signal start / progress / abort
	 * @returns Array with artist IDs where something is off (should be more details report)
	 */
	reportArtistArtworkDiscrepancies(signal) {
		return this.apiClient.post(`/admin/maintain/actions/ar-aw-dscr?signal=${signal}`)
	}
}

// Inspect
export class AdminInspectApi extends ApiService {
	constructor(pinia, router) {
		super(pinia, router, 'AdminInspectApi')
	}

	//
	//

	// Get some sample ids for on the inspect page
	getSampleIds() {
		return this.apiClient(`/admin/inspect/sample-ids`)
	}

	// Takes id and returns what enity type it is, used to forward URL
	getEntityType(id) {
		return this.apiClient(`/admin/inspect/entity-type/${id}`)
	}

	// Load any entity (artist, artwork, user, list, room)
	inspectEntity(entityType, id_username_namePath) {
		return this.apiClient(`/admin/inspect/entity/${entityType}/${id_username_namePath}`)
	}

	// Load JSON html for any entity.
	getJson(entityType, id) {
		return this.apiClient(`/admin/inspect/entity-json/${entityType}/${id}`)
	}

	// Get paginated list of a user's lists.
	getLists(entityType, id_username, pageSize, page, sort) {
		page = page ? page : 1
		sort = sort ? '&sort=' + sort : ''
		console.log(
			`/admin/inspect/entity-clusters/list/${entityType}/${id_username}?pagesize=${pageSize}&page=${page}${sort}`
		)
		return this.apiClient(
			`/admin/inspect/entity-clusters/list/${entityType}/${id_username}?pagesize=${pageSize}&page=${page}${sort}`
		)
	}

	// Get paginated list of a user's lists.
	getRooms(entityType, id_username, pageSize, page, sort) {
		page = page ? page : 1
		sort = sort ? '&sort=' + sort : ''
		return this.apiClient(
			`/admin/inspect/entity-clusters/room/${entityType}/${id_username}?pagesize=${pageSize}&page=${page}${sort}`
		)
	}

	// Get list of people listed under entity,
	// i.e. collectors/followers/following.
	getPeople(entityType, relationType, id_username_namePath, pageSize, page, sort) {
		page = page ? page : 1
		sort = sort ? '&sort=' + sort : ''
		return this.apiClient(
			`/admin/inspect/entity-people/${entityType}/${relationType}/${id_username_namePath}?pagesize=${pageSize}&page=${page}${sort}`
		)
	}

	// List ghosts & orphans.
	reportDiscrepancies(entityType, relationType, id) {
		return this.apiClient(`/admin/inspect/discrepancies/report/${entityType}/${relationType}/${id}`)
	}

	// Visually compare artwork discrepancies.
	reviewDiscrepancies(entityType, relationType, id, pageSize, page) {
		return this.apiClient(
			`/admin/inspect/discrepancies/review/${entityType}/${relationType}/${id}?pagesize=${pageSize}&page=${page}`
		)
	}

	clearGhosts(entityType, relationType, id, ghostIds) {
		return this.apiClient.post(
			`/admin/inspect/discrepancies/clear-ghosts/${entityType}/${relationType}/${id}?ghostIds=${ghostIds}`
		)
	}

	deleteOrphans(orphanIds) {
		return this.apiClient.post(`/admin/inspect/discrepancies/clear-orphans?orphanIds=${orphanIds}`)
	}

	adoptOrphans(entityType, relationType, id, orphanIds) {
		return this.apiClient.post(
			`/admin/inspect/discrepancies/adopt-orphans/${entityType}/${relationType}/${id}?orphanIds=${orphanIds}`
		)
	}
}

//
//
//
//

// Documentation
export class DocApi extends ApiService {
	constructor(pinia, router) {
		super(pinia, router, 'DocApi')
	}

	//
	//

	loadImages(namePath, amount) {
		amount = amount ? amount : 5
		return this.apiClient(`/doc/load-images/${namePath}?amount=${amount}`)
	}
}
export class SearchApi extends ApiService {
	constructor(pinia, router) {
		super(pinia, router, 'SearchApi')
	}

	getSearchResults(entry, entityType, options) {
		// console.log('getSearchResults', { entry, entityType, options })
		// For some reason, period or double period causes error nd never reaches the API.
		if (entry == '.' || entry == '..') return { status: 200, data: { results: [], total: 0 } }

		const query = queryUrl(options) // limit, listAll, page
		return this.apiClient(`/search/${entityType}/${encodeURIComponent(entry)}${query}`)
	}
}
