import { defineStore } from 'pinia'

export const useFullscreenStore = defineStore('FullscreenStore', {
	state: () => ({
		active: false,
	}),
	actions: {
		toggle() {
			if (this.active) {
				this.deactivate()
			} else {
				this.activate()
			}
		},
		activate() {
			this.active = true
			this._requestFullscreen()
		},
		deactivate() {
			this.active = false
			this._exitFullscreen()
		},
		_requestFullscreen() {
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen()
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen()
			} else if (document.documentElement.msRequestFullscreen) {
				document.documentElement.msRequestFullscreen()
			}
		},
		_exitFullscreen() {
			// There's multipe ways to activate fullscreen, so we
			// want to avoid calling exitFullscreen unnecessarily.
			if (window.innerHeight != window.outerHeight) return

			if (document.exitFullscreen) {
				document.exitFullscreen()
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen()
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen()
			}
		},
	},
})

// // Fullscreen functionality
// // ** Note: on Safari, multiple webkitRequestFullscreen requests are layered,
// // so to exit, webkitExitFullscreen would have to be called multiple times.
// // This is to be avoided, hence convoluted logic not to layer fulscreen requests.
// export function toggleFullscreen(forceState) {
// 	const forceStateTrue = typeof forceState != 'undefined' && forceState
// 	const forceStateUndefined = typeof forceState == 'undefined'
// 	const docElm = document.documentElement
// 	forceState

// 	if (docElm.requestFullscreen) {
// 		/* General */
// 		if (!document.fullscreenElement && (forceStateUndefined || forceStateTrue)) {
// 			docElm.requestFullscreen()
// 			FULLSCREEN = true
// 		} else if (document.fullscreenElement && !forceState) {
// 			document.exitFullscreen()
// 			FULLSCREEN = false
// 		}
// 	} else if (docElm.webkitRequestFullscreen) {
// 		/* Safari/Opera Prefix */
// 		if (!document.webkitFullscreenElement && (forceStateUndefined || forceStateTrue)) {
// 			// **
// 			docElm.webkitRequestFullscreen()
// 			FULLSCREEN = true
// 		} else if (document.webkitFullscreenElement && !forceState) {
// 			// **
// 			document.webkitExitFullscreen()
// 			FULLSCREEN = false
// 		}
// 	} else if (docElm.msRequestFullscreen) {
// 		/* IE11 Prefix */
// 		if (!document.msFullscreenElement && (forceStateUndefined || forceStateTrue)) {
// 			docElm.msRequestFullscreen()
// 			FULLSCREEN = true
// 		} else if (document.msFullscreenElement && !forceState) {
// 			document.msExitFullscreen()
// 			FULLSCREEN = false
// 		}
// 	}
// }
