<template>
	<div id="wrap">
		<!-- Header -->
		<div class="header">
			<a href="#" @click.prevent="e => copyText({ text: `{ id: '${entity.id}' }`, elm: e.target })">{{
				entityType
			}}</a>
		</div>

		<!-- Entity ID -->
		<div>
			<a href="#" class="id" @click.prevent="e => copyText({ text: entity.id, elm: e.target })">{{
				entity.id
			}}</a>
		</div>

		<!-- Inspect -->
		<router-link
			:to="{
				name: 'AdminInspectEntity',
				params: { entityType, id_username_namePath: entity.id },
			}"
			>Inspect</router-link
		>

		<!-- Delete -->
		<a href="#" @click.prevent="deleteEntity()">Delete</a>

		<!-- Artwork: Copy Credits -->
		<div v-if="entityType == 'artwork'">
			<a href="#" @click.prevent="copyCredits">Copy Credits</a>
		</div>

		<!-- Artwork/Image: B2 Images -->
		<div v-if="entityType == 'artwork' || entityType == 'image'">
			<a href="#" @click.prevent="showB2Images">B2 Images</a>
		</div>

		<!-- Artist: Take Down / Restore -->
		<template v-if="entityType == 'artist'">
			<a v-if="takenDown" href="#" @click.prevent="restoreArtist">Restore</a>
			<a v-else href="#" @click.prevent="takeDownArtist">Take Down</a>
		</template>

		<!-- Artist: Image Reco -->
		<a v-if="entityType == 'artist'" href="#" @click.prevent="loadPanel('ImgRecoArtist')">Img Reco</a>

		<!-- Artist: Image Reco -->
		<a v-if="entityType == 'artwork'" href="#" @click.prevent="loadPanel('ImgRecoArtwork')">Img Reco</a>

		<!-- Hide Panel -->
		<a href="#" @click.prevent="$emit('hide')">Hide Panel</a>
	</div>
</template>

<script>
// Internal
import AdminPanel from '@/mixins/mx_AdminPanel'
import copyText from '@/use/CopyText'
import { ArtistActions, ItmActions, UserActions, ClusterActions } from '@/use/EntityActions'
import { itmCredits } from '@/use/Helpers'

export default {
	name: 'AdminControlsOverview',
	mixins: [AdminPanel],
	emits: ['hide'],
	setup() {
		return { copyText }
	},
	data() {
		return {
			// Only for artist
			takenDown: this.entityType == 'artist' ? this.entity.takenDown : null,
		}
	},
	mounted() {
		if (this.entityType == 'artist') {
			this.artistActions = new ArtistActions(this.$imperial)
		} else if (this.entityType == 'artwork' || this.entityType == 'image') {
			this.itmActions = new ItmActions(this.$imperial)
		} else if (this.entityType == 'user' || this.entityType == 'list') {
			this.userActions = new UserActions(this.$imperial)
			this.clusterActions = new ClusterActions(this.$imperial)
		} else if (this.entityType == 'room') {
			this.clusterActions = new ClusterActions(this.$imperial)
		}
	},
	methods: {
		deleteEntity() {
			if (this.entityType == 'artist') {
				this.artistActions.delete(this.entity.id)
			} else if (this.entityType == 'artwork' || this.entityType == 'image') {
				this.itmActions.delete(this.entity.id, this.entityType)
			} else if (this.entityType == 'user') {
				this.userActions.delete(this.entity.id)
			} else if (this.entityType == 'list' || this.entityType == 'room') {
				this.clusterActions.delete(this.entity.id, this.entityType)
			}
		},
		showB2Images() {
			this.itmActions.showB2Images(this.entity.id, this.viewNr)
		},
		takeDownArtist() {
			this.artistActions.takeDown(this.entity.id, success => {
				this.takenDown = success === true
			})
		},
		restoreArtist() {
			this.artistActions.restore(this.entity.id, success => {
				this.takenDown = success !== true
			})
		},
		copyCredits(e) {
			const text = itmCredits(this.entity)
			copyText({ text, elm: e.target })
		},
		loadPanel(panelName) {
			this.$emit('loadPanel', panelName)
		},
	},
}
</script>

<style scoped lang="scss">
#wrap {
	max-width: 2rem;
	border-bottom: none;
	line-height: 0.16rem;
}
#wrap .header {
	font-weight: 600;
	text-transform: uppercase;
}
#wrap .header a {
	color: $black;
}
#wrap > a,
#wrap > div {
	display: block;
	padding: 0.05rem 0.1rem;
	border-bottom: solid 0.01rem $black-10;
}
#wrap .id {
	color: #d00;
}
#wrap .credits {
	width: 100%;
	height: 0.3rem;
	margin: 0;
	position: absolute;
	bottom: -0.3rem;
	left: 0;
	z-index: -1;
	pointer-events: none;
	opacity: 0;
}
</style>
