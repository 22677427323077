<template>
	<div class="act-remove" :class="{ dark }">
		<BaseIcon :name="name" />
	</div>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'

export default {
	name: 'ActionRemove',
	components: { BaseIcon },
	props: {
		removed: Boolean,
		forceState: String, // add / remove --> fixed state for selection bar
		dark: Boolean,
	},
	computed: {
		name() {
			if (this.forceState == 'add' || this.removed) {
				return 'add'
			} else {
				return 'remove'
			}
		},
	},
}
</script>

<style scoped lang="scss">
.act-remove {
	width: 0.5rem;
	height: 0.5rem;
	padding: 0.15rem;
	cursor: pointer;
}
.act-remove:deep() .icn {
	fill: $black;
}

// Dark version
.act-remove.dark:deep() .icn {
	fill: $white-75;
}

@media (hover: hover) {
	.act-remove:hover:deep() .icn {
		fill: $primary;
	}

	// // Dark version
	// .act-remove:hover.dark:deep() .icn {
	// 	fill: $white;
	// }
}
</style>
