/* General purpose helper functions. */

// Vue
import { onBeforeRouteLeave } from 'vue-router'

// Stores
import { useDialogStore } from '@/stores/DialogStore'

// Internal
import { isSSR, isProd } from '@/use/Base'
import flash from '@/use/FlashAlert'

//
//

// Detect if string is email
// NOT USED
export function isEmail(value) {
	// emailregex.com --> https://stackoverflow.com/a/201378
	const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
	return regex.test(String(value))
}

export function isUrl(value) {
	const regex = /^(http(s)?):\/\/(www\.)?[-a-zA-Z0-9:._]{2,256}\.[a-z]{2,12}\b([-a-zA-Z0-9@:%._,+~*;'#=?&()/]*)$/
	return regex.test(String(value))
}

// Async delay
export function delay(ms) {
	return new Promise(resolve => {
		setTimeout(() => {
			resolve()
		}, ms)
	})
}

// Async requestAnimationFrame
export function requestAnimationFrame() {
	return new Promise(resolve => {
		window.requestAnimationFrame(() => {
			resolve()
		})
	})
}

// Retrieve a cookie
// NOT USED
// export function getCookie(name) {
// 	var value = '; ' + document.cookie
// 	var parts = value.split('; ' + name + '=')
// 	if (parts.length == 2)
// 		return parts
// 			.pop()
// 			.split(';')
// 			.shift()
// }

/**
 * Takes form response, dispatches errors and callbacks success
 * @param {Object} response Object returned from axios
 * @param {Object} errors Error object that can store individual errors per field
 * @param {Function} onSuccess Callback to execute when all is good
 */
export function processFormResponse(response, onError, onSuccess) {
	const { status, data, statusText } = response
	if (status == 200) {
		onSuccess(data)
		return true
	} else if (status == 400) {
		onError(data, statusText)
	} else {
		handleApiError({ status, data, statusText, noFlash: true })
		onError({ general: statusText }, statusText)
	}
	return false
}

// Construct queryUrl from object
// { foo: bar } --> ?foo=bar
export function queryUrl(queryObject) {
	if (!queryObject) return ''
	// Remove undefined parameters
	Object.keys(queryObject).forEach(key =>
		queryObject[key] === undefined || queryObject[key] === null ? delete queryObject[key] : {}
	)

	// Construct URL
	const queryUrl = Object.keys(queryObject)
		.map(key => key + '=' + queryObject[key]) // Should this use encodeURIComponent(queryObject[key]) ?
		.join('&')
	return queryUrl.length ? '?' + queryUrl : ''
}

// Construct query object from qyery url
// foo=bar --> { foo: bar }
export function queryObject(queryUrl) {
	if (!queryUrl) return {}
	let queryObject = queryUrl.split('&')
	queryObject = queryObject.map(keyValue => {
		return keyValue.split('=')
	})
	return Object.fromEntries(queryObject)
}

// Check if any input field is in focus
export function inputInFocus() {
	if (document.activeElement.tagName.match(/^INPUT|TEXTAREA$/i)) {
		return document.activeElement.tagName.toLowerCase()
	} else {
		return null
	}
}

// Maximum age for a cookie, 400 days.
// https://developer.chrome.com/blog/cookie-max-age-expires/
export const cookieMaxAge = 60 * 60 * 24 * 400

// Clone object without any references.
// - - -
// Optional 'keys' lets you select which fields to clone.
// Can be array ['name', 'username'] or string 'name username'
export function cloneDeep(obj, keys) {
	if (keys) {
		keys = typeof keys == 'string' ? keys.split(' ') : keys
		if (Array.isArray(keys)) {
			const freshObj = {}
			keys.forEach(key => (freshObj[key] = obj[key]))
			obj = freshObj
		}
	}
	return _parseOneLevel(obj)

	//
	//

	function _parseOneLevel(val) {
		const isArray = Array.isArray(val)
		const isObject = val && typeof val == 'object'
		if (isArray) {
			// Array
			return val.map(v => _parseOneLevel(v))
		} else if (isObject) {
			// Object
			return Object.fromEntries(
				Object.entries(val).map(entry => {
					if (typeof entry[1] == 'object') {
						const destructuredValue = Array.isArray(entry[1]) ? [...entry[1]] : { ...entry[1] }
						return [entry[0], _parseOneLevel(destructuredValue)]
					} else {
						return [entry[0], entry[1]]
					}
				})
			)
		} else {
			return val
		}
	}
}

// Prevent user from leaving the page while they have unsaved changes.
export function preventLeaveStart(options) {
	if (isSSR) return
	let { title, msg } = options || {}
	title = title || 'You have unsaved changes'
	msg = msg || 'Are you sure you want to leave?'
	window.onbeforeunload = () => {
		return msg
	}
	onBeforeRouteLeave((to, from, next) => {
		const dialogStore = useDialogStore()
		dialogStore.confirm(next, msg, { persistent: true, title })
	})
}
export function preventLeaveEnd() {
	window.onbeforeunload = () => {}
	onBeforeRouteLeave(() => {})
}

// A log visible in the viewport, useful for mobile debugging.
// import { domLog } from '@/use/Helpers'
// domLog('Foo', 'bar', x, y)
export function domLog() {
	if (isSSR || isProd) return
	let elm = document.getElementById('dom-log')
	let virgin = false
	if (!elm) {
		elm = _createElm()
		virgin = true
	}
	const text = Array.from(arguments).reduce((total, arg) => {
		total += '\\' + arg
		return total
	})

	// Log
	elm.append((virgin ? '' : '\n') + text)
	// console.log(Array.from(arguments).join('  '))

	function _createElm() {
		const div = document.createElement('pre')
		div.setAttribute('id', 'dom-log')
		div.style.position = 'fixed'
		div.style['z-index'] = 100
		div.style.left = 0
		div.style.bottom = 0
		div.style.border = 'solid 1px red'
		div.style.background = '#fff'
		div.style.margin = 0
		div.style.padding = '5px'
		div.style['font-size'] = '10px'
		// div.style['pointer-events'] = 'none'
		div.addEventListener('click', () => {
			document.body.removeChild(div)
		})
		document.body.appendChild(div)
		return div
	}
}

// Debounce
// Limit rate of rapid-fire event, executing only when time limit expires.
// Usefull for window resize events, etc.
// Source: https://blog.webdevsimplified.com/2022-03/debounce-vs-throttle/
//
// import { debounce } from '@/use/Helpers'
// const debouncer = debounce(this.resizeHandler, 500)
// window.addEventListener('resize', debouncer)
export function debounce(func, delay = 300) {
	let timeout
	return (...args) => {
		clearTimeout(timeout)
		timeout = setTimeout(() => {
			func(...args)
		}, delay)
	}
}

// Throttle
// Limit rate of rapid-fire event, executing only once per time limit
// Useful for scroll handlers, etc.
// Source: https://blog.webdevsimplified.com/2022-03/debounce-vs-throttle/
//
// import { throttle } from '@/use/Helpers'
// const throttler = throttle(this.scrollHandler, 500)
// window.addEventListener('scroll', throttler)
export function throttle(func, delay = 3000) {
	let wait = false
	let waitArgs
	const timeoutFunc = () => {
		if (waitArgs == null) {
			wait = false
		} else {
			func(...waitArgs)
			waitArgs = null
			setTimeout(timeoutFunc, delay)
		}
	}

	return (...args) => {
		if (wait) {
			waitArgs = args
			return
		}
		func(...args)
		wait = true
		setTimeout(timeoutFunc, delay)
	}
}

// Download image
export function downloadImg(id) {
	const url = '/img/download/' + id
	console.log('Download: ' + url)
	window.location.href = url
}

// Creates a clean string describing an artwork
export function itmCredits(itm) {
	// Artwork only
	if (itm.artistName) {
		const maybeYear = itm.year ? ', ' + itm.year : ''
		return (itm.title || 'Untitled') + maybeYear + ' by ' + itm.artistName
	}
}

// Unified handling of API errors.
export function handleApiError({ status, data, statusText, action, noFlash }) {
	console.log('handleApiError')
	const statusMsg = `Error ${status}: ${statusText}`
	console.error(statusMsg)
	console.error(data)
	if (!noFlash) {
		const errorMsg = [`<p>Something went wrong ${action}.</p>`, `<pre class="soft">${statusMsg}</pre>`]
		flash(errorMsg.join('\n'), { type: 'error', html: true })
	}
}

// This locks the page in its current scroll position
// and hides the scroll bar. Used when overlaying the
// carousel or search.
export function lockScroll(doLock) {
	if (isSSR) return

	if (doLock) {
		return _lock()
	} else {
		_unlock()
	}

	//
	//

	function _lock() {
		const scrollbarWidth = window.innerWidth - document.body.clientWidth
		document.body.style.marginRight = scrollbarWidth + 'px'
		document.body.style.overflow = 'hidden'
		return scrollbarWidth
	}
	function _unlock() {
		document.body.removeAttribute('style')
	}
}

// Check is valie is string.
export function isString(str) {
	return typeof str === 'string' || str instanceof String
}

// Destructure a route object
// This is needed because not doing so causes errors
// when you store the route in the store etc.
export function destructureRoute(route) {
	const destructuredRoute = {
		name: route.name,
		params: route.params,
		query: route.query,
		hash: route.hash,
		path: route.path,
		fullPath: route.fullPath,
		// matched: [],
	}
	return destructuredRoute
}

// Spits back a truncated paragraph from a longer text.
// Returns
export function paragraphTrim(text, options) {
	if (!text) return

	// Options:
	options = options || {}
	// The maximum length we allow a paragraph to be before we trim.
	const maxLength = options.maxLength || 1500
	// The length of the text after trimming.
	// Note that it's shorter than the maxLength, to avoid orphan text.
	const trimLength = options.trimLength || 1000
	// When text is expanable we return the trimmed text as well.
	const expandable = options.expandable || true

	// Trim or not.
	const needsTrim = text.length > maxLength

	// Slice text into array of paragraphs.
	let paragraphs = text.trim().split(/(\r|\n)/)
	paragraphs = paragraphs.filter(p => p.replace(/(\r|\n)/, '').trim().length)

	// Limit the first paragraph to the trimLength.
	// prettier-ignore
	if (needsTrim) {
		const visibleSlice = paragraphs[0].slice(0, trimLength)
		if (expandable) {
			const hiddenSlice = paragraphs[0].slice(trimLength)
			return hiddenSlice.length ? [visibleSlice, hiddenSlice] : [visibleSlice]
			// if (hiddenSlice.length) {
			// 	paragraphs[0] = `${visibleSlice}<span class="collapsable">${hiddenSlice}</span>`
			// }
		} else {
			return visibleSlice
		}
	} else {
		return expandable ? [text] : text
	}

	// Join paragraphs in <p> tags.
	// room.intro = '<p>' + paragraphs.join('</p><p>') + '</p>'
}
