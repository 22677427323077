import { defineStore } from 'pinia'

export const useFeaturedStore = defineStore('FeaturedStore', {
	state: () => ({
		rooms: [
			// Variables added below:
			// - needsTrim ()
			// - expand
		],
		isLastPage: false,
	}),
	getters: {
		// Turns whitespace into paragraphs and takes care of collapsable text.
		htmlIntros() {
			return this.rooms.map(room => {
				if (room.intro) {
					// Mark & collapse room intros that should be trimmed.
					room.needsTrim = room.intro.length > 1500

					// Slice into array of paragraphs.
					let paragraphs = room.intro.trim().split(/(\r|\n)/)
					paragraphs = paragraphs.filter(p => p.replace(/(\r|\n)/, '').trim().length)

					// Limit the first paragraph to 1000
					// characters when the total goes over 1500.
					// prettier-ignore
					if (room.needsTrim) {
						const visibleSlice = paragraphs[0].slice(0, 1000)
						const hiddenSlice = paragraphs[0].slice(1000)
						if (hiddenSlice.length) {
							paragraphs[0] = `${visibleSlice}<span class="collapsable">${hiddenSlice}</span>`
						}
					}

					// Join paragraphs in <p> tags.
					room.intro = '<p>' + paragraphs.join('</p><p>') + '</p>'
				}
				return room.intro
			})
		},
	},
	actions: {
		storeRooms({ rooms, isLastPage }) {
			this.rooms = rooms
			// this.rooms = [rooms[1]]
			this.isLastPage = isLastPage
		},
		toggleExpand(i) {
			this.rooms[i].expand = !this.rooms[i].expand
		},
	},
})
