<template>
	<!-- <pre>{{ itmEditData }}</pre> -->
	<!-- <pre>{{ itm }}</pre> -->
	<div class="content-pad narrow">
		<h1 id="title">Edit Artwork<ActionClose @click="$emit('exit')" /></h1>

		<DebugDisplay
			v-if="debug && (debug == 1 || debug == 3)"
			:data="itmEditData"
			style="margin-top:0.2rem"
		/>
		<DebugDisplay
			v-if="debug && (debug == 1 || debug == 3)"
			:data="errors"
			style="margin:0.05rem 0 0.2rem 0"
		/>

		<!-- Views -->
		<p v-if="itm.views.length > 1">Drag views to rearrange. The first view is the cover image.</p>
		<RearrangeImages
			:itms="itm.views.map(view => ({ views: [view] }))"
			@update.stop
			@rearrange="onUpdateViewOrder"
		/>

		<!-- Form -->
		<div id="form" class="structured-form two-cols">
			<!-- Art / Design toggle -->
			<FormToggle v-model="isArtwork" label="Art / Design" :debug="debug && debug > 1" />
			<div class="empty-slot"></div>

			<div class="separator"></div>

			<!-- Artist & Title -->
			<template v-if="isArtwork">
				<FormArtist
					:initialData="{ name: itm.artistName, id: itm.artistId }"
					:error="errors.artist"
					@update="onUpdateArtist"
					:debug="debug && debug > 1"
				/>
				<FormText v-model="itmEditData.title" label="Title" maxlength="200" />

				<!-- Year -->
				<FormYear v-model="itmEditData.year" @error="onErrorYear" :debug="debug && debug > 1" />
			</template>

			<!-- Labels -->
			<FormLabels
				:class="{ 'full-width': !isArtwork }"
				:initialLabels="regularLabels"
				@update="onUpdateLabels"
				:debug="debug && debug > 1"
			/>

			<!-- Dimensions & Medium -->
			<template v-if="isArtwork">
				<FormDimensions v-model:dim="itmEditData.dim" :debug="debug && debug > 1" />
				<FormLabels
					:initialLabels="materialLabels"
					:initialMedium="itm.medium"
					:initialMaterialsFF="itm.materialsFF"
					:isMedium="true"
					@update="onUpdateMaterials"
					:debug="debug && debug > 1"
				/>
			</template>

			<!-- Non-artwork caption -->
			<FormText
				v-model="itmEditData.caption"
				type="textarea"
				class="caption full-width"
				label="Caption"
				maxlength="10000"
			/>

			<!-- Photo credit -->
			<FormPhotoCredit v-model="itmEditData.photoCredit" />

			<!-- Bottom section outside of flex layout -->
			<div class="bottom">
				<!-- Error message -->
				<div v-if="errorMsg" id="error-msg" v-html="errorMsg"></div>

				<!-- Submit -->
				<FormButton
					:level="1"
					:value="['Save', 'Saving']"
					:wait="isSubmitted"
					@click="onSubmit"
				/><FormButton :level="2" value="Cancel" @click="$emit('exit')" />
			</div>
		</div>
	</div>
</template>

<script>
// Stores
import { useApiStore } from '@/stores/ApiStore'
import { useDialogStore } from '@/stores/DialogStore'

// Views
import RearrangeImages from '@/components/RearrangeImages'

// Components
import ActionClose from '@/components/ActionClose'
import FormToggle from '@/components/FormToggle'
import FormArtist from '@/components/FormArtist'
import FormText from '@/components/FormText'
import FormYear from '@/components/FormYear'
import FormLabels from '@/components/FormLabels'
import FormDimensions from '@/components/FormDimensions'
import FormButton from '@/components/FormButton'
import DebugDisplay from '@/components/DebugDisplay'
import FormPhotoCredit from '@/components/FormPhotoCredit'

// Internal
import { removeUnchangedValues } from '@/use/General'

export default {
	name: 'ItmEdit',
	components: {
		RearrangeImages,
		ActionClose,
		FormToggle,
		FormArtist,
		FormText,
		FormYear,
		FormLabels,
		FormDimensions,
		FormButton,
		FormPhotoCredit,
		DebugDisplay,
	},
	props: {
		itm: {
			type: Object,
			required: true,
		},
	},
	emits: ['update', 'exit'],
	setup() {
		const dialogStore = useDialogStore()
		const apiStore = useApiStore()
		const itmApi = apiStore.loadApi('itm')
		return { dialogStore, itmApi }
	},
	data() {
		return {
			// Shows debug displays (1/2/3)
			debug: this.$route.query.debug,

			// Itm data clone where we store our edits.
			itmEditData: this.initItmEditData(this.itm),

			// Centralized input errors.
			errors: {},

			// Counter of errors used in errorMsg.
			errorCount: 0,

			// Error message that is rendered via computed errorMsg.
			mainError: null,

			// v-model for image type dropdown.
			isArtwork: this.$route.name == 'Artwork',

			// Triggers the waiting state for the submit button.
			isSubmitted: false,
		}
	},
	computed: {
		// Labels cleaned from unnecessary database fields.
		cleanLabels() {
			return this.itm.labels.map(label => {
				const { value, type, id } = label
				return { value, type, id }
			})
		},

		// Subject & style labels.
		regularLabels() {
			let labels = this.cleanLabels.filter(label => label.type == 'subject' || label.type == 'style')
			return labels
		},

		// Material and substrate labels.
		materialLabels() {
			return this.cleanLabels.filter(label => label.type == 'material' || label.type == 'substrate')
		},

		// Used to watch for changes and reset errorMsg.
		stringifiedData() {
			return JSON.stringify(this.itmEditData)
		},

		// Error message at the bottom.
		// Keep in sync with Upload/Form.vue
		errorMsg() {
			if (this.mainError) {
				return this.mainError
			} else if (!this.errorCount) {
				return null
			} else if (this.errorCount == 1) {
				return 'There is one error, scroll up to see details.'
			} else {
				return `There are ${this.errorCount} errors, scroll up to see details.`
			}
		},
	},
	watch: {
		// Reset error message whenever data changes.
		stringifiedData() {
			this.resetErrorMsg()
		},

		// When user changes artwork to image, we show warning.
		isArtwork(newValue) {
			if (!newValue) {
				const warningMsg =
					'<p>Turning this artwork into an image will detach it from the artist and remove any artwork related information.</p><p>Are you sure you want to do this?</p>'
				this.dialogStore.confirm(null, warningMsg, {
					title: 'Treacherous Action',
					warning: true,
					html: true,
					onCancel: () => {
						this.isArtwork = true
					},
				})
			}
		},
	},
	methods: {
		// Reduce artwork object to only the relevant fields for editing
		initItmEditData(itm) {
			// Artist field has more complex data structure so these
			// are added when an @update is emitted once they're mounted.
			// TO DO: update FormArtist field to use v-model like FormYear
			// - - -
			// Note: if this data structure becomes more complex, we might need to use deepClone
			// like we do in ArtistEdit.vue, but this is expensive and currently not yet necessary here.
			let { id, artistId, title, year, labels, dim, medium, materialsFF, caption, photoCredit } = itm
			dim = { ...dim }

			// Separate labels
			const materialLabels = labels
				.filter(label => label.type == 'material' || label.type == 'substrate')
				.map(label => label.id)
			labels = labels
				.filter(label => label.type == 'subject' || label.type == 'style')
				.map(label => label.id)

			return {
				id,
				artistId,
				title,
				year,
				labels,
				materialLabels,
				dim,
				medium,
				materialsFF,
				caption,
				photoCredit,
			}
		},
		onUpdateViewOrder(order) {
			console.log('## onUpdateViewOrder', order)
			// console.log(itemsArray.sort((a, b) => sortingArr.indexOf(a) - sortingArr.indexOf(b)))
			this.itmEditData.viewOrder = order
		},
		onUpdateArtist(artist) {
			const { id, inputValue, error } = artist || {}
			this.itmEditData.artistId = id

			// Set error
			this.errors.artist = (!inputValue ? 'Required' : error) || undefined
		},
		// Whenever error state changes.
		onErrorYear(error) {
			this.errors.year = error || undefined
			this.resetErrorMsg()
		},
		onUpdateLabels(labels) {
			this.itmEditData.labels = labels
		},
		onUpdateMaterials({ materialLabels, medium, materialsFF }) {
			// console.log('onUpdateMaterials', materialsFF)
			this.itmEditData.materialLabels = materialLabels
			this.itmEditData.medium = medium
			this.itmEditData.materialsFF = materialsFF
		},

		// Update errorCount
		countErrors() {
			// Remove undefined values, which shoulnd't trigger errorCount
			Object.keys(this.errors).forEach(key =>
				this.errors[key] === undefined ? delete this.errors[key] : {}
			)
			return Object.keys(this.errors).length
		},

		// Reset error message at the bottom.
		resetErrorMsg() {
			this.mainError = null
			this.errorCount = null
		},

		// Validate data & maybe show errors
		// Keep in sync with UploadStore.js
		validate() {
			// Return error Boolean.
			this.errorCount = this.countErrors()
			return this.errorCount === 0
		},

		// Trims all the superfluous info used for validation
		// and returns clean data ready for API.
		formatData() {
			let uploadData = { ...this.itmEditData }

			// Remove any values that didn't change.
			uploadData = removeUnchangedValues(uploadData, this.itm)

			// Collapse labels
			if (uploadData.materialLabels) {
				uploadData.labels = (uploadData.labels || []).concat(uploadData.materialLabels)
				uploadData.labels = uploadData.labels.length ? uploadData.labels : null
				delete uploadData.materialLabels
			}

			// The isArtwork flag lets us switch between image and artwork in the API.
			// - - -
			// Note: when turning an artwork into an image, we chose the strategy
			// to keep all the artwork data since this allows us to easily restore
			// it if a mistake was made. So instead of deleting artwork-specific data,
			// we just don't display it in the image template. When the API detects
			// isArtwork == true but an artistId is set, it will take care of
			// stripping artistId, artistNamePath and titlePath.
			// See workers/entities.js updateItm() in API.
			uploadData.isArtwork = this.isArtwork

			// When no artist id is present, we make sure artistName and artistNamePath
			// are also removed. This is essential for when an artwork is turned into an image.
			if (!this.isArtwork) {
				uploadData.artistName = null
				uploadData.artistNamePath = null
			}

			// Note: undefined values are ignored by the API. A null values however will
			// delete the key from the document.
			console.log('Formatted data for API:', uploadData)
			return uploadData
		},

		// Submit to API
		async onSubmit() {
			const valid = this.validate()
			if (valid) {
				const uploadData = this.formatData()
				const { status, data, statusText } = await this.itmApi.update(uploadData)
				if (status == 200) {
					this.isSubmitted = true
					this.$emit('update', data)
				} else {
					this.mainError = statusText ? statusText : 'Something went wrong...'
				}
			}
		},
	},
}
</script>

<style scoped lang="scss">
// Form
#form .caption {
	height: 4rem;
}
#form .bottom {
	margin-top: 0.4rem;
	flex: 0 0 100%;
}

// Error message
#error-msg {
	color: $bad;
	line-height: 0.2rem;
	margin-top: -0.2rem;
	margin-bottom: 0.2rem;
	animation: blink 170ms 3;
}

@media only screen and (max-width: $form) {
	#form {
		width: auto;
		flex-basis: 0;
		flex-direction: column;
	}
	#form:deep() .wrap {
		width: 100%;
	}
	#form .separator {
		width: 100%;
		flex-basis: 0.01rem;
	}
}
</style>
