/*

Consolidated handling of window scroll & resize events.
Takes care of debounce/throttle and self-destructs at onBeforeUnmount.
- - -

import { useWindowStore } from '@/stores/WindowStore'
const windowStore = useWindowStore()
this.windowStore.listen('resize', () => {
	// Do something
})
this.windowStore.listen('scroll', () => {
	// Do something
})
		

*/

// Vue
import { onBeforeUnmount } from 'vue'
import { defineStore } from 'pinia'

// Internal
import { debounce as doDebounce, throttle as doThrottle } from '@/use/Helpers'
import { isSSR } from '@/use/Base'

export const useWindowStore = defineStore('WindowStore', {
	state: () => ({
		scroll: 0,
	}),
	actions: {
		// Add listener
		listen(eventType, handler, options) {
			// Throttle or debounce as needed.
			// - Throtte: execute only once per throttle limit (best when scrolling)
			// - Debounce: execute only once when debounce limit expires (best when resizing)
			if (isSSR) return
			const { debounce, throttle, log } = options || {}
			if (debounce) handler = doDebounce(handler, debounce)
			else if (throttle) handler = doThrottle(handler, throttle)

			// Add new handler.
			if (eventType == 'resize') {
				window.addEventListener('resize', handler)

				// Remove listener on route change
				// TO DO: This is illegal! Can only be called from within setup().
				// I guess this needs to become a component?
				onBeforeUnmount(() => {
					window.removeEventListener('resize', handler)
				})
			} else if (eventType == 'scroll') {
				const f = log ? _logHandler.bind(this) : handler

				window.addEventListener('scroll', f)

				// Remove listener on route change
				onBeforeUnmount(() => {
					window.removeEventListener('scroll', f)
				})
			}

			//
			//

			function _logHandler(e) {
				this.scroll = e.target.scrollTop
				handler()
			}
		},

		// Remove listener
		unlisten(eventType, handler, options) {
			// console.log('unlisten', eventType, handler)
			const { debounce, throttle } = options || {}
			if (debounce) handler = doDebounce(handler, debounce)
			else if (throttle) handler = doThrottle(handler, throttle)
			if (eventType == 'resize') {
				window.removeEventListener(eventType, handler)
			} else if (eventType == 'scroll') {
				window.removeEventListener(eventType, handler)
			}
		},
	},
})
