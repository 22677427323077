// This file contains all artwork/image related functions that are used on various locations.

// Stores
import { useApiStore } from '@/stores/ApiStore'
import { useAuthStore } from '@/stores/AuthStore'
import { useDialogStore } from '@/stores/DialogStore'

// Internal
import flash from '@/use/FlashAlert'
import { handleApiError } from '@/use/Helpers'

export class ItmActions {
	constructor(pinia) {
		// this.pinia = pinia
		const apiStore = useApiStore()
		this.itmApi = apiStore.loadApi('itm')
		this.authStore = useAuthStore(pinia)
		this.dialogStore = useDialogStore()
	}

	/**
	 * Single itms
	 */

	// When artwork or image is successfully edited.
	async onUpdate({ itm, data, router, callbackReloadPage, callbackToggleEditMode }) {
		const { id, artistId, artistNamePath, titlePath, category } = data
		const newRoute = _reroute()
		if (newRoute) {
			await router.push(newRoute)
		} else {
			await callbackReloadPage()
		}
		const itmType = artistId ? 'artwork' : 'image'
		flash(`You successfully updated this ${itmType}.`, { type: 'success' })
		callbackToggleEditMode()

		// We might have to reroute.
		function _reroute() {
			const artwork2Image = itm.artistId && !artistId
			const image2Artwork = !itm.artistId && artistId
			const artworkReassigned = itm.artistId != artistId
			const newTitle = itm.titlePath != titlePath
			if (artwork2Image) {
				// Artwork was turned into image.
				return { name: 'Image', params: { id } }
			} else if (image2Artwork || artworkReassigned || newTitle) {
				// Image was turned into artwork OR artwork was reassigned
				if (!artistNamePath || !titlePath || !category) return console.error('Failed to forward')
				return { name: 'Artwork', params: { namePath: artistNamePath, titlePath, category } }
			} else {
				return false
			}
		}
	}

	/**
	 * Multiple itms
	 */

	// Collect array of itms
	async collectAll(itmIds, options) {
		const { alreadyCollected, onSuccess, onError } = options || {}

		// Collect all (ignore the ones already collected)
		const { status, data, statusText } = await this.itmApi.collect(itmIds)

		if (status == 200) {
			// Success flash alert
			const total = itmIds.length
			if (total > 1) {
				const alreadyCollectedString =
					alreadyCollected == 1 ? 'One of them was' : `${alreadyCollected} of them were`
				let successMsg = `${total} items were added to your collection.`
				if (alreadyCollected) {
					successMsg += `<br><small class="soft">${alreadyCollectedString} already in your collection.</small>`
				}
				flash(successMsg, { html: true })
			}
			if (onSuccess) onSuccess()
		} else {
			// Error collecting
			const action =
				'adding' + (itmIds.length > 1 ? ' these items ' : ' this item ') + 'to your collection'
			handleApiError({ status, data, statusText, action })
			if (onError) onError()
		}
	}

	// Uncollect array of itms
	async uncollectAll(itmIds, options) {
		const { onSuccess, onError, onCancel } = options || {}

		// Check if itm ids are in any lists or rooms and either show
		// confirm dialog or uncollect immediately if they're not.
		this._checkClusters(itmIds, { onConfirm: _uncollectAll.bind(this), onError, onCancel })

		async function _uncollectAll() {
			const { status, data, statusText } = await this.itmApi.uncollect(itmIds)

			if (status == 200) {
				// Success flash alert
				const total = itmIds.length
				if (total > 1) {
					let successMsg = `${total} items were removed from your collection.`
					flash(successMsg)
				}
				if (onSuccess) onSuccess()
			} else {
				// Error uncollecting
				this._handleUncollectError({ status, data, statusText, itmIds })
				if (onError) onError()
			}
		}
	}

	// Add itms to selected lists and rooms
	async addToClusters({ itmIds, listIds, roomIds }, options) {
		const { onSuccess, onError, noFlash } = options || {}

		const { status, data, statusText } = await this.itmApi.addToClusters({
			itmIds,
			listIds,
			roomIds,
		})
		if (status == 200) {
			if (!noFlash) this._flashAddRemoveSuccess(true, { itmIds, listIds, roomIds })
			const clusterIds = [...(listIds || []), ...(roomIds || [])]
			if (onSuccess) onSuccess({ clusterIds, listIds, roomIds })
		} else {
			const hasLists = listIds && listIds.length
			const hasRooms = roomIds && roomIds.length
			const s =
				(roomIds && roomIds.length && roomIds.length > 1) ||
				(listIds && listIds.length && listIds.length > 1)
					? 's'
					: ''
			const clusters = hasLists ? (hasRooms ? 'lists and rooms' : 'list' + s) : 'room' + s
			const action =
				'adding' + (itmIds.length > 1 ? ' these items ' : ' this item ') + 'to your ' + clusters
			handleApiError({ status, data, statusText, action })
			if (onError) onError()
		}
	}

	// Remove itms from current room
	async removeFromClusters({ itmIds, listIds, roomIds }, options) {
		const { onSuccess, onError, noFlash } = options || {}

		const { status, data, statusText } = await this.itmApi.removeFromClusters({
			itmIds,
			listIds,
			roomIds,
		})
		if (status == 200) {
			if (!noFlash) this._flashAddRemoveSuccess(false, { itmIds, listIds, roomIds })
			if (onSuccess) onSuccess()
		} else {
			const hasLists = listIds && listIds.length
			const hasRooms = roomIds && roomIds.length
			const s =
				(roomIds && roomIds.length && roomIds.length > 1) ||
				(listIds && listIds.length && listIds.length > 1)
					? 's'
					: ''
			const clusters = hasLists ? (hasRooms ? 'lists and rooms' : 'list' + s) : 'room' + s
			const action =
				'removing' + (itmIds.length > 1 ? ' these items ' : ' this item ') + 'from your ' + clusters
			handleApiError({ status, data, statusText, action })
			if (onError) onError()
		}
	}

	// Success flash alert after adding or removing an item to a room or list.
	_flashAddRemoveSuccess(add, { itmIds, roomIds, listIds }) {
		const action = add ? 'added' : 'removed'
		const toFrom = add ? 'to' : 'from'
		const itmTotal = itmIds.length
		const itmTotalString = itmTotal == 1 ? 'This item was' : `${itmTotal} items were`
		const clusterType = roomIds ? 'room' : 'list'
		const clusterTotal = roomIds ? roomIds.length : listIds ? listIds.length : 0
		const clusterTotalString =
			clusterTotal == 1 ? `one ${clusterType}` : `${clusterTotal} ${clusterType}s`
		let successMsg = `${itmTotalString} ${action} ${toFrom} ${clusterTotalString}.`
		flash(successMsg)
	}

	/**
	 * Shared
	 */

	// Find any rooms or lists the selected itmId(s) are in
	async _checkClusters(itmIds, { onConfirm, onError, onCancel }) {
		// Fetch any of my lists and rooms this itm is in.
		const { status, data: clusters, statusText } = await this.itmApi.getMyContainingClusters(itmIds)

		if (status != 200) {
			// Error fetching clusters
			this._handleUncollectError({ status, data: clusters, statusText, itmIds })
			if (onError) onError()
		} else if (clusters.length) {
			// Rooms or lists found --> Show dialog listing them
			const myUsername = this.authStore.myUsername
			_showWarningDialog.bind(this)(myUsername)
		} else {
			// Uncollect immediately if no lists or rooms were found
			onConfirm()
		}

		//
		//

		function _showWarningDialog(myUsername) {
			const count = itmIds.length
			const multipleItms = count > 1
			const lists = clusters.filter(cluster => cluster.type == 'list')
			const rooms = clusters.filter(cluster => cluster.type == 'room')
			const listsAndRooms = lists.length && rooms.length

			// Construct title
			const title =
				(listsAndRooms ? 'Some Rooms & Lists' : lists.length ? 'Some Lists' : 'Some Rooms') +
				' Affected'

			// Construct message
			const strItms = multipleItms ? 'One or more items' : 'This item'
			let message = strItms + ' will be removed from the following places'
			message = [`<p>${message}:</p>`]

			// List rooms
			if (rooms.length) {
				message.push('<b style="display:block;margin-top:0.1rem;margin-bottom:0.05rem">Rooms</b>')
				message.push('<ul>')
				rooms.forEach(room => {
					message.push(`<li><a target="_blank" href="/room/${room.id}">${room.name}</a></li>`)
				})
				message.push('</ul>')
			}

			// List lists
			if (lists.length) {
				message.push('<b style="display:block;margin-top:0.1rem;margin-bottom:0.05rem">Lists</b>')
				message.push('<ul>')
				lists.forEach(room => {
					message.push(
						`<li><a target="_blank" href="/${myUsername}/${room.namePath}">${room.name}</a></li>`
					)
				})
				message.push('</ul>')
			}

			message = message.join('')

			// Show comfirmation dialog
			this.dialogStore.confirm(onConfirm, message, {
				persistent: true,
				title,
				warning: true,
				html: true,
				onCancel,
			})
		}
	}

	// The Uncollect error handler is called from two separate spots.
	_handleUncollectError({ status, data, statusText, itmIds }) {
		const action =
			'removing' + (itmIds.length > 1 ? ' these items ' : ' this item ') + 'from your collection'
		handleApiError({ status, data, statusText, action })
	}
}
