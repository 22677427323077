<template>
	<!-- Regular architecture is needed to allows @click events in parent -->
	<router-link v-if="itm && disableCarousel" :to="itmUrl" class="img-wrap">
		<BaseImageArt :itm="itm" imgSize="thumb" :viewNr="viewNr" />
	</router-link>

	<!-- Slot architecture is needed to prevent link & allow carousel -->
	<router-link v-else-if="itm && !disableCarousel" :to="itmUrl" custom v-slot="{ href }">
		<a :href="href" @click="openCarousel" class="img-wrap">
			<BaseImageArt :itm="itm" :imgSize="imgSize" :viewNr="viewNr" />
		</a>
	</router-link>

	<a v-else class="img-wrap blank">
		<PixelTransparent class="art thumb" />
	</a>
</template>

<script>
// Components
import BaseImageArt from '@/components/BaseImageArt'
import PixelTransparent from '@/components/PixelTransparent'

// Stores
import { useCarouselStore } from '@/stores/CarouselStore'

// Internal
import { getItmUrl } from '@/use/ItmHelpers'

export default {
	name: 'ArtThumbSquare',
	components: {
		BaseImageArt,
		PixelTransparent,
	},
	props: {
		itm: Object,
		// Which artwork view to show
		viewNr: Number,
		// whether thumb should launch carousel
		disableCarousel: {
			type: Boolean,
			default: true,
		},
	},
	setup() {
		const carouselStore = useCarouselStore()
		return { carouselStore }
	},
	computed: {
		// Link to artwork or image page
		itmUrl() {
			return getItmUrl(this.itm, this.viewNr)
		},
	},
	methods: {
		openCarousel(e) {
			e.preventDefault()
			this.carouselStore.init(e.target, this.itm)
		},
	},
}
</script>

<style lang="scss" scoped>
// Link wrap
.img-wrap {
	flex-basis: 1.5rem;
	width: 1.5rem;
	height: 1.5rem;
	border-radius: $br;

	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;

	font-size: 0;
	line-height: 0;

	// Replace the default blue tap overlay
	-webkit-tap-highlight-color: $black-10;
}
.img-wrap picture {
	width: 100%;
	height: 100%;
}
.img-wrap:deep() img {
	width: 100%;
	height: 100%;

	user-select: none;
	background: $black-05;

	// // This prevents thumbnails being less wide than their wrapper. No longer needed I think
	// min-width: 100%;
	// min-height: 100%;
}
</style>
