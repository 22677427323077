<template>
	<div id="dev-guides">
		<div class="h" :style="{ top }">
			<div class="line-1 line blue"></div>
			<div class="line-2 line"></div>
			<div class="line-3 line blue"></div>
			<div class="line-4 line green"></div>
			<div class="line-5 line green"></div>
			<div class="band-1 line band"></div>
			<div class="band-2 line band yellow"></div>
			<div class="band-3 line band yellow"></div>
			<!-- <div class="line band"></div> -->
		</div>
		<div class="v">
			<div class="line-1 line blue"></div>
			<div class="line-2 line"></div>
			<div class="line-3 line"></div>
			<div class="line-4 line blue"></div>
		</div>
		<div class="stroke h"></div>
	</div>
</template>

<script>
import { usePageUserStore } from '@/stores/PageUserStore'
export default {
	name: 'DebugDevGuides',
	setup() {
		const pageUserStore = usePageUserStore()
		return { pageUserStore }
	},
	computed: {
		top() {
			if (this.$route.name.slice(0, 4) == 'User') {
				if (this.pageUserStore.user && !this.pageUserStore.user.isMe) {
					// return null
					return 1.8 + 'rem'
				}
			}
			return null
		},
	},
}
</script>

<style scoped lang="scss">
#dev-guides {
	opacity: 0.3;
	pointer-events: none;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1000;
}
#dev-guides .h,
#dev-guides .v {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
}
#dev-guides .line {
	background: red;
	position: absolute;
}
#dev-guides .h .line {
	width: 100%;
	height: 1px;
}
#dev-guides .v .line {
	height: 100%;
	width: 1px;
}
#dev-guides .blue {
	background: blue;
	opacity: 0.3;
}
#dev-guides .green {
	background: #090;
	opacity: 0.8;
}
#dev-guides .yellow {
	background: #cc0;
	opacity: 0.7;
}

// H
#dev-guides .h .line-1 {
	top: calc(var(--pp) - 0.1rem);
}
#dev-guides .h .line-2 {
	top: var(--pp);
}
#dev-guides .h .line-3 {
	top: calc(var(--pp) + 0.4rem);
}
#dev-guides .h .line-4 {
	top: calc(var(--pp) + 0.9rem);
	opacity: 1;
}
#dev-guides .h .line-5 {
	top: calc(var(--pp) + 1.8rem);
	opacity: 1;
}
#dev-guides .h .band-1 {
	height: 0.3rem;
	top: var(--pp);
	opacity: 0.2;
}
#dev-guides .h .band-2 {
	height: 0.3rem;
	top: calc(var(--pp) + 0.9rem);
	z-index: -1;
}
#dev-guides .h .band-3 {
	height: 0.3rem;
	top: calc(var(--pp) + 1.8rem);
	z-index: -1;
}
// #dev-guides .h .line.band:nth-child(7) {
// 	display: none;
// }

// V
#dev-guides .v .line-1 {
	left: calc(var(--pp) - 0.1rem);
}
#dev-guides .v .line-2 {
	left: var(--pp);
}
#dev-guides .v .line-3 {
	right: var(--pp);
}
#dev-guides .v .line-4 {
	right: calc(var(--pp) - 0.1rem);
}

@media only screen and (max-width: $mobile) {
	#dev-guides .h .line-4 {
		top: calc(var(--pp) + 0.8rem);
	}
	#dev-guides .h .line-5 {
		top: calc(var(--pp) + 1.7rem);
	}
	#dev-guides .h .band-2 {
		top: calc(var(--pp) + 0.8rem);
	}
	#dev-guides .h .band-3 {
		top: calc(var(--pp) + 1.7rem);
	}
}
</style>
