<template>
	<!-- <pre>{{ options }}</pre> -->
	<div class="act-menu" :class="{ dark, inverse }">
		<BaseIcon name="options" />
		<!-- $emit('select') -->
		<select v-model="myChoice" :disabled="disabled">
			<option
				v-for="(option, i) in options"
				:key="i"
				:value="option.value"
				:disabled="option.disabled"
				:hidden="option.hidden"
				>{{ option.display }}</option
			>
		</select>
	</div>
</template>

<script>
// Components
import BaseIcon from '@/components/BaseIcon'

// Internal
import { nonUserBlock } from '@/use/General'

export default {
	name: 'ActionMenu',
	components: { BaseIcon },
	props: {
		options: Array,
		disabled: Boolean,
		dark: Boolean,
		inverse: Boolean,
	},
	data() {
		return {
			myChoice: null,
		}
	},
	watch: {
		myChoice(newValue) {
			const option = this.options.find(option => option.value == newValue)
			if (!this.$isLoggedIn && option && option.wall) {
				// Block action when you're not logged in.
				nonUserBlock(option.wall)
			} else if (option && option.onSelect && typeof option.onSelect == 'function') {
				// Select
				option.onSelect()
			}
			this.myChoice = null
		},
	},
}
</script>

<style scoped lang="scss">
// Wrap
.act-menu {
	width: 0.5rem;
	height: 0.5rem;
	padding: 0.15rem;
	position: relative;
	cursor: pointer;
	// background: rgba(red, 0.1);
}

// Dark & inverse version
.act-menu.dark:deep() .icn,
.act-menu.inverse:deep() .icn {
	fill: $white-75;
}

// Dropdown
.act-menu select {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
	cursor: pointer;
}

@media (hover: hover) {
	.act-menu:hover:deep() .icn {
		fill: $primary;
	}

	// Inverse version
	.act-menu.inverse:hover:deep() .icn {
		fill: $primary-inverse;
	}
}
</style>
