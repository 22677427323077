<template>
	<div id="flash-alert-wrap">
		<transition-group name="pop" v-on:after-leave="maybeDeactivate">
			<!-- <button @click="show = !show">toggle</button> -->
			<!-- <div>{{ flashStore.flashes }}</div> -->
			<template v-if="show">
				<div class="collapsable-wrap" v-for="flash in flashStore.flashes" :key="flash.index">
					<FlashAlert :flash="flash" />
				</div>
			</template>
		</transition-group>
	</div>
</template>

<script>
// Stores
import { useFlashStore } from '@/stores/FlashStore'

// Components
import FlashAlert from '@/components/FlashAlert'

export default {
	name: 'TheFlashWrap',
	components: { FlashAlert },
	setup() {
		const flashStore = useFlashStore()
		return { flashStore }
	},
	data() {
		return {
			// Required to trigger the transition when
			// component gets activated in App.js
			show: false,
		}
	},
	mounted() {
		this.show = true
	},
	methods: {
		maybeDeactivate() {
			if (!this.flashStore.flashes.length) {
				this.flashStore.deactivate()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
#flash-alert-wrap {
	position: fixed;
	top: 0.2rem;
	right: 0.2rem;
	z-index: 25;
	max-width: calc(100% - 0.4rem);
	display: flex;
	flex-direction: column;
}
#flash-alert-wrap .collapsable-wrap {
	height: 0.57rem;
}

/* Transition */
.pop-enter-from {
	transform: translateY(0.05rem);
	opacity: 0;
}
.pop-enter-to,
.pop-leave-from {
	transform: translateY(0);
	opacity: 1;
}
.pop-leave-to {
	height: 0 !important;
	// margin-bottom: -0.6rem;
	transform: translateY(-0.05rem);
	opacity: 0;
}
.pop-enter-active {
	transition: all 150ms ease-out;
}
.pop-leave-active {
	transition: all 250ms ease-out;
}
</style>
