<template>
	<FloatActions :responsive="{ mobile: 0 }" />
	<TheWelcome :home="true" />
	<TheArtGrid feedName="staff-picks-random" />
</template>

<script>
// Components
import TheWelcome from '@/components/TheWelcome'
import TheArtGrid from '@/components/TheArtGrid'
import FloatActions from '@/components/FloatActions'

export default {
	name: 'HomePage',
	components: {
		TheWelcome,
		TheArtGrid,
		FloatActions,
	},
}
</script>

<style lang="scss" scoped></style>
