export default function({ text, elm }) {
	const dummy = document.createElement('textarea')
	const style = {
		position: 'absolute',
		width: '50px',
		height: '10px',
		left: '-100px',
		top: '-100px',
	}
	Object.keys(style).forEach(key => (dummy.style[key] = style[key]))
	document.body.appendChild(dummy)
	dummy.value = text
	dummy.select()
	dummy.setSelectionRange(0, 99999) /* For mobile devices */
	document.execCommand('copy')
	document.body.removeChild(dummy)

	// Blink feedback
	setTimeout(() => {
		// Need a zero timeout to ensure the blink is triggered.
		elm.classList.add('blink-copied')
	}, 0)
	setTimeout(() => {
		elm.classList.remove('blink-copied')
	}, 500)
}
