<template>
	<div class="act-select" :class="{ dark, color, on }">
		<BaseIcon :name="on ? (color ? 'select-on-color' : 'select-on') : 'select'" />
	</div>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'

export default {
	name: 'ActionSelect',
	components: { BaseIcon },
	props: {
		on: Boolean,
		dark: Boolean,
		color: Boolean, // Checked state will be green with white circle
	},
}
</script>

<style scoped lang="scss">
.act-select {
	width: 0.5rem;
	height: 0.5rem;
	padding: 0.15rem;
	position: relative;
	cursor: pointer;
}
.act-select:deep() .icn {
	position: relative;
	z-index: 1;
}

// Dark version
.act-select.dark:deep() .icn-select {
	stroke: $white-50;
}
.act-select.dark:deep() .icn-select-on {
	fill: $white-75;
}

// Color version
.act-select.color:deep() .icn-select {
	stroke: $white-50;
}
.act-select.color:deep() .icn-select-on {
	fill: $primary-darker;
}
.act-select.on.color {
	filter: drop-shadow(0 0 0.05rem $black-20);
}

@media (hover: hover) {
	.act-select:hover:deep() .icn-select {
		stroke: $primary;
	}
	.act-select:hover:deep() .icn-select-on {
		fill: $primary;
	}
	.act-select.color:hover:deep() .icn-select-on {
		fill: $good-dark;
	}
}
</style>
