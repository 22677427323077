<template>
	<!-- Join button that displays at bottom when you're scrolled up.  -->
	<div v-if="!['About', 'AboutTemp'].includes($route.name)" class="buttons-wrap" id="tease-btns">
		<FormButton
			v-if="!['Featured', 'Explore', 'About', 'HomeTemp'].includes($route.name)"
			id="tease-btn-home"
			:level="2"
			value="Home"
			@click="$router.push({ name: 'Home' })"
		/>
		<FormButton id="tease-btn-about" :level="2" value="What?" @click="$router.push({ name: 'About' })" />
		<FormButton
			v-if="$route.name != 'Home'"
			id="tease-btn-join"
			class="join"
			:class="{ virgin }"
			value="Join"
			@click="dialogStore.dialogByName('DialogAuth', { page: 'wait-list' })"
		/>
	</div>
</template>
<script>
// Stores
import { useDialogStore } from '@/stores/DialogStore'

// Components
import FormButton from '@/components/FormButton'

export default {
	name: 'TeaseButtons',
	components: {
		FormButton,
	},
	props: {
		isHomePage: Boolean,
	},
	setup() {
		const dialogStore = useDialogStore()
		return { dialogStore }
	},
	data() {
		return {
			virgin: true,
		}
	},
	computed: {},
	watch: {},
	mounted() {
		setTimeout(() => {
			this.virgin = false
		}, 3000)
	},
	methods: {},
}
</script>

<style scoped lang="scss">
#tease-btns {
	position: fixed;
	z-index: 4;
	bottom: 0.1rem;
	right: 0.1rem;
}
#tease-btns button {
	min-width: 0;
}
#tease-btns button.secondary {
	background: #fff;
}
#tease-btns button.primary:not(.inverse) {
	background: $black-opaque;
}
#tease-btns .join:not(:hover) {
	animation: pulse-join 2s ease-in-out infinite;
}
#tease-btns .join.virgin {
	animation: blink 200ms 5;
	animation-delay: 1s;
}
@media (hover: hover) {
	#tease-btns .join:hover {
		animation: pulse-join-hover 1000ms linear infinite;
	}
}

@keyframes pulse-join {
	0% {
		color: $white;
	}
	50% {
		color: $gold;
		background: $black-dark-opaque;
	}
	100% {
		color: $white;
	}
}
@keyframes pulse-join-hover {
	0% {
		background: #de2f66;
	}
	9.9% {
		background: #de2f66;
	}
	10% {
		background: #82296f;
	}
	19.9% {
		background: #82296f;
	}
	20% {
		background: #af3f48;
	}
	29.9% {
		background: #af3f48;
	}
	30% {
		background: #9a4a70;
	}
	39.9% {
		background: #9a4a70;
	}
	40% {
		background: #a18058;
	}
	49.9% {
		background: #a18058;
	}
	50% {
		background: #d198bc;
	}
	59.9% {
		background: #d198bc;
	}
	60% {
		background: #e44258;
	}
	69.9% {
		background: #e44258;
	}
	70% {
		background: #9b6898;
	}
	79.9% {
		background: #9b6898;
	}
	80% {
		background: #871479;
	}
	89.9% {
		background: #871479;
	}
	90% {
		background: #d7a489;
	}
	99.9% {
		background: #d7a489;
	}
}

/**
 * Responsive
 */

@media only screen and (max-width: $mobile) {
	// #tease-btn-about::after {
	// 	content: '?';
	// }
}
@media only screen and (min-width: $mobile_) {
	// #tease-btn-about::after {
	// 	content: ' is this';
	// }
	// #tease-btn-join::after {
	// 	content: ' wait list';
	// }
}
</style>
