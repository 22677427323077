// This is implemented once in App.vue and takes care of a page progress bar.
// Settings are defined in app.js
export default {
	mounted() {
		// Initial finish
		this.$Progress.finish()
	},
	created() {
		// Initial start
		this.$Progress.start()

		// Start
		this.$router.beforeEach((to, from, next) => {
			// When we have search or carousel open, we don't
			// want to get bothered by the progress bar.
			if (this.$gl.lockedRouteActive) return next()

			// Custom behavior per route.
			if (to.meta.progress !== undefined) {
				let meta = to.meta.progress
				this.$Progress.parseMeta(meta)
			}
			this.$Progress.start()
			next()
		})

		// Finish
		this.$router.afterEach(() => {
			this.$Progress.finish()
		})
	},
}
