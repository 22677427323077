<template>
	<div id="mast-head">
		<ActionLogo />
		<!-- <h1>Arthur</h1> -->
		<!-- <p class="soft">A digital museum</p> -->
		<h1>Arthur is a<br />Digital Museum</h1>
	</div>
</template>

<script>
import ActionLogo from '@/components/ActionLogo'

export default {
	name: 'TheLogoHeader',
	components: {
		ActionLogo,
	},
}
</script>

<style lang="scss" scoped>
#mast-head {
	margin-top: -10%;
}
#mast-head:deep() .act-logo {
	margin: 0 auto 0.3rem auto;
}
#mast-head h1 {
	text-align: center;
	// font-size: 0.3rem;
	margin-bottom: 0;
}
</style>
