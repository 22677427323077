// Vue
import { defineStore } from 'pinia'

// Modules
import atob_node from 'atob'
import Cookies from 'universal-cookie'

// Stores
import { useMeStore } from '@/stores/MeStore'
import { useSsrCookieStore } from '@/stores/SsrCookieStore'
import { useApiStore } from '@/stores/ApiStore'

// Internal
import { isSSR, isSSRClient } from '@/use/Base'
import flash from '@/use/FlashAlert'
import { cookieMaxAge } from '@/use/Helpers'

export const useAuthStore = defineStore('AuthStore', {
	// On initial page load we read the authToken from an http-only
	// cookie via the request headers (server.js) and update the store.
	// https://www.youtube.com/watch?v=w8n7Soz7khw
	state: () => {
		const r = Math.round(Math.random() * 100)
		const cookies = isSSR ? null : new Cookies()
		return {
			cookies,
			id: null,
			username: null,
			accessLevel: 0,
			authToken: isSSR ? null : window.localStorage.getItem('authToken') || null, // In development we can't read the http-only cookie so we use localStorage as fallback. // cookies.get('authToken') ||
			status: null, // Currently not using status, might be useful to avoid overlapping auth requests?
			version: 2, // Update version to force-clear auth store & cookie (when doing changes to auth logic)
			clientVersion: isSSR ? '' : cookies.get('authVersion') || null,
			test: r,
		}
	},
	getters: {
		isLoggedIn: state => {
			const { payload } = _decodeJWT(state.authToken)
			if (!payload) return false
			const { accessLevel } = payload
			return accessLevel > 0
		},
		isAdmin: state => state.accessLevel > 1,
		isSuperAdmin: state => state.accessLevel > 2,
		token: state => state.authToken,
		myUserId: state => state.id,
		myUsername: state => state.username,
		userData: state => ({
			id: state.id,
			accessLevel: state.accessLevel,
			authToken: state.authToken,
		}),
	},
	actions: {
		setTest(nr) {
			this.test = nr
		},
		// Initialize user data on initial app load.
		async initUser() {
			if (this.clientVersion && this.clientVersion != this.version) {
				// Updated auth logic:
				// Update authVersion cookie, clear store and log out.
				if (isSSR) {
					const ssrCookieStore = useSsrCookieStore()
					ssrCookieStore.addApiCookie({ authVersion: this.version })
				} else {
					this.cookies.set('authVersion', this.version, {
						path: '/',
						maxAge: cookieMaxAge,
					})
				}

				// Update auth client version
				this.clientVersion = this.version
				console.log('Outdated auth version')
				this.logout()
			} else if (this.authToken) {
				// Identify user based on authToken
				const { id, username, accessLevel } = _decodeJWT(this.authToken).payload
				this._init({ id, username, accessLevel })
			} else {
				// Continue as guest
				this._init({ accessLevel: 0 })
			}
			// Returning promise allows server.js to wait for initUser to be completed.
			return new Promise(resolve => {
				resolve()
			})
		},

		// Login / Signup --> DialogAuth.vue
		// Todo: move APi calls here.
		async storeLogin(response) {
			const { status, data } = response
			if (status == 200) {
				this._authSuccess({
					id: data.user.id,
					username: data.user.username,
					accessLevel: data.user.accessLevel,
					authToken: data.authToken,
				})
				const meStore = useMeStore()
				meStore.loadMyData(data.user.id)
			} else {
				this._authError()
			}
			return response
		},

		// Logout --> use/General.js
		async logout($router) {
			console.log('AuthStore - Log out')
			this.status = ''
			this.id = null
			this.accessLevel = 0
			this.authToken = ''
			if (!isSSR && !isSSRClient) window.localStorage.removeItem('authToken')

			// Delete authToken cookie.
			// Load API
			const apiStore = useApiStore()
			const userAuthApi = apiStore.loadApi('userAuth')
			await userAuthApi.logout()

			// Display flash.
			flash('You are now logged out. Goodbye.')

			// Redirect.
			if ($router && $router.currentRoute.value.meta.accessLevel > 0) {
				$router.push({ name: 'Home' })
			}
		},

		// Used whenever we need to change the authToken
		// › Log out
		// › Expired token
		// › First guest visit
		// › First user visit
		setAuthToken(authToken) {
			// console.log('SET_AUTH_TOKEN', authToken)
			this.authToken = authToken

			// On production we set a http-only cookie (/api/account/login)
			// but because we can't validate this using JS, we store the
			// authToken in localStorage for development authentication.
			if (!isSSR && !isSSRClient) window.localStorage.setItem('authToken', authToken)
		},

		// Used exclusively on initial page load to read client auth
		// version from cookie and store it in the store server-side.
		setAuthClientVersion(authClientVersion) {
			this.clientVersion = authClientVersion
		},

		// xxx
		_init({ id, username, accessLevel }) {
			this.status = 'success'
			this.id = id
			this.username = username
			this.accessLevel = accessLevel
		},

		// xxx
		_authSuccess({ id, username, accessLevel, authToken }) {
			this.status = 'success'
			this.id = id
			this.username = username
			this.accessLevel = accessLevel
			this.authToken = authToken

			// On production we set a http-only cookie (/api/account/login)
			// but because we can't read this using JS, we store the
			// authToken in localStorage for development authentication.
			if (!isSSR && !isSSRClient) window.localStorage.setItem('authToken', authToken)
		},

		_authError() {
			this.status = 'error'
		},
	},
})

// Decode JWToken without validating.
// This is 10x faster than authenticating it, and we're
// only using it for non-critical stuff on the client.
function _decodeJWT(t) {
	const atob = isSSR ? atob_node : window.atob
	let token = {}
	if (t) {
		t = t.replace(/^Bearer /, '')
		token.raw = t
		token.header = JSON.parse(atob(t.split('.')[0]))
		token.payload = JSON.parse(atob(t.split('.')[1]))
	}
	return token
}
