// Vue
import { defineStore } from 'pinia'

// Stores
import { useCarouselStore } from '@/stores/CarouselStore'
import { useSearchStore } from '@/stores/SearchStore'
import { useGlobalStore } from '@/stores/GlobalStore'

// Helpers
// import { domLog } from '@/use/Helpers'

export const useSessionStore = defineStore('SessionStore', {
	state: () => ({
		/**
		 * Browser Support
		 */

		isTouch: false,
		_webpSupport: null,
		_retina: null,

		// User agent
		ua: {
			chrome: false,
			android: false,
			safari: false, // Not yet used or implemented
			firefox: false, // Not yet used or implemented
		},

		/**
		 * Screen Information
		 */

		// Used by TheArtGrid.vue. It's stored here
		// because the FeedStore is reset regularly
		// and this should never be reset.
		availWidth: null, // Window minus side padding
		sidePadding: null, // Sidepadding is different on small/large screens
		scrollBarWidth: 0, // Used for nav

		/**
		 * Various
		 */

		// Once per session we show you the hidden prev/next buttons.
		virginCarousel: true,

		// When set to true, the router won't
		// scroll up while updating the route.
		// Used by teh carouselStore.
		maintainScrollPos: false,
	}),
	getters: {
		/**
		 * Browser Support
		 */

		// Whether the browser can display webp.
		webpSupport() {
			if (this._webpSupport === null) {
				this.storeSrcInfo()
				return this._webpSupport
			} else {
				return this._webpSupport
			}
		},
		// Whether the screen displays 2x images.
		retina() {
			if (this._retina === null) {
				this.storeSrcInfo()
				return this._retina
			} else {
				return this._retina
			}
		},
	},
	actions: {
		/**
		 * Browser Support
		 */
		setIsTouch() {
			this.isTouch = true
		},

		// Image source info is stored the
		// first time the getter is called.
		async storeSrcInfo() {
			if (typeof document === 'undefined') return // SSR

			// Check if there's any image already loaded
			const DOMImg = document.querySelector('img[src^="/img/art/"], img[src^="/img/art-nsfw/"]')

			// Unlikeable case of no DOM images / Old browser fallback
			if (!DOMImg || !DOMImg.currentSrc) {
				this._webpSupport = false
				this._retina = false
				return
			}

			this._webpSupport = !!DOMImg.currentSrc.match(/webp(\?dev=1)?$/)
			this._retina = DOMImg.currentSrc.match(/\/(small|medium|(x)?large)-2x\//)
		},

		// Store user agent (browser/device)
		storeUserAgent() {
			if (navigator.userAgentData && navigator.userAgentData.platform) {
				// New but not supported everywhere.
				// - - -
				// From a Chrome alert: Starting in Chrome 101,
				// the amount of information available in the
				// User Agent string will be reduced. Migrate
				// to navigator.userAgentData.
				const ua = navigator.userAgentData
				this.ua.android = ua.platform == 'Android'
				this.ua.chrome = !!ua.brands.find(b => b.brand == 'Google Chrome')
				// console.log(ua)
			} else {
				const ua = navigator.userAgent
				this.ua.android = !!ua.match(/Android/i)
				this.ua.chrome = !!ua.match(/chrome/i)
				// domLog(JSON.stringify(this.ua))
				// console.log(ua)
			}
		},

		/**
		 * Screen Information
		 */

		// Store width and padding so fitGrid() can use it.
		storePageWidth(screenWidth, gridSidePadding) {
			this.availWidth = screenWidth
			this.sidePadding = gridSidePadding
		},

		// Scrollbar width so navigation is neatly aligned.
		storeScrollBarWidth() {
			this.scrollBarWidth = window.innerWidth - document.body.clientWidth
		},

		/**
		 * Various
		 */

		// We show some carousel overlay (back/forward button)
		// only once when you open it the first time per session.
		unvirginCarousel() {
			this.virginCarousel = false
		},

		// When back button is pressed.
		onPopState(e) {
			// console.log('popstate')
			// e.preventDefault()
			// history.go(1)
			// return

			const carouselStore = useCarouselStore()
			const searchStore = useSearchStore()
			const globalStore = useGlobalStore()
			console.log('popstate')

			// Close the carousel / search on back button
			// - - -
			// If the new current route is the same as the lockedRoute
			// AND there's a forward state in the history, then we
			// can safely assume the user clicked the back button
			// and we should close the carousel / search.
			const routeIsLocked = globalStore.lockedRoute
			const historForwardExists = e.state && !!e.state.forward
			const sameRoutes =
				globalStore.lockedRoute && e.state && e.state.current == globalStore.lockedRoute.fullPath
			if (routeIsLocked && historForwardExists) {
				if (sameRoutes) {
					// console.log('reset overlay')
					if (carouselStore.isActive) carouselStore.close()
					if (searchStore.overlay) searchStore.deactivateOverlay()
				}
			}
		},

		// Block/unblock auto-scroll-to-top.
		toggleMaintainScrollPos(bool) {
			// console.log('lock scroll', bool)
			this.maintainScrollPos = bool
		},
	},
})
