<template>
	<div class="act-close" :class="{ small, tiny, dark, inverse, soft, inline }" @click="onClick">
		<BaseIcon name="x-close" />
	</div>
</template>

<script>
// Stores
import { useKeyStore } from '@/stores/KeyStore'

// Components
import BaseIcon from '@/components/BaseIcon'

export default {
	name: 'ActionClose',
	components: { BaseIcon },
	props: {
		to: Object,
		//
		small: Boolean,
		tiny: Boolean,
		dark: Boolean,
		inverse: Boolean,
		soft: Boolean,
		inline: Boolean,
	},
	setup() {
		const keyStore = useKeyStore()
		return { keyStore }
	},
	methods: {
		onClick() {
			if (!this.to) return
			if (this.keyStore.mod) {
				window.open(this.to.path)
			} else {
				this.$router.push(this.to)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.act-close {
	width: 0.5rem;
	height: 0.5rem;
	padding: 0.15rem;
	cursor: pointer;
}
.act-close:not(.inline) {
	position: absolute;
	top: 0;
	right: 0;
}

// Small version (currently unused, too small for tapping better to use negative margin)
.act-close.small {
	width: 0.3rem;
	height: 0.3rem;
	padding: 0.05rem;
}

// Tiny version
.act-close.tiny {
	width: 0.2rem;
	height: 0.2rem;
	padding: 0;
}

// Dark & inverse version
.act-close.dark:deep() .icn,
.act-close.inverse:deep() .icn {
	fill: $white-75;
}

// // Soft version
// .act-close.soft:deep() .icn {
// 	fill: $black-30;
// }

@media (hover: hover) {
	.act-close:hover:deep() .icn {
		fill: $primary;
	}

	// Inverse version
	.act-close.inverse:hover:deep() .icn {
		fill: $primary-inverse;
	}
}
</style>
