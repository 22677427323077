<template>
	<component
		:is="to && !editMode ? 'router-link' : 'div'"
		:to="to"
		class="label"
		:data-type="label.type"
		:data-id="label.id"
		:class="classes"
		@click="onClick"
	>
		{{ label.value }}
		<BaseIcon v-if="editMode" name="mark-bad" />
	</component>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'
// import flash from '@/use/FlashAlert'

export default {
	name: 'BaseLabel',
	components: {
		BaseIcon,
	},
	props: {
		label: {
			type: Object,
			required: true,
		},
		to: Object, // link
		editMode: Boolean,
	},
	emits: ['delete'],
	computed: {
		classes() {
			const classes = []
			if (this.editMode) {
				classes.push('edit-mode')
				// Substrate labels show darker in edit mode
				if (this.label.type == 'medium-substrate') {
					classes.push('substrate')
				}
			}
			return classes.join(' ')
		},
	},
	methods: {
		onClick() {
			if (this.editMode) {
				this.$emit('delete')
				// } else {
				// 	flash('Label search is coming soon.')
			}
		},
	},
}
</script>

<style scoped lang="scss">
.label {
	float: left;
	padding: 0 0.08rem;
	border-radius: $br;
	background: $black-05;
	height: 0.24rem;
	line-height: 0.24rem;
	font-size: small;
	display: flex;
	cursor: pointer;
}
a.label {
	color: $black;
}
.label.edit-mode {
	background: $black-50;
	color: #fff;
	padding-right: 0;
}
.label.edit-mode.flash {
	animation: blink 170ms 3;
}
// Darker labels for medium substrate
.label.edit-mode.substrate {
	background: $black;
}

.label:deep() .icn {
	flex: 0.2rem 0 0;
	margin: 0.02rem;
	stroke: #fff;
	stroke-width: 0.015rem;
}

@media (hover: hover) {
	.label:hover {
		background: $black;
		color: #fff;
	}
	.label.edit-mode:hover {
		background: $bad;
	}
}
</style>
