<template>
	<form @submit.prevent="login" novalidate class="structured-form">
		<FormText
			v-model="modelEmail"
			:error="errors.email"
			@update="resetError('email')"
			:autoFocus="true"
			label="Email"
			type="email"
			autocomplete="username"
		/>
		<FormText
			v-model="password"
			:error="errors.password"
			@update="resetError('password')"
			:focus="passwordFocus"
			label="Password"
			type="password"
			autocomplete="current-password"
			helper="Forgot password"
			@helperClick="forgotPassword"
		/>
		<p class="error-msg small" v-if="errors.general">
			{{ errors.general }}
		</p>
		<p class="ok-msg small" v-if="alreadyRegistered">
			Psst... You already have an account with us.
		</p>
		<div class="buttons-wrap">
			<FormButton :level="1" :wait="waiting" />
			<FormButton :level="2" value="Cancel" @click="$emit('cancel')" />
		</div>
	</form>
</template>

<script>
// Stores
import { useApiStore } from '@/stores/ApiStore'
import { useAuthStore } from '@/stores/AuthStore'

// Components
import FormText from '@/components/FormText'
import FormButton from '@/components/FormButton'

// Internal
import { processFormResponse } from '@/use/Helpers'
import flash from '@/use/FlashAlert'

export default {
	name: 'AuthLogin',
	components: {
		FormText,
		FormButton,
	},
	props: {
		email: String,
		alreadyRegistered: Boolean,
	},
	emits: ['update:email', 'alreadyRegistered', 'cancel', 'success'],
	setup() {
		const authStore = useAuthStore()
		const apiStore = useApiStore()
		const userAuthApi = apiStore.loadApi('userAuth')
		return { authStore, userAuthApi }
	},
	data() {
		return {
			// email: '',
			password: '',
			passwordConfirm: '',
			waiting: false, // Controls the waiting state of the submit buttons
			errors: {
				name: '',
				email: '',
				password: '',
				passwordConfirm: '',
				general: '',
			},
			passwordFocus: false, // To focus on password field
		}
	},
	computed: {
		// Email is a v-model value that's shared
		// between login/signup/waitlist.
		modelEmail: {
			get() {
				return this.email
			},
			set(val) {
				this.$emit('update:email', val)
			},
		},
	},
	watch: {
		email() {
			// This message should disappear when you edit email.
			this.$emit('alreadyRegistered', false)
		},
	},
	methods: {
		resetError(fieldName) {
			if (fieldName) this.errors[fieldName] = ''
			this.errors.general = ''
		},

		// Login
		async login() {
			this.waiting = true
			const response = await this.userAuthApi.login(this.email, this.password)
			this.authStore.storeLogin(response)
			processFormResponse(
				response,
				// Error
				errors => {
					this.waiting = false
					this.errors = errors
					this.$emit('alreadyRegistered', false)
				},
				// Success
				this.onSuccess
			)
		},

		// Login success
		onSuccess(data) {
			this.waiting = false
			this.$emit('success', data.user.name)
		},

		// Forgot password
		forgotPassword() {
			flash(
				[
					"Sorry, the forgot password functionality hasn't been built yet.",
					"Please email <a href='mailto:resetpassword@arthur.io'>resetpassword@arthur.io</a> for assistance.",
				].join('<br>'),
				{ type: 'error', persistent: true, html: true }
			)
		},
	},
}
</script>

<style lang="scss" scoped>
.error-msg,
.ok-msg {
	width: 100%;
	margin-top: -0.1rem;
	margin-bottom: 0.2rem;
}
.ok-msg {
	animation: blink 200ms linear 3;
}
</style>
