<template>
	<router-link
		:to="{
			name: 'Room',
			params: { id: room.id },
		}"
		class="room"
		:class="{ private: room.isPrivate }"
	>
		<div class="img-wrap">
			<BaseImageArt v-if="room.previewItm" :itm="room.previewItm" imgSize="small" />
			<template v-else>No images yet</template>
		</div>
		<div class="title-wrap">
			<b :title="room.nameTruncated != room.name ? room.name : null">
				<BaseFlag v-if="isMe && !room.isPublished" text="draft" />
				{{ room.nameTruncated }}
			</b>
			<BaseIcon v-if="isMe && room.isPrivate" name="lock" />
			<BaseCount v-else-if="!isMe" :count="room.artworkCount" />
		</div>
		<small v-if="isMe" class="soft">{{ room.artworkCount }} images</small>
		<small v-if="!isMe" class="soft">{{ room.owner.name }}</small>
	</router-link>
</template>

<script>
// A module that displays room information and one previe image.
// Used in /user/rooms and on itm pages amongst others.

// Components
import BaseImageArt from '@/components/BaseImageArt'
import BaseFlag from '@/components/BaseFlag'
import BaseIcon from '@/components/BaseIcon'
import BaseCount from '@/components/BaseCount'

export default {
	name: 'RoomPreview',
	components: {
		BaseImageArt,
		BaseFlag,
		BaseIcon,
		BaseCount,
	},
	props: {
		room: Object,
		isMe: Boolean,
	},
	data() {
		return {}
	},
	computed: {},
	created() {},
	methods: {},
}
</script>

<style scoped lang="scss">
.room {
	flex: 0 0 3rem;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	font-size: 0.16rem;
	color: $black;
	padding: 0.1rem;
	// background: #ff0;
}

// Image
.room .img-wrap {
	flex: 0 0 1.5rem;
	width: calc(100% + 0.2rem);
	border-radius: $br;
	overflow: hidden;
	background: $black-05;
	margin: -0.1rem;
	margin-bottom: 0.2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: $small;
	color: $black-30;
}
.room .img-wrap:deep() picture {
	flex-basis: 100%;
	height: 100%;
}
.room .img-wrap:deep() img {
	width: 100%;
	height: 100%;
}

// Title-wrap
.title-wrap {
	// background: #ff0;
	width: 100%;
	min-height: 0.2rem;
	display: flex;
	margin-bottom: 0.03rem;
	// border: solid 1px red;
}

// Title
.title-wrap b {
	flex-grow: 1;

	// Truncate text to max two lines (title)
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

// Draft flag
.title-wrap .flag {
	margin-right: 0.05rem;
	// margin-bottom: -0.02rem; // To not interfer with line-height
	position: relative;
	top: -0.01rem;
}

// Private icon
.title-wrap:deep() .icn {
	// background: pink;
	flex: 0 0 0.2rem;
	margin-left: 0.06rem;
	margin-right: -0.04rem;
	fill: $black-30;
}

/**
 * HOVER STATES
 */
@media (hover: hover) {
	.room:hover b {
		color: $primary;
	}
}
@media (hover: hover) and (min-width: $mobile_) {
	// .room:hover {
	// 	background: $white;
	// }
	// .room:hover .img-wrap {
	// 	border-bottom-right-radius: 0;
	// 	border-bottom-left-radius: 0;
	// }
	.room:hover:deep() img {
		transform: scale(1.1) rotate(3deg);
		transition: transform 100ms ease-out;
	}
}
</style>
