<template>
	<FormButton :level="1" value="Follow" :small="true" :inverse="inverse" @click="onClick" />
</template>
<script>
// Components
import FormButton from '@/components/FormButton'

// Internal
// import flash from '@/use/FlashAlert'
import { nonUserBlock, comingSoon } from '@/use/General'

export default {
	name: 'ButtonFollow',
	components: {
		FormButton,
	},
	props: {
		inverse: Boolean,
	},
	methods: {
		onClick() {
			if (!this.$isLoggedIn) {
				nonUserBlock('follow people')
			} else {
				comingSoon('Following people ')
			}
		},
	},
}
</script>

<style scoped lang="scss"></style>
