<template>
	<FormText
		v-model="photoCredit"
		label="Photo Credit"
		class="full-width"
		placeholder="Photographer name"
		helper="What is this?"
		@helperClick="showPhotoCreditAbout"
		maxlength="100"
		:mB="0"
	/>
</template>

<script>
import FormText from '@/components/FormText'
import flash from '@/use/FlashAlert'

export default {
	name: 'FormPhotoCredit',
	components: {
		FormText,
	},
	props: {
		modelValue: String,
	},
	computed: {
		// v-model
		photoCredit: {
			get() {
				return this.modelValue
			},
			set(val) {
				this.$emit('update:modelValue', val)
			},
		},
	},
	methods: {
		showPhotoCreditAbout() {
			flash('When the photographer of an artwork or design item requires their own credit.', {
				persistent: true,
			})
		},
	},
}
</script>

<style scoped lang="scss"></style>
