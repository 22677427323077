import { defineStore } from 'pinia'

export const useHttpErrorStore = defineStore('HttpErrorStore', {
	state: () => ({
		active: false,
		show: false,
		status: null,
		statusText: null,
		byLine: null,
		type: null,
	}),
	getters: {
		getError: state => state,
		isActive: state => state.active,
	},
	actions: {
		setError(error) {
			// Because httpErrors are called from a number of places, we log where it's originating.
			if (process.env.NODE_ENV != 'production') console.log('HTTP Error origin: ', error.origin)

			const defaultError = {
				statusText: 'Unknown Error',
				byLine: '',
				type: 'page',
			}
			error = { ...defaultError, ...error }

			// Set error
			const { status, statusText, byLine, type } = error
			this.active = true
			this.show = true
			this.status = status
			this.statusText = statusText
			this.byLine = byLine
			this.type = type
		},
		clearError() {
			console.log('clearError')
			this.active = false
			this.show = false
			this.status = null
			this.statusText = null
			this.byLine = null
			this.type = null
		},
		hide() {
			this.show = false
		},
	},
})
