// Vue
import { inject } from 'vue'
import { defineStore } from 'pinia'

// Stores
import { useMeStore } from '@/stores/MeStore'
import { useApiStore } from '@/stores/ApiStore'

// Internal
import { displayDimensions, getOptimalUnit } from '@/use/Dimensions'
import materialsText from '@/use/MaterialsText'
import { google, tumblr, pinterest } from '@/use/ExternalLinks'

export const usePageItmStore = defineStore('PageItmStore', {
	state: () => ({
		itm: null,
		artist: null,
		defaultFeedInfo: null, // { entityType, id }
		//
		imgLoading: true,
		previewLoading: true,
		// When you click a thumbnail of another itm or another view
		// from the same itm, we send the source here so we can preview
		// the lo-res version immediately upon click.
		loadingSrc: null,
	}),
	getters: {
		// Make sure we have everything before loading the ArtGrid
		renderGallery() {
			return this.artist && this.artist.feed && this.artist.feed.itms && this.artist.feed.itms.length
		},
		// Generate materials text (eg. Oil on canvas).
		materials() {
			if (!this.itm || !this.itm.labels) return null

			// Medium - may want to bring this back in another form!
			// It's awkward to display the medium (eg. "sculpture") as it is self-evident, so we don't.
			// const mediumMaterials = [titlefy(this.itm.medium), materialsStr].filter(v => !!v).join(' ~ ')

			// Free-form material string.
			if (this.itm.materialsFF) return this.itm.materialsFF

			// Generated materials string
			const labels = [] //this.itm.labels.filter(label => label.type == 'material' || label.type == 'substrate')
			return labels.length ? materialsText(labels) : null
		},
		dimensions() {
			if (!this.itm) return null

			// Dimensions
			let dimensions = ''
			if (this.itm.dim) {
				const $imperial = inject('$imperial')
				const unit = getOptimalUnit(this.itm.dim, $imperial)
				dimensions = displayDimensions(this.itm.dim, unit)
			}

			return dimensions
		},
		labels() {
			if (!this.itm) return null

			// If a medium is set, we display it as an extra label.
			if (this.itm.medium && this.itm.medium != 'other') {
				const mediumLabel = {
					value: this.itm.medium,
					type: 'medium',
				}
				return [mediumLabel].concat(this.itm.labels)
			} else {
				return this.itm.labels
			}
		},
		hasMeta() {
			if (!this.itm) return false
			return (
				this.materials || this.dimensions || (this.labels && this.labels.length) || this.itm.caption
			)
		},
		copyrightYear() {
			return this.itm && this.itm.year && this.itm.year[0] ? this.itm.year[0] : new Date().getFullYear()
		},
		googleUrl() {
			const name = this.artist ? this.artist.name : ''
			return google(name)
		},
		tumblrUrl() {
			const name = this.artist ? this.artist.name : ''
			return tumblr(name)
		},
		pinterestUrl() {
			const name = this.artist ? this.artist.name : ''
			return pinterest(name)
		},

		/**
		 * Containing Lists & rooms
		 * - - -
		 * Any updates here should be mirrored in the carouselStore.
		 */

		// The list ids of all lists in which the focus itm is present.
		allContainingLists() {
			return this.itm && this.itm.listIds ? this.itm.listIds : []
		},

		// The room ids of all rooms in which the focus itm is present.
		allContainingRooms() {
			return this.itm && this.itm.roomIds ? this.itm.roomIds : []
		},

		// The list ids of my own lists in which the focus itm is present.
		containingLists() {
			if (!this.allContainingLists.length) return []
			const meStore = useMeStore()
			if (!meStore.listIds.length) return []
			const containingLists = []
			meStore.listIds.forEach(id => {
				if (this.allContainingLists.includes(id)) {
					containingLists.push(id)
				}
			})
			return containingLists
		},

		// The room ids of my own rooms in which the focus itm is present.
		containingRooms() {
			if (!this.allContainingRooms.length) return []
			const meStore = useMeStore()
			if (!meStore.roomIds.length) return []
			const containingRooms = []
			meStore.roomIds.forEach(id => {
				if (this.allContainingRooms.includes(id)) {
					containingRooms.push(id)
				}
			})
			return containingRooms
		},

		// containingLists & containingRooms combined
		containingClusterCount() {
			return this.containingRooms.length + this.containingLists.length
		},
	},
	actions: {
		/**
		 * API Calls
		 * - - -
		 * TO DO: After going back and forth on this (and inconsistent implementation)
		 * I think API calls should happen from within the store. So instead of
		 * storeItm and storeArtist this should change into fetchItm and fetchArtist
		 */

		// Store itm
		storeItm(itm) {
			this.itm = itm
		},

		// Store artist
		storeArtist(artist) {
			this.artist = artist
		},

		// When you land on an itm page and you open the carousel,
		// we'll be loading the default feed. When there's an artist,
		// this is usually the artist, but for images we'll fall back
		// to the first room, list or user we find. This is called on
		// page load so we'll know what feed to load.
		// - - -
		// Later on this could get more advanced,and depend on the previous
		// page, the reputation of the room/list/user in question etc.
		async fetchDefaultFeedInfo() {
			if (!this.itm) return
			const apiStore = useApiStore()
			const itmApi = apiStore.loadApi('itm')
			const { status, data } = await itmApi.fetchItmDefaultFeedInfo(this.itm.id)
			if (status == 200 && data) {
				const { entityType, id, index } = data
				this.defaultFeedInfo = { entityType, id }
				// Index is stored in the itm itself.
				this.itm.index = index
			} else {
				this.defaultFeedInfo = {}
			}
		},

		/**
		 * Actions
		 */

		markCollected() {
			this.itm.collected = true
		},
		markUncollected() {
			this.itm.collected = false
		},
		addClusterIds({ listIds, roomIds }) {
			this.itm.listIds = this.itm.listIds ? this.itm.listIds.concat(listIds) : listIds
			this.itm.roomIds = this.itm.roomIds ? this.itm.roomIds.concat(roomIds) : roomIds
		},
		clearClusterIds() {
			delete this.itm.listIds
			delete this.itm.roomIds
		},

		/**
		 * Loading
		 */
		startLoading() {
			// console.log('startLoading')
			this.imgLoading = true
		},
		endLoading() {
			// console.log('endLoading')
			this.imgLoading = false
		},
		startPreviewLoading() {
			// console.log('startPreviewLoading')
			this.previewLoading = true
		},
		endPreviewLoading() {
			// console.log('endPreviewLoading')
			this.previewLoading = false
		},
		setLoadingSrc(src) {
			// console.log('setLoadingSrc', src)
			this.loadingSrc = src
		},
	},
})
