// Vuew
import { defineStore } from 'pinia'

// Stores
import { useApiStore } from '@/stores/ApiStore'
// import { usePageUserStore } from '@/stores/PageUserStore'

// Modules
import Cookies from 'universal-cookie'

// Internal
import { isSSR } from '@/use/Base'

export const useMeStore = defineStore('MeStore', {
	state: () => {
		const cookies = isSSR ? null : new Cookies()
		return {
			test: Math.random(),
			id: '',
			name: '',
			username: '',
			//
			listIds: [],
			roomIds: [],
			activeLists: [], // All lists (later: lists that are not archived)
			activeRooms: [], // Unpublished rooms
			//
			email: '',
			legacy: null, // Curiator users
			imperial: isSSR ? '' : cookies.get('imperial') == 'true', // Whether to display units in imperial system
			showLegacyWelcome: null, // Displays legacy users welcome message.
			// endorse: null,
		}
	},
	actions: {
		// Load my data
		// This is not widely used, but whenever we need to display this user's name it comes in handy.
		async loadMyData(myUserId) {
			if (!myUserId) return
			const apiStore = useApiStore()
			const userApi = await apiStore.loadApi('user')
			const { status, data } = await userApi.get(myUserId)
			if (status == 200) {
				// Store my data
				this.id = data.user.id
				this.name = data.user.name
				this.username = data.user.username
				this.email = data.user.email
				this.legacy = data.user.legacy
				this.showLegacyWelcome = data.user.showLegacyWelcome
				// this.endorse = data.user.endorse
			}

			// Load my lists & rooms.
			this.loadMyClusters()
		},

		// Load lists & rooms
		async loadMyClusters() {
			if (!this.id) return
			// const pageUserStore = usePageUserStore()
			const apiStore = useApiStore()
			const userApi = await apiStore.loadApi('user')
			const { status, data } = await userApi.getMyClusters(this.id)
			if (status == 200) {
				// Store my cluster data
				this.listIds = data.listIds
				this.roomIds = data.roomIds
				this.activeLists = data.activeLists
				this.activeRooms = data.activeRooms

				// // Update the userStore if you're on your own profile.
				// if (pageUserStore && pageUserStore.user && pageUserStore.user.id == this.id) {
				// 	pageUserStore.updateClusters({
				// 		listIds: data.listIds,
				// 		roomIds: data.roomIds,
				// 		lists: data.activeLists,
				// 		// %% This is overwriting my rooms with my active rooms.
				// 		// Need to rethink how we're handling refreshing rooms
				// 		rooms: data.activeRooms,
				// 	})
				// }
			}
		},

		// When you create a new list or room, we have to update the list.
		addCluster(newCluster) {
			const { type } = newCluster
			if (type == 'room') {
				this.roomIds.unshift(newCluster.id)
				this.activeRooms.unshift(newCluster)
			} else if (type == 'list') {
				this.listIds.unshift(newCluster.id)
				this.activeLists.unshift(newCluster)
			}
		},

		// Dismiss legacy users welcome message.
		dismissLegacyWelcome() {
			this.showLegacyWelcome = false
		},

		// Set imperial unit preference
		setImperial(imperial) {
			this.imperial = imperial
		},
	},
})
