<template>
	<div id="loader-anim">
		<div class="wrap-wrap">
			<div class="wrap">
				<div class="dot"><div></div></div>
				<div class="dot"><div></div></div>
				<div class="dot"><div></div></div>
				<div class="dot"><div></div></div>
				<div class="dot"><div></div></div>
				<div class="dot"><div></div></div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
#loader-anim {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	min-width: 0.7rem;
	min-height: 0.7rem;
}

// Slowly rotating wrapper
.wrap-wrap {
	display: inline-block;
	width: 0.7rem;
	height: 0.7rem;
	position: relative;
	animation: loader-rotate-continuous 6s infinite linear;
}

// Jerky rotating wrapper
.wrap {
	width: 100%;
	height: 100%;
	position: absolute;
	animation: loader-rotate-jerk 2s infinite ease-in-out;
}

// Structure
.dot {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}
.dot > div {
	left: 0.35rem;
	top: 0.25rem;
	width: 0.08rem;
	height: 0.08rem;
	background: $black;
	border-radius: 0.04rem;
	position: absolute;
	margin-top: -0.04rem;
	margin-left: -0.04rem;
}
.dark .dot > div {
	background: $white-50;
}

// Positioning
.wrap .dot:nth-child(2) {
	transform: rotate(120deg);
}
.wrap .dot:nth-child(3) {
	transform: rotate(-120deg);
}

.wrap .dot:nth-child(4) {
	transform: rotate(60deg);
}
.wrap .dot:nth-child(5) {
	transform: rotate(180deg);
}
.wrap .dot:nth-child(6) {
	transform: rotate(-60deg);
}

// Animation
.wrap .dot:nth-child(1) > div,
.wrap .dot:nth-child(2) > div,
.wrap .dot:nth-child(3) > div {
	animation: dot-cycle-a 4s infinite;
}
.wrap .dot:nth-child(4) > div,
.wrap .dot:nth-child(5) > div,
.wrap .dot:nth-child(6) > div {
	animation: dot-cycle-b 4s infinite;
}

@keyframes dot-cycle-a {
	0% {
		transform: translateY(0);
		animation-timing-function: cubic-bezier(0.32, 0, 0.67, 0);
	}
	25% {
		transform: translateY(-0.15rem);
		animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
	}
	50% {
		transform: translateY(-0.1rem);
		animation-timing-function: cubic-bezier(0.32, 0, 0.67, 0);
	}
	75% {
		transform: translateY(0);
		animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes dot-cycle-b {
	0% {
		transform: translateY(-0.1rem);
		animation-timing-function: cubic-bezier(0.32, 0, 0.67, 0);
	}
	25% {
		transform: translateY(0);
		animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
	}
	50% {
		transform: translateY(0);
		animation-timing-function: cubic-bezier(0.32, 0, 0.67, 0);
	}
	75% {
		transform: translateY(-0.15rem);
		animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
	}
	100% {
		opacity: 1;
		transform: translateY(-0.1rem);
	}
}

@keyframes loader-rotate-continuous {
	0% {
		transform: rotate(0deg) scale(0.5);
	}
	100% {
		transform: rotate(360deg) scale(0.5);
	}
}
@keyframes loader-rotate-jerk {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(120deg);
	}
}
</style>
