<template>
	<button
		class="button"
		:type="level == 1 ? 'submit' : 'button'"
		:class="{ wait, icon, small, inverse, error, primary, secondary, tertiary }"
		@click="blur"
		:style="style"
	>
		<BaseIcon v-if="icon" :name="icon" :color="theIconColor" />
		{{ theValue }}
	</button>
</template>

<script>
import FormItemsStyle from '@/mixins/mx_FormItemsStyle'
import BaseIcon from '@/components/BaseIcon'

export default {
	name: 'FormButton',
	components: { BaseIcon },
	mixins: [FormItemsStyle],
	props: {
		icon: String,
		iconColor: String,
		value: {
			type: [Array, String],
			default: 'Submit',
		},
		wait: Boolean,
		small: Boolean,
		// 1/2/3 = Primary / Secondary / Tertiary
		level: {
			type: Number,
			default: 1,
		},
		inverse: Boolean,
		error: Boolean,
	},
	computed: {
		// Dynamic value must be an array with [defaultValue, waitValue]
		theValue() {
			return Array.isArray(this.value) && this.value.length == 2
				? this.wait
					? this.value[1]
					: this.value[0]
				: this.value
		},
		primary() {
			return this.level == 1
		},
		secondary() {
			return this.level == 2
		},
		tertiary() {
			return this.level == 3
		},
		theIconColor() {
			return this.iconColor || (this.primary ? '#fff' : null)
		},
	},
	methods: {
		blur() {
			document.activeElement.blur()
		},
	},
}
</script>

<style lang="scss" scoped>
.button {
	min-width: 1rem;
	height: 0.4rem;
	color: $black;
	border: none;
	border-radius: $br;
	padding: 0 0.16rem;
	font-family: 'Barlow', sans-serif;
	font-size: 0.16rem;
	line-height: 0.4rem;
	position: relative;
	cursor: pointer;
}

// Primary
.button.primary {
	color: #fff;
	background-color: $black;
}
.button.secondary {
	background-color: $black-05;
}
// Secondary
.button.tertiary {
	background: transparent;
}

// Error state
.button.error {
	color: #fff;
	background: $bad;
}

// Disabled state
.button:disabled {
	cursor: not-allowed;
}
.button.primary:disabled {
	opacity: 0.3;
}
.button:not(.primary):disabled {
	color: $black-30;
}

// Small
.button.tiny,
.button.small {
	height: 0.3rem;
	line-height: 0.2rem;
	min-width: auto;
	padding: 0 0.1rem;
}

// Inverse colors
.button.primary.inverse {
	background-color: $white;
	color: $black;
}
.button.secondary.inverse {
	color: $white;
	background-color: $white-20;
}
.button.tertiary.inverse {
	color: $white;
}

// Optional icon
.button:deep() svg.icn {
	position: absolute;
	top: 0.1rem;
	left: 0.1rem;
}
.button.icon {
	padding-left: 0.36rem;
}

// Active state
.button:active {
	background-color: $black-15;
}
.button.primary:active {
	background-color: $primary-dark;
}

// Success response
.button.success {
	animation: btn-blink 200ms linear 3;
}

// Error state
.button:not(.wait).error {
	background: $bad;
}

// Waiting state
.button::before {
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	transition: opacity 300ms linear;
	transition-delay: 200ms;
}
.button.wait {
	pointer-events: none;
}
.button.wait::before {
	// #AAA stripes
	background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjMwcHgiIGhlaWdodD0iMzBweCIgdmlld0JveD0iMCAwIDMwIDMwIj48cmVjdCBmaWxsPSIjYWFhIiB4PSIyLjIiIHk9Ii0xNC45IiB0cmFuc2Zvcm09Im1hdHJpeCgwLjcwNzEgMC43MDcxIC0wLjcwNzEgMC43MDcxIDcuNSAtMy4xMDY2KSIgd2lkdGg9IjEwLjYiIGhlaWdodD0iNDQuNyIvPjxyZWN0IGZpbGw9IiNhYWEiIHg9IjE3LjIiIHk9IjAuMSIgdHJhbnNmb3JtPSJtYXRyaXgoMC43MDcxIDAuNzA3MSAtMC43MDcxIDAuNzA3MSAyMi41IC05LjMxOTgpIiB3aWR0aD0iMTAuNiIgaGVpZ2h0PSI0NC43Ii8+PC9zdmc+)
		0 0 repeat;
	animation: btn-wait 1s linear infinite;
	opacity: 0.2;
}
.button.wait::after {
	content: '...';
}

@media (hover: hover) {
	// Focus
	.button:not(:disabled):not(.wait):focus::after {
		content: '';
		position: absolute;
		top: 0.04rem;
		bottom: 0.04rem;
		left: 0.04rem;
		right: 0.04rem;
		border: solid 0.01rem $black-10;
	}
	.button.primary:not(:disabled):focus::after {
		border-color: $white-30;
	}

	// Hover
	.button.primary:not(.wait):not(:disabled):hover {
		background-color: $primary;
	}
	.button.secondary:not(.wait):not(:disabled):hover {
		background-color: $black-15;
	}
	.button.tertiary:not(.wait):not(:disabled):hover {
		background-color: $black-05;
	}

	// Error hover
	.button:not(.wait):not(:disabled).error:hover {
		background-color: $bad-dark;
	}

	// Inverse hover
	.button:not(.wait):not(:disabled).inverse.secondary:hover {
		background-color: $white-40;
	}
	.button:not(.wait):not(:disabled).inverse.tertiary:hover {
		background-color: $white-05;
	}
}
</style>
