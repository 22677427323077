import { titlefy } from '@/use/StringMagic'

// List of categories.
// Keep in sync with backend categories (/helpers/categories)
const categories = {
	art: 'artist',
	photography: 'photographer',
	illustration: 'illustrator',
	'product-design': 'product designer',
	'graphic-design': 'graphic designer',
	fashion: 'fashion designer',
	jewelry: 'jewelry designer',
	tattoo: 'tattoo artist',
	architecture: 'architect',
	cgi: 'CGI artist',
	other: 'other',
}

export default {
	// Get list of categories
	get() {
		return Object.keys(categories)
	},

	// Renders dropdown data
	dropdown() {
		return Object.entries(categories).map(([value, display]) => ({ value, display: titlefy(display) }))
	},

	// Check if a category is valid
	validate(string) {
		return Object.keys(categories).includes(string)
	},

	// Parse category into personified version
	personify(cat) {
		return categories[cat]
	},
}
