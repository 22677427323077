<template>
	<div class="key">{{ text }}</div>
</template>

<script>
export default {
	name: 'BaseKey',
	components: {},
	props: {
		text: String,
	},
}
</script>

<style scoped lang="scss">
.key {
	font-size: $small;
	text-transform: uppercase;
	height: 0.24rem;
	line-height: 0.22rem;
	padding: 0 0.06rem;
	color: $black-15;
	border: dashed 1px $black-10;
	border-radius: $br;
	display: inline-block;
}
</style>
