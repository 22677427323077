// Vue
import { nextTick } from 'vue'
import { defineStore } from 'pinia'

// Stores
import { useFeedStore } from '@/stores/FeedStore'
import { useSessionStore } from '@/stores/SessionStore'
import { useRouteStore } from '@/stores/RouteStore'
import { useApiStore } from '@/stores/ApiStore'
import { useMeStore } from '@/stores/MeStore'

// Internal
import { parseSrc } from '@/use/General'
import { imgPlaceholder } from '@/use/ImgPlaceholder'
import { preloadImages } from '@/use/PreloadImages'
import { lockScroll } from '@/use/Helpers'
// import { domLog } from '@/use/Helpers'

export const useCarouselStore = defineStore('CarouselStore', {
	state: () => ({
		// All itms in the slideshow.
		// Itms that are not loaded yet will be represented
		// by an undefined value, so the length of this
		// array will always be equal to the total itms.
		itms: [],
		itmsLoaded: false,

		// Parallel to itms, this array stores the lo-res src that we load below.
		loRes: [],

		// Index in relation to the entire feed.
		// --> Page number (+1)
		index: null,

		// Info on what feed to load.
		// { entityType, id }
		defaultFeedInfo: null,

		// Total number of slides.
		total: null,

		// This is the image size we display the images at in the carousel.
		// Down the line we may want to be smarter about this depending on
		// the screen real estate.
		displaySize: 'large',

		// What type of source the browser uses.
		// webp/webp2x/jpg/jpg2x
		srcType: null,

		// Toggle that controls the carousel activation.
		isActive: false,

		// Blocks pagination until itms are loaded.
		readyForPagination: false,

		// Feed identifier used for non-entity feeds to tell
		// the API what images to load. Also used to generate feedTitle.
		feedName: null,

		// Infinite feeds like Staff Picks, Random and Recent are paginated
		// relatively to where they were started by the user, otherwise the
		// number would change constantly while you're cycling trough.
		infinite: false,

		// For infinite feeds, we calculate the index for newly
		// loaded itms by adding the pageSize multiplied by the batch number.
		// These infinite feeds only load forward so this is only used for next().
		batchNr: 0,

		// This is true during when we're process the pagination update.
		// This is very brief but because we use requestAnimationFrame
		// and a promise, it can take a few miliseconds and this flag
		// makes sure we're loading the correct next/prev image.
		processing: false,

		// The 15 itms rendered in the carousel.
		carouselItms: [],

		// A transparent image placeholder per slide.
		imgPlaceholders: [],

		// The clicked image.
		target: null,

		// The HTML elm containing the slides.
		// We cycle via DOM for consistency.
		ref_slidesWrap: null,

		// Loader that shows on top of clicked thumbnail
		// until we're ready to expand it.
		loadingFirstItm: false,
		loaderPos: { x: 0, y: 0 },

		// Individual loaders for:
		// - Main image
		imgLoaders: new Array(15).fill(true),
		// - Preview image
		previewLoaders: new Array(15).fill(true),
		// - Transparent placeholder image
		placeholderLoaders: new Array(15).fill(true),
		// - Itm info
		itmLoaders: new Array(15).fill(false),

		// Flag set to true while new batch of itms is being fetched from API.
		loadingNewItms: false,

		// The middle slide that is visible in the browser.
		// This is a static value but using it as a variable
		// so it's clear where this seven comes from.
		mid: 7,

		// Only show prev/next first time.
		showPrevNext: null,
	}),
	getters: {
		feedTitle() {
			// Only non-entities have feedNames.
			// Entities have an entityType + id
			const titles = {
				all: 'Recent',
				'staff-picks': 'Staff Picks',
				'staff-picks-random': 'Random',
			}
			return titles[this.feedName] || this.feedName
		},
		ref_slides() {
			return this.ref_slidesWrap ? this.ref_slidesWrap.children : null
		},
		// The itm that is currently visible in the viewport
		focusItm() {
			return this.itmsLoaded ? this.itms[this.index] : {}
		},

		// When there's less tahn 15 itms in the feed, we fill up the carousel with blanks.
		blanksLeft: state => (state.total < 15 ? Math.ceil((15 - state.total) / 2) : 0),
		blanksRight: state => (state.total < 15 ? Math.floor((15 - state.total) / 2) : 0),

		// When the carousel gets to an index that's less than 10 above
		// the start of the endSlice array, we are ready to load the prev page.
		readyForPrevPage() {
			// console.log(
			// 	'readyForPrevPage',
			// 	!this.status.complete,
			// 	!this.loadingNewItms,
			// 	this.status.sliceB.length && this.index <= 10 - this.status.sliceB.length,
			// 	this.status.sliceB.length &&
			// 		this.index > this.status.sliceB[0].index &&
			// 		this.index <= this.status.sliceB[0].index + 10,
			// 	!this.status.sliceB.length &&
			// 		this.status.sliceA.length &&
			// 		this.index <= this.status.sliceA[0].index + 10
			// )
			return (
				!this.status.complete &&
				!this.loadingNewItms &&
				((this.status.sliceB.length && this.index <= 10 - this.status.sliceB.length) ||
					(this.status.sliceB.length &&
						this.index > this.status.sliceB[0].index &&
						this.index <= this.status.sliceB[0].index + 10) ||
					(!this.status.sliceB.length &&
						this.status.sliceA.length &&
						this.index <= this.status.sliceA[0].index + 10))
			)
		},

		// When the carousel gets to an index that's less than 10 below
		// the end of the itms array, we are ready to load the next page.
		readyForNextPage() {
			// console.log(
			// 	'readyForNextPage:',
			// 	!this.status.complete,
			// 	!this.loadingNewItms,
			// 	!!this.status.sliceA.length,
			// 	this.status.sliceA.length
			// 		? `${this.index} >= ${this.status.sliceA[this.status.sliceA.length - 1].index - 10}`
			// 		: false
			// )
			return (
				!this.status.complete &&
				!this.loadingNewItms &&
				this.status.sliceA.length &&
				this.index >= this.status.sliceA[this.status.sliceA.length - 1].index - 10
			)
		},

		// Status object holding { sliceA, sliceB, complete }
		//
		// Example: [0,1,2,undefined,undefined,5,6,7]
		// --> sliceA = [0,1,2]
		// --> sliceB = [5,6,7]
		// --> Complete: false
		//
		// Example: [undefined,undefined,2,3,4,5,undefined,undefined]
		// --> sliceA: [2,3,4,5]
		// --> sliceB: []
		// --> Complete: false
		//
		// Example: [0,1,2,3,4,5,6,7]
		// --> sliceA: [0,1,2,3,4,5,6,7]
		// --> sliceB: []
		// --> Complete: true
		status() {
			// Slice off front slice.
			const gapIndex = this.itms.findIndex(itm => !itm)

			if (this.infinite) {
				// Infinite feed (incomplete itms array)
				return {
					sliceA: this.itms,
					sliceB: [],
					complete: false,
				}
			} else if (gapIndex == -1) {
				// Finite feed, complete itms array with no gap --> all itms are loaded
				return {
					sliceA: this.itms,
					sliceB: [],
					complete: true,
				}
			} else {
				// Finite feed, complete itms array with a gap --> feed is incomplete
				const sliceAStart = this.itms.findIndex(itm => !!itm)
				let sliceA = this.itms.slice(sliceAStart)
				const sliceAEnd = sliceA.findIndex(itm => !itm)
				let sliceB = sliceA.splice(sliceAEnd)
				let sliceBStart = sliceB.findIndex(itm => !!itm)
				if (sliceBStart > -1) {
					sliceB.splice(0, sliceBStart)
				} else {
					sliceB = []
				}

				return { sliceA, sliceB, complete: false }
			}
		},

		/**
		 * Containing Lists & rooms
		 * - - -
		 * Any updates here should be mirrored in the pageItmStore.
		 */

		// The list ids of all lists in which the focus itm is present.
		focusItmAllContainingLists() {
			return this.focusItm && this.focusItm.listIds ? this.focusItm.listIds : []
		},

		// The room ids of all rooms in which the focus itm is present.
		focusItmAllContainingRooms() {
			return this.focusItm && this.focusItm.roomIds ? this.focusItm.roomIds : []
		},

		// The list ids of my own lists in which the focus itm is present.
		focusItmContainingLists() {
			if (!this.focusItmAllContainingLists.length) return []
			const meStore = useMeStore()
			if (!meStore.listIds.length) return []
			const containingLists = []
			meStore.listIds.forEach(id => {
				if (this.focusItmAllContainingLists.includes(id)) {
					containingLists.push(id)
				}
			})
			return containingLists
		},

		// The room ids of my own rooms in which the focus itm is present.
		focusItmContainingRooms() {
			if (!this.focusItmAllContainingRooms.length) return []
			const meStore = useMeStore()
			if (!meStore.roomIds.length) return []
			const containingRooms = []
			meStore.roomIds.forEach(id => {
				if (this.focusItmAllContainingRooms.includes(id)) {
					containingRooms.push(id)
				}
			})
			return containingRooms
		},

		// containingLists & containingRooms combined
		focusItmContainingClusterCount() {
			return this.focusItmContainingRooms.length + this.focusItmContainingLists.length
		},
	},
	actions: {
		/**
		 * Initialization
		 */

		// Import slide-wrap ref onMounted()
		setRefSlideWrap(elm) {
			this.ref_slidesWrap = elm.value
		},

		// Load first image and activate slideshow.
		async init(target, itm, defaultFeedInfo) {
			// Load stores
			const sessionStore = useSessionStore()
			const feedStore = useFeedStore()

			// Flash hidden prev/next buttons, but only if this is
			// the first time you open the carousel per session.
			// Fade is handled by CSS.
			this.showPrevNext = sessionStore.virginCarousel
			window.requestAnimationFrame(() => {
				this.showPrevNext = false
				sessionStore.unvirginCarousel()
			})

			// Store the targetSrc to activate the transition animation.
			this.target = target

			// Set the pagination index.
			let itmIndex = target.getAttribute('data-index')
			this.index = Number(itmIndex)
			this.total = feedStore.total // Will remain undefined when opening from non-feeds.

			// Load the first image first. Once that's done,
			// loadRestOfFeed is triggered from witin TheCarousel.vue.
			this.itms = [itm]
			this.carouselItms = [...Array(7), itm, ...Array(7)]
			this._generateOnePlaceholder(this.mid)

			// Open.
			this.__activate()

			// We store the lockedRoute which triggers a route override (see App.vue)
			// which allows us to change the URL without updating the page.
			sessionStore.toggleMaintainScrollPos(true)
			const routeStore = useRouteStore()
			routeStore.lockRoute()
			await this._updateRoute(itm, true)

			// Store defaultFeedInfo when the carousel is
			// loaded from a context with a parentEntity defined.
			// Used by FeatureGrid.vue
			this.defaultFeedInfo = defaultFeedInfo
		},

		// Actvate the carousel and start the transition animation.
		__activate() {
			this.isActive = true
			lockScroll(true)
		},

		// When you click on a thumbnail before it's loaded,
		// we display a loader on top and wait before we open the carousel.
		showLoader(callback) {
			// Position loader
			const targetPos = this.target.getBoundingClientRect()
			const x = targetPos.left + targetPos.width / 2 - 10
			const y = targetPos.top + targetPos.height / 2 - 10
			this.loaderPos = { x, y }

			// Display loader
			this.loadingFirstItm = true

			// Resolve
			this.target.addEventListener('load', onLoad.bind(this))
			function onLoad() {
				this.loadingFirstItm = false
				callback()
				this.target.removeEventListener('load', onLoad)
			}
		},

		// Updating the URL to refkect itm in view.
		// Only on init() do we do a router.push, all the
		// consecutive slides we use router.replace so we
		// don't overload the history state.
		_updateRoute(itm, push) {
			itm = itm || this.focusItm
			const itmRoute = itm.artistId
				? {
						name: 'Artwork',
						params: {
							namePath: itm.artistNamePath,
							titlePath: itm.titlePath,
							category: itm.category,
						},
				  }
				: {
						name: 'Image',
						params: {
							id: itm.id,
						},
				  }

			if (push) {
				// To push a history entry with the current route, we add a hash.
				// We need the history entry for the (un)lockRoute to work.
				itmRoute.hash = '#'

				this.$router.push(itmRoute)
			} else {
				// console.log('\nREPLACE route')
				this.$router.replace(itmRoute)
			}
		},

		// After the first image is loaded, we immediately load the rest.
		// We do this in two steps to make sure the carousel launches immediately.
		// Triggered from witin TheCarousel.vue.
		async loadRestOfFeed() {
			// console.log(22, 'loadRestOfFeed')

			if (this.total == 1) return

			// Abort if the carousel was closed before the first image was loaded.
			if (!this.isActive) return

			const feedStore = useFeedStore()
			if (feedStore.isEmpty) {
				// Carousel is opened from a non-feed page.
				// --> Fetch feed from the API.
				const feedData = await this.__loadFreshFeed()

				if (!feedData) return

				// Abort if the carousel was closed before
				// the server returned our results.
				if (!this.isActive) return

				const { itms, total } = feedData || {}
				this.total = total
				if (itms && this.total > 1) {
					this._insertFirstItms([...itms])
				}
			} else {
				// Carousel is opened from a feed page.
				// --> Transfer feed from feedStore.
				this.total = feedStore.total
				this.feedName = feedStore.feedName
				this._insertFirstItms([...feedStore.itms])
			}
			this.itmsLoaded = true

			// Only allow pagination once itms are loaded.
			this.readyForPagination = true

			// Preload batch
			// this.preloadFirstBatch() // Maybe later
			this._fillCarouselItms()

			// When opening one of the first images in a feed,
			// we need to fetch the endSlice separately after
			// transferring the main slice from the feedStore.
			if (!feedStore.isEmpty && !this.infinite && this.index < 7 && !this.itms[this.itms.length - 1]) {
				// When the index changes before the endSlice is inserted,
				// we need to offset the inserted itms or else the order
				// gets messed up.
				const indexBefore = this.index
				// console.time('A')
				// console.log(this.index)
				const endSliceItms = await this.__fetchEndSliceItms()
				// console.timeEnd('A')
				// console.log(this.index)
				const indexOffset = indexBefore - this.index
				// console.log('@', indexBefore, this.index)
				// console.log('Fetching end slice:', indexOffset, endSliceItms)
				this._insertItms(endSliceItms)
				this._fillCarouselItms(indexOffset)
			}

			// Load next page if needed.
			if (this.readyForNextPage) this._loadNextPage(true)
		},

		// When clicking on a thumbnail outside of the ArtGrid,
		// we need to load an entire new feed.
		async __loadFreshFeed() {
			// console.log('__loadFreshFeed')
			if (this.defaultFeedInfo && this.defaultFeedInfo.id) {
				const feedStore = useFeedStore()
				const feedData = await feedStore.loadFeedPage({
					entityType: this.defaultFeedInfo.entityType,
					id: this.defaultFeedInfo.id,
					beforeAndAfterId: this.itms[0].id,
				})
				return feedData
			}
			return null
		},

		// When the carousel is opened on the first 7 images,
		// we load the end slice and complete the loop
		// after the rest of the carousel is loaded.
		async __fetchEndSliceItms() {
			// console.log('_fetchEndSliceItms')

			// Load stores
			const apiStore = useApiStore()
			const feedStore = useFeedStore()

			// Load API
			const generalApi = await apiStore.loadApi('general')

			// Get id to load. If we're on a feed, we need to fetch the feed id.
			// Otheriwse, we need to fetch the artist id.
			const id = feedStore.id || this.itms[0].artistId

			// Load itms
			const { data: endSliceItms } =
				(await generalApi.getFeedEndSlice({
					entityType: feedStore.entityType,
					id,
				})) || {}

			return endSliceItms
		},

		// Transfer itms from itms to carouselItms.
		_fillCarouselItms(indexOffset) {
			// console.log('\n_fillCarouselItms', this.index, this.mid)

			let carouselItms
			if (this.total == 2) {
				// console.log('** 2')
				// Two itms
				if (this.index == 0) {
					const slides = [this.itms[1], this.itms[0], this.itms[1], this.itms[0]]
					carouselItms = [...Array(6), ...slides, ...Array(5)]
				} else if (this.index == 1) {
					const slides = [this.itms[0], this.itms[1], this.itms[0], this.itms[1]]
					carouselItms = [...Array(6), ...slides, ...Array(5)]
				}
			} else if (this.total <= 15) {
				// console.log('** <=15')
				// 3-15 Items
				// Offset = how far the first/last slides are from the center slide.
				const leftOffset = Math.floor((this.total - 1) / 2)
				const rightOffset = Math.ceil((this.total - 1) / 2) + 1
				const sliceStart = (this.total + this.index - leftOffset) % this.total
				const sliceEnd = (this.index + rightOffset) % this.total
				const slides = this.itms.slice(sliceStart).concat(this.itms.slice(0, sliceEnd))
				carouselItms = [...Array(this.blanksLeft), ...slides, ...Array(this.blanksRight)]
			} else if (this.index < this.mid) {
				// Feed start - insert endSlice
				// console.log('** Feed start')
				// Add end slice itms, or blank placeholders, to the front.
				const endSliceLength = this.mid - this.index
				const endSliceLoaded = this.itms[this.total - 1]
				const endSlice = endSliceLoaded ? this.itms.slice(endSliceLength * -1) : Array(endSliceLength)
				const beginSlice = this.itms.slice(0, this.index + this.mid + 1)
				carouselItms = endSlice.concat(beginSlice)

				// When we change the index (go to next or prev image)
				// before the endSlice is loaded, we need to offset the
				// new carouselItms with the number of pages we shifted.
				if (indexOffset) {
					carouselItms = carouselItms.slice(indexOffset).concat(carouselItms.slice(0, indexOffset))
				}
			} else if (this.index + this.mid + 1 > this.total) {
				// Feed end - insert frontSlice
				// console.log('** Feed end')
				// Fill the back of carouselItms with first itms.
				const frontSlice = this.itms.slice(0, (this.index + this.mid + 1) % this.total)
				const mainSlice = this.itms.slice(this.index - this.mid, this.total)
				carouselItms = mainSlice.concat(frontSlice)
			} else {
				// Regular transfer.
				// console.log('** Regular')
				carouselItms = this.itms.slice(this.index - this.mid, this.index + this.mid + 1)
			}

			// Fill the carousel without replacing the hero itm (7)
			// because that can cause a flash while refreshing the DOM.
			const leftSlice = carouselItms.slice(0, 7)
			const rightSlice = carouselItms.slice(8, 15)
			this.carouselItms.splice(0, 7, ...leftSlice)
			this.carouselItms.splice(8, 7, ...rightSlice)

			this._generateAllPlaceholders()
		},

		// Load the first batch of itms.
		// Finite feeds are filled up with blanks so every itm's position matches its index.
		_insertFirstItms(itms) {
			// console.log('_insertFirstItms')
			// console.log(itms.map(itm => itm.index).join('.'))

			// prettier-ignore
			const zeroIndex = itms.findIndex(itm => itm.index === 0)
			if (this.feedName && this.feedName.match(/^(all|staff-picks|staff-picks-random)$/)) {
				// Infinite feed
				this.itms = itms
				this.infinite = true
			} else if (zeroIndex > 0) {
				// [0,1,2,3,...,...,6,7,8,9]
				const sliceA = itms.slice(zeroIndex)
				const sliceB = itms.slice(0, zeroIndex)
				const blanks = this.total - itms.length
				this.itms = [...sliceA, ...Array(blanks), ...sliceB]
			} else {
				// [0,1,2,3,4,5,6,7,...,...]
				// [...,1,2,3,4,5,6,7,8,...]
				// Calculate the blanks to be inserted.
				const firstItmIndex = itms[0].index
				const lastItmIndex = itms[itms.length - 1].index
				const blanksLeft = firstItmIndex
				const blanksRight = this.total - lastItmIndex - 1
				this.itms = [...Array(blanksLeft), ...itms, ...Array(blanksRight)]
			}

			// Store lo-res src to display while hi-res image loads.
			this.__storeLoResSrc()
		},

		// Splice new itms into the existing itms array.
		// Front/end slices are separated beforehand so the itms never straddle end/front.
		_insertItms(newItms) {
			// console.log('_insertItms', newItms)
			// Consecutive inserts
			if (this.infinite) {
				// For infinite feeds like staff picks, we just append the results.
				this.itms = this.itms.concat(newItms)
			} else {
				// Finite feeds have an itms array that
				// matches the total number of itms, so we
				// splice in the newly loaded itms.
				// The array's length has aready been set, so we splice in new itms.
				const start = newItms[0].index
				const count = newItms.length
				this.itms.splice(start, count, ...newItms)
				// console.log('_insertItms', `${start}/${count}`, newItms.map(itm => itm.index || itm.title))
			}

			// Store lo-res src to display while hi-res image loads.
			this.__storeLoResSrc()

			// Preload batch.
			// this.preloadMoreImages(newItms)
		},

		// The carousel loads a lo-res version under every hi-res image,
		// to speed up image loading. If the image is available in the
		// DOM, we use that exact src, or else we just store the small version.
		async __storeLoResSrc() {
			this.itms.forEach(itm => {
				if (itm) {
					const domElm = document.querySelector(`img[data-id="${itm.id}"]`)
					let src
					if (domElm && domElm.currentSrc) {
						// Fetch preview image from DOM if it's already loaded.
						src = domElm.currentSrc
					} else {
						src = parseSrc({
							baseSrc: itm.views[0].src,
							imgSize: 'small',
						})
					}
					this.loRes[itm.index] = src
				}
			})
		},

		/**
		 * Actions
		 */

		// Close without resetting route:
		// Back button or whenever you click a link.
		async close(resetRoute) {
			// console.log('close', resetRoute)

			// Unlock route
			const routeStore = useRouteStore()
			await routeStore.unlockRoute(resetRoute)

			await nextTick()

			// Re-enable the router's scroll-to-top behavior.
			const sessionStore = useSessionStore()
			sessionStore.toggleMaintainScrollPos(false)

			// Close the carousel.
			this.removeTransitionImg()
			this.isActive = false
			this.$reset()
			lockScroll(false)
		},

		// Remove transition image
		removeTransitionImg() {
			const transitionImg = document.getElementById('img-transition')
			if (transitionImg) transitionImg.remove()
		},

		prev() {
			// console.log('prev')
			// if (this.processing) console.log('\n************************************************\n')
			if (this.processing) return
			this.processing = true

			if (!this.readyForPagination) {
				this.processing = false
				return
			}

			// When the loop is not connected and the endSlice hasn't
			// loaded yet, you can't go backwards from the first itm.
			if (this.index === 0 && !this.status.complete && !this.status.sliceB.length) {
				console.log("Beginning of non-looped feed, can't go back.")
				this.processing = false
				return
			}

			// When there's 15 itms or less.
			if (this.total <= 15) {
				this.__handlePrevMini()
				return
			}

			// Get carousel index for right most slide (which will be swapped out).
			const ref_mostRightSlide = this.ref_slides[14]
			const mostRightSlideCarouselIndex = Number(ref_mostRightSlide.getAttribute('data-ci'))

			// Get prev index to be loaded.
			let prevIndex = this.index - this.mid - 1

			// Stop at zero for infinite feeds, or loop around for finite feeds.
			prevIndex = this.infinite ? Math.max(-1, prevIndex) : (this.total + prevIndex) % this.total

			if (!this.itms[prevIndex]) {
				if (this.infinite) {
					this.ref_slidesWrap.prepend(ref_mostRightSlide)
					this._updateIndex()
				} else {
					// When you go really fast, it's possible
					// the prev page's itms didn't load yet.
					// console.log(`Requested prevIndex (${prevIndex}) not available.`)
					this.processing = false
				}
				return
			}

			// The use of requestAnimationFrame ensures
			// immediate interaction feedback.
			window.requestAnimationFrame(() => {
				// DOM manipulation: send last slide elm to front.
				this.ref_slidesWrap.prepend(ref_mostRightSlide)
				this.imgLoaders[mostRightSlideCarouselIndex] = true
				this.previewLoaders[mostRightSlideCarouselIndex] = true
				this.placeholderLoaders[mostRightSlideCarouselIndex] = true
				this.itmLoaders[mostRightSlideCarouselIndex] = true

				// Update index number
				this._updateIndex()

				// Wait until DOM update has been rendered before
				// continuing, this ensures snappy reaction.
				window.requestAnimationFrame(async () => {
					// Swap out image
					this.carouselItms[mostRightSlideCarouselIndex] = this.itms[prevIndex]

					// Remove loading as soon as the new itm has been rendered.
					nextTick(() => {
						this.itmLoaders[mostRightSlideCarouselIndex] = false
						// Note imgLoaders/previewLoaders/placeholderLoaders are being
						// disabled via @load event in TheCarousel.vue.
					})

					// Generate placeholder.
					this._generateOnePlaceholder(mostRightSlideCarouselIndex)

					// Load more itms if we're near the front.
					// prettier-ignore
					// console.log('readyForPrevPage: ', this.index, '<=', (this.status.sliceB[0] || this.status.sliceA[0]).index + 10)
					if (this.readyForPrevPage) this._loadPrevPage()

					// Update url
					this._updateRoute()
				})
			})

			// // When reaching the beginning of the feed,
			// // we don't need to refresh the thumbs.
			// if (!this.loop && this.relativeIndex < 8) {
			// 	// TODO: With loop loading this condition won't be necessary anymore?
			// 	this._updateIndex()
			// 	return
			// }
		},
		next() {
			// console.log('next')
			// if (this.processing) console.log('\n########################################\n')
			if (this.processing) return
			this.processing = true
			if (!this.readyForPagination) {
				this.processing = false
				return
			}

			// When there's 15 itms or less.
			if (this.total <= 15) {
				this.__handleNextMini()
				return
			}

			// Get carousel index for left most slide (which will be swapped out).
			const ref_mostLeftSlide = this.ref_slides[0]
			const mostLeftSlideCarouselIndex = Number(ref_mostLeftSlide.getAttribute('data-ci'))

			// Get next index to be loaded.
			const nextIndex = (this.index + 7 + 1) % this.total
			// console.log('nextIndex', nextIndex, this.index)

			// When you go really fast, it's possible
			// the next page's itms didn't load yet.
			if (!this.itms[nextIndex]) {
				console.log(`Requested nextIndex (${nextIndex}) not available.`)
				this.processing = false
				return
			}

			// The use of requestAnimationFrame ensures
			// immediate interaction feedback.
			window.requestAnimationFrame(() => {
				// DOM manipulation
				// Send first slide elm to back & activate loaders.
				this.ref_slidesWrap.append(ref_mostLeftSlide)
				this.imgLoaders[mostLeftSlideCarouselIndex] = true
				this.previewLoaders[mostLeftSlideCarouselIndex] = true
				this.placeholderLoaders[mostLeftSlideCarouselIndex] = true
				this.itmLoaders[mostLeftSlideCarouselIndex] = true

				// Update index number.
				this._updateIndex()

				// Wait until DOM update has been rendered before
				// continuing, this ensures snappy reaction.
				window.requestAnimationFrame(async () => {
					// Swap out image.
					this.carouselItms[mostLeftSlideCarouselIndex] = this.itms[nextIndex]

					// Remove loading as soon as the new itm has been rendered.
					nextTick(() => {
						this.itmLoaders[mostLeftSlideCarouselIndex] = false
						// Note imgLoaders/previewLoaders/placeholderLoaders are being
						// disabled via @load event in TheCarousel.vue.
					})

					// Generate placeholder.
					this._generateOnePlaceholder(mostLeftSlideCarouselIndex)

					// Load more itms if we're near the end.
					// prettier-ignore
					// console.log('readyForNextPage:', this.readyForNextPage, this.index, '>=', this.status.sliceA[this.status.sliceA.length - 1].index - 10)
					if (this.readyForNextPage) this._loadNextPage()

					// Update url
					this._updateRoute()
				})
			})
		},

		//
		//
		//

		// Once the index (pagination) comes closer than
		// 10 itms from the start of the endSlice, we load the next page.
		async _loadPrevPage(fillCarousel) {
			// console.log('_loadPrevPage')
			const feedStore = useFeedStore()
			this.loadingNewItms = true
			const beforeId = this.status.sliceB.length ? this.status.sliceB[0].id : this.status.sliceA[0].id
			// console.log('beforeId: ', this.status.sliceA[0])

			const data = await feedStore.loadPrevFeedPage({ beforeId, forCarousel: true })
			if (data) {
				const { itms: newItms } = data
				if (newItms.length) {
					const partA = newItms
					const zeroIndex = partA.findIndex(itm => itm.index === 0)
					const partB = zeroIndex > 0 ? partA.splice(zeroIndex) : null
					this._insertItms(partA)
					if (partB) this._insertItms(partB)
				}
			}
			this.loadingNewItms = false

			// Move itms to carouselItms only when we're
			// still initilalizing the carousel.
			if (fillCarousel) this._fillCarouselItms()
		},

		// Once the index (pagination) comes closer than
		// 10 itms from the end, we load the next page.
		async _loadNextPage(fillCarousel) {
			// console.log('_loadNextPage')
			const feedStore = useFeedStore()
			this.loadingNewItms = true
			const afterId = this.status.sliceA[this.status.sliceA.length - 1].id // For entity feeds
			const afterOId = this.status.sliceA[this.status.sliceA.length - 1]._id // For non-entity feeds
			this.batchNr++

			// const lastLoadedItm = this.status.sliceA[this.status.sliceA.length - 1]
			// // When we started the feed in the middle, and
			// // we've already loaded the feed until the end.
			// const backToStart = lastLoadedItm.index == this.total - 1
			// const afterId = backToStart ? null : lastLoadedItm.id
			// console.log({ backToStart })

			// console.log({ afterId })

			const data = await feedStore.loadNextFeedPage({
				afterId,
				afterOId,
				forCarousel: true,
				batchNr: this.batchNr,
			})
			if (data) {
				const { itms: newItms } = data
				if (newItms.length) {
					// console.log(newItms.map(itm => itm.titlePath))
					const partA = newItms
					const zeroIndex = partA.findIndex(itm => itm.index === 0)
					const partB = zeroIndex > 0 ? partA.splice(zeroIndex) : null
					this._insertItms(partA)
					if (partB) this._insertItms(partB)
					// this.itms.splice(partA[0].index, partA.length, ...partA)
					// if (partB) this.itms.splice(partB[0].index, partB.length, ...partB)
					// console.log({ zeroIndex, afterId })
					// prettier-ignore
					// console.log('A',partA.map(itm => itm.index))
					// prettier-ignore
					// console.log('B',partB ? partB.map(itm => itm.index) : null)
				}
			}
			this.loadingNewItms = false

			// Move itms to carouselItms only when we're
			// still initilalizing the carousel.
			if (fillCarousel) this._fillCarouselItms()
		},

		__handlePrevMini() {
			if (this.total == 1) {
				return
			} else if (this.total == 2) {
				const ref_mostRightSlide = this.ref_slides[9]
				const ref_blankSlides = [0, 1, 2, 3, 4, 5].map(i => this.ref_slides[i])
				this.ref_slidesWrap.prepend(ref_mostRightSlide)
				ref_blankSlides.forEach(blank => {
					this.ref_slidesWrap.prepend(blank)
				})
				this._updateIndex()
			} else if (this.total <= 15) {
				// Array eg. [0,1,2,3]
				const blankIndexes = Array.from(Array(this.blanksLeft).keys())

				// Manipulate DOM
				const ref_mostRightSlide = this.ref_slides[15 - this.blanksRight - 1]
				const ref_blankSlides = blankIndexes.map(i => this.ref_slides[i])
				this.ref_slidesWrap.prepend(ref_mostRightSlide)
				ref_blankSlides.forEach(blank => {
					this.ref_slidesWrap.prepend(blank)
				})
				this._updateIndex()
			}

			// Update url
			nextTick().then(this._updateRoute)
		},

		__handleNextMini() {
			if (this.total == 1) {
				return
			} else if (this.total == 2) {
				const ref_mostLeftSlide = this.ref_slides[6]
				const ref_blankSlides = [10, 11, 12, 13, 14].map(i => this.ref_slides[i])
				this.ref_slidesWrap.append(ref_mostLeftSlide)
				ref_blankSlides.forEach(blank => {
					this.ref_slidesWrap.append(blank)
				})
				this._updateIndex()
			} else if (this.total <= 15) {
				// Array eg. [11,12,13,14]
				const blankIndexes = Array.from(Array(this.blanksRight).keys()).map(
					i => i + this.blanksLeft + this.total
				)

				// Manipulate DOM
				const ref_mostLeftSlide = this.ref_slides[this.blanksLeft]
				const ref_blankSlides = blankIndexes.map(i => this.ref_slides[i])
				this.ref_slidesWrap.append(ref_mostLeftSlide)
				ref_blankSlides.forEach(blank => {
					this.ref_slidesWrap.append(blank)
				})
				this._updateIndex()
			}

			// Update url
			nextTick().then(this._updateRoute)
		},

		// Update pagination
		_updateIndex() {
			// console.log('_updateIndex')
			const ref_midSlide = this.ref_slides[this.mid]
			const index = Number(ref_midSlide.querySelector('img.art').getAttribute('data-index'))
			this.index = index
			this.processing = false
		},

		// Generate transparent image placeholders.
		_generateAllPlaceholders() {
			// console.log('_generateAllPlaceholders')
			this.imgPlaceholders = this.carouselItms.map(itm => {
				return itm ? imgPlaceholder({ view: itm.views[0], displaySize: this.displaySize }) : null
			})
		},

		_generateOnePlaceholder(carouselIndex) {
			// console.log('_generateOnePlaceholder', carouselIndex)
			const itm = this.carouselItms[carouselIndex]
			this.imgPlaceholders[carouselIndex] = imgPlaceholder({
				view: itm.views[0],
				displaySize: this.displaySize,
			})
		},

		// Manipulate any focu itm prop.
		setFocusItmProp(key, val) {
			this.focusItm[key] = val
		},

		// When you add the focus itm to a new room.
		addFocusItmActiveClusterIds({ listIds, roomIds }) {
			this.focusItm.listIds = this.focusItm.listIds ? this.focusItm.listIds.concat(listIds) : listIds
			this.focusItm.roomIds = this.focusItm.roomIds ? this.focusItm.roomIds.concat(roomIds) : roomIds
		},
		clearFocusItmActiveClusterIds() {
			delete this.focusItm.listIds
			delete this.focusItm.roomIds
		},

		/**
		 * Preloading
		 * - - -
		 * Currently not used because it doesn't seem to remove the image
		 * loading delay as expected. Should be investigated further as to
		 * if and how this could help speed up loading.
		 */

		preloadFirstBatch() {
			if (this.itms.length > 1) {
				preloadImages({
					itms: this.itms,
					imgSize: 'large',
				})
			}
		},

		preloadMoreImages(itms) {
			preloadImages({
				itms,
				imgSize: 'large',
			})
		},
	},
})
