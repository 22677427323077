<template>
	<NavSub :items="navItems" />
	<AuthLogin
		v-if="page == 'login'"
		v-model:email="email"
		:alreadyRegistered="alreadyRegistered"
		@alreadyRegistered="onAlreadyregistered"
		@cancel="onCancel"
		@success="onAuthSuccess"
	/>
	<AuthSignup
		v-if="page == 'signup'"
		v-model:name="name"
		v-model:email="email"
		:occupationDdOptions="occupationDdOptions"
		@goTo="goTo"
		@alreadyRegistered="onAlreadyregistered"
		@cancel="onCancel"
		@success="onAuthSuccess"
	/>
	<AuthWaitList
		v-if="page == 'wait-list'"
		v-model:name="name"
		v-model:email="email"
		:occupationDdOptions="occupationDdOptions"
		@cancel="onCancel"
	/>
</template>

<script>
// Stores
import { useDialogStore } from '@/stores/DialogStore'

// Components
import NavSub from '@/components/NavSub'
import AuthLogin from '@/views/Auth/AuthLogin'
import AuthSignup from '@/views/Auth/AuthSignup'
import AuthWaitList from '@/views/Auth/AuthWaitList'

// Helpers
import { queryObject } from '@/use/Helpers'

// Internal
import flash from '@/use/FlashAlert'

export default {
	name: 'AuthMain',
	components: {
		NavSub,
		AuthLogin,
		AuthSignup,
		AuthWaitList,
	},
	props: {
		isDialog: Boolean,
		landingPage: String, // What page to open the auth dialog on (login / signup / wait-list)
	},
	emits: ['exit'], // Triggers dialog onCancel
	setup() {
		const dialogStore = useDialogStore()
		return { dialogStore }
	},
	data() {
		return {
			// v-model values shared between forms.
			email: '',
			name: '',

			page: null, // Controls what tab you see.
			alreadyRegistered: false, // Controls message.

			// Translates between route names and page names
			translator: {
				Login: 'login',
				Signup: 'signup',
				WaitList: 'wait-list',
				//
				login: 'Login',
				signup: 'Signup',
				'wait-list': 'WaitList',
			},

			occupationDdOptions: [
				{
					text: "I'm an artist/designer",
					value: 'artist',
				},
				{
					text: 'I collect art/design',
					value: 'collector',
				},
				{
					text: 'I work in the art/design world',
					value: 'professional',
				},
				{
					text: 'I just love art/design',
					value: 'lover',
				},
			],
		}
	},
	computed: {
		navItems() {
			return [
				{
					display: 'Login',
					sel: this.page == 'login',
					onClick: () => {
						this.goTo('login')
					},
				},
				{
					display: 'Signup',
					sel: this.page == 'signup',
					onClick: () => {
						this.goTo('signup')
					},
				},
				{
					display: 'Wait List',
					sel: this.page == 'wait-list',
					onClick: () => {
						this.goTo('wait-list')
					},
				},
			]
		},
	},
	watch: {
		'$route.name'(newValue) {
			this.goToRoutePage(newValue)
		},
	},
	mounted() {
		this.page = this.landingPage
	},
	methods: {
		// Switch page as route changes.
		goToRoutePage(routeName) {
			routeName = routeName || this.$route.name
			this.page = this.translator[routeName]
		},

		// Go to page when link is clicked.
		goTo(page) {
			if (this.isDialog) {
				this.page = page
			} else {
				this.$router.replace({
					name: this.translator[page],
				})
			}
		},

		// When a user tries to sign up but is already registered.
		onAlreadyregistered(val) {
			this.alreadyRegistered = val
			if (val) this.goTo('login')
		},

		// onCancel
		onCancel() {
			if (this.isDialog) {
				this.$emit('exit')
			} else {
				this.$router.push({ name: 'Home' })
			}
		},

		// When your login or signup was successful.
		onAuthSuccess(name, isNewUser) {
			// Display welcome message.
			const welcomeMsg = isNewUser ? 'Welcome to Arthur' : 'Welcome back'
			flash(`${welcomeMsg}, ${name}.`)

			// Redirect or go to feed.
			const { redirect } = this.$route.query
			if (redirect) {
				const query = queryObject(redirect.split('?')[1])
				this.$router.push({ path: redirect, query })
			} else {
				// console.log('REDIRECT')
				this.$router.push({ name: 'Feed' })
			}

			// Close dialog.
			this.$emit('exit')
		},
	},
}
</script>

<style lang="scss" scoped>
// :deep() .wrap {
// 	width: 100%;
// }
</style>
