<template>
	<div class="wrap">
		<!-- Wrap needed for debug display -->
		<FormText
			v-model="data.value"
			:label="label"
			:placeholder="placeholder"
			helper="Help"
			:valid="data.valid"
			:error="theError"
			:disabled="disabled"
			@helperClick="showTips"
			@blur="onBlur"
		/>

		<DebugDisplay v-if="debug" :data="data" style="margin-top:0.05rem" />
	</div>
</template>

<script>
// Components
import FormText from '@/components/FormText'
import DebugDisplay from '@/components/DebugDisplay'

// Internal
import flash from '@/use/FlashAlert'
import displayYear from '@/use/DisplayYear'

export default {
	name: 'FormYear',
	props: {
		modelValue: Array,
		label: {
			type: String,
			default: 'Year',
		},
		placeholder: String,
		error: String,
		required: Boolean,
		disabled: Boolean,
		debug: {
			type: [Boolean, Number],
			default: false,
		},
	},
	emits: ['update:modelValue', 'update', 'blur', 'error'],
	components: {
		FormText,
		DebugDisplay,
	},
	data() {
		return {
			data: {
				value: displayYear(this.modelValue), // Where we store input value
				valid: null, // Sets the good/bad state
				error: null, // Error message
			},
		}
	},
	computed: {
		// v-model
		// Exposes year in array format on blur.
		theValue: {
			get() {
				return this.modelValue
			},
			set(val) {
				val = this.parseInput(val)
				this.$emit('update:modelValue', val)
				// if (val) this.data.value = displayYear(val)
			},
		},
		theError() {
			return this.error || this.data.error
		},
	},
	watch: {
		// As soon as the value is edited, we remove all (in)validated data.
		'data.value'() {
			this.clearError()
		},
		// Emit error whenever it changes.
		'data.error'(err) {
			this.$emit('error', err)
		},
		// Because v-model is connected to theValue but not to the data.value,
		// the input text needs to be explicitly updated whenever theValue changes.
		theValue(newValue) {
			if (this.data.valid !== false) this.data.value = displayYear(newValue)
		},
	},
	methods: {
		onBlur() {
			this.theValue = this.data.value
			this.$emit('update', this.theValue)
			this.$emit('blur')
		},

		// Parse input string into year array format: [1920, 1930, 1]
		parseInput(value) {
			// Allowed formats:
			// 1917

			// 1910-1920 // Any dash works
			// 1910 - 1920 // Any dash works

			// 19th century
			// 19th cent.
			// 19th c.

			// 100 AD
			// 100 A.D.
			// 100 A.D
			// 100 AD.

			// 100 BC
			// 100 B.C.
			// 100 B.C
			// 100 BC.

			// 100 BC-100 AD 0 // Any dash works
			// 100 BC - 100 AD 0 // Any dash works
			value = value ? value.trim() : null

			// Catch empty value.
			if (!value) {
				if (this.required) {
					// Show required
					this.data.valid = false
					this.data.error = 'Required'
				}
				return null
			}

			// Catch circa.
			const circa = !!value.match(/^(circa|ca|c)\.?\s*/i)
			if (circa) {
				value = value.replace(/^(circa|ca|c)\.?\s*/i, '')
				value = value.trim()
			}

			// Returns numbers array: [date] / [beginning, end] / false
			// 1000 BC would be [-1000], 20th century would be [1900, 2000]
			const output =
				_is0000(value) ||
				_isDecade(value) ||
				_is0000_0000(value) ||
				_isBCAD(value) ||
				_isBCAD_BCAD(value) ||
				_isNthCentury(value) ||
				_isNthCentury_nthCentury(value)

			if (output) {
				// Valid year output

				// Check if (beginning) date is not in the future
				const thisYear = new Date().getFullYear()
				const inFuture = output[0] ? output[0] > thisYear : false

				// Check if beginning and start date are not reversed
				if (output[1] < output[0]) output.reverse()

				// Check if it's not twice the same date
				const sameDate = output[0] && output[0] == output[1]

				// Add circa
				if (circa) output[2] = circa ? 1 : 0

				// Validate
				this.data.valid = output.length && !inFuture && !sameDate

				// Set error
				if (this.data.valid) {
					this.data.error = null
				} else if (sameDate) {
					this.data.error = 'Same Date'
				} else if (inFuture) {
					this.data.error = 'Future'
				}

				// Render result
				return this.data.valid ? output : null
			} else {
				// Invalid or empty year output.
				this.data.valid = false
				this.data.error = 'Invalid'
				return null
			}

			//
			//

			// Verify format: 1917
			function _is0000(value) {
				const isYear = value.match(/^\d{4}$/)
				return isYear ? [Number(value)] : false
			}

			// Verify format: 1960s
			function _isDecade(value) {
				let year = value.match(/^(\d{4})s$/)
				year = year ? Number(year[1]) : null
				if (year) {
					const isDecade = Math.round(year / 10) == year / 10
					if (isDecade) return [year, year + 10]
				}
				return false
			}

			// Verify format: 1910-1920 + variants
			function _is0000_0000(value) {
				const match = value.match(/^(\d{4})\s*(-|–|—|\/)\s*(\d{4})$/)
				return match ? [Number(match[1]), Number(match[3])] : false
			}

			// Verify format: 100 BC/AD + variants
			function _isBCAD(value) {
				const match = value.match(/^(\d{1,6})\s*((AD|BC)\.?$|A\.?\s*D\.?$|B\.?\s*C\.?$)/i)
				if (match) {
					const factor = match[2].replace(/\.|\s/g, '').toLowerCase() == 'bc' ? -1 : 1
					const outputValue = Number(match[1]) * factor
					return [outputValue]
				} else {
					return false
				}
			}

			// Verify format: 100 BC - 100 AD + variants
			function _isBCAD_BCAD(value) {
				let split = value.split('-') // dash
				if (split.length == 1) split = value.split('–') // m-dash
				if (split.length == 1) split = value.split('—') // l-dash
				if (split.length == 1) split = value.split('/') // slash
				if (split.length == 1) return false

				let matchA = split[0].trim()
				let matchB = split[1].trim()
				let matchA_BCAD = _isBCAD(matchA)
				let matchB_BCAD = _isBCAD(matchB)

				if (matchA_BCAD && matchB_BCAD) {
					// 100 AD-200 AD
					// 500 BC-500 AD
					return matchA_BCAD.concat(matchB_BCAD)
				} else if (matchB_BCAD) {
					// 100-200 AD
					// 100-200 BC
					let matchA_number = Number(matchA)
					const isBC = matchB_BCAD < 0
					if (isBC) matchA_number *= -1
					return matchB_BCAD.concat(matchA_number)
				}
			}

			// Verify format: 20th century / 5th century AD + variants
			function _isNthCentury(value) {
				const match = value.match(
					/^(1st|2nd|3rd|\d{1,2}th)\s(century|c\.|cent\.)(\s((AD|BC)\.?|A\.?\s*D\.?|B\.?\s*C\.?))?$/i
				)
				if (match) {
					const century = Number(match[1].replace(/st|nd|rd|th/, ''))
					const factor = match[3] && match[3].replace(/\.|\s/, '').toUpperCase() == 'BC' ? -1 : 1
					const outputValues = [(century - 1) * 100 * factor, century * 100 * factor]
					if (factor == -1) outputValues.reverse()
					return outputValues
				} else {
					return false
				}
			}

			// Very format: 16th-17th century / 16th century-17th century
			function _isNthCentury_nthCentury(value) {
				let split = value.split('-') // dash
				if (split.length == 1) split = value.split('–') // m-dash
				if (split.length == 1) split = value.split('—') // l-dash
				if (split.length == 1) split = value.split('/') // slash
				if (split.length == 1) return false

				let matchA = split[0].trim()
				let matchB = split[1].trim()
				let matchA_isNthCentury = _isNthCentury(matchA)
				let matchB_isNthCentury = _isNthCentury(matchB)

				if (matchA_isNthCentury && matchB_isNthCentury) {
					// 16th century-17th century
					// 16th century BC-17th century BC
					// 16th century-17th century BC
					// 16th century BC-17th century BC
					// We order the date ranges from low to high and return [earliest,latest]
					const dates = matchA_isNthCentury.concat(matchB_isNthCentury).sort((a, b) => a - b)
					dates.splice(1, 2)
					return dates
				} else if (matchB_isNthCentury) {
					// 16th-17th century
					// 16th-17th century BC
					matchA_isNthCentury = _isNthCentury(matchA + ' century')
					if (matchA_isNthCentury) {
						const dates = matchA_isNthCentury.concat(matchB_isNthCentury).sort((a, b) => a - b)
						dates.splice(1, 2)
						return dates
					} else {
						return false
					}
				}
			}
		},

		// Clear data
		clearError() {
			this.data.valid = null
			this.data.error = null
		},

		// Show formatting tips
		showTips() {
			let formats = [
				'1917',
				'1910-1920',
				'1920s',
				'ca. 1920',
				'500 BC',
				'500 AD',
				'100 BC-100 AD',
				'5th century',
				'5th century BC',
				'5th century AD',
				'16th-17th century',
				'3rd-4th century BC',
				'1st century BC - 1st century AD',
			]
			formats = formats.map(f => `<li>${f}</li>`).join('')
			const msg = `<h3>Year Formats</h3><ul>${formats}</ul>`
			flash(msg, { persistent: true, html: true, name: 'year-format' })
		},
	},
}
</script>

<style src="../assets/css/form-items.scss" lang="scss" scoped></style>
