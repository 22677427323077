<template>
	<div class="autocomplete-wrap" :class="{ 'with-label': !!label }">
		<FormText
			ref="input"
			v-model="theValue"
			:label="label"
			:error="theError"
			:valid="valid"
			:placeholder="placeholder"
			:maxlength="maxlength"
			:preventKeys="preventKeys"
			:margin="margin"
			:disabled="disabled"
			autocomplete="off"
			@keydown="onKeydown"
			@keyup="onKeyup"
			@focus="onFocus"
			@blur="onBlur"
			@paste="onPaste"
		/>
		<div class="tray-anchor" :class="{ show: showTray }">
			<FormSuggestions ref="tray" :items="suggestions || []" @click="selectResult" />
		</div>
	</div>
</template>

<script>
// Stores
import { useApiStore } from '@/stores/ApiStore'

// Components
import FormText from '@/components/FormText'
import FormSuggestions from '@/components/FormSuggestions'

// Internal
import FormItems from '@/mixins/mx_FormItems'
import { handleApiError, throttle } from '@/use/Helpers'

export default {
	name: 'FormAutocomplete',
	components: { FormText, FormSuggestions },
	mixins: [FormItems],
	props: {
		label: String,
		placeholder: {
			type: String,
			default: 'Search...',
		},
		entityType: {
			type: String,
			required: true,
		},
		disabled: Boolean,
		valid: {
			type: Boolean,
			default: null,
		},
		filter: Object, // { type: 'medium' }
		selectedValue: String,
		preventKeys: {
			type: String,
			default: '',
		},
		margin: Array,
		lowercase: {
			type: Boolean,
			default: false,
		},
		maxlength: [Number, String],
	},
	emits: ['select', 'create', 'blur', 'focus', 'keydown', 'keyup', 'paste', 'error'],
	setup() {
		const apiStore = useApiStore()
		const searchApi = apiStore.loadApi('search')
		return { searchApi }
	},
	data() {
		return {
			// List of suggestions
			suggestions: [],

			// Lets us toggle suggestions tray:
			showTray: false,

			// Block the tray so completing result
			// doesn't cause it to pop up again
			blockTray: false,

			// Throttle API errors
			throttler: throttle(handleApiError, 3000),
		}
	},
	watch: {
		theValue(newValue) {
			this.onValueChange(newValue)
		},
	},
	methods: {
		//
		// Show tray on focus
		onFocus(e) {
			this.showTray = true
			this.$emit('focus', e)
		},

		//
		// Blur actions
		onBlur(e) {
			// Making sure user is not selecting a result (enter/click)
			const trayInFocus = e.relatedTarget && e.relatedTarget == this.$refs.tray.getTray()
			if (trayInFocus) return

			// a) Exact match but no suggestion selected
			// b) Single match
			// --> select top match
			const topMatch = this.suggestions[0]
			const isExactMatch = topMatch && this.theValue == topMatch.value
			const singleMatch = this.suggestions.length == 2
			if (isExactMatch || singleMatch) {
				this.$emit('blur', true) // With item selected
				this.selectResult(topMatch)
				return
			}

			this.showTray = false
			this.$emit('blur', e, false) // With no item selected
		},

		//
		// Show suggestions as you type
		async onValueChange(newValue) {
			const value = this.lowercase ? newValue.toLowerCase() : newValue
			const limit = 6

			// When selecting a value (eg artist name) we update the input but this
			// triggers onValueChange again, causing the tray to pop up.
			// So if the input value matches the stored value, we hide the tray.
			// const noTray = newValue == this.selectedValue
			if (this.blockTray) {
				this.blockTray = false
				return
			}

			// Display suggestions after 2 characters.
			if (value.length >= 2) {
				// Load new suggestions from API.
				const { status, statusText, data } = await this.searchApi.getSearchResults(
					value,
					this.entityType,
					{ pageSize: limit }
				)
				const results = data ? data.results : []

				if (status == 200) {
					this.internalError = null
					this.$emit('error', null)
				} else {
					// Error
					const action = 'connecting to the server'
					this.throttler({ status, data: results, statusText, action, noFlash: true })
					//
					this.internalError = statusText
					this.$emit('error', this.internalError)
					return
				}

				// Show '+ Add <label>' when there's no exact match.
				const noExactMatch = !results.length || !results.find(result => result.value == this.theValue)
				const listFull = results.length == limit
				if (noExactMatch) {
					if (listFull) results.pop()
					results.push({
						display: `<div class="add-value">+ Add <mark class="x">${value}</mark></div>`,
					})
				}

				// When you delete text before the server sent back the suggestions,
				// we need to prevent trayLabel to be filled with outdated results.
				if (this.theValue) this.suggestions = results
			} else {
				this.suggestions = []
			}
		},

		//
		// Cycle through labels with UP/DOWN arrows
		async onKeydown(e) {
			// Select the right tray
			const suggestions = this.suggestions
			const tray = this.$refs.tray

			// Move selection
			const { key } = e
			if (key == 'ArrowDown') {
				if (suggestions && suggestions.length) {
					tray.moveSel(true)
					e.preventDefault()
				}
			} else if (key == 'ArrowUp') {
				if (suggestions && suggestions.length) {
					tray.moveSel(false)
					e.preventDefault()
				}
			}

			// Hide tray on Escape
			if (key == 'Escape') {
				this.clearSuggestions()
			}

			// Select result on Enter
			if (key.match(/^Enter|,|;$/)) {
				const selected = tray.getSelected()
				if (selected) this.selectResult(selected)
			}
			this.$emit('keydown', e)
		},

		onKeyup(e) {
			this.$emit('keyup', e)
		},

		onPaste(e) {
			this.$emit('paste', e)
		},

		//
		// Select suggestion (click or keyboard)
		selectResult(selected) {
			if (selected.id) {
				// Select existing
				this.$emit('select', selected)
				this.clearSuggestions()
			} else {
				// Create new dialog
				this.$emit('create')
				this.clearSuggestions()
			}

			this.blockTray = true
		},

		//

		//
		// Empty tray
		clearSuggestions() {
			this.suggestions = []
		},

		//
		//

		// External:
		// Remove label value and empty tray
		clearInput() {
			this.theValue = ''
		},
		select() {
			this.$refs.input.select()
		},
		focus() {
			this.$refs.input.focus()
		},
	},
}
</script>

<style src="../assets/css/form-items.scss" lang="scss" scoped></style>

<style scoped lang="scss">
.autocomplete-wrap {
	position: relative;
}
// Anchor for suggestions
.tray-anchor {
	width: 100%;
	position: absolute;
	left: 0;
	top: 0.45rem;
	display: none;
}
.with-label .tray-anchor {
	top: 0.65rem;
}
.tray-anchor.show {
	display: block;
}
</style>
