<template>
	<div class="act-search" :class="{ dark, inverse }" @click="onClick">
		<BaseIcon name="search" />
	</div>
</template>

<script>
// Stores
import { useSearchStore } from '@/stores/SearchStore'
import { useKeyStore } from '@/stores/KeyStore'

// Components
import BaseIcon from '@/components/BaseIcon'

export default {
	name: 'ActionSearch',
	components: { BaseIcon },
	props: {
		dark: Boolean,
		inverse: Boolean,
	},
	setup() {
		const searchStore = useSearchStore()
		const keyStore = useKeyStore()
		return { searchStore, keyStore }
	},
	methods: {
		onClick() {
			if (this.keyStore.mod) {
				window.open('/search/artist')
			} else {
				this.searchStore.activateOverlay()
				// this.$router.push({ name: 'Search' })
			}
		},
	},
}
</script>

<style scoped lang="scss">
.act-search {
	width: 0.5rem;
	height: 0.5rem;
	padding: 0.15rem;
	cursor: pointer;
}

// Dark & inverse version
.act-search.dark:deep() .icn,
.act-search.inverse:deep() .icn {
	fill: $white-75;
}

@media (hover: hover) {
	.act-search:hover:deep() .icn {
		fill: $primary;
	}

	// Inverse version
	.act-search.inverse:hover:deep() .icn {
		fill: $primary-inverse;
	}
}
</style>
