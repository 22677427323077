// SSR Setup
import { createSSRApp, createApp, h } from 'vue'
import { createHead } from '@vueuse/head' // Metadata
import { isSSR } from '@/use/Base'
import App from '@/App.vue'
import progressBar from '@aacassandra/vue3-progressbar'

// Internal Plugins
import globalVars from '@/plugins/globalVars'

// Syntax highlighting for docs
// Supported laguages: https://github.com/highlightjs/highlight.js/blob/main/SUPPORTED_LANGUAGES.md
import VueHighlightJS from 'vue3-highlightjs'
import '@/assets/css/code.scss'

// Global CSS
import '@/assets/css/fonts.scss'
import '@/assets/css/animations.scss'
import '@/assets/css/main.scss'
import '@/assets/css/main-layout.scss'

// Exported as createApp() via server-entry.js and client-entry.js
export default function(args) {
	const rootComponent = {
		render: () => h(App),
		components: { App },
	}

	// Create app
	const app = (isSSR ? createSSRApp : createApp)(rootComponent)

	// Create head
	const head = createHead()

	app.use(args.router)
	app.use(args.pinia)
	app.use(head)
	app.use(globalVars)
	app.use(VueHighlightJS)

	// See PageProgress mixin for implementation
	app.use(progressBar, {
		// color: '#00ff85',
		color: '#119965',
		failedColor: '#d00',
		thickness: '3px',
	})

	return {
		app,
		head,
	}
}
