<template>
	<!-- {{ responsive.mobile }} / {{ responsive.tablet }} / {{ responsive.bp1000 }} -->
	<!-- <BaseImageProfile
			v-if="pageUserStore.user && pageUserStore.user.isMe"
			:entity="pageUserStore.user"
			size="mini"
		/> -->
	<div id="action-buffer" :style="cssVars1"></div>
	<div id="float-actions" :class="{ hide }" style="color:white" :style="cssVars2">
		<div v-if="rightAlign" class="filler left" :class="{ hide: !rightAlign }"></div>
		<ActionLogo v-if="showLogo" :inverse="true" />
		<div class="filler middle" :class="{ hide: rightAlign }"></div>
		<ActionMenu v-if="menuOptions && $isLoggedIn" :options="menuOptions" :inverse="true" />
		<ActionClose v-if="closeBtn" :inverse="true" :inline="true" @click="$emit('close')" />
		<div v-if="!$isLoggedIn" class="btn-wrap">
			<FormButton
				:small="true"
				:level="3"
				value="Log in"
				@click="dialogStore.dialogByName('DialogAuth', { page: 'login' })"
			/>
			<!-- <FormButton
				:small="true"
				:level="1"
				value="Join"
				@click="dialogStore.dialogByName('DialogAuth', { page: 'wait-list' })"
			/> -->
		</div>
		<ActionSearch :inverse="true" />
	</div>
</template>

<script>
// Stores
import { usePageHomeStore } from '@/stores/PageHomeStore'
import { useWindowStore } from '@/stores/WindowStore'
import { useDialogStore } from '@/stores/DialogStore'

// Components
import ActionLogo from '@/components/ActionLogo'
import ActionMenu from '@/components/ActionMenu'
import ActionClose from '@/components/ActionClose'
import ActionSearch from '@/components/ActionSearch'
import FormButton from '@/components/FormButton'
// import BaseImageProfile from '@/components/BaseImageProfile'

export default {
	name: 'FloatActions',
	components: {
		ActionLogo,
		ActionMenu,
		ActionClose,
		ActionSearch,
		FormButton,
		// BaseImageProfile,
	},
	props: {
		rightAlign: Boolean, // Puts the logo with the search on the right
		menuOptions: Array, // { value, display, onSelect }
		closeBtn: Boolean,
		hideOnTop: Boolean, // Moves buttons to bottom (itm pages only)
		responsive: {
			type: Object,
			default: () => {
				return {
					mobile: 0.8,
					tablet: 0,
					bp1000: 0,
				}
			},
		},
	},
	emits: ['close'],
	setup() {
		const pageHomeStore = usePageHomeStore()
		const dialogStore = useDialogStore()
		return { pageHomeStore, dialogStore }
	},
	data() {
		return {
			hide: false, // Hides actions on itm page
			'--buffer1000': this.responsive.bp1000 ? this.responsive.bp1000 + 'rem' : null,
			'--bufferTablet': this.responsive.tablet ? this.responsive.tablet + 'rem' : null,
			'--bufferMobile': this.responsive.mobile ? this.responsive.mobile + 'rem' : null,
		}
	},
	computed: {
		isHomePage() {
			return this.$route.name.slice(0, 4) == 'Home'
		},
		showLogo() {
			if (this.isHomePage) {
				return this.pageHomeStore.sticky
			} else {
				return true
			}
		},
		cssVars1() {
			return {
				'--buffer1000': this['--buffer1000'],
				'--bufferTablet': this['--bufferTablet'] || this['--buffer1000'],
				'--bufferMobile': this['--bufferMobile'] || this['--bufferTablet'] || this['--buffer1000'],
			}
		},
		cssVars2() {
			// prettier-ignore
			return {
				'--flip1000Block': this.responsive.bp1000 ? 'block' : 'none',
				'--flip1000None': this.responsive.bp1000 ? 'none' : 'block',
				'--flipTabletBlock': this.responsive.tablet || this.responsive.bp1000 ? 'block' : 'none',
				'--flipTabletNone': this.responsive.tablet || this.responsive.bp1000 ? 'none' : 'block',
				'--flipMobileBlock': this.responsive.mobile || this.responsive.tablet || this.responsive.bp1000 ? 'block' : 'none',
				'--flipMobileNone': this.responsive.mobile || this.responsive.tablet || this.responsive.bp1000 ? 'none' : 'block',
			}
		},
	},
	created() {
		if (this.hideOnTop) {
			this.hide = true
			const windowStore = useWindowStore()
			windowStore.listen('scroll', this.onScroll, { throttle: 100 })
		}
	},
	methods: {
		onScroll() {
			this.hide = window.scrollY === 0
		},
	},
}
</script>

<style scoped lang="scss">
#action-buffer {
	height: 0;
	background: #eed;
}

#float-actions {
	// background: rgba(255, 255, 0, 0.1);
	mix-blend-mode: difference;
	position: fixed;
	top: 0.5rem;
	left: 0.5rem;
	right: 0.5rem;
	z-index: 2; // See App.vue for z-index coordination.
	height: 0.5rem;
	display: flex;
	align-items: center;
	pointer-events: none;
	transition: opacity calc(var(--animSpeed) * 0.5) linear;
}
#float-actions.hide {
	opacity: 0;
}

// Icon buttons
#float-actions .act-logo,
#float-actions .act-search,
#float-actions .act-menu,
#float-actions .act-close {
	flex: 0;
	pointer-events: auto;
	// background: blue;
}

// Fillers
#float-actions .filler {
	flex: 1;
}
#float-actions .filler.hide {
	display: none;
}

// Login / Join buttons
#float-actions .btn-wrap {
	// background: pink;
	// margin-right: 0.05rem;
	pointer-events: auto;
	filter: invert(1);
}
// #float-actions .btn-wrap > button:first-child {
// 	margin-right: 0.1rem;
// }

/**
 * Responsive
 */

@media only screen and (max-width: 1000px) {
	#action-buffer {
		margin-bottom: var(--buffer1000);
	}

	// Undo right alignment
	#float-actions .filler.hide:not(.left) {
		display: var(--flip1000Block);
	}
	#float-actions .filler.left {
		display: var(--flip1000None);
	}
}

@media only screen and (max-width: $tablet) {
	#action-buffer {
		margin-bottom: var(--bufferTablet);
	}

	// Undo right alignment
	#float-actions .filler.hide:not(.left) {
		display: var(--flipTabletBlock);
	}
	#float-actions .filler.left {
		display: var(--flipTabletNone);
	}
}

@media only screen and (max-width: $mobile) {
	#action-buffer {
		margin-bottom: var(--bufferMobile);
	}
	#float-actions {
		// margin: 0.2rem 0.2rem 0 0.2rem;
		top: 0.2rem;
		right: 0.2rem;
		left: 0.2rem;
	}

	// Undo right alignment
	#float-actions .filler.hide:not(.left) {
		display: var(--flipMobileBlock);
	}
	#float-actions .filler.left {
		display: var(--flipMobileNone);
	}
}
</style>
