<template>
	<!-- <pre style="position: fixed; top: 10px;z-index:100000">
@{{ routeOverride ? routeOverride.fullPath : '--' }}!</pre
	> -->
	<!-- <button @click="fullscreenStore.activate">on</button> -->
	<!-- <div style="padding: 40px">
		<button @click="searchStore.active = true">search</button>
		<br /><br /><br />
		<button @click="$router.push({ name: 'Search' })">search via router</button>
		<br /><br /><br />
		<button @click="$router.push({ name: 'About' })">reset</button>
	</div> -->
	<vue-progress-bar></vue-progress-bar>
	<OldBrowsers />
	<div id="page-wrap" :class="{ 'no-footer': hideFooter, blur: dialogIsActive }" :style="cssVars">
		<template v-if="httpErrorIsActive">
			<HttpError />
			<component :is="$isLoggedIn ? 'Feed' : 'HomePage'" v-if="httpErrorStore.status == 404" />
		</template>
		<SearchPage v-else-if="searchStore.overlay && !omitSearch" />
		<router-view ref="thePage" :route="routeOverride" />
	</div>
	<TheWelcome v-if="!$isLoggedIn && !httpErrorIsActive && $route.meta.showWelcome" />
	<TeaseButtons v-if="!$isLoggedIn" />
	<TheFooter v-else-if="!hideFooter && !httpErrorIsActive" :class="{ left: $route.meta.leftFooter }" />
	<TheNav v-if="$isLoggedIn && !hideNav && !carouselStore.isActive && !httpErrorIsActive" />
	<Carousel v-if="carouselStore.isActive" />
	<TheDialog v-if="dialogIsActive" />
	<TheFlashWrap v-if="flashIsActive" />
	<TheScrollBarDetection />

	<EnvDisplay v-if="!$env.isProd" />
	<DebugDevGuides v-if="!$env.isProd && showGuides" />
</template>

<script>
// Global Z-indexes:
// 35: HTTP Error					HttpError.vue
// 30: HTTP Error					HttpError.vue
// 25: Flash Message				TheFlashWrap.vue
// 20: Dialog						TheDialog.vue
// 19: Search						SearchPage.vue
// 12: Carousel buttons				TheCarousel.vue
// 11: Carousel transition image	TheCarousel.vue
// 10: Carousel						TheCarousel.vue
// 09: Carousel loader				TheCarousel.vue
// 05: Selection bar				TheSelectionBar.vue
// 04: Reselect btn / tease btns	TheartGrid.vue
// 02: Sticky nav / float actions	HomeSubNav.vue / FloatActions.vue
// 01: Collect icons				ArtThumb.vue

// Vue
import { useRouter } from 'vue-router'
import { computed } from 'vue'

// Stores
import { useApiStore } from '@/stores/ApiStore'
import { useSessionStore } from '@/stores/SessionStore'
import { useFeedStore } from '@/stores/FeedStore'
import { useKeyStore } from '@/stores/KeyStore'
import { useCarouselStore } from '@/stores/CarouselStore'
import { useHttpErrorStore } from '@/stores/HttpErrorStore'
import { useFullscreenStore } from '@/stores/FullscreenStore'
import { useDialogStore } from '@/stores/DialogStore'
import { useFlashStore } from '@/stores/FlashStore'
import { useSearchStore } from '@/stores/SearchStore'
import { useGlobalStore } from '@/stores/GlobalStore'
import { useMeStore } from '@/stores/MeStore'
import { useAuthStore } from '@/stores/AuthStore'

// Views
import HomePage from '@/views/HomePage'
import Feed from '@/views/Feed/FeedMain'
import SearchPage from '@/views/SearchPage'
import TheScrollBarDetection from '@/components/TheScrollBarDetection'

// Components
import OldBrowsers from '@/components/OldBrowsers'
import HttpError from '@/components/HttpError'
import TeaseButtons from '@/components/TeaseButtons'
import TheWelcome from '@/components/TheWelcome'
import TheFooter from '@/components/TheFooter'
import TheNav from '@/components/TheNav'
import Carousel from '@/components/TheCarousel'
import TheDialog from '@/components/TheDialog'
import TheFlashWrap from '@/components/TheFlashWrap'
import EnvDisplay from '@/components/EnvDisplay'
import DebugDevGuides from '@/components/DebugDevGuides'

// Internal
import { isSSR } from '@/use/Base'
import { injectMetaData } from '@/use/MetaData'
import { ItmActions } from '@/use/ItmActions'
import PageProgress from '@/mixins/mx_PageProgress'

export default {
	name: 'App',
	components: {
		HomePage,
		Feed,
		SearchPage,
		TeaseButtons,
		TheWelcome,
		TheFooter,
		TheScrollBarDetection,
		OldBrowsers,
		HttpError,
		TheNav,
		Carousel,
		TheDialog,
		TheFlashWrap,
		EnvDisplay,
		DebugDevGuides,
	},
	mixins: [PageProgress],
	provide() {
		/*
		Options API
			// Global variable
			this.$imperial

			// Root variable
			inject: ['$itmActions']
			this.$itmActions

		Composition API:
			// Global & root variables
			import { inject } from 'vue'
			const $imperial = inject('$imperial')
			const $itmActions = inject('$itmActions')
		*/
		return {
			// Global variables
			// (See globalVars.js plugin)
			$isLoggedIn: this.$isLoggedIn,
			$isAdmin: this.$isAdmin,
			$isSuperAdmin: this.$isSuperAdmin,
			$myUserId: this.$myUserId,
			$myUsername: this.$myUsername,
			$imperial: this.$imperial,
			$animSpeed: this.$animSpeed,
			//
			// Root variables
			$itmActions: new ItmActions(this.$pinia),
		}
	},
	setup() {
		// Trigger default meta data.
		injectMetaData()

		// Load stores
		const apiStore = useApiStore()
		const sessionStore = useSessionStore()
		const feedStore = useFeedStore()
		const carouselStore = useCarouselStore()
		const keyStore = useKeyStore()
		const httpErrorStore = useHttpErrorStore()
		const fullscreenStore = useFullscreenStore()
		const dialogStore = useDialogStore()
		const flashStore = useFlashStore()

		// The carousel and some dialogs (like login) will override
		// the route with lockedRoute, allowing the URL to be updated
		// without changing the page content.
		const router = useRouter()
		const globalStore = useGlobalStore()
		const routeOverride = computed(() => {
			const routeOverride = globalStore.lockedRoute ? router.resolve(globalStore.lockedRoute) : null
			// console.log(888, routeOverride, router.currentRoute.value.name)
			return routeOverride
		})

		// Load my data to the meStore.
		const searchStore = useSearchStore()
		const authStore = useAuthStore()
		const meStore = useMeStore()
		meStore.loadMyData(authStore.id)

		return {
			apiStore,
			sessionStore,
			feedStore,
			carouselStore,
			keyStore,
			httpErrorStore,
			fullscreenStore,
			dialogStore,
			flashStore,
			searchStore,
			routeOverride,
		}
	},
	data() {
		return {
			// When we select images in TheArtGrid, #page-wrap gets extra top margin
			// to make room for the selection bar when we're scrolled to top.
			selMode: 0,
			// Variables that we use in our CSS.
			cssVars: { '--animSpeed': this.$animSpeed + 'ms' },
			showGuides: false,
		}
	},
	computed: {
		hideFooter() {
			return this.$route.meta.hideFooter
		},
		hideNav() {
			return this.$route.meta.hideNav
		},
		omitSearch() {
			return ['Upload', 'Admin', 'Doc'].includes(this.$route.matched[0].name)
		},
		dialogIsActive() {
			return this.dialogStore.isActive
		},
		flashIsActive() {
			return this.flashStore.isActive
		},
		httpErrorIsActive() {
			return this.httpErrorStore.isActive
		},
	},
	mounted() {
		// Store user agent.
		this.sessionStore.storeUserAgent()

		// Enable key handling.
		this.keyStore.init()

		if (this.$env.isDev) {
			this.keyStore.addAll({
				// F key will launch fullscreen
				// Need to add shift
				// f: {
				// 	action: this.fullscreenStore.toggle,
				// },
				'*': {
					action: this.toggleGuides,
				},
			})
		}

		// Mark touch devices.
		window.addEventListener('touchstart', this._setIsTouch)

		// Apply global CSS variables
		Object.entries(this.cssVars).forEach(([key, val]) => {
			document.getElementById('app').style.setProperty(key, val)
		})

		if (!isSSR) {
			window.addEventListener('popstate', this.sessionStore.onPopState)
		}
	},
	unmounted() {
		if (this.$env.isDev) {
			this.keyStore.addAll({
				'*': {
					action: this.toggleGuides,
				},
			})
		}
	},
	// TRASH - this moved to The ArtGrid - to be tested
	// watch: {
	// 	// Resetting feed on every route change assures proper feed reloading.
	// 	$route(newValue, oldValue) {
	// 		// Within user collection, pages are kept alive, so we don't want to clear the feed store.
	// 		const withinUser =
	// 			oldValue.name.match(/^User/) &&
	// 			newValue.name.match(/^User/) &&
	// 			newValue.params.username == oldValue.params.username
	// 		if (withinUser) return

	// 		this.feedStore.$reset()
	// 	},
	// },
	methods: {
		_setIsTouch() {
			this.sessionStore.setIsTouch()
			window.removeEventListener('touchstart', this._setIsTouch)
		},
		toggleGuides() {
			this.showGuides = !this.showGuides
		},
	},
}
</script>

<style lang="scss" scoped></style>
