<template>
	<!-- Without transition -->
	<!-- Note 1: unnamed transition wrap causes a flash, so we
	have to render it without the transition html -->
	<template v-if="dialogStore.skipTransition">
		<div
			v-if="dialogStore.showShadow"
			id="shadow-box"
			@click.self="dialogStore.softClose"
			:class="{ 'flash-red': dialogStore.flashRed }"
			:style="cssVars"
		>
			<component
				v-if="dialogStore.showDialog"
				ref="dialogContent"
				:is="dialogStore.dialogName"
			></component>
		</div>
	</template>

	<!-- With transition -->
	<transition v-else name="fade" v-on:after-leave="dialogStore.deactivateDialog">
		<div
			v-if="dialogStore.showShadow"
			id="shadow-box"
			@click.self="dialogStore.softClose"
			:class="{ 'flash-red': dialogStore.flashRed }"
			:style="cssVars"
		>
			<transition name="pop">
				<component
					v-if="dialogStore.showDialog"
					ref="dialogContent"
					:is="dialogStore.dialogName"
				></component>
			</transition>
		</div>
	</transition>
</template>

<script>
// Vue
import { defineAsyncComponent } from 'vue'

// Stores
import { useKeyStore } from '@/stores/KeyStore'
import { useDialogStore } from '@/stores/DialogStore'

// Internal
import DialogAuth from '@/dialogs/DialogAuth'

export default {
	name: 'TheDialog',
	components: {
		DialogAuth, // We never want to wait for this
		DialogMain: defineAsyncComponent(() =>
			import(/* webpackChunkName: 'dialog-main' */ '@/dialogs/main/DialogMain')
		),
		DialogLabelCreate: defineAsyncComponent(() =>
			import(/* webpackChunkName: 'dialog-label-create' */ '@/dialogs/DialogLabelCreate')
		),
		DialogLabelTypes: defineAsyncComponent(() =>
			import(/* webpackChunkName: 'dialog-label-types' */ '@/dialogs/DialogLabelTypes')
		),
		DialogArtistCreate: defineAsyncComponent(() =>
			import(/* webpackChunkName: 'dialog-artist-create' */ '@/dialogs/DialogArtistCreate')
		),
		DialogClusterCreate: defineAsyncComponent(() =>
			import(/* webpackChunkName: 'dialog-cluster-create' */ '@/dialogs/DialogClusterCreate')
		),
		DialogB2Images: defineAsyncComponent(() =>
			import(/* webpackChunkName: 'dialog-b2-images' */ '@/dialogs/DialogB2Images')
		),
		DialogShare: defineAsyncComponent(() =>
			import(/* webpackChunkName: 'dialog-share-itm' */ '@/dialogs/DialogShare')
		),
		DialogSkipLine: defineAsyncComponent(() =>
			import(/* webpackChunkName: 'dialog-skip-line' */ '@/dialogs/DialogSkipLine')
		),
	},
	setup() {
		const keyStore = useKeyStore()
		const dialogStore = useDialogStore()
		return { keyStore, dialogStore }
	},
	data() {
		return {}
	},
	computed: {
		cssVars() {
			return this.dialogStore.params.width
				? { '--dialogWidth': this.dialogStore.params.width / 100 + 'rem' }
				: { '--dialogWidth': '2.5rem' }
		},
	},
	watch: {
		// When dialog is called from inside another dialog // Not currently used
		// 'dialogStore.dialogName'(newVal) {
		// 	if (newVal) this.open()
		// },
	},
	mounted() {
		// Add key actions
		// To do: maybe add this to the store? Would be cleaner to
		// disable these in the store than set the hacky ignoreEnterKey.
		this.keyStore.addAll({
			Escape: { action: this.dialogStore.softClose },
			Enter: { action: this.dialogStore.doConfirm },
		})
		this.dialogStore.transitionIn()
	},
	beforeUnmount() {
		// Remove key actions.
		this.keyStore.removeAll({
			Escape: { action: this.dialogStore.softClose },
			Enter: { action: this.dialogStore.doConfirm },
		})

		// Have to clear timeouts by all cost so there's
		// no reference to the app that's leaking into the JS memory.
		clearTimeout(this.dialogStore.flashRedTimeout)
		clearTimeout(this.dialogStore.showDialogTimeout)
	},
}
</script>

<style lang="scss" scoped>
// Shwdow box
#shadow-box {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 20; // See App.vue for z-index coordination.
	width: 100%;
	height: 100%;
	background-color: $black-30;
	display: flex;
	justify-content: center;
	align-items: center;
}
#shadow-box.flash-red {
	// easeInCubic
	animation: dialog-flash-red 500ms cubic-bezier(0.55, 0.055, 0.675, 0.19) 1;
}

// Dialog
#dialog {
	width: var(--dialogWidth);
	background: $bg;
	border-radius: $br;
	padding: 0.4rem;
	display: flex;
	flex-direction: column;
	box-sizing: content-box;
	max-width: calc(100vw - 1.2rem); // Includes the padding bc of box-sizing.
}
#dialog:deep() h2 {
	margin-top: -0.05rem;
}
#dialog:deep() > .wrap {
	// Form elements 100% width usually.
	// Relevant on mobile.
	width: 100%;
}

// Warning title
#dialog:deep() h3 span {
	color: $bad;
}

// Navigation
#dialog:deep() .subnav-wrap {
	height: 0.8rem;
}

// Transition: Pop dialog
.pop-enter-from,
.pop-leave-to {
	transform: translateY(0.05rem);
	opacity: 0;
}
.pop-enter-to,
.pop-leave-from {
	transform: translateY(0);
	opacity: 1;
}
.pop-enter-active {
	transition: all 150ms ease-in-out;
	transition-delay: 50ms;
}
.pop-leave-active {
	transition: all 150ms ease-in-out;
}

// Transition: Fade shadowBox
.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
	opacity: 1;
}
.fade-enter-active {
	transition: all 150ms ease-in-out;
}
.fade-leave-active {
	transition: all 150ms ease-in-out;
	transition-delay: 50ms;
}

@media only screen and (max-width: $mobile) {
	#dialog {
		// width: auto !important; // Override in-dialog CSS
		width: calc(100vw - 1.2rem) !important; // Override in-dialog CSS
		position: absolute;
		bottom: 0.1rem;
	}
}
</style>
